import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../../utils/api/API";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Checkbox from "../../../ui/checkbox";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface ModalProps {
  id?: string;
  setPopup: any;
  getCategories?: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
  restaurantBranchId?: any;
}

const AddCategoryPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const { id, setPopup, data, isEdit, restaurantBranchId } = props;

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(IDLE);

  const refresh = () => {
    window.location.reload();
  };

  const addCategory = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.branchId = restaurantBranchId;
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.menuCategory,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(
          getErrorMessage(err.response.data.message) ||
            getErrorMessage(err.response.data.message)
        );
        setStatus(ERROR);
      });
  };

  const updateCategory = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    var payload: any = {};

    if (request?.name !== data?.name) {
      payload = { ...payload, name: request?.name };
    }

    if (request?.isVisible !== data?.isVisible) {
      payload = { ...payload, isVisible: request?.isVisible };
    }

    if (request?.pos !== data?.pos) {
      payload = { ...payload, pos: request?.pos };
    }
    // request.branchId = restaurantBranchId;
    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.getMenuCategory(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateCategory(data) : addCategory(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, isVisible, pos } = data || {};
      setValue("name", name);
      setValue("isVisible", isVisible);
      setValue("pos", pos);
    }
  }, [data, isEdit]);

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Menu Category" : "Add Menu Category"}
            subTitle={
              isEdit
                ? "Enter the following details to update menu category"
                : "Enter the following details to add menu category"
            }
          />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Name"
              name="name"
              required
              placeholder="Enter Name"
            />
            {/* <Input
              register={register}
              label="Position"
              name="pos"
              placeholder="Enter Position"
              type="number"
              valueAsNumber
            /> */}
            <Checkbox
              register={register}
              label="Is Visible?"
              name="isVisible"
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Category" : "Add Category"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
    </>
  );
};

export default AddCategoryPopup;
