import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../ui";
import AddStoreBranchPopup from "./addStoreBranchPopup/AddStoreBranchPopup";
import StoreBranchCard from "./storeBranchCard/StoreBranchCard";
import { getMe } from "../../../helper";
import AddStoreImagePopup from "./addStoreImagePopup/AddStoreImagePopup";
import ViewStoreImagesPopup from "./viewStoreImagesPopup/ViewStoreImagesPopup";

const StoreSingle = () => {
  const Navigate = useNavigate();
  const [addStoreBranchPopup, setAddStoreBranchPopup] = useState(false);
  const [imagePopup, setImagePopup] = useState(false);
  const [viewPopup, setViewPopup] = useState(false);
  const navigate = useNavigate();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [user, setUser] = useState({
    role: "",
  });

  useEffect(() => {
    getMe(setUser, setIsUserLoading);
  }, []);

  useEffect(() => {
    if (user.role === "STORE_ADMIN") {
      navigate("/");
    }
  }, [user]);
  const { id } = useParams();

  return (
    <>
      <div className="order-single-page">
        <div className="page-header">
          <div>
            <span className="go-back" onClick={() => Navigate(-1)}>
              <img
                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                alt="arrow-back"
                className="arrow"
              />
              <h6>Go back</h6>
            </span>
            <h2>Store Branches</h2>
          </div>
          <div className="header-buttons">
            <Button
              text="Add Image"
              className="shop-add"
              onClick={() => setImagePopup(true)}
            />
            <Button
              text="View Images"
              className="shop-add"
              onClick={() => setViewPopup(true)}
            />

            <Button
              text="Add Store Branch"
              className="shop-add"
              onClick={() => setAddStoreBranchPopup(true)}
            />
          </div>
        </div>

        <div className="store-details">
          <StoreBranchCard storeId={id} />
        </div>
      </div>
      {addStoreBranchPopup && (
        <AddStoreBranchPopup setPopup={setAddStoreBranchPopup} storeId={id} />
      )}
      {imagePopup && <AddStoreImagePopup id={id} setPopup={setImagePopup} />}
    {viewPopup && <ViewStoreImagesPopup id={id} setPopup={setViewPopup} />}
    </>
  );
};

export default StoreSingle;
