import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import { Button } from "../../../../ui";
import Paginate from "../../../../ui/paginate";
import AddVoucherPopup from "./addVoucherPopup/AddVoucherPopup";
import { useNavigate } from "react-router-dom";
import VoucherTable from "../../../../ui/table/voucherTable/VoucherTable";
import API from "../../../../../utils/api/API";
import { getErrorMessage } from "../../../../../helper";
import Heading from "../../../../ui/heading";

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Vouchers"
      subTitle="See all your voucher and their details here"
    />
  );
};


const VoucherSettings = () => {
  const [voucherDetails, setVoucherDetails] = useState([]);
  const [popup, setPopup] = useState(false);

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedVoucher(data);
    setPopup(true);
  };

  const getVouchers = async () => {
    setVoucherDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.vouchers,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data?.data?.meta.totalItems)
        const formattedList = resp.data?.data?.vouchers?.map(
          (data: any, index: any) => {
            return {
              id: data?.id,
              name: data?.name,
              code: data?.code,
              type: data?.type,
              value: data?.value,
              cdMeanings: data?.cdMeanings,
              startDate: data?.startDate,
              endDate: data?.endDate,
              isUnique: data?.isUnique,
              useCount: data?.useCount,
              validOn: data?.validOn,
              isForDelivery: data?.isForDelivery,
            };
          }
        );
        setVoucherDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  const deleteVoucher = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getVouchers(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getVouchers();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getVouchers();
  }, [filter]);
  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className=""></div>
        <Button
          text="Add Voucher"
          className="customer-add"
          onClick={() => {
            setIsEdit(false);
            setPopup(true);
          }}
        />
      </div>
      <div className="table-wrapper">
        <VoucherTable
          data={voucherDetails}
          emptyHeight="300px"
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteVoucher={deleteVoucher}
          handleEditModal={handleEditModal}
          clickable
        />
      </div>
      {status !== LOADING && voucherDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddVoucherPopup
          getVouchers={getVouchers}
          setPopup={setPopup}
          data={selectedVoucher}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default VoucherSettings;
