import { useState } from "react";
import "./Switch.scss";

interface SwitchProps {
  className?: string;
  onChange?: Function;
  value?: any;
  id?: string;
  textOff?: string;
  textOn?: string;
  bgColor?: string;
  width?: string;
  height?: string;
  innerWidth?: string;
  circleLeft?: string;
  circleRight?: string;
  setPopup?: any;
}

const Switch = (props: SwitchProps) => {
  const {
    className,
    onChange,
    value,
    id,
    textOff,
    textOn,
    setPopup,
    bgColor,
    width,
    height,
    innerWidth,
    circleLeft,
    circleRight,
  } = props;
  // const [isOff, setIsOff] = useState(!value);

  const handleChange = (event: any) => {
    event.preventDefault();
    // setIsOff(!value);
    onChange?.(value);
    setPopup(true);
  };

  return (
    <>
      <div
        className={`switch-button ${className} ${
          !value ? "switch-button__off" : ""
        }`}
        onClick={handleChange}
        id={id}
        style={{
          backgroundColor: bgColor ? bgColor : "#FD5C1D",
          border: bgColor ? `1px solid ${bgColor}` : "1px solid #FD5C1D",
          width: width && width,
          height: height && height,
        }}
      >
        <div
          className={value ? "text off" : "text"}
          style={{ color: bgColor ? bgColor : "#FD5C1D" }}
        >
          {value ? (textOff ? textOff : "ON") : textOn ? textOn : "OFF"}
        </div>
        <div
          className={value ? "circle right" : "circle left"}
          style={{
            // backgroundColor: bgColor ? bgColor : "#FD5C1D",
            border: bgColor ? `1px solid ${bgColor}` : "1px solid #FD5C1D",
            height: innerWidth && innerWidth,
            right: value && circleRight,
            background: value && bgColor ? "#ffffff" : bgColor,
          }}
        ></div>
      </div>
    </>
  );
};

export default Switch;
