import Heading from "../../ui/heading";

const PageHeading = () => {
  return (
    <Heading
      title="Vendors"
      subTitle="See and manage your vendors information here"
    />
  );
};

const Vendors = () => {
  return (
    <>
      <PageHeading />
    </>
  );
};

export default Vendors;
