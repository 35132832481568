import { useState, useEffect } from "react";
import { getStores } from "../../../helper";

interface IFilterProps {
  filterClicked: any;
  setFilterClicked: any;
  setIsClearFilter?: any;
}

const ProductFilter = (props: IFilterProps) => {
  const {
    filterClicked,
    setFilterClicked,
    setIsClearFilter,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const { orderFilter, setOrderFilter } = useContext(filterContext);

  const [dropdown, setDropdown] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState(false);

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const handleClicked = (index: any, item: any) => {
    if (filterClicked === index) {
      setIsClearFilter(false);
      setFilterClicked(item);
      // setOrderFilter(item[0]);
      // setDropdown(false);
    }
    setIsClearFilter(false);
    setFilterClicked(item);
    // setOrderFilter(item);
    // setDropdown(false);
  };

  const [filterMap, setFilterMap] = useState<any>([]);
  const [isStoreLoading, setIsStoreLoading] = useState(false);

  useEffect(() => {
    getStores(setFilterMap, setIsStoreLoading);
  }, []);

  return (
    <div className="order-filters">
      <div className="order-filter">
        <div className="left-div" style={{width:"200px !important"}}>
          <div
            className="filter dropdown"
            onClick={() => setStatusDropdown(!statusDropdown)}
          >
            <h6>Filter By Store</h6>
            <img
              className="arrow-down"
              src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow-down_zvrlww.png"
            />
          </div>
          {statusDropdown && (
            <div className="dropdown-option wider" style={{width:"200px !important"}}>
              {filterMap.map(
                (item: any, index: any) => (
                  <div
                    className="dropdown-list"
                    key={index}
                    onClick={() => handleClicked(index, item.value)}
                  >
                    <h6>{item.label}</h6>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="rightt-div" onClick={() => setIsClearFilter(true)}>
        <h6>Clear Filter</h6>
      </div>
    </div>
  );
};

export default ProductFilter;
