import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { getToken } from "../../../../storage";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Select from "../../../ui/select";
import { stateDetails } from "../../../../data/city";
import Alert from "../../../ui/alert";
import PlaceAutoComplete from "../../../ui/placeAutocomplete/PlaceAutoComplete";
import { defaultOption, getErrorMessage } from "../../../../helper";
import API from "../../../../utils/api/API";
import "./AddStoreBranch.scss";
import CaretRight from "../../../../assets/caret-right.svg";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  storeId?: any;
  getStoreBranch?: any;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddStoreBranchPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const { setPopup, storeId, getStoreBranch, data, isEdit, selectedId } = props;

  const option = stateDetails.map((state) => {
    return {
      label: state.label,
      value: state.value,
    };
  });

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [timeSettings, setTimeSettings] = useState(false);

  const addStoreBranch = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.coords = [latLng.lat, latLng.lng];
    const payload = {
      name: data?.name,
      email: data?.email,
      address: data?.address,
      slackChannel: data?.slackChannel,
      coords: [latLng.lat, latLng.lng],
      timeSettings: {
        SUNDAY: {
          openingTime: data?.sundayOpeningTime,
          closingTime: data?.sundayClosingTime,
        },
        MONDAY: {
          openingTime: data?.mondayOpeningTime,
          closingTime: data?.mondayClosingTime,
        },
        TUESDAY: {
          openingTime: data?.tuesdayOpeningTime,
          closingTime: data?.tuesdayClosingTime,
        },
        WEDNESDAY: {
          openingTime: data?.wednessdayOpeningTime,
          closingTime: data?.wednessdayClosingTime,
        },
        THURSDAY: {
          openingTime: data?.thursdayOpeningTime,
          closingTime: data?.thursdayClosingTime,
        },
        FRIDAY: {
          openingTime: data?.fridayOpeningTime,
          closingTime: data?.fridayClosingTime,
        },
        SATURDAY: {
          openingTime: data?.saturdayOpeningTime,
          closingTime: data?.saturdayClosingTime,
        },
      },
    };

    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.addStoreBranch(storeId),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message))
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const updateStoreBranch = (request: any) => {
    setStatus(LOADING);
    setMessage("");

    request.coords = [latLng.lat, latLng.lng];
    (request.coords[0] === null ||
      request.coords[1] === null ||
      request.coords === undefined ||
      request.coords === null) &&
      (request.coords = data.coordinates);

    const payload = {
      name: request?.name,
      email: request?.email,
      address: request?.address,
      slackChannel: request?.slackChannel,
      coords: [latLng.lat, latLng.lng],
      timeSettings: {
        SUNDAY: {
          openingTime: request?.sundayOpeningTime,
          closingTime: request?.sundayClosingTime,
        },
        MONDAY: {
          openingTime: request?.mondayOpeningTime,
          closingTime: request?.mondayClosingTime,
        },
        TUESDAY: {
          openingTime: request?.tuesdayOpeningTime,
          closingTime: request?.tuesdayClosingTime,
        },
        WEDNESDAY: {
          openingTime: request?.wednessdayOpeningTime,
          closingTime: request?.wednessdayClosingTime,
        },
        THURSDAY: {
          openingTime: request?.thursdayOpeningTime,
          closingTime: request?.thursdayClosingTime,
        },
        FRIDAY: {
          openingTime: request?.fridayOpeningTime,
          closingTime: request?.fridayClosingTime,
        },
        SATURDAY: {
          openingTime: request?.saturdayOpeningTime,
          closingTime: request?.saturdayClosingTime,
        },
      },
    };

    payload.coords = [latLng.lat, latLng.lng];
    (payload.coords[0] === null ||
      payload.coords[1] === null ||
      payload.coords === undefined ||
      payload.coords === null) &&
      (payload.coords = data.coordinates);

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getStoreBranch(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getStoreBranch();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    if (isEdit) {
      updateStoreBranch(data);
    } else {
      if (latLng.lat === null && latLng.lng === null) {
        setStatus(ERROR);
        setMessage("Location is required");
      } else {
        addStoreBranch(data);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      const {
        name,
        email,
        minOrderQty,
        maxOrderQty,
        minOrderAmount,
        maxOrderAmount,
        minDeliveryTime,
        maxDeliveryTime,
        address,
        slackChannel,
        mapLocation,
        sundayClosingTime,
        sundayOpeningTime,
        mondayClosingTime,
        mondayOpeningTime,
        tuesdayClosingTime,
        tuesdayOpeningTime,
        wednesdayClosingTime,
        wednesdayOpeningTime,
        thursdayClosingTime,
        thursdayOpeningTime,
        fridayClosingTime,
        fridayOpeningTime,
        saturdayClosingTime,
        saturdayOpeningTime,
      } = data || {};
      setValue("name", name);
      setValue("email", email);
      setValue("minOrderQty", minOrderQty);
      setValue("maxOrderQty", maxOrderQty);
      setValue("minOrderAmount", minOrderAmount);
      setValue("maxOrderAmount", maxOrderAmount);
      setValue("minDeliveryTime", minDeliveryTime);
      setValue("maxDeliveryTime", maxDeliveryTime);
      setValue("address", address);
      setValue("slackChannel", slackChannel);
      setValue("mapLocation", mapLocation);
      setValue("sundayClosingTime", sundayClosingTime);
      setValue("sundayOpeningTime", sundayOpeningTime);
      setValue("mondayClosingTime", mondayClosingTime);
      setValue("mondayOpeningTime", mondayOpeningTime);
      setValue("tuesdayClosingTime", tuesdayClosingTime);
      setValue("tuesdayOpeningTime", tuesdayOpeningTime);
      setValue("wednessdayClosingTime", wednesdayClosingTime);
      setValue("wednessdayOpeningTime", wednesdayOpeningTime);
      setValue("thursdayClosingTime", thursdayClosingTime);
      setValue("thursdayOpeningTime", thursdayOpeningTime);
      setValue("fridayClosingTime", fridayClosingTime);
      setValue("fridayOpeningTime", fridayOpeningTime);
      setValue("saturdayClosingTime", saturdayClosingTime);
      setValue("saturdayOpeningTime", saturdayOpeningTime);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Store Branch" : "Add Store Branch"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Store Branch"
                : "Enter the following details to add a Store Branch"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Branch Name"
              name="name"
              required
              placeholder="Enter Branch Name"
            />
            <Input
              register={register}
              label="Email"
              name="email"
              required
              placeholder="Enter Email"
            />
            <Input
              register={register}
              label="Address"
              name="address"
              required
              placeholder="Enter Address"
            />
            <Input
              register={register}
              label="Slack Channel"
              type="text"
              name="slackChannel"
              // required
              placeholder="Enter Slack Channel"
            />
            <PlaceAutoComplete
              setLatLng={setLatLng}
              inputLabel="Location"
              placeholder="Enter your location"
            />

            <div className="branch-time-setting">
              <div
                className="time-setting-heading"
                onClick={() => setTimeSettings(!timeSettings)}
              >
                <img
                  src={CaretRight}
                  alt="arrow"
                  style={{
                    transform: `${
                      timeSettings ? "rotate(90deg)" : "rotate(0deg)"
                    }`,
                  }}
                />
                <p className="type2">Time Setting</p>
              </div>
              {timeSettings && (
                <div className="time-settings-body">
                  <div className="time-settings-row">
                    <p className="type3">Sunday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        label="Opening Time"
                        name="sundayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        label="Closing Time"
                        name="sundayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Monday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="mondayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="mondayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Tuesday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="tuesdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="tuesdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Wednessday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="wednessdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="wednessdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Thursday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="thursdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="thursdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Friday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="fridayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="fridayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Saturday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="saturdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="saturdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Button
              className="add-button"
              text={isEdit ? "Update Store Branch" : "Add Store Branch"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a store to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Store Branch"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddStoreBranchPopup;
