import { useNavigate, useParams } from "react-router-dom";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import { useEffect, useState } from "react";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import CardLoader from "../../../../ui/cardLoader";
import EmptyView from "../../../../ui/emptyView";
import ErrorView from "../../../../ui/ErrorView";
import Layout from "../../../../ui/layout";
import { confirmAlert } from "react-confirm-alert";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../../../assets/more.svg";
import ViewIcon from "../../../../../assets/view.svg";
import EditIcon from "../../../../../assets/edit.svg";
import DeleteIcon from "../../../../../assets/delete.svg";
import AddProductBrand from "../addProductBrand/AddProductBrand";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const GSAProductSinglePage = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [subStatus, setSubStatus] = useState(IDLE);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productPopup, setProductPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryProductList, setCategoryProductList] = useState<any>();
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [pageData, setPageData] = useState<any>({ media: [] });

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const { id } = useParams();

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.category.id": id,
    isSortByCategory: true,
    isAdmin: true,
  };

  const handleProductEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setProductPopup(true);
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  const getSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getGsaProduct(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setPageData(resp.data?.content);
        Object.keys(resp.data?.content).length === 0
          ? setStatus(NULLMODE)
          : setStatus(DATAMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting data");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getSingleData();
  }, []);

  const refresh = () => {
    window.location.reload();
  };
  const categoryDetails = [
    {
      key: "Name",
      value: `${pageData?.name}`,
      className: "",
      image: `${
        pageData?.picture === null
          ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
          : pageData?.media[0]?.path
      }`,
    },
    {
      key: "Description",
      value: `${pageData?.description}`,
      className: "",
    },
    {
      key: "Quantity",
      value: `${pageData?.quantity}`,
      className: "",
    },
  ];

  const handleEditProduct = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setPopup(true);
  };

  const deleteBrand = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.updateGsaProductBrand(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data?.message);
        getSingleData();
      })
      .catch((err) => {
        setStatus(DATAMODE);
        toast.error(err.response?.data?.message);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's nothing here presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="store-branch-details">
              {categoryDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {pageData?.brands?.length > 0 && (
              <div className="table-details" style={{ marginTop: "24px" }}>
                <div className="table-details-body">
                  <div
                    className="details-table-header"
                    style={{ width: "100%" }}
                  >
                    <p style={{ width: "5%" }}>Image</p>
                    <p style={{ width: "85%" }}>Brands Name</p>
                    <p style={{ width: "10%", textAlign: "center" }}>Action</p>
                  </div>
                  {pageData?.brands?.length > 0 &&
                    pageData?.brands?.map((item: any, index: any) => (
                      <div className="details-table-body" key={index}>
                        <div
                          style={{
                            width: "5%",
                          }}
                        >
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              backgroundColor: "black",
                            }}
                          >
                            <img
                              src={item?.media[0]?.path}
                              alt={item?.name}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <p style={{ width: "85%" }}>{item?.name}</p>
                        <Popover
                          className="flex-1 text-right"
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          <>
                            <Popover.Button
                              className="popover-button"
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                              }}
                            >
                              <img src={MoreIcon} alt="" />
                            </Popover.Button>

                            <Popover.Panel
                              className="popover-panel"
                              style={{
                                position: "absolute",
                                right: "64px",
                                zIndex: "500",
                                // padding: "14px",
                                width: "150px",
                                background: "white",
                                borderRadius: "16px",
                                boxShadow: "5px 5px #00000030",
                                border: "0.5px solid #00000030",
                              }}
                            >
                              <div
                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                style={{ borderRadius: "16px" }}
                              >
                                <div
                                  className="items-center grid gap-4 bg-white px-5 py-4"
                                  style={{
                                    alignItems: "center",
                                    background: "white",
                                    padding: "16px, 20px",
                                  }}
                                >
                                  <div
                                    onClick={() => handleEditProduct(item)}
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img src={ViewIcon} alt="" />
                                    <p className="pl-2 text-[#B00020]">
                                      Edit Brand
                                    </p>
                                  </div>
                                  <div
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                    onClick={() => {
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to delete this brand?",
                                        message: "This brand will be deleted",
                                        buttons: [
                                          {
                                            label: "Cancel",
                                            // Do Close operation
                                          },
                                          {
                                            label: "Delete Brand",
                                            onClick: () =>
                                              deleteBrand(item?.id),
                                          },
                                        ],
                                      });
                                    }}
                                  >
                                    <img src={DeleteIcon} alt="" />
                                    <p className="pl-2 text-[16px]">
                                      Delete Brand
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </>
                        </Popover>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <Layout>
        <div className="order-single-page">
          <div className="page-header">
            <div style={{ width: "100%" }}>
              <span className="go-back" onClick={() => Navigate(-1)}>
                <img
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                  alt="arrow-back"
                  className="arrow"
                />
                <h6>Go back</h6>
              </span>
              <div className="page-header">
                <h2>GSA Product Details</h2>
              </div>
            </div>
          </div>
          {renderBasedOnStatus()}
        </div>
      </Layout>
      {popup && (
        <AddProductBrand
          getProducts={getSingleData}
          setPopup={setPopup}
          data={selectedProduct}
          isEdit={isEdit}
          // selectedId={selectedId}
        />
      )}
    </>
  );
};

export default GSAProductSinglePage;
