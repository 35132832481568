import OrderSingle from "../components/dashboard/orderSingle";
import Layout from "../components/ui/layout";

const OrderSinglePage = () => {
  return (
    <Layout>
      <OrderSingle />
    </Layout>
  );
};

export default OrderSinglePage;
