import Store from '../components/dashboard/store'
import Layout from '../components/ui/layout'

const StorePage = () => {
  return (
    <Layout>
        <Store />
    </Layout>
  )
}

export default StorePage