import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import CardLoader from "../../../ui/cardLoader";
import EmptyView from "../../../ui/emptyView";
import ErrorView from "../../../ui/ErrorView";
import "./StoreBranchCard.scss";
import API from "../../../../utils/api/API";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

interface ModalProps {
  storeId: any;
}

const StoreBranchCard = (props: ModalProps) => {
  const { storeId } = props;
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [storeBranchDetails, setStoreBranchDetails] = useState([
    {
      id: "",
      name: "",
      details: "",
    },
  ]);

  const Navigate = useNavigate();
  const handleClick = (id: any) => {
    Navigate(`/store-branches/${id}`);
  };

  const refresh = () => {
    window.location.reload();
  };

  const filter = {
    "filter.store.id": storeId,
  };

  const getStoreBranch = async () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.storeBranch,
      params: filter
    })
      .then((resp) => {
        const formattedList = resp.data?.data.storeBranches.map((data: any, index: any) => {
          return {
            id: data.id,
            name: data.name,
            details: data.description,
          };
        });
        setStoreBranchDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  useEffect(() => {
    getStoreBranch();
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no store branch presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="cards">
            {storeBranchDetails?.map((data, index) => {
              const { name, id } = data;
              return (
                <div
                  className="branch-card"
                  onClick={() => handleClick(id)}
                  key={index}
                >
                  <div className="head">
                    <img
                      src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png"
                      alt="store-branch"
                    />
                  </div>
                  <h3>{name}</h3>
                </div>
              );
            })}
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return <>{renderBasedOnStatus()}</>;
};

export default StoreBranchCard;
