import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import { Button, Input } from "../../ui";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../ui/alert";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getErrorMessage, handlePrice } from "../../../helper";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  getCategories?: Function;
  data?: any;
  isOrderSide?: boolean;
  selectedId?: string;
  categoryId?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const RefundingPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const { setPopup, getCategories, data, isOrderSide, categoryId, selectedId } =
    props;

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const processRefund = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    const refundData = isOrderSide
      ? { orderId: data?.id, amount: parseFloat(request.amount) * 100 }
      : { orderId: data?.order?.id, amount: parseFloat(request.amount) * 100 };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.processRefund,
      data: refundData,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    processRefund(data);
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={"Process Refund"}
            subTitle={"Enter the following details to process a refund"}
          />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="amount"
              control={control}
              defaultValue={
                isOrderSide ? data?.total / 100 : data?.amount / 100
              }
              rules={{
                required: true,
                validate: (value) => {
                  const parsedValue = parseFloat(value);
                  return (
                    !isNaN(parsedValue) &&
                    parsedValue >= 1 &&
                    parsedValue <=
                      (isOrderSide ? data?.total / 100 : data?.amount / 100)
                  );
                },
              }}
              render={({ field }) => (
                <div>
                  <Input
                    register={register}
                    label={`Amount (Can not be greater than ${handlePrice(
                      isOrderSide ? data?.total : data?.amount
                    )})`}
                    id="amount"
                    placeholder="Enter Amount"
                    type="number"
                    hasError={errors?.amount ? true : false}
                    {...field}
                    errorMsg={`Enter a valid amount not greater than ${handlePrice(
                      isOrderSide ? data?.total : data?.amount
                    )}`}
                  />
                </div>
              )}
            />
            <Button
              className="add-button"
              text={"Process Refund"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully process a refund for this transaction."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Refunding this"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default RefundingPopup;
