export const ADD_FEE_FIELD = [
  {
    name: "name",
    type: "text",
    label: "Name",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Name",
  },
  {
    name: "priceInMinor",
    type: "number",
    label: "Price",
    required: true,
    valueAsNumber: true,
    placeholder: "Enter Price",
  },
];
