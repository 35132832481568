import { Controller, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import Search from "../../../ui/search";
import API from "../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import CardLoader from "../../../ui/cardLoader";
import { toast } from "react-toastify";
import { handlePrice } from "../../../../helper";
import UpdatingPopupStepTwo from "./UpdatingPopupStepTwo";

interface ModalProps {
  setPopup: any;
  data?: any;
  orderProductSingleData?: any;
  isEdit?: boolean;
  orderId?: any;
  orderItems?: any;
  amountPaid?: any;
  deliveryFee?: any;
  serviceFee?: any;
  packs?: any;
  selectedPack?: any;
  isFoodOrder?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const UpdatingPopup = (props: ModalProps) => {
  const {
    setPopup,
    data,
    orderProductSingleData,
    isEdit,
    amountPaid,
    deliveryFee,
    serviceFee,
    orderId,
    packs,
    selectedPack,
    isFoodOrder,
  } = props;
  const [searchText, setSearchText] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const [productDetails, setProductDetails] = useState([{ name: "" }]);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);
  const [message, setMessage] = useState("");
  const [isContinueUpdate, setIsContinueUpdate] = useState(false);
  const [updatedOrderDetails, setUpdatedOrderDetails] = useState<any>();

  const getProducts = async () => {
    setProductDetails([]);
    setProductStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: isFoodOrder ? apiUrls?.menuItem : apiUrls?.products,
      // params: userRole !== "STORE_ADMIN" ? params : storeParams,
      params: { search: searchText, isAdmin: true },
    })
      .then((resp) => {
        setProductStatus(SUCCESS);
        isFoodOrder
          ? setProductDetails(resp.data.data.menuItems)
          : setProductDetails(resp.data.data.products);
        // const formattedList = resp.data.data.products.map(
        //   (data: any, index: any) => {
        //     return {
        //       id: data.id,
        //       name: data.name,
        //       category: data.category?.name,
        //       categoryId: data.category?.id,
        //       picture: data.picture,
        //       dropperPrice: data.dropperPrice,
        //       price: data.price,
        //       status: data.status,
        //       store: data.storeBranch?.name,
        //       storeBranchId: data.storeBranch?.id,
        //       description: data.description,
        //       metaKeywords: data.metaKeywords,
        //       dropStockOnPurchase: data.dropStockOnPurchase,
        //       excerpt: data.excerpt,
        //       hasCoupon: data.hasCoupon,
        //       hasPromo: data.hasPromo,
        //       priceDiscount: data.priceDiscount,
        //       sku: data.sku,
        //       slug: data.slug,
        //       stock: data.stock,
        //       m_unit: data.m_unit,
        //       size: data.size,
        //       isBestSeller: data.isBestSeller,
        //       bestSellingScore: data.bestSellingScore,
        //       categoryScore: data.categoryScore,
        //     };
        //   }
        // );
        setIsSearchAvailable(true);

        (isFoodOrder ? resp.data?.data?.menuItems : resp.data?.data?.products)
          ?.length > 0
          ? setProductStatus(DATAMODE)
          : setProductStatus(NULLMODE);
      })
      .catch((err) => {
        setProductStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  useEffect(() => {
    if (searchText !== "") {
      getProducts();
    }
  }, [searchText]);

  const onSubmit = (data: any) => {
    const resultArray = Object.keys(data).map((key: any) => ({
      productId: key.split("_")[1],
      dropperPrice: parseInt(key.split("_")[2]),
      quantity: data[key],
    }));
    const filteredArray = resultArray.filter((item) => item.quantity !== "");
    setUpdatedOrderDetails(filteredArray);
    setIsContinueUpdate(true);
  };

  const [productList, setProductList] = useState<any>(
    isFoodOrder ? selectedPack?.packItems : orderProductSingleData
  );
  const updateProductList = (product: any) => {
    // Check if the product is already in the productList
    const isProductInList = productList.some((item: any) =>
      isFoodOrder
        ? item.unitCost
          ? item?.menuItem.id === product.id
          : item.id === product.id
        : item.id === product.id || item.productId === product.id
    );

    if (!isProductInList) {
      // If the product is not in the list, add it
      setProductList((prevArray: any) => [...prevArray, product]);
    } else {
      // If the product is already in the list, you can handle it here (e.g., show a message)
      toast.error("Product is already in the order items");
    }
  };

  const handleRemoveDetail = (item: any) => {
    // Remove the product from the list
    setProductList((prevArray: any) =>
      prevArray.filter((detail: any) => detail.id !== item?.id)
    );

    // Reset the form state for the deleted product
    // reset({ [`quantity_${item?.productId}_${dropperPrice}`]: 0 });
    setValue(
      `quantity_${
        isFoodOrder
          ? item.unitCost
            ? item?.menuItem?.id
            : item?.id
          : item?.productId
          ? item?.productId
          : item.id
      }_${
        isFoodOrder
          ? item?.unitCost
            ? item?.unitCost
            : item?.dropperPrice
          : item?.dropperPrice
      }`,
      0
    );
  };

  return (
    <>
      <div className="popup widest">
        <div
          className="popup-container trail"
          style={{ width: "100%", maxWidth: "750px" }}
        >
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading className="popup-header" title="Update Order" />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <div
              className="form-heading"
              style={{
                width: "100%",
                display: "flex",
                gap: "24px",
                fontSize: "14px",
              }}
            >
              <div style={{ width: "40%" }}>Product Name</div>
              <div style={{ width: "20%" }}>Quantity</div>
              <div style={{ width: "20%" }}>Price</div>
              <div style={{ width: "20%" }}>Action</div>
            </div>
            {productList.map((item: any, index: any) => (
              <div
                className="product-details"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <div className="product-name" style={{ width: "40%" }}>
                  {isFoodOrder
                    ? item?.menuItem?.name || item?.name
                    : item?.name}
                </div>
                <div
                  className="input"
                  style={{ width: "20%", marginTop: "-20px" }}
                >
                  <Input
                    register={register}
                    type="number"
                    name={`quantity_${
                      isFoodOrder
                        ? item.unitCost
                          ? item?.menuItem?.id
                          : item?.id
                        : item?.productId
                        ? item?.productId
                        : item.id
                    }_${
                      isFoodOrder
                        ? item?.unitCost
                          ? item?.unitCost
                          : item?.dropperPrice
                        : item?.dropperPrice
                    }`} // Use a unique name based on the product's identifier and price
                    required
                    placeholder="Enter Quantity"
                    valueAsNumber
                    defaultValue={
                      isFoodOrder
                        ? item.quantity
                        : item?.productId
                        ? item?.quantity
                        : 1
                    }
                  />
                </div>
                <div
                  className="product-name"
                  style={{ width: "20%", fontSize: "14px" }}
                >
                  {handlePrice(
                    isFoodOrder
                      ? item?.unitCost || item?.dropperPrice
                      : item?.dropperPrice
                  )}
                </div>
                <div className="delete-product" style={{ width: "20%" }}>
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => handleRemoveDetail(item)}
                  />
                </div>
              </div>
            ))}
          </form>
          {!isEdit && (
            <div
              className="search-box"
              style={{ marginBottom: "12px", marginTop: "40px" }}
            >
              <p style={{ fontSize: "16px", marginBottom: "12px" }}>
                Add more Product
              </p>
              <Search
                setValue={setSearchText}
                placeholder="Search for existing product..."
                // setIsClearFilter={setIsAbstractProduct}
              />
            </div>
          )}
          {isSearchAvailable && !isEdit && searchText !== "" && (
            <div
              className="searched-products"
              style={{
                borderRadius: "8px",
                border: "0.5px solid rgba(26, 27, 26, 0.2)",
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {productStatus === DATAMODE ? (
                productDetails.map((item, index) => (
                  <p
                    className="product"
                    key={index}
                    onClick={() => updateProductList({ ...item, quantity: 1 })}
                    style={{
                      cursor: "pointer",
                      padding: "8px",
                    }}
                  >
                    {item?.name}
                  </p>
                ))
              ) : (
                <p
                  className="product"
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  No product match your search parameter
                </p>
              )}
            </div>
          )}
          <Button
            className="add-button"
            text={isEdit ? "Proceed" : "Proceed"}
            onClick={handleSubmit(onSubmit)}
            //   isLoading={status === "LOADING" ? true : false}
          />
        </div>
      </div>
      {isContinueUpdate && (
        <UpdatingPopupStepTwo
          setPopup={setIsContinueUpdate}
          orderProductSingleData={orderProductSingleData}
          orderId={orderId}
          amountPaid={amountPaid}
          deliveryFee={deliveryFee}
          serviceFee={serviceFee}
          updatedOrderDetails={updatedOrderDetails}
          packs={packs}
          selectedPack={selectedPack}
          isFoodOrder={isFoodOrder}
        />
      )}
      {productStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default UpdatingPopup;
