import "./User.scss";

interface IFilterProps {
  filterClicked: any;
  setFilterClicked: any;
  updatePage: any;
  totalCustomer?: any;
}

const UserFilter = (props: IFilterProps) => {
  const { filterClicked, setFilterClicked, updatePage, totalCustomer } = props;

  const handleClicked = (index: any, item: any) => {
    if (filterClicked === index) {
      setFilterClicked(item);
    }
    setFilterClicked(item);
    updatePage();
  };
  const filterMap = [
    { name: "Customer", value: "SHOPPER", total: true },
    { name: "Dropper", value: "DROPPER", total: false },
    { name: "Super Packer", value: "STORE_ADMIN", total: false },
    { name: "Admin", value: "ADMIN", total: false },
    { name: "Super Admin", value: "SUPER_ADMIN", total: false },
  ];
  return (
    <div className="order-filter user">
      <div className="left-div">
        {filterMap.map((item, index) => (
          <div
            className={`filter-type user ${
              filterClicked === item.value ? "clicked" : ""
            }`}
            key={index}
            onClick={() => handleClicked(index, item.value)}
          >
            <h6
              className={`${filterClicked === item.value ? "text-active" : ""}`}
            >
              {item.name}
            </h6>
            {item?.total === true && (
              <div className="user-total">
                <h6>{totalCustomer}</h6>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserFilter;
