import { useEffect, useState } from "react";
// import BusinessMetricsCard from "./BusinessMetricsCard";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import OperationMetricsCard from "./OperationMetricsCard";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const PageIntro = () => {
  return (
    <Heading
      title="Operations Metrics"
      subTitle="See all your operation metrics here"
    />
  );
};

const OperationMetrics = () => {
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [storeBranchStatus, setStoreBranchStatus] = useState(IDLE);
  const [userRole, setUserRole] = useState<string>();
  const [storeBranchId, setStoreBranchId] = useState("");
  const [storeBranches, setStoreBranches] = useState("");
  const [products, setProducts] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const storeBranchId: string = user.storeBranchId;
      const role: string = user.role;
      setUserRole(role);
      setStoreBranchId(storeBranchId);
    }
  }, []);
  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const [dateFilter, setDateFilter] = useState(getLastNDaysFormatted(1));
  return (
    <div style={{ marginBottom: "80px" }}>
      <PageIntro />
      <OperationMetricsCard />
    </div>
  );
};

export default OperationMetrics;
