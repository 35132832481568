import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input, TelephoneInput } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import "./AddStorePopup.scss";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

interface ModalProps {
  setPopup: any;
  getStores: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddStorePopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const { setPopup, getStores, data, isEdit, selectedId } = props;

  const [imageURL, setImageURL] = useState();
  const [image, setImage] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(resp.data.data.url);
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(getErrorMessage(err.response.data.message))
        setImageStatus(ERROR);
      });
  };

  const addStore = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.logo = image;
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.store,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const updateStore = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.logo = image;
    (request.logo === "" || request.logo === undefined) &&
      (request.logo = data.logo);

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getStore(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    // if (imageFile?.length > 0) {
    isEdit ? updateStore(data) : addStore(data);
    // } else {
    // setStatus(ERROR)
    // setMessage("An image is required");
    // }
  };

  useEffect(() => {
    if (isEdit) {
      const { name, productImage } = data || {};
      setValue("name", name);
      setImageFile(productImage);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Store" : "Add Store"}
            subTitle={
              isEdit
                ? "Enter the following details to update a store"
                : "Enter the following details to add a store"
            }
          />

          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                      : isEdit && data?.logo?.length > 0
                    ? data?.logo
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload store logo "
              onChange={upload}
              // isUploading={isUploading}
            />
          </div>
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Store Name"
              name="name"
              required
              placeholder="Enter Store Name"
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Store" : "Add Store"}
              isLoading={status === "LOADING" ? true : false}
              disabled={imageStatus === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a store to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Store"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddStorePopup;
