import { useState, useContext, useEffect } from "react";
import { filterContext } from "../../../helper/Context";
import DateFilter from "../../ui/filter/walletFilter/dateFilter/DateFilter";

interface IFilterProps {
  filterClicked: any;
  setFilterClicked: any;
  setStoreFilterClicked: any;
  setStartDate?: any;
  setEndDate?: any;
  getOrders?: any;
  setIsClearFilter?: any;
  setIsScheduledOrder?: any;
}

const OrderFilter = (props: IFilterProps) => {
  const {
    filterClicked,
    setFilterClicked,
    setStoreFilterClicked,
    setEndDate,
    setStartDate,
    getOrders,
    setIsClearFilter,
    setIsScheduledOrder,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const { orderFilter, setOrderFilter } = useContext(filterContext);

  const [dropdown, setDropdown] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState(false);

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const handleClicked = (index: any, item: any) => {
    if (filterClicked === index) {
      setIsClearFilter(false);
      setFilterClicked(item);
      setStoreFilterClicked(item);
      setIsScheduledOrder(false)
      // setOrderFilter(item[0]);
      // setDropdown(false);
    }
    setIsClearFilter(false);
    setFilterClicked(item);
    setStoreFilterClicked(item);
    setIsScheduledOrder(false)
    // setOrderFilter(item);
    // setDropdown(false);
  };

  // const setIsClearFilter = () => {
  //   setFilterClicked(
  //   setStoreFilterClicked(
  //     "$in:STARTED,ACCEPTED_BY_STORE,REJECTED_BY_STORE,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,COMPLETED,REFUNDED,PAYMENT_RECEIVED"
  //   );
  //   setStartDate("2023-01-01");
  //   setEndDate(new Date().toLocaleDateString("en-CA"));
  //   getOrders()
  // };

  const filterMap = [
    // { name: "All", value: "" },
    { name: "Started", value: "STARTED" },
    { name: "Accepted By Store", value: "ACCEPTED_BY_STORE" },
    { name: "Rejected By Store", value: "REJECTED_BY_STORE" },
    { name: "Assigned", value: "ASSIGNED" },
    { name: "Rider Accepted", value: "ACCEPTED_BY_RIDER" },
    { name: "Picking Up", value: "PICKING_UP" },
    { name: "Dropping", value: "DROPPING" },
    { name: "Arrived", value: "ARRIVED" },
    { name: "Flagged", value: "FLAGGED" },
    { name: "Completed", value: "COMPLETED" },
    { name: "Refunded", value: "REFUNDED" },
    { name: "Payment Received", value: "PAYMENT_RECEIVED" },
  ];

  const storeFilterMap = [
    // { name: "All", value: "" },
    // { name: "Started", value: "STARTED" },
    { name: "Accepted By Store", value: "ACCEPTED_BY_STORE" },
    { name: "Rejected By Store", value: "REJECTED_BY_STORE" },
    { name: "Assigned", value: "ASSIGNED" },
    { name: "Rider Accepted", value: "ACCEPTED_BY_RIDER" },
    { name: "Picking Up", value: "PICKING_UP" },
    { name: "Dropping", value: "DROPPING" },
    { name: "Arrived", value: "ARRIVED" },
    { name: "Flagged", value: "FLAGGED" },
    { name: "Completed", value: "COMPLETED" },
    { name: "Refunded", value: "REFUNDED" },
    { name: "Payment Received", value: "PAYMENT_RECEIVED" },
  ];
  const timeLineMap = [
    { name: "All Days", value: "" },
    { name: "Today", value: "Today" },
    { name: "This Week", value: "This_Week" },
    { name: "This Month", value: "This_Month" },
    { name: "This Year", value: "This_Year" },
  ];
  return (
    <div className="order-filters">
      <div className="order-filter">
        <div className="left-div">
          {(userRole === "STORE_ADMIN" ? storeFilterMap : filterMap).map(
            (item, index) => (
              <div
                className={`filter-type ${
                  filterClicked === index ? "clicked" : ""
                }`}
                key={index}
                onClick={() => handleClicked(index, item.value)}
              >
                <h6
                  className={`${filterClicked === index ? "text-active" : ""}`}
                >
                  {item.name}
                </h6>
                {/* <span className={`value ${item.name}`}>445</span> */}
              </div>
            )
          )}
          <div
            className="filter dropdown"
            onClick={() => setStatusDropdown(!statusDropdown)}
          >
            <h6>Filter By Status</h6>
            <img
              className="arrow-down"
              src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow-down_zvrlww.png"
            />
          </div>
          {statusDropdown && (
            <div className="dropdown-option">
              {(userRole === "STORE_ADMIN" ? storeFilterMap : filterMap).map(
                (item, index) => (
                  <div
                    className="dropdown-list"
                    key={index}
                    onClick={() => handleClicked(index, item.value)}
                  >
                    <h6>{item.name}</h6>
                  </div>
                )
              )}
            </div>
          )}
        </div>
        <div className="right-div">
          <div className="dropdown" onClick={() => setDropdown(!dropdown)}>
            <h6>Filter by Date</h6>
            <img
              className="arrow-down"
              src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow-down_zvrlww.png"
            />
          </div>
          {dropdown && (
            <div className="dropdown-option">
              <DateFilter
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setIsClearFilter={setIsClearFilter}
              />
            </div>
          )}
        </div>
        {userRole !== "DROPPER" && (
          <div className="right-div">
            <div
              className="filter dropdown"
              onClick={() => {setIsClearFilter(false);
                setIsScheduledOrder(true)}}
            >
              <h6>Scheduled Order</h6>
            </div>
          </div>
        )}
      </div>
      <div
        className="rightt-div"
        onClick={() => {
          setIsScheduledOrder(false);
          setIsClearFilter(true);
        }}
      >
        <h6>Clear Filter</h6>
      </div>
    </div>
  );
};

export default OrderFilter;
