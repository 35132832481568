import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../storage";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
// import "./OrderSingle.scss";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import UserInformation from "./customerDetails/UserInformation";
import { Button } from "../../ui";
import Alert from "../../ui/alert";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";
import AssignStoreBranchPopup from "./AssignStoreBranchPopup";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const UserSingle = () => {
  const Navigate = useNavigate();
  const [status, setStatus] = useState(IDLE);
  const [assignStoreBranchPopup, setAssignStoreBranchPopup] = useState(false);
  const [unassignStatus, setUnassignStatus] = useState(IDLE);
  const [userSingleData, setUserSingleData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    phone: "",
    photo: "",
    placeId: "",
    referralCode: "",
    referredBy: "",
    defaultStoreBranchId: "",
    storeBranchId: "",
    storeBranches: "",
    dateCreated:"",
  });
  const [message, setMessage] = useState("");

  const { id } = useParams();

  const getUserSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getAUser(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const data = resp.data?.data;
        const formattedList = {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
          email: data.email,
          phone: data.phone,
          photo: data.photo,
          placeId: data.placeId,
          referralCode: data.referralCode,
          referredBy: data.referredBy,
          defaultStoreBranchId: data.defaultStoreBranchId,
          storeBranchId: data.storeBranchId,
          storeBranches: data.storeBranches,
          dateCreated: data.createdAt,
        };
        setUserSingleData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setMessage("Error getting User");
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
        setStatus(ERROR);
      });
  };

  const unassignStoreBranch = async () => {
    setUnassignStatus(LOADING);
    await API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls.unassignStoreBranch(id),
    })
      .then((resp) => {
        setUnassignStatus(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        setUnassignStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
      });
  };

  useEffect(() => {
    if (id) {
      getUserSingleData();
    }
  }, [id]);

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no User presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="order-details">
            <UserInformation
              id={userSingleData.id}
              firstName={userSingleData.firstName}
              lastName={userSingleData.lastName}
              role={userSingleData.role}
              email={userSingleData.email}
              phone={userSingleData.phone}
              photo={userSingleData.photo}
              placeId={userSingleData.placeId}
              referralCode={userSingleData.referralCode}
              referredBy={userSingleData.referredBy}
              defaultStoreBranchId={userSingleData.defaultStoreBranchId}
              storeBranchId={userSingleData.storeBranchId}
              storeBranches={userSingleData.storeBranches}
              dateCreated={userSingleData.dateCreated}
            />
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">
        <span className="go-back" onClick={() => Navigate(-1)}>
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
            alt="arrow-back"
            className="arrow"
          />
          <h6>Go back</h6>
        </span>
        <div className="page-header">
          <h2>User Details</h2>
          {/* {userSingleData.storeBranchId !== null && ( */}
          {(userSingleData.role === "STORE_ADMIN" ||
            userSingleData.role === "DROPPER") && (
            <Button
              text="Assign Store Branch"
              onClick={() => setAssignStoreBranchPopup(true)}
              // isLoading={unassignStatus === LOADING ? true : false}
            />
          )}
          {/* )} */}
        </div>
        {renderBasedOnStatus()}
      </div>
      {assignStoreBranchPopup && (
        <AssignStoreBranchPopup
          setPopup={setAssignStoreBranchPopup}
          data={userSingleData?.storeBranches}
          selectedId={id}
          fetchData={getUserSingleData}
        />
      )}
      {unassignStatus === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {unassignStatus === ERROR && (
        <Alert
          title="Error unassigning admin"
          text={message}
          timeOut={3000}
          alertOut={setUnassignStatus}
        />
      )} */}
    </>
  );
};

export default UserSingle;
