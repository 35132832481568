import { useState } from "react";
import Alert from "../alert";
import "./Input.scss";
import { toast } from "react-toastify";

interface InputTypes {
  inputClass?: string;
  labelClass?: string;
  label?: string;
  placeholder?: string;
  onChange?: Function;
  value?: any;
  id?: string;
  name?: string;
  type?: string;
  invertStyle?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  className?: string;
  disabled?: boolean;
  copy?: boolean;
  tested?: boolean;
  register?: Function;
  required?: boolean;
  pattern?: any;
  min?: any;
  max?: any;
  step?: any;
  defaultValue?: any;
  valueAsNumber?: boolean;
  copyText?: boolean;
}

const Input = (props: InputTypes) => {
  const [alert, setAlert] = useState(false);
  const {
    inputClass,
    labelClass,
    placeholder,
    label,
    onChange,
    value,
    id,
    name,
    type,
    invertStyle,
    errorMsg,
    hasError,
    className,
    disabled,
    copy,
    tested,
    register,
    required,
    pattern,
    valueAsNumber,
    min,
    max,
    defaultValue,
    step,
    copyText,
  } = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setAlert(true);
    toast.success("text copied");
  };

  return (
    <>
      <div className={`text-input ${className}`}>
        <label className={`label ${labelClass}`} htmlFor={id}>
          {label}
        </label>
        <input
          className={`input ${
            invertStyle ? "input-invert" : ""
          } ${inputClass} ${hasError ? "input-error-input" : ""}`}
          placeholder={placeholder}
          type={type}
          onChange={(event) => onChange?.(event.target.value)}
          value={value}
          id={id}
          name={name}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          defaultValue={defaultValue}
          {...register?.(name, {
            required: required,
            pattern: pattern,
            valueAsNumber: valueAsNumber,
          })}
        />
        {copy &&
          (copyText ? (
            <p className="copy-text" onClick={handleCopy}>Copy</p>
          ) : (
            <img
              src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/copy-outline_yfjdto.svg"
              alt="copy"
              className="copy"
              onClick={handleCopy}
            />
          ))}
        {tested && (
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666628076/enterprise-dashboard/tested_puhyzz.png"
            alt="tested"
            className="copy"
          />
        )}

        {hasError && (
          <span className="input-error">
            {errorMsg ? errorMsg : `${label} is required.`}
          </span>
        )}
        {/* {alert && (
          <Alert
            title="Text Copied"
            text="Phone Number copied"
            alertOut={setAlert}
            timeOut={10000}
          />
        )} */}
      </div>
    </>
  );
};

export default Input;
