import CustomerSingle from "../components/dashboard/customerSingle";
import Layout from "../components/ui/layout";

const CustomerSinglePage = () => {
  return (
    <Layout>
      <CustomerSingle />
    </Layout>
  );
};

export default CustomerSinglePage;
