import DropFoodCategory from "../components/dashboard/dropFoodCategory";
import Layout from "../components/ui/layout";

const DropFoodCategoryPage = () => {
  return (
    <Layout>
      <DropFoodCategory />
    </Layout>
  );
};

export default DropFoodCategoryPage;
