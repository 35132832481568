import React from "react";
import Layout from "../../../ui/layout";
import SettingLayout from "../../../ui/settingLayout/SettingLayout";
import FeeSettings from "./settingsBody/feeSettings";

const GSASettings = () => {
  return (
    <Layout>
      <SettingLayout isGSA>
        <FeeSettings />
      </SettingLayout>
    </Layout>
  );
};

export default GSASettings;
