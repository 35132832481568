import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button, Input } from "../../../ui";
import Alert from "../../../ui/alert";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../../utils/api/API";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../ui/select";
import { defaultOption, getAbstractCategory, getErrorMessage } from "../../../../helper";
import CardLoader from "../../../ui/cardLoader";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface ModalProps {
  id?: string;
  setPopup: any;
  getCategories?: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
  storeBranchId?: any;
}

const AddCategoryPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
  } = useForm();

  const watchName = watch("name");

  const { id, setPopup, data, isEdit, storeBranchId } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [isCatLoading, setIsCatloading] = useState(false);
  const [abstractPreFill, setAbstractPreFill] = useState({
    description: "",
    picture: "",
    color: "",
    name: "",
    thumbnail: "",
  });
  const [imageURL, setImageURL] = useState();
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [status, setStatus] = useState(IDLE);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [abstractCategoryOption, setAbstractCategoryOption] = useState([]);
  const [isAbstractCategoryLoading, setIsAbstractCategoryLoading] =
    useState(false);

  useEffect(() => {
    getAbstractCategory(
      setAbstractCategoryOption,
      setIsAbstractCategoryLoading
    );
  }, []);

  const handleFileChange = (file: any) => {
    const filesArray = Array.from(file);
    setSelectedFiles(filesArray as File[]);
  };

  const refresh = () => {
    window.location.reload();
  };

  const bgColours = [
    {
      label: "Orange",
      value: "#FFEEE2",
    },
    {
      label: "Green",
      value: "#E8FBE8",
    },
    {
      label: "Yellow",
      value: "#FFF6D6",
    },
    {
      label: "Blue",
      value: "#E1F2FB",
    },
  ];

  const onSubmit = (data: any) => {
    // isEdit ? updateCategory(data) : addCategory(data);
  };

  const fetchAbstractCategory = (id: any) => {
    setIsCatloading(true);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getAbstractCategory(id),
    })
      .then((resp) => {
        setIsCatloading(false);
        setAbstractPreFill(resp.data?.data);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message))
      });
  };

  const addCategory = async () => {
    setStatus(LOADING);
    setMessage("");
    const data = {
      name: abstractPreFill?.name,
      description: abstractPreFill?.description,
      color: abstractPreFill?.color,
      picture: abstractPreFill?.picture,
      thumbnail: abstractPreFill?.thumbnail,
      storeBranchId: id,
    };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.category,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const updateCategory = async () => {
    setStatus(LOADING);
    setMessage("");
    const request = {
      name: abstractPreFill?.name,
      description: abstractPreFill?.description,
      color: abstractPreFill?.color,
      picture: abstractPreFill?.picture,
      thumbnail: abstractPreFill?.thumbnail,
      storeBranchId: storeBranchId?.id,
    };
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateCategory(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (watchName !== undefined) {
      fetchAbstractCategory(watchName);
    }
  }, [watchName]);

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Store Category" : "Add Store Category"}
            subTitle={
              isEdit
                ? "Enter the following details to update store category"
                : "Enter the following details to add store category"
            }
          />
          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : abstractPreFill?.picture?.length > 0
                    ? abstractPreFill?.picture
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            {/* <FileInput
              labelText="Tap to upload store logo "
              onChange={upload}
              // isUploading={isUploading}
            /> */}
          </div>
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={abstractCategoryOption}
                  value={
                    isEdit
                      ? defaultOption(abstractCategoryOption, data.name)
                      : ""
                  }
                  label="Select  Category"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            />
            <Input
              register={register}
              label="Description"
              name="description"
              value={abstractPreFill?.description}
              required
              placeholder="Enter Description"
              disabled
            />
            <Controller
              name="color"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={bgColours}
                  value={defaultOption(bgColours, abstractPreFill.color)}
                  label="Select An Accent"
                  disabled
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Category" : "Add Category"}
              isLoading={status === "LOADING" ? true : false}
              onClick={isEdit ? updateCategory : addCategory}
              disabled={
                (
                  abstractPreFill?.name ||
                  abstractPreFill?.description ||
                  abstractPreFill?.color ||
                  abstractPreFill?.picture ||
                  abstractPreFill?.thumbnail
                ).length === 0
                  ? true
                  : false
              }
            />
          </form>
        </div>
      </div>
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Category"
          text=""
          alertOut={setStatus}
          timeOut={3000}
        />
      )} */}
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added an image to this store branch."
          onClick={refresh}
        />
      )}
      {(isAbstractCategoryLoading || isCatLoading) && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddCategoryPopup;
