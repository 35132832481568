import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import AddStoreBranchPopup from "../storeSingle/addStoreBranchPopup/AddStoreBranchPopup";
import "./Store.scss";
import StoreBranchTable from "../../ui/table/storesBranchTable/StoreBranchTable";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/api/API";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Store Branches"
      subTitle="Hi there! This are the available branches"
    />
  );
};

const StoreBranches = () => {
  const [addStoreBranchPopup, setAddStoreBranchPopup] = useState(false);
  const [storeDetails, setStoreDetails] = useState([]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedStoreBranch, setSelectedStoreBranch] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedStoreBranch(data);
    setAddStoreBranchPopup(true);
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const getStoreBranch = async () => {
    setStoreDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.storeBranch,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data?.data.meta.totalItems);
        const formattedList = resp.data?.data.storeBranches.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data?.id,
              name: data?.name,
              address: data?.address,
              email: data?.email,
              rep: data?.rep,
              status: data?.status,
              slackChannel: data?.slackChannel,
              contact: data?.phoneNumber,
              coordinates: data?.coords?.coordinates,
              sundayOpeningTime: data?.timeSettings?.SUNDAY?.openingTime,
              sundayClosingTime: data?.timeSettings?.SUNDAY?.closingTime,
              mondayOpeningTime: data?.timeSettings?.MONDAY?.openingTime,
              mondayClosingTime: data?.timeSettings?.MONDAY?.closingTime,
              tuesdayOpeningTime: data?.timeSettings?.TUESDAY?.openingTime,
              tuesdayClosingTime: data?.timeSettings?.TUESDAY?.closingTime,
              wednesdayOpeningTime: data?.timeSettings?.WEDNESDAY?.openingTime,
              wednesdayClosingTime: data?.timeSettings?.WEDNESDAY?.closingTime,
              thursdayOpeningTime: data?.timeSettings?.THURSDAY?.openingTime,
              thursdayClosingTime: data?.timeSettings?.THURSDAY?.closingTime,
              fridayOpeningTime: data?.timeSettings?.FRIDAY?.openingTime,
              fridayClosingTime: data?.timeSettings?.FRIDAY?.closingTime,
              saturdayOpeningTime: data?.timeSettings?.SATURDAY?.openingTime,
              saturdayClosingTime: data?.timeSettings?.SATURDAY?.closingTime,
            };
          }
        );
        setStoreDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteStoreBranch = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getStoreBranch(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getStoreBranch();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getStoreBranch();
  }, [filter]);

  return (
    <>
      <div className="page-header">
        <PageHeading />
      </div>
      <div className="table-wrapper">
        {/* {!isLoading && dropsTableData.length > 0 && (
          <DropsFilter setFilterFunction={setOverviewDropFilter} />
        )} */}
        <StoreBranchTable
          clickable
          data={storeDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteStore={deleteStoreBranch}
          handleEditModal={handleEditModal}
        />
      </div>
      {status !== LOADING && storeDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {addStoreBranchPopup && (
        <AddStoreBranchPopup
          // storeId={}
          getStoreBranch={getStoreBranch}
          setPopup={setAddStoreBranchPopup}
          data={selectedStoreBranch}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default StoreBranches;
