import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import Heading from "../heading";
import Button from "../button";
import SuccessfulModal from "../modal/successfulModal/SuccessfulModal";
import Alert from "../alert";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AvailabilityPopup = (props: {
  setPopup?: any;
  setIsAvailable?: any;
  value?: boolean;
  storeBranchId?: string;
}) => {
  const { setPopup, storeBranchId, value, setIsAvailable } = props;
  //   const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(IDLE);

  const makeAvailable = () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.makeStoreBranchAvailable(storeBranchId),
      data: storeBranchId,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setPopup(false);
        setMessage(resp.data.message);
        setIsAvailable(!value);
      })
      .catch((err) => {
        setStatus(ERROR);
        setIsAvailable(value);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const makeUnavailable = () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.makeStoreBranchUnavailable(storeBranchId),
      //   data: storeBranchId,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setPopup(false);
        setMessage(resp.data.message);
        setIsAvailable(!value);
      })
      .catch((err) => {
        setStatus(ERROR);
        setIsAvailable(value);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
      });
  };

  const closePopup = () => {
    setPopup(false);
    setIsAvailable(value);
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={closePopup}
          />
          <Heading
            className="popup-header"
            title={
              value ? "Set store as unavailable?" : "Set store as available?"
            }
            // subTitle="Enter the following details to add a Product"
          />
          <div
            style={{
              display: "flex",
              gap: "24px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              text="No"
              invertStyle
              onClick={closePopup}
              style={{ width: "100%", flex: "1" }}
              className=""
            />
            <Button
              text="Yes"
              onClick={value ? makeAvailable : makeUnavailable}
              isLoading={status === LOADING ? true : false}
              style={{ width: "100%", flex: "1" }}
            />
          </div>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={() => setPopup(false)}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AvailabilityPopup;
