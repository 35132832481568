import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
// import "./AddCategoryPopup.scss";
import { ADD_FEE_FIELD } from "./enum";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../../../../../utils/dataQueryStatus";
import API from "../../../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../../../utils/api";
import Heading from "../../../../../../ui/heading";
import Select from "../../../../../../ui/select";
import { defaultOption, getErrorMessage } from "../../../../../../../helper";
import { Button, Input } from "../../../../../../ui";
import SuccessfulModal from "../../../../../../ui/modal/successfulModal/SuccessfulModal";

interface ModalProps {
  setPopup: any;
  getServiceCharge: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddFeePopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const selectedOption = watch("hasPromo");

  const { setPopup, getServiceCharge, data, isEdit, selectedId } = props;

  const [imageFile, setImageFile] = useState("");
  const [feeId, setFeeId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const addServiceCharge = async (data: any) => {
    setStatus(LOADING);
    data.type === "PERCENT"
      ? (data.percentValue = data.feeInMinor)
      : (data.feeInMinor = data.feeInMinor * 100);
    setMessage("");

    const payload =
      data?.type === "PERCENT"
        ? {
            name: data?.name,
            type: data?.type,
            percentValue: data?.percentValue,
          }
        : {
            name: data?.name,
            type: data?.type,
            feeInMinor: data?.feeInMinor,
          };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaServiceCharges,
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const updateServiceCharge = (request: any) => {
    setStatus(LOADING);
    request.type === "PERCENT"
      ? (request.percentValue = request.feeInMinor)
      : (request.feeInMinor = request.feeInMinor * 100);
    setMessage("");

    const payload =
      request?.type === "PERCENT"
        ? {
            name: request?.name,
            type: request?.type,
            percentValue: request?.percentValue,
          }
        : {
            name: request?.name,
            type: request?.type,
            feeInMinor: request?.feeInMinor,
          };

    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.getGsaServiceCharges(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        getServiceCharge();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateServiceCharge(data) : addServiceCharge(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, feeInMinor, percentValue, type } = data || {};
      setValue("name", name);
      setValue(
        "feeInMinor",
        type === "PERCENT" ? percentValue : feeInMinor / 100
      );
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const type = [
    {
      label: "Flat",
      value: "FLAT",
    },
    {
      label: "Percent",
      value: "PERCENT",
    },
  ];

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Service Charge" : "Add Service Charge"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Service Charge"
                : "Enter the following details to add a Service Charge"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Name"
              name="name"
              required
              placeholder="Enter Name"
              type="text"
            />
            <Controller
              name="type"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Select
                  options={type}
                  value={isEdit ? defaultOption(type, data?.type) : ""}
                  label="Select Fee Type"
                  onChange={(val: any) => onChange(val.value)}
                  required
                />
              )}
            />
            <Input
              register={register}
              label="Fee"
              name="feeInMinor"
              required
              placeholder="Enter Fee"
              type="number"
              valueAsNumber
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Service Charge" : "Add Service Charge"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Service Charge"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddFeePopup;
