import PlacesAutocomplete, {
  geocodeByAddress,
  // geocodeByLatLng,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import { useEffect, useState } from "react";
import "./PlaceAutoComplete.scss";

const REACT_APP_GOOGLE_API_KEY = "AIzaSyBJ6LckSpVPgqAYOdT4zI-EDad6CUZJBbI";

const PlaceAutoComplete = (props: {
  isScriptLoaded?: boolean;
  isScriptLoadSucceed?: boolean;
  inputLabel?: string;
  setPlaceId?: any;
  setLatLng?: any;
  placeholder?: string;
  name?: string;
  register?: any;
  required?: boolean;
  pattern?: any;
  valueAsNumber?: boolean;
}) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const {
    isScriptLoaded,
    isScriptLoadSucceed,
    inputLabel,
    setPlaceId,
    placeholder,
    setLatLng,
    name,
    register,
    required,
    pattern,
    valueAsNumber,
  } = props;
  const handleChange = (address: any) => {
    setAddress(address);
  };

  // const latLng = {
  //   lat: -8.0484882,
  //   lng: 114.1721815,
  // };

  // // Fetch the address using the latLng
  // useEffect(() => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   geocoder.geocode({ location: latLng }, (results, status) => {
  //     if (status === "OK" && results && results.length > 0) {
  //       setAddress(results[0].formatted_address);
  //     } else {
  //       console.error("Error geocoding:", status);
  //     }
  //   });
  // }, [latLng]);

  const handleSelect = async (selectedAddress: any) => {
    setAddress(selectedAddress);

    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setLatLng(latLng);
      setCoordinates(latLng);

    } catch (error) {
      console.error("Error", error);
    }
  };


  if (isScriptLoaded && isScriptLoadSucceed) {
    return (
      <div>
        <PlacesAutocomplete
          value={address}
          onChange={handleChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="text-input">
              <input
                name={name}
                className="input"
                id="input"
                {...register?.(name, {
                  required: required,
                  pattern: pattern,
                  valueAsNumber: valueAsNumber,
                })}
                {...getInputProps({
                  placeholder: `${placeholder}`,
                })}
              />
              <label className="label" htmlFor="input">
                {inputLabel}
              </label>
              <div>
                {loading && (
                  <div className="loading">
                    <p>loading...</p>
                  </div>
                )}
                <div
                  className={
                    suggestions.length !== 0 ? "suggestions" : "no-suggestion"
                  }
                >
                  {suggestions.map((suggestion, index) => {
                    return (
                      <div
                        className="suggestion"
                        {...getSuggestionItemProps(suggestion)}
                        key={index}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places`,
])(PlaceAutoComplete);

