import { useEffect, useState } from "react";
import BusinessMetricsCard from "./BusinessMetricsCard";
import Heading from "../../../../ui/heading";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const PageIntro = () => {
  return <Heading title="GSA Metrics" subTitle="See all your metrics here" />;
};

const BusinessMetrics = () => {
  const [isClearRevenueFilter, setIsClearRevenueFilter] = useState(false);
  const [isClearOrderFilter, setIsClearOrderFilter] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [orderStatus, setOrderStatus] = useState(IDLE);
  const [userRole, setUserRole] = useState<string>();
  const [orderCompleted, setOrderCompleted] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [analyticsData, setAnalyticsData] = useState<any>();

  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const getAnalytics = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.gsaAnalytics,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setAnalyticsData(resp.data?.content);
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <div style={{ marginBottom: "80px" }}>
      <PageIntro />
      <BusinessMetricsCard
        analyticsData={analyticsData}
        status={status}
      />
    </div>
  );
};

export default BusinessMetrics;
