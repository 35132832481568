import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";
import VoucherSettings from "../components/dashboard/settings/settingsBody/voucherSettings";

const VoucherPage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <VoucherSettings />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default VoucherPage;
