import { useEffect, useState } from "react";
import { ILayoutProps } from "./LayoutSidebar";
import { Link, useNavigate } from "react-router-dom";
import Switch from "../switch";
import AvailabilityPopup from "./AvailabilityPopup";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import DropperIcon from "../../../assets/logo.png";
import Button from "../button";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const LayoutHeader = (props: ILayoutProps) => {
  const {
    isSidebarExpanded,
    setIsSidebarExpanded,
    children,
    firstLetter,
    lastLetter,
    userImage,
  } = props;

  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);

  const [userRole, setUserRole] = useState("");
  const [storeBranchId, setStoreBranchId] = useState("");
  const [isAvailable, setIsAvailable] = useState<boolean>();
  const [availabilityPopup, setAvailabilityPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("storeBranches");
    navigate("/login");
  };

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const storeBranchId: string = user.storeBranchId;
      const role: string = user.role;
      setUserRole(role);
      setStoreBranchId(storeBranchId);
    }
  }, []);

  const getOrderSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.checkStoreBranchAvailability(storeBranchId),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setIsAvailable(!resp?.data?.data?.isAvailable);
        const data = resp.data?.data;
      })
      .catch((err) => {
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (storeBranchId !== "" && userRole === "STORE_ADMIN") {
      getOrderSingleData();
    }
  }, [storeBranchId]);

  const path = window.location.pathname;

  return (
    <>
      <div className={`main-section ${isSidebarExpanded ? "" : "expanded"}`}>
        <div className={`header ${isSidebarExpanded ? "" : "expanded"}`}>
          <div className="search"></div>
          <div
            className="mobile-logo"
            onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
          >
            <img
              src="https://res.cloudinary.com/dm19qay3n/image/upload/v1687130045/internal-dashboard/hamburger_grx7ka.png"
              alt="dropper-hamburger"
              className="hamburger"
            />
            <div className="mobile-logo-icon">
              <img src={DropperIcon} alt="dropper-logo" className="logo" />
            </div>
          </div>
          <div className="notification-wrapper">
            {userRole === "STORE_ADMIN" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <p className="type2 bold">Available?</p>
                <Switch
                  textOff="YES"
                  textOn="NO"
                  value={isAvailable}
                  onChange={setIsAvailable}
                  setPopup={setAvailabilityPopup}
                  bgColor="#2EBB55"
                />
              </div>
            )}
            {(userRole === "ADMIN" || userRole === "SUPER_ADMIN") &&
              (path.includes("/gsa/") ? (
                <Button
                  text="Switch to Groceries"
                  onClick={() => navigate("/")}
                  buttonStyle={{fontSize:"14px", whiteSpace:"nowrap"}}
                />
              ) : (
                <Button
                  text="Switch to GSA"
                  onClick={() => navigate("/gsa/overview")}
                  buttonStyle={{fontSize:"14px", whiteSpace:"nowrap"}}
                />
              ))}
            <div className="profile">
              <div
                className="profile-pics"
                onClick={() => navigate("/settings")}
              >
                <img className="user-image" src={userImage} />
                <h4 style={{ color: "white" }}>{firstLetter + lastLetter}</h4>
              </div>
              <div className="profile-menu" onClick={() => setMenu(!menu)}>
                <span>Profile</span>
                <img
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {menu && (
                <div className="menu-wrapper">
                  <Link
                    to="/profile"
                    className="menu-bar"
                    style={{ textDecoration: "none" }}
                  >
                    Profile
                  </Link>
                  <span className="menu-bar" onClick={handleLogout}>
                    Logout
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="main-contents">{children}</div>
      </div>
      {availabilityPopup && (
        <AvailabilityPopup
          value={isAvailable}
          storeBranchId={storeBranchId}
          setPopup={setAvailabilityPopup}
          setIsAvailable={setIsAvailable}
        />
      )}
    </>
  );
};

export default LayoutHeader;
