import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
// import "./AddCategoryPopup.scss";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import API from "../../../../../utils/api/API";
import Heading from "../../../../ui/heading";
import { Button } from "../../../../ui";
import Select from "../../../../ui/select";
import { getErrorMessage } from "../../../../../helper";

interface ModalProps {
  setPopup: any;
  getData: Function;
  data?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const UpdateOrderStatusPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const { setPopup, data, getData } = props;

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const updateServiceCharge = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.updateGsaOrderDeliveryStatus(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data.message);
        setMessage(resp.data.message);
        getData();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    updateServiceCharge(data);
  };

  const statusOption = [
    { label: "PROCESSING", value: "PROCESSING" },
    { label: "ASSIGNED", value: "ASSIGNED" },
    { label: "DROPPING", value: "DROPPING" },
    { label: "DELIVERED", value: "DELIVERED" },
  ];

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={"Update Order Status"}
            subTitle={"Enter the following details to update a Order Status"}
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="status"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={statusOption}
                  // value={
                  //   isEdit
                  //     ? defaultOption(UNIT_ENUM, data?.m_unit)
                  //     : isAbstractProduct
                  //     ? defaultOption(UNIT_ENUM, prefillData?.m_unit)
                  //     : ""
                  // }
                  label="Select Status"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            />

            <Button
              className="add-button"
              text={"Update Order Status"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {/* {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )} */}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Order Status"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default UpdateOrderStatusPopup;
