import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import Alert from "../../../ui/alert";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import "./ViewStoreImagesPopup.scss";
import ErrorView from "../../../ui/ErrorView";
import EmptyView from "../../../ui/emptyView";
import CardLoader from "../../../ui/cardLoader";
import Paginate from "../../../ui/paginate";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const ViewStoreImagesPopup = (props: { id?: string; setPopup?: any }) => {
  const { id, setPopup } = props;
  const [status, setStatus] = useState(IDLE);
  const [deleteStatus, setDeleteStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [hover, setHover] = useState(null);
  const [galleryDetails, setGalleryDetails] = useState([
    {
      name: "",
      image: "",
      id: "",
      thumbnail: "",
    },
  ]);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const refresh = () => {
    window.location.reload();
  };

  const toggle = (index: any) => {
    if (hover === index) {
      return setHover(null);
    }
    setHover(index);
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  const filters = {
    "filter.store.id": id,
    ...filter
  };

  const getImages = async () => {
    setGalleryDetails([])
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getGallery,
      params: filters,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data?.data.meta.totalItems)
        const formattedList = resp.data?.data.media.map((data: any, index: any) => {
          return {
            name: data.name,
            id: data.id,
            image: data.url,
          };
        });
        setGalleryDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message))
        setMessage(err.response.message);
      });
  };

  const deleteImage = async (id: any) => {
    setDeleteStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getImage(id),
    })
      .then((resp) => {
        setDeleteStatus(SUCCESS);
        getImages();
      })
      .catch((err) => {
        setDeleteStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message))
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  useEffect(() => {
    getImages();
  }, [filter]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading" style={{width:"100vw"}}>
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading" style={{width:"100vw"}}>
            <EmptyView message="There's no images presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="gallery">
            {galleryDetails.map((image, index) => (
              <div
                className="image"
                key={index}
                onMouseEnter={() => toggle(index)}
                onMouseLeave={() => toggle(index)}
              >
                <img src={image.image} alt={image.name} />
                {hover === index && (
                  <div className="action">
                    <Button
                      text="Delete Image"
                      className="action-button"
                      onClick={() => deleteImage(image.id)}
                      isLoading={deleteStatus === LOADING ? true : false}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="view popup" style={{display:'flex', flexDirection:"column"}}>
        <img
          src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
          alt="close"
          className="close-popup"
          onClick={() => setPopup(false)}
        />
        {renderBasedOnStatus()}
      {status !== LOADING && galleryDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      </div>
      {/* {deleteStatus === ERROR && (
        <Alert
          title="Error Adding Image"
          text=""
          alertOut={setStatus}
          timeOut={3000}
        />
      )} */}
      {deleteStatus === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully deleted an image from this store branch."
          onClick={() => setDeleteStatus(IDLE)}
        />
      )}
    </>
  );
};

export default ViewStoreImagesPopup;
