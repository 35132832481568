import { useEffect, useState } from "react";
import { getToken } from "../../../storage";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import CustomerModal from "../../ui/modal/customerModal/CustomerModal";
import Paginate from "../../ui/paginate";
import CustomersTable from "../../ui/table/customersTable/CustomersTable";
import ErrorModal from "../../ui/modal/errorModel/ErrorModal";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import UserFilter from "./userFilter";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/api/API";
import CardLoader from "../../ui/cardLoader";
import { getErrorMessage, handleDate } from "../../../helper";
import { Popover } from "@headlessui/react";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import DeleteIcon from "../../../assets/delete.svg";
import { confirmAlert } from "react-confirm-alert";
import RoleModal from "./RoleModal";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Users"
      subTitle="See all your users and their details here"
    />
  );
};

const Users = () => {
  const [popup, setPopup] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [customerDetails, setCustomerDetails] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [filterClicked, setFilterClicked] = useState("SHOPPER");

  const [suspendStatus, setSuspendStatus] = useState(IDLE);
  const [activateStatus, setActivateStatus] = useState(IDLE);
  const [deleteStatus, setDeleteStatus] = useState(IDLE);

  const [totalCustomer, setTotalCustomer] = useState(0);
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      role: filterClicked,
    },
  });
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateRole = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        role: filterClicked,
      },
    }));
  };

  const handleRoleModal = (item: any) => {
    setRoleModal(true);
    setSelectedUser(item);
  };

  const updatePage = () => {
    const updatedFilter = {
      ...filter,
      page: 1,
    };
    updateFilter(updatedFilter);
  };

  useEffect(() => {
    updateRole();
  }, [filterClicked]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.role": filter.filter.role,
  };

  const getCustomers = async () => {
    setCustomerDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.users,
      params: params,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data.data.users
          .filter(
            (data: any) => data.firstName !== null && data.lastName !== null
          )
          .map((data: any, index: any) => {
            return {
              image: data.photo,
              id: data.id,
              name: data.firstName + " " + data.lastName,
              role: data.role,
              email: data.email,
              contact: data.phone,
              dateCreated: data.createdAt
            };
          });
        setCustomerDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        // setMessage(err.response.data.error);
      });
  };

  const getTotalCustomers = async () => {
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.users,
      params: { "filter.role": "SHOPPER" },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalCustomer(resp.data.data.meta.totalItems);
      })
      .catch((err) => {
        // setStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.data.error);
      });
  };

  const suspendUser = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.suspendUser(id),
    })
      .then((resp) => {
        setSuspendStatus(SUCCESS);
        setMessage("Successfully suspended user");
        getCustomers();
      })
      .catch((err) => {
        setSuspendStatus(ERROR);
        setMessage("Error suspending user");
      });
  };

  const activateUser = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.activateUser(id),
    })
      .then((resp) => {
        setActivateStatus(SUCCESS);
        getCustomers();
      })
      .catch((err) => {
        setActivateStatus(ERROR);
        setMessage("Error activating user");
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getCustomers();
  }, [filter]);

  useEffect(() => {
    getTotalCustomers();
  }, []);

  const refresh = () => {
    window.location.reload();
  };

  const deleteUser = async (id: any) => {
    setDeleteStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getAUser(id),
    })
      .then((resp) => {
        setDeleteStatus(SUCCESS);
        getCustomers();
      })
      .catch((err) => {
        setDeleteStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message));
      });
  };

  return (
    <>
      <div className="page-header">
        <PageHeading />
        {userRole === "SUPER_ADMIN" && (
          <Button
            text="Add Admin"
            className="customer-add"
            onClick={() => setPopup(true)}
          />
        )}
      </div>
      {popup && <CustomerModal setPopup={setPopup} />}
      <div className="table-wrapper">
        {status !== ERROR && (
          <UserFilter
            filterClicked={filterClicked}
            setFilterClicked={setFilterClicked}
            updatePage={updatePage}
            totalCustomer={totalCustomer}
          />
        )}
        {status === LOADING ? (
          <>
            <div
              className="details"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          </>
        ) : status === ERROR ? (
          <>
            <div
              className="details"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ErrorView />
            </div>
          </>
        ) : status === NULLMODE ? (
          <>
            <div
              className="details"
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EmptyView />
            </div>
          </>
        ) : (
          <>
            <div className="table-details" style={{ marginTop: "24px" }}>
              <div className="table-details-body">
                <div className="details-table-header" style={{ width: "100%" }}>
                  <p style={{ width: "5%", fontSize: "12px" }}>Image</p>
                  <p style={{ width: "30%", fontSize: "12px" }}>Name</p>
                  <p style={{ width: "10%", fontSize: "12px" }}>Role</p>
                  <p style={{ width: "25%", fontSize: "12px" }}>
                    Email Address
                  </p>
                  <p style={{ width: "10%", fontSize: "12px" }}>Contact</p>
                  <p style={{ width: "15%", fontSize: "12px" }}>Date Created</p>
                  <p style={{ width: "5%" }}></p>
                </div>
                {customerDetails.map((item: any, index: any) => (
                  <div key={index}>
                    <div
                      className="details-table-body"
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          width: "5%",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            // marginRight:"24px"
                          }}
                          onClick={() => navigate(`${item?.id}`)}
                        >
                          <img
                            src={
                              item?.image ||
                              "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                            }
                            alt={item?.name}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <p
                        style={{ width: "30%", fontSize: "12px" }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        {item?.name}
                      </p>
                      <p
                        style={{ width: "10%", fontSize: "12px" }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        {item?.role}
                      </p>
                      <p
                        style={{ width: "25%", fontSize: "12px" }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        {item?.email}
                      </p>
                      <p
                        style={{ width: "10%", fontSize: "12px" }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        {item?.contact}
                      </p>
                      <p
                        style={{ width: "15%", fontSize: "12px" }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        {handleDate(item?.dateCreated)}
                      </p>
                      <Popover
                        className="flex-1 text-right"
                        style={{ width: "5%" }}
                      >
                        <>
                          <Popover.Button
                            className="popover-button"
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                            }}
                          >
                            <img src={MoreIcon} alt="" />
                          </Popover.Button>

                          <Popover.Panel
                            className="popover-panel"
                            style={{
                              position: "absolute",
                              right: "64px",
                              zIndex: "500",
                              // padding: "14px",
                              width: "150px",
                              background: "white",
                              borderRadius: "16px",
                              boxShadow: "5px 5px #00000030",
                              border: "0.5px solid #00000030",
                            }}
                          >
                            <div
                              className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                              style={{ borderRadius: "16px" }}
                            >
                              <div
                                className="items-center grid gap-4 bg-white px-5 py-4"
                                style={{
                                  alignItems: "center",
                                  background: "white",
                                  padding: "16px, 20px",
                                }}
                              >
                                <div
                                  onClick={() => navigate(`${item?.id}`)}
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img src={ViewIcon} alt="" />
                                  <p className="pl-2 text-[#B00020]">View</p>
                                </div>
                                {item?.role !== "SHOPPER" && (
                                  <div
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleRoleModal(item)}
                                  >
                                    <img src={EditIcon} alt="" />
                                    <p className="pl-2 text-[16px]">
                                      Change Role
                                    </p>
                                  </div>
                                )}
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    confirmAlert({
                                      title:
                                        "Are you sure you want to delete this user?",
                                      message:
                                        "This user will be deleted from the system and you won't have access to them again",
                                      buttons: [
                                        {
                                          label: "Cancel",
                                          // Do Close operation
                                        },
                                        {
                                          label: "Delete User",
                                          onClick: () => deleteUser(item?.id),
                                        },
                                      ],
                                    });
                                  }}
                                >
                                  <img src={DeleteIcon} alt="" />
                                  <p
                                    className="pl-2 text-[16px]"
                                    style={{ color: "red" }}
                                  >
                                    Delete
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </>
                      </Popover>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {status !== LOADING && customerDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {(activateStatus === ERROR ||
        suspendStatus === SUCCESS ||
        deleteStatus === SUCCESS) && (
        <ErrorModal title="Error!" subTitle={message} onClick={refresh} />
      )}
      {(activateStatus === SUCCESS ||
        suspendStatus === SUCCESS ||
        deleteStatus === SUCCESS) && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {deleteStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
      {roleModal && <RoleModal data={selectedUser} setPopup={setRoleModal} />}
    </>
  );
};

export default Users;
