import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui";
// import "./OverviewCard.scss";
import CardLoader from "../../../ui/cardLoader";
import { useState } from "react";
import { handlePrice } from "../../../../helper";
import CustomPopup from "../customPopup/CustomPopup";

interface IMetricsProps {
  totalRevenue?: any;
  totalOrder?: any;
  totalStoreBranches?: any;
  isRevenueLoading?: any;
  isOrderLoading?: any;
  isStoreBranchLoading?: any;
  revenue?: boolean;
  revenueDateFilter?: any;
  setRevenueDateFilter?: any;
  orderDateFilter?: any;
  setOrderDateFilter?: any;
  getLastNDaysFormatted?: any;
  setIsClearRevenueFilter?: any;
  setIsClearOrderFilter?: any;
  userRole?: any;
}

const BusinessMetricsCard = (props: IMetricsProps) => {
  const {
    totalRevenue,
    totalOrder,
    isRevenueLoading,
    isOrderLoading,
    isStoreBranchLoading,
    revenue,
    revenueDateFilter,
    setRevenueDateFilter,
    orderDateFilter,
    setOrderDateFilter,
    getLastNDaysFormatted,
    setIsClearRevenueFilter,
    setIsClearOrderFilter,
    userRole,
  } = props;
  const Navigate = useNavigate();
  const [revenueFilter, setRevenueFilter] = useState("");
  const [revenueFilterClicked, setRevenueFilterClicked] = useState(0);
  const [revenueDropdown, setRevenueDropdown] = useState(false);
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterClicked, setOrderFilterClicked] = useState(0);
  const [orderDropdown, setOrderDropdown] = useState(false);

  function getStartAndEndDate() {
    const currentDate = new Date();

    // Start date: First day of the current month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedStartDate = formatDate(startOfMonth);

    // End date: Tomorrow's date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedEndDate = formatDate(tomorrow);

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  }

  function getStartAndEndDateOfWeek() {
    const currentDate = new Date();

    // Start date: First day of the current week (Monday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(
      currentDate.getDate() -
        currentDate.getDay() +
        (currentDate.getDay() === 0 ? -6 : 1)
    );
    const formattedStartDate = formatDate(startOfWeek);

    // End date: Tomorrow's date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedEndDate = formatDate(tomorrow);

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  }

  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const filterMap = [
    {
      name: "Today",
      value: "Today",
      dateRange: getLastNDaysFormatted(1),
    },
    {
      name: "This Week",
      value: "This Week",
      dateRange: getStartAndEndDateOfWeek(),
    },
    {
      name: "This Month",
      value: "This Month",
      dateRange: getStartAndEndDate(),
    },
    {
      name: "All Time",
      value: "All Time",
      dateRange: "clearFilter",
    },
  ];
  const handleClicked = (
    index: any,
    item: any,
    dateRange: any,
    filterClicked: any,
    setFilterClicked: any,
    setFilter: any,
    setDropdown: any,
    setIsClearFilter: any,
    setDateFilter: any
  ) => {
    if (filterClicked === index) {
      setFilterClicked(1);
      setFilter(item[0]);
      dateRange === "clearFilter"
        ? setIsClearFilter(true)
        : setIsClearFilter(false);
      setDateFilter(dateRange[0]);
      setDropdown(false);
    }
    setFilterClicked(index);
    setFilter(item);
    dateRange === "clearFilter"
      ? setIsClearFilter(true)
      : setIsClearFilter(false);
    setDateFilter(dateRange);
    setDropdown(false);
  };

  const handleClick = (path: any) => {
    Navigate(path);
  };

  const overviewCardDetails = [
    {
      icon: "",
      description: "Total Revenue",
      details: `${handlePrice(totalRevenue)}`,
      className: "fa-solid fa-people-group",
      path: "/revenue/",
      isLoading: isRevenueLoading,
      filter: true,
    },
    {
      icon: "",
      description: "Total Order Completed",
      details: `${totalOrder} order`,
      className: "fa-solid fa-people-group",
      path: "/orders/",
      isLoading: isOrderLoading,
      filter: true,
    },
  ];

  const [customPopup, setCustomPopup] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(
    overviewCardDetails.length + 1
  );

  const toggle = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(overviewCardDetails.length + 1);
    } else {
      setClickedIndex(index);
    }
  };

  return (
    <>
      <div className="cards">
        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            <div
              className="custom-date-filter"
              style={{
                fontSize: "12px",
                padding: "4px 8px",
                borderRadius: "4px",
                color: "white",
                backgroundColor: "#FD5C1D",
                cursor: "pointer",
                marginRight: "-100px",
              }}
              onClick={() => setCustomPopup(true)}
            >
              Custom
            </div>
            <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setRevenueDropdown(!revenueDropdown)}
              >
                <h6>{revenueFilter === "" ? "today" : revenueFilter}</h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {revenueDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          revenueFilterClicked,
                          setRevenueFilterClicked,
                          setRevenueFilter,
                          setRevenueDropdown,
                          setIsClearRevenueFilter,
                          setRevenueDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* END */}
          </div>
          {isRevenueLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Revenue</p>
              <p className="detail">{handlePrice(totalRevenue)}</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            {/* <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setOrderDropdown(!orderDropdown)}
              >
                <h6>{orderFilter === "" ? "today" : orderFilter}</h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {orderDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          orderFilterClicked,
                          setOrderFilterClicked,
                          setOrderFilter,
                          setOrderDropdown,
                          setIsClearOrderFilter,
                          setOrderDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {/* END */}
          </div>
          {isOrderLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Order Completed</p>
              <p className="detail">{totalOrder} Order</p>
            </div>
          )}
        </div>
      </div>
      {customPopup && (
        <CustomPopup
          setPopup={setCustomPopup}
          setDateFilter={setRevenueDateFilter}
        />
      )}
    </>
  );
};

export default BusinessMetricsCard;
