import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import EditPromoPopup from "./editPromoPopup/EditPromoPopup";
import { useNavigate } from "react-router-dom";
import API from "../../../../../utils/api/API";
import { handlePrice } from "../../../../../helper";
import Heading from "../../../../ui/heading";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../../../assets/more.svg";
import EditIcon from "../../../../../assets/edit.svg";
import CardLoader from "../../../../ui/cardLoader";

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return <Heading title="Promo" subTitle="See your promo settings here" />;
};

const PromoSetting = () => {
  const [promoDetails, setPromoDetails] = useState<any>();
  const [popup, setPopup] = useState(false);

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedPromo, setSelectedPromo] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  const handleEdit = (promo: any) => {
    setSelectedPromo(promo);
    setIsEdit(true);
    setPopup(true);
  };

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const getPromo = async () => {
    setPromoDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.promo,
    })
      .then((resp) => {
        setStatus(SUCCESS);

        setPromoDetails(resp.data?.data);
      })
      .catch((err) => {
        console.log(err);
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getPromo();
  }, []);

  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className=""></div>
      </div>
      {status === "LOADING" ? (
        <div
          className="details"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardLoader />
        </div>
      ) : (
        <div className="table-details" style={{ marginTop: "24px" }}>
          <div className="table-details-body">
            <div className="details-table-header" style={{ width: "100%" }}>
              <p style={{ width: "30%", fontSize: "12px" }}>Promo Ongoing?</p>
              <p style={{ width: "30%", fontSize: "12px" }}>Promo Amount</p>
              <p style={{ width: "30%", fontSize: "12px" }}>Promo Discount</p>
              <p style={{ width: "5%" }}></p>
            </div>
            {/* {promoDetails?.map((item: any, index: any) => ( */}
            <div>
              <div className="details-table-body" style={{ width: "100%" }}>
                <p style={{ width: "30%", fontSize: "12px" }}>
                  {promoDetails?.isPromoOngoing ? "Yes" : "No"}
                </p>
                <p style={{ width: "30%", fontSize: "12px" }}>
                  {handlePrice(promoDetails?.promoAmount) || "N/A"}
                </p>
                <p style={{ width: "30%", fontSize: "12px" }}>
                  {handlePrice(promoDetails?.promoDiscount) || "N/A"}
                </p>
                <Popover className="flex-1 text-right" style={{ width: "5%" }}>
                  <>
                    <Popover.Button
                      className="popover-button"
                      style={{
                        cursor: "pointer",
                        background: "transparent",
                      }}
                    >
                      <img src={MoreIcon} alt="" />
                    </Popover.Button>

                    <Popover.Panel
                      className="popover-panel"
                      style={{
                        position: "absolute",
                        right: "64px",
                        zIndex: "500",
                        // padding: "14px",
                        width: "150px",
                        background: "white",
                        borderRadius: "16px",
                        boxShadow: "5px 5px #00000030",
                        border: "0.5px solid #00000030",
                      }}
                    >
                      <div
                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                        style={{ borderRadius: "16px" }}
                      >
                        <div
                          className="items-center grid gap-4 bg-white px-5 py-4"
                          style={{
                            alignItems: "center",
                            background: "white",
                            padding: "16px, 20px",
                          }}
                        >
                          <div
                            className="flex items-center"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 14px",
                              gap: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEdit(promoDetails)}
                          >
                            <img src={EditIcon} alt="" />
                            <p className="pl-2 text-[#B00020]">Edit Promo</p>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </>
                </Popover>
              </div>
            </div>
            {/* ))} */}
          </div>
        </div>
      )}
      {popup && (
        <EditPromoPopup
          getPromo={getPromo}
          setPopup={setPopup}
          data={selectedPromo}
          isEdit={isEdit}
        />
      )}
    </>
  );
};

export default PromoSetting;
