import FeeSettings from "../components/dashboard/settings/settingsBody/feeSettings";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";

const FeeSettingsPage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <FeeSettings />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default FeeSettingsPage;
