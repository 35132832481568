import { useForm } from "react-hook-form";
import { useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";

interface ModalProps {
  setPopup: any;
  data?: any;
  picture?:string;
  name:string;
  isEdit?: boolean;
  orderId?: any;
  orderItems?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const ViewProductImagePopup = (props: ModalProps) => {

  const { setPopup, name, picture } = props;

  return (
    <>
      <div className="popup wider">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={name}
          />
          <form className="add-form">
          <img
            src={picture}
            alt="close"
            // className="close"
            onClick={() => setPopup(false)}
          />
          </form>
        </div>
        
      </div>
    </>
  );
};

export default ViewProductImagePopup;
