import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { Button, Dropdown } from "../../../ui";
import "./OrderInformation.scss";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../ui/select";
import {
  dropperOrderStatusEnum,
  orderStatusEnum,
  storeOrderStatusEnum,
} from "../../../../data/statusEnum";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import ErrorModal from "../../../ui/modal/errorModel/ErrorModal";
import { getDropper, getErrorMessage, handlePrice } from "../../../../helper";
import API from "../../../../utils/api/API";
import { ToggleButton } from "@mui/material";
import Switch from "../../../ui/switch";
import Check from "../../../../assets/check.svg";
import ArrivedPopup from "./ArrivedPopup";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface OrderSingleProps {
  instruction?: string;
  modelId?: string;
  orderRef?: string;
  status?: string;
  deliveryFee?: string;
  paymentGateway?: string;
  paymentMethod?: string;
  paymentReference?: string;
  serviceFee?: string;
  storeBranch?: string;
  storeBranchId?: string;
  storeBranchAddress?: string;
  total?: string;
  subTotal?: string;
  dropperSubTotal?: string;
  refundAmounts?: string;
  id?: string;
  chefInstruction?: string;
  ratingContent?: string;
  ratingValue?: number;
  getOrderSingleData?: any;
}

const OrderInformation = (props: OrderSingleProps) => {
  const {
    instruction,
    modelId,
    orderRef,
    status,
    deliveryFee,
    paymentGateway,
    paymentMethod,
    paymentReference,
    serviceFee,
    storeBranch,
    storeBranchId,
    storeBranchAddress,
    dropperSubTotal,
    refundAmounts,
    subTotal,
    total,
    id,
    getOrderSingleData,
    chefInstruction,
    ratingContent,
    ratingValue,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const [statusUpdate, setStatusUpdate] = useState(IDLE);
  const [statuss, setStatuss] = useState();
  const [message, setMessage] = useState("");
  const [dropper, setDropper] = useState([]);
  const [isDropperLoading, setIsDropperLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [isArrived, setIsArrived] = useState<boolean>(true);
  const [arrivedPopup, setArrivedPopup] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [dropperUpdate, setDropperUpdate] = useState(IDLE);
  const [ratingStatus, setRatingStatus] = useState(IDLE);
  const [ratingDetails, setRatingDetails] = useState<any>([]);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    getDropper(setDropper, setIsDropperLoading);
  }, []);

  const updateOrder = (request: any, setStatus: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateOrder(id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        // setPopup(false)
        // setImagePopup(true);
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onSubmit = (data: any) => {
    updateOrder(data, setStatusUpdate);
  };

  const submit = (data: any) => {
    data.status = "DROPPING";
    updateOrder(data, setDropperUpdate);
  };

  const ordersInformation = [
    {
      description: "Order Ref",
      value: orderRef,
    },
    {
      description: "Payment Reference",
      value: paymentReference,
    },
    {
      description: "Payment Method",
      value: paymentGateway,
    },
    {
      description: "Store Branch",
      value: storeBranch,
    },
    {
      description: "Store Branch Address",
      value: storeBranchAddress,
    },
    {
      description: "Subtotal",
      value: handlePrice(subTotal),
    },
    {
      description: "Dropper Subtotal",
      value: handlePrice(dropperSubTotal),
    },
    {
      description: "Delivery Fee",
      value: handlePrice(deliveryFee),
    },
    {
      description: "Service Fee",
      value: handlePrice(serviceFee),
    },
    {
      description: "Total",
      value: handlePrice(total),
    },
    {
      description: "Refund Amount",
      value: handlePrice(refundAmounts),
    },
    {
      description: "Status",
      value: status,
    },
    {
      description: "Chef Instruction",
      value: chefInstruction || "N/A",
    },
    {
      description: "Dropper Instruction",
      value: instruction || "N/A",
    },
    {
      description: "Rating",
      value: ratingValue || "N/A",
    },
    {
      description: "Rating comment",
      value: ratingContent || "N/A",
    },
  ];

  const storeOrdersInformation = [
    {
      description: "Order Ref",
      value: orderRef,
    },
    {
      description: "Store Branch Address",
      value: storeBranchAddress,
    },
    {
      description: "Subtotal",
      value: handlePrice(subTotal),
    },
    {
      description: "Status",
      value: status,
    },
  ];

  const dropperOrdersInformation = [
    {
      description: "Order Ref",
      value: orderRef,
    },
    {
      description: "Store Branch Address",
      value: storeBranchAddress,
    },
    {
      description: "Subtotal",
      value: handlePrice(subTotal),
    },
    {
      description: "Status",
      value: status,
    },
  ];

  const refresh = () => {
    window.location.reload();
  };

  const handleCompleteUdate = () => {
    setArrivedPopup(true);
    setOrderStatus("COMPLETED");
  };

  return (
    <>
      <div className="order-information">
        <div
          className="store-branch-details dropper-order-details"
          style={{ overflow: "visible" }}
        >
          {(userRole === "DROPPER"
            ? dropperOrdersInformation
            : userRole === "STORE_ADMIN"
            ? storeOrdersInformation
            : ordersInformation
          ).map((info: any, index) => (
            <div
              className={
                info.imageSRC
                  ? "store-branch-detail images"
                  : "store-branch-detail dropper-order-detail"
              }
              key={index}
            >
              {info.imageSRC && (
                <img className="image" src={info.imageSRC} alt="image" />
              )}
              <div className="description">
                <p className="type3">{info.description}</p>
                <h5
                  style={{
                    fontWeight: "bold",
                    margin: "10px 0 0",
                    color: `${
                      info.description === "Order Ref"
                        ? "#7435B2"
                        : info.description === "Status"
                        ? "#FD5C1D"
                        : "#222222"
                    }`,
                  }}
                >
                  {info.value}
                </h5>
              </div>
            </div>
          ))}

          {userRole === "STORE_ADMIN" ? (
            <></>
          ) : (
            <div
              className={`store-branch-detail ${
                userRole === "DROPPER" && "mobile-hidden"
              }`}
            >
              <div className="description">
                <p className="type3">Update Order Status</p>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={
                          userRole === "DROPPER"
                            ? dropperOrderStatusEnum
                            : userRole === "STORE_ADMIN"
                            ? storeOrderStatusEnum
                            : orderStatusEnum
                        }
                        onChange={(val: any) => {
                          onChange(val.value);
                          setStatuss(val.value);
                        }}
                      />
                    )}
                  />
                  <Button
                    className="add-button"
                    text="Update Status"
                    isLoading={statusUpdate === "LOADING" ? true : false}
                    disabled={statuss === undefined ? true : false}
                  />
                </form>
              </div>
            </div>
          )}
          {userRole === "DROPPER" ? (
            <></>
          ) : (
            <div
              className="store-branch-detail"
              style={{ overflow: "visible" }}
            >
              <div className="description">
                <p className="type3">Assign Dropper</p>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  onSubmit={handleSubmit(submit)}
                >
                  <Controller
                    name="dropperId"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={dropper}
                        onChange={(val: any) => {
                          onChange(val.value);
                        }}
                        disabled={isDropperLoading}
                      />
                    )}
                  />
                  <Button
                    className="add-button"
                    text="Assign Dropper"
                    isLoading={dropperUpdate === "LOADING" ? true : false}
                  />
                </form>
              </div>
            </div>
          )}
        </div>
        <div className="page-action-container">
          {userRole === "DROPPER" && status !== "COMPLETED" && (
            <p style={{ fontWeight: "500" }}>Update Order Status</p>
          )}

          {userRole === "DROPPER" &&
            status !== "ARRIVED" &&
            status !== "COMPLETED" && (
              <div className="arrived-action-section">
                <p style={{ fontWeight: "500" }}>Arrived</p>
                <Switch
                  textOff=" "
                  textOn=" "
                  width="38px"
                  height="22.8px"
                  innerWidth="18.78px"
                  bgColor="#2EBB55"
                  circleLeft="1.34px"
                  circleRight="1.34px"
                  value={isArrived}
                  onChange={setIsArrived}
                  setPopup={setArrivedPopup}
                />
              </div>
            )}

          {userRole === "DROPPER" && status !== "COMPLETED" && (
            <div className="completed-action-bar">
              <div className="action-description">
                <p>Mark as Completed</p>
                <div className="check-mark">
                  <img src={Check} alt="check" />
                </div>
              </div>
              <div className="action-bar">
                <button onClick={() => handleCompleteUdate()}>Update</button>
              </div>
            </div>
          )}
        </div>
      </div>
      {(statusUpdate === SUCCESS || dropperUpdate === SUCCESS) && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {(statusUpdate === ERROR || dropperUpdate === ERROR) && (
        <ErrorModal title="Error!" subTitle={message} onClick={refresh} />
      )}
      {arrivedPopup && (
        <ArrivedPopup
          value={isArrived}
          id={id}
          setPopup={setArrivedPopup}
          setIsAvailable={setIsArrived}
          orderStatus={orderStatus}
          setOrderStatus={setOrderStatus}
        />
      )}
    </>
  );
};

export default OrderInformation;
