import "./Search.scss";
import SearchIcon from "../../../assets/search.svg";
import { useState } from "react";

interface SearchProps {
  className?: string;
  placeholder?: string;
  isIconRight?: boolean;
  onChange?: Function;
  value?: string;
  setValue?: any;
  setIsClearFilter?: any;
  id?: string;
  name?: string;
  invertStyle?: boolean;
}

const Search = (props: SearchProps) => {
  const {
    isIconRight,
    placeholder,
    className,
    onChange,
    value,
    setValue,
    setIsClearFilter,
    id,
    name,
    invertStyle,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      // Trigger the API call when the Enter key is pressed
      setIsClearFilter && setIsClearFilter(false);
      setValue(searchTerm);
      // handleSearch();
    }
  };

  const handleSearchInput = (e: any) => {
    if (e.target.value === "") {
      // Here, you can perform the action when the clear icon is clicked
      setValue("");
    }
  };

  return (
    <>
      <div
        className={`search-input ${
          invertStyle ? "search-input-invert" : ""
        } ${className}`}
      >
        {!isIconRight && (
          <img src={SearchIcon} alt="search" className="search" />
        )}
        <input
          type="search"
          placeholder={placeholder ? placeholder : "Search..."}
          className={isIconRight ? "input-shift" : ""}
          onChange={(e) => setSearchTerm?.(e.target.value)}
          value={value}
          id={id}
          name={name}
          onKeyDown={handleKeyDown}
          onInput={handleSearchInput}
        />
        {isIconRight && (
          <img src={SearchIcon} alt="search" className="search" />
        )}
      </div>
    </>
  );
};

export default Search;
