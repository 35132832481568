import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import OrdersTable from "../../ui/table/ordersTable/OrdersTable";
import OrderFilter from "./OrderFilter";
import "./Order.scss";
import API from "../../../utils/api/API";
import CardLoader from "../../ui/cardLoader";
import { getErrorMessage, handlePrice } from "../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import ErrorView from "../../ui/ErrorView";
import EmptyView from "../../ui/emptyView";
import SuperPacker from "./orderPageComponents/SuperPacker";
import Dropper from "./orderPageComponents/Dropper";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import DeleteIcon from "../../../assets/delete.svg";
import ActivateIcon from "../../../assets/activate.svg";
import { toast } from "react-toastify";

const PageHeading = () => {
  return (
    <Heading
      title="Orders"
      subTitle="See all your orders and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const Orders = () => {
  const [Popup, setPopup] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [cancelStatus, setCancelStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [isClearFilter, setIsClearFilter] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);

  const [completedFilter, setCompletedFilter] = useState(false);
  const [storeFilterClicked, setStoreFilterClicked] = useState(
    "$in:ACCEPTED_BY_STORE,ARRIVED,REJECTED_BY_STORE,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,COMPLETED,REFUNDED,PAYMENT_RECEIVED"
  );
  const [filterClicked, setFilterClicked] = useState(
    "$in:STARTED,ACCEPTED_BY_STORE,ARRIVED,REJECTED_BY_STORE,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,COMPLETED,REFUNDED,PAYMENT_RECEIVED"
  );
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const [startDate, setStartDate] = useState("2023-01-01");
  const [endDate, setEndDate] = useState(
    currentDate.toISOString().slice(0, 10)
  );
  const [isScheduledOrder, setIsScheduledOrder] = useState(false);
  const [userRole, setUserRole] = useState("");
  const Navigate = useNavigate();

  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      status:
        userRole !== "" && userRole === "STORE_ADMIN"
          ? storeFilterClicked
          : filterClicked,
      createdAt: `$btw:${startDate},${endDate}`,
      isScheduled: false,
    },
  });

  const location = useLocation();

  // Get the 'page' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1; // Default to page 1 if 'page' param is not present

  const [storeBranchId, setStoreBranchId] = useState("");
  const [dropperId, setDropperId] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      const storeBranchId = user.storeBranchId;
      const dropperId = user.id;
      setUserRole(role);
      setStoreBranchId(storeBranchId);
      setDropperId(dropperId);
    }
  }, []);

  const updateStatus = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        status:
          userRole !== "" && userRole === "STORE_ADMIN"
            ? `${storeFilterClicked}`
            : `${filterClicked}`,
        createdAt: `$btw:${startDate},${endDate}`,
        isScheduled: isScheduledOrder,
      },
    }));
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const param = isClearFilter
    ? { limit: filter.limit, page: currentPage, "filter.isFoodOrder": false }
    : isScheduledOrder
    ? {
        limit: filter.limit,
        page: currentPage,
        "filter.isFoodOrder": false,
        isScheduled: filter.filter.isScheduled,
      }
    : {
        limit: filter.limit,
        page: currentPage,
        "filter.isFoodOrder": false,
        // isScheduled: filter.filter.isScheduled,
        "filter.status": filter.filter.status,
        "filter.createdAt": filter.filter.createdAt,
      };

  const storeParams = completedFilter
    ? {
        limit: filter.limit,
        page: currentPage,
        "filter.storeBranch.id": storeBranchId,
        "filter.status": "$in:COMPLETED",
      }
    : {
        limit: filter.limit,
        page: currentPage,
        "filter.storeBranch.id": storeBranchId,
        "filter.status":
          "$in:ACCEPTED_BY_STORE,REJECTED_BY_STORE,ARRIVED,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,REFUNDED,PAYMENT_RECEIVED",
      };

  const dropperParams = completedFilter
    ? {
        limit: filter.limit,
        page: currentPage,
        "filter.dropper.id": dropperId,
        "filter.storeBranch.id": storeBranchId,
        "filter.status": "$in:COMPLETED",
      }
    : {
        limit: filter.limit,
        page: currentPage,
        "filter.dropper.id": dropperId,
        "filter.storeBranch.id": storeBranchId,
        "filter.status":
          "$in:ACCEPTED_BY_STORE,REJECTED_BY_STORE,ARRIVED,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,REFUNDED,PAYMENT_RECEIVED",
      };

  const storeParam = isClearFilter
    ? {
        limit: filter.limit,
        page: currentPage,
        "filter.storeBranch.id": storeBranchId,
        "filter.status": storeFilterClicked,
      }
    : {
        limit: filter.limit,
        page: currentPage,
        "filter.status": storeFilterClicked,
        "filter.storeBranch.id": storeBranchId,
        "filter.createdAt": filter.filter.createdAt,
      };

  useEffect(() => {
    updateStatus();
  }, [filterClicked, storeFilterClicked, startDate, endDate, isScheduledOrder]);

  const handleAddModal = () => {
    setIsEdit(false);
    setPopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedOrder(data);
    setPopup(true);
  };

  const getOrders = async () => {
    setOrderDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getOrders,
      params:
        userRole === "STORE_ADMIN"
          ? storeParams
          : userRole === "DROPPER"
          ? dropperParams
          : param,
    })
      .then((resp) => {
        setStatus(SUCCESS);

        setTotalItems(resp.data.data.meta.totalItems);

        // const filteredOrders = resp.data?.data.orders.filter(
        //   (data:any) => data.status !== "STARTED"
        // );

        const formattedList = resp.data?.data.orders.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              store: data.storeBranch?.name,
              deliveryFee: data.deliveryFee,
              orderRef: data.orderRef,
              serviceFee: data.serviceFee,
              status: data.status,
              createdAt: data.createdAt,
              subTotal: data.subTotal,
              customerName: data?.user?.firstName + " " + data?.user?.lastName,
            };
          }
        );
        setOrderDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
      });
  };

  // onst filteredArray = originalArray.filter((item) => item.status !== "STARTED");

  // const storeOrdersToShow = orderDetails.filter(
  //   (item: any) => item.status !== "STARTED"
  // );

  const cancelOrder = async (id: any) => {
    setCancelStatus(LOADING);
    setMessage("");
    const payload = {
      status: "CANCELLED",
    };
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateOrder(id),
      data: payload,
    })
      .then((resp) => {
        setCancelStatus(SUCCESS);
        toast.success(resp.data.message);
        getOrders();
      })
      .catch((err) => {
        setCancelStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteOrder = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getOrder(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getOrders();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
    handlePaginationClick(result.currentPage);
  };

  const handlePaginationClick = (pageNumber: any) => {
    // Update the URL query parameter when pagination button is clicked
    queryParams.set("page", pageNumber);
    const newUrl = `?${queryParams.toString()}`;
    // Use navigate to update the URL without refreshing the page
    window.location.search = newUrl;
  };

  useEffect(() => {
    if (userRole === "") {
      setStatus(LOADING);
    } else {
      getOrders();
    }
  }, [filter, isClearFilter, userRole, completedFilter]);

  return (
    <>
      <PageHeading />

      {userRole === "STORE_ADMIN" ? (
        <SuperPacker
          data={orderDetails}
          status={status}
          completedFilter={completedFilter}
          setCompletedFilter={setCompletedFilter}
        />
      ) : (
        // <OrdersTable
        //   clickable
        //   data={orderDetails}
        //   emptyHeight="530px"
        //   // arrange={overviewDropFilter}
        //   isCardLoading={status === LOADING ? true : false}
        //   isCardError={status === ERROR ? true : false}
        //   dataNull={status === NULLMODE ? true : false}
        //   message={message}
        //   deleteOrder={deleteOrder}
        //   handleEditModal={handleEditModal}
        // />
        <div></div>
      )}

      {userRole === "DROPPER" ? (
        <Dropper
          data={orderDetails}
          status={status}
          completedFilter={completedFilter}
          setCompletedFilter={setCompletedFilter}
        />
      ) : (
        // <OrdersTable
        //   clickable
        //   data={orderDetails}
        //   emptyHeight="530px"
        //   // arrange={overviewDropFilter}
        //   isCardLoading={status === LOADING ? true : false}
        //   isCardError={status === ERROR ? true : false}
        //   dataNull={status === NULLMODE ? true : false}
        //   message={message}
        //   deleteOrder={deleteOrder}
        //   handleEditModal={handleEditModal}
        // />
        <div></div>
      )}

      {(userRole === "ADMIN" || userRole === "SUPER_ADMIN") && (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("orders") && "active"
                }`}
                onClick={() => Navigate("/orders/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-order") && "active"
                }`}
                onClick={() => Navigate("/drop-food-order/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div className={"table-wrapper"}>
            {(status !== LOADING && userRole === "ADMIN") ||
              (userRole === "SUPER_ADMIN" && (
                <OrderFilter
                  filterClicked={filterClicked}
                  setFilterClicked={setFilterClicked}
                  setStoreFilterClicked={setStoreFilterClicked}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  getOrders={getOrders}
                  setIsClearFilter={setIsClearFilter}
                  setIsScheduledOrder={setIsScheduledOrder}
                />
              ))}
            {status === LOADING ? (
              <div
                className="details"
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardLoader />
              </div>
            ) : status === ERROR ? (
              <div
                className="details"
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ErrorView />
              </div>
            ) : status === NULLMODE ? (
              <div
                className="details"
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EmptyView />
              </div>
            ) : userRole === "ADMIN" || userRole === "SUPER_ADMIN" ? (
              <div className="table-details" style={{ marginTop: "24px" }}>
                <div className="table-details-body">
                  <div
                    className="details-table-header"
                    style={{ width: "100%" }}
                  >
                    <p style={{ width: "5%", fontSize: "12px" }}>No</p>
                    <p style={{ width: "15%", fontSize: "12px" }}>Order Ref</p>
                    <p style={{ width: "15%", fontSize: "12px" }}>Stores</p>
                    <p style={{ width: "15%", fontSize: "12px" }}>
                      Delivery Fee
                    </p>
                    <p style={{ width: "15%", fontSize: "12px" }}>
                      Service Fee
                    </p>
                    <p style={{ width: "20%", fontSize: "12px" }}>Status</p>
                    <p
                      style={{
                        width: "10%",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Actions
                    </p>
                  </div>
                  {orderDetails.map((item: any, index: any) => (
                    <div key={index}>
                      <div
                        className="details-table-body"
                        style={{ width: "100%" }}
                      >
                        <p
                          style={{ width: "5%", fontSize: "12px" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          {index + 1}
                        </p>
                        <p
                          style={{ width: "15%", fontSize: "12px" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          {item?.orderRef}
                        </p>
                        <p
                          style={{ width: "15%", fontSize: "12px" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          {item?.store}
                        </p>
                        <p
                          style={{ width: "15%", fontSize: "12px" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          {handlePrice(item?.deliveryFee)}
                        </p>
                        <p
                          style={{ width: "15%", fontSize: "12px" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          {handlePrice(item?.serviceFee)}
                        </p>
                        <p
                          style={{ width: "20%" }}
                          onClick={() => Navigate(`${item?.id}`)}
                        >
                          <span
                            style={{
                              padding: "4px 8px",
                              fontSize: "12px",
                              borderRadius: "4px",
                              color: `${
                                item?.status === "STARTED" ||
                                item?.status === "COMPLETED" ||
                                item?.status === "ACCEPTED_BY_RIDER"
                                  ? "#2EBB55"
                                  : item?.status === "REJECTED_BY_STORE" ||
                                    item?.status === "FLAGGED"
                                  ? "#eb5757"
                                  : item?.status === "PICKING_UP" ||
                                    item?.status === "REFUNDED" ||
                                    item?.status === "IN_STOCK" ||
                                    item?.status === "ACCEPTED_BY_STORE"
                                  ? "#1d3588"
                                  : "#f1b418"
                              }`,
                              background: `${
                                item?.status === "STARTED" ||
                                item?.status === "COMPLETED" ||
                                item?.status === "ACCEPTED_BY_RIDER"
                                  ? "#2EBB5526"
                                  : item?.status === "REJECTED_BY_STORE" ||
                                    item?.status === "FLAGGED"
                                  ? "#eb575726"
                                  : item?.status === "PICKING_UP" ||
                                    item?.status === "REFUNDED" ||
                                    item?.status === "IN_STOCK" ||
                                    item?.status === "ACCEPTED_BY_STORE"
                                  ? "#1d358826"
                                  : "#f1b41826"
                              }`,
                            }}
                          >
                            {item?.status}
                          </span>
                        </p>
                        <Popover
                          className="flex-1 text-right"
                          style={{ width: "10%", textAlign: "center" }}
                        >
                          <>
                            <Popover.Button
                              className="popover-button"
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                              }}
                            >
                              <img src={MoreIcon} alt="" />
                            </Popover.Button>

                            <Popover.Panel
                              className="popover-panel"
                              style={{
                                position: "absolute",
                                right: "64px",
                                zIndex: "500",
                                // padding: "14px",
                                width: "150px",
                                background: "white",
                                borderRadius: "16px",
                                boxShadow: "5px 5px #00000030",
                                border: "0.5px solid #00000030",
                              }}
                            >
                              <div
                                className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                style={{ borderRadius: "16px" }}
                              >
                                <div
                                  className="items-center grid gap-4 bg-white px-5 py-4"
                                  style={{
                                    alignItems: "center",
                                    background: "white",
                                    padding: "16px, 20px",
                                  }}
                                >
                                  <div
                                    onClick={() => Navigate(`${item?.id}`)}
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img src={ViewIcon} alt="" />
                                    <p className="pl-2 text-[#B00020]">
                                      View Order
                                    </p>
                                  </div>
                                  <div
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to cancel this order?",
                                        message:
                                          "This order will be canceled and you will not be able to process them further",
                                        buttons: [
                                          {
                                            label: "Go Back",
                                            // Do Close operation
                                          },
                                          {
                                            label: "Cancel Order",
                                            onClick: () =>
                                              cancelOrder(item?.id),
                                          },
                                        ],
                                      });
                                    }}
                                  >
                                    <img src={EditIcon} alt="" />
                                    <p className="pl-2 text-[16px]">
                                      Cancel Order
                                    </p>
                                  </div>
                                  {item?.status === "STARTED" &&
                                    (userRole === "ADMIN" ||
                                      userRole === "SUPER_ADMIN") && (
                                      <div
                                        className="flex items-center"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px 14px",
                                          gap: "8px",
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                        onClick={() => {
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this order?",
                                            message:
                                              "This order’s data will be erased and you will not be able to retrieve them",
                                            buttons: [
                                              {
                                                label: "Cancel",
                                                // Do Close operation
                                              },
                                              {
                                                label: "Delete",
                                                onClick: () =>
                                                  deleteOrder(item?.id),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <img src={DeleteIcon} alt="" />
                                        <p className="pl-2 text-[16px]">
                                          Delete Order
                                        </p>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </Popover.Panel>
                          </>
                        </Popover>
                        {/* <p
                        style={{
                          width: "10%",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <span
                          style={{
                            color: "#32BA56",
                            padding: "4px 8px",
                            background: "#E4F4E6",
                            // border: "0.5px solid #32BA56",
                            borderRadius: "4px",
                          }}
                          onClick={() => Navigate(item?.id)}
                        >
                          View
                        </span>
                        {item?.status === "STARTED" &&
                        (userRole === "ADMIN" || userRole === "SUPER_ADMIN") ? (
                          <span
                            style={{
                              color: "red",
                              padding: "4px 8px",
                              background: "#FDEEEF",
                              border: "0.5px solid red",
                              borderRadius: "4px",
                            }}
                            onClick={() => {
                              confirmAlert({
                                title:
                                  "Are you sure you want to delete this order?",
                                message:
                                  "This order’s data will be erased and you will not be able to retrieve them",
                                buttons: [
                                  {
                                    label: "Cancel",
                                    // Do Close operation
                                  },
                                  {
                                    label: "Delete",
                                    onClick: () => deleteOrder(item?.id),
                                  },
                                ],
                              });
                            }}
                          >
                            Delete
                          </span>
                        ) : (
                          ""
                        )}
                      </p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </>
      )}

      {status !== LOADING && orderDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={currentPage}
          onResult={onResult}
        />
      )}

      {cancelStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default Orders;
