import { useContext, useEffect, useState } from "react";
import { city } from "../../../data/city";
import { ordersTableData } from "../../../data/Data";
// import { filterContext, testToggleContext } from "../../../helper/Context";
import { getToken } from "../../../storage";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
// import DropsFilter from "../../ui/filter/dropsFilter/DropsFilter";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import OrdersTable from "../../ui/table/ordersTable/OrdersTable";
import "./Overview.scss";
import OverviewCards from "./overviewCard/OverviewCards";
import API from "../../../utils/api/API";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../../helper";

const PageHeading = () => {
  return (
    <Heading title="Overview" subTitle="Hi there! Welcome to your dashboard" />
  );
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const Overview = () => {
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [storeBranchStatus, setStoreBranchStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [storeBranches, setStoreBranches] = useState("");
  const [products, setProducts] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [userRole, setUserRole] = useState<string>();
  const [storeBranchId, setStoreBranchId] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const storeBranchId: string = user.storeBranchId;
      const role: string = user.role;
      setUserRole(role);
      setStoreBranchId(storeBranchId);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN" || userRole === "DROPPER") {
      Navigate("/orders/");
    }
  });

  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const [dateFilter, setDateFilter] = useState(getLastNDaysFormatted(1));
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      createdAt: `$btw:${dateFilter.startDate},${dateFilter.endDate}`,
    },
  });

  const updateStatus = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        createdAt: `$btw:${dateFilter.startDate},${dateFilter.endDate}`,
      },
    }));
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = isClearFilter
    ? { limit: filter.limit, page: filter.page }
    : {
        limit: filter.limit,
        page: filter.page,
        "filter.createdAt": filter.filter.createdAt,
      };

  useEffect(() => {
    updateStatus();
  }, [dateFilter, isClearFilter]);

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedOrder(data);
    // setPopup(true);
  };

  const getOrders = async () => {
    setOrderDetails([]);
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getOrders,
      params: params,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data.data.orders.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              store: data.storeBranch?.name,
              deliveryFee: data.deliveryFee,
              orderRef: data.orderRef,
              serviceFee: data.serviceFee,
              status: data.status,
            };
          }
        );
        setOrderDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        setStatus(ERROR);
        setAlert(true);
      });
  };

  const productParams =
    userRole === "STORE_ADMIN"
      ? {
          "filter.storeBranch.id": storeBranchId,
          isAdmin: true,
        }
      : {
          isAdmin: true,
        };

  const getStoreBranches = async () => {
    setStoreBranchStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.storeBranch,
    })
      .then((resp) => {
        setStoreBranchStatus(SUCCESS);
        setStoreBranches(resp.data.data.meta.totalItems);
      })
      .catch((err) => {
        setStoreBranchStatus(ERROR);
      });
  };

  const getProducts = async () => {
    setProductStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.products,
      params: productParams,
    })
      .then((resp) => {
        setProductStatus(SUCCESS);
        setProducts(resp.data.data.meta.totalItems);
      })
      .catch((err) => {
        setProductStatus(ERROR);
      });
  };

  const deleteOrder = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getOrder(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getOrders();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
  };

  useEffect(() => {
    // if (userRole === "SUPER_ADMIN" && storeBranchId == "") {
    getStoreBranches();
    // }
  }, []);

  useEffect(() => {
    if (userRole !== "" && storeBranchId !== "") {
      getProducts();
    } else if (userRole === "ADMIN" || userRole === "SUPER_ADMIN") {
      getProducts();
    }
  }, [storeBranchId]);

  useEffect(() => {
    getOrders();
  }, [filter]);

  const storeOrdersToShow = orderDetails.filter(
    (item: any) => item.status !== "STARTED"
  );

  return (
    <>
      <PageHeading />
      <OverviewCards
        totalOrders={totalItems}
        totalProducts={products}
        totalStoreBranches={storeBranches}
        isOrderLoading={status === LOADING ? true : false}
        isProductLoading={productStatus === LOADING ? true : false}
        isStoreBranchLoading={storeBranchStatus === LOADING ? true : false}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        getLastNDaysFormatted={getLastNDaysFormatted}
        setIsClearFilter={setIsClearFilter}
        userRole={userRole}
      />
      <div className="table-wrapper">
        {status !== LOADING && orderDetails.length > 0 && (
          <p className="type3 sub-category">Recent Orders</p>
        )}
        {/* {!isLoading && orderDetails.length > 0 && (
          <DropsFilter setFilterFunction={setOverviewDropFilter} />
        )} */}
        <OrdersTable
          data={userRole === "STORE_ADMIN" ? storeOrdersToShow : orderDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteOrder={deleteOrder}
          handleEditModal={handleEditModal}
        />
      </div>
      {status !== LOADING && orderDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
    </>
  );
};

export default Overview;
