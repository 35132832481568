import ProductSingle from "../components/dashboard/productSingle";
import Layout from "../components/ui/layout";

const ProductSinglePage = () => {
  return (
    <Layout>
      <ProductSingle />
    </Layout>
  );
};

export default ProductSinglePage;
