import { useEffect, useState } from "react";
import Heading from "../../ui/heading";
import RevenueStatistics from "./revenueStatistics/RevenueStatistics";
import RevenueTableSection from "./revenueTable/RevenueTableSection";
import { useNavigate } from "react-router-dom";
import "./style/Revenue.scss";
import RevenueCards from "./revenueStatistics/revenueCard/RevenueCard";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import BusinessMetrics from "./businessMetrics/BusinessMetrics";
import FinancialMetrics from "./financials/FinancialMetrics";
import OperationMetrics from "./operationsMetric/OperationMetrics";

const PageIntro = () => {
  return <Heading title="Analytics" subTitle="See all your analytics here" />;
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const Analytics = () => {
  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();
  const [revenueStatus, setRevenueStatus] = useState(IDLE);
  const [revenue, setRevenue] = useState("");
  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const [isClearFilter, setIsClearFilter] = useState(false);
  const [dateFilter, setDateFilter] = useState(getLastNDaysFormatted(1));
  const [filter, updateFilter] = useState({
    isAll: isClearFilter,
    startDate: `${dateFilter.startDate}`,
    endDate: `${dateFilter.endDate}`,
  });

  const updateStatus = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      isAll: isClearFilter,
      startDate: `${dateFilter.startDate}`,
      endDate: `${dateFilter.endDate}`,
    }));
  };

  const params = isClearFilter
    ? { isAll: filter.isAll }
    : {
        isAll: filter.isAll,
        endDate: filter.endDate,
        startDate: filter.startDate,
      };

  useEffect(() => {
    updateStatus();
  }, [dateFilter, isClearFilter]);

  const filterMap = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Years",
      value: "years",
    },
    {
      name: "All time",
      value: "",
    },
  ];

  const getRevenue = async () => {
    setRevenueStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.revenue,
      params: params,
    })
      .then((resp) => {
        setRevenueStatus(SUCCESS);
        setRevenue(resp.data.data.revenue);
      })
      .catch((err) => {
        setRevenueStatus(ERROR);
      });
  };

  useEffect(() => {
    getRevenue();
  }, [filter]);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);
  return (
    <>
      <BusinessMetrics />
      <FinancialMetrics />
      <OperationMetrics />

      {/* <PageIntro />
      <div className="wallet-cards" style={{ width: "100%" }}>
        <RevenueCards
          isRevenueLoading={revenueStatus === "LOADING" ? true : false}
          totalRevenue={revenue}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          getLastNDaysFormatted={getLastNDaysFormatted}
          setIsClearFilter={setIsClearFilter}
        />
        <RevenueStatistics />
      </div>
      <RevenueTableSection /> */}
    </>
  );
};

export default Analytics;
