import React from "react";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";
import BannerSeeting from "../components/dashboard/settings/settingsBody/banner";

const BannerSettingsPage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <BannerSeeting />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default BannerSettingsPage;
