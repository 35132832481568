import Loader from "../loader";
import "./Button.scss";

interface ButtonProps {
  text: string;
  className?: string;
  onClick?: Function;
  id?: string;
  invertStyle?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  type?: any;
  style?: any;
  buttonStyle?: any;
}

const Button = (props: ButtonProps) => {
  const {
    text,
    className,
    onClick,
    id,
    invertStyle,
    isLoading,
    disabled,
    type,
    style,
    buttonStyle,
  } = props;
  return (
    <div className="custom-button" style={style}>
      <button
        id={id}
        className={`button ${invertStyle ? "button-invert" : ""} ${className}`}
        onClick={(e) => {
          // e.preventDefault();
          onClick?.(id);
        }}
        // style={style}
        disabled={disabled}
        type={type}
        style={buttonStyle}
      >
        {isLoading ? <Loader /> : text}
      </button>
    </div>
  );
};

export default Button;
