import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../../storage";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
// import "./OrderSingle.scss";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
// import UserInformation from "./customerDetails/UserInformation";
import { Button } from "../../ui";
import Alert from "../../ui/alert";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../utils/api/API";
import ProductsTable from "../../ui/table/productTable.tsx/ProductTable";
import AddProductPopup from "../products/addProductPopup/AddProductPopup";
import Paginate from "../../ui/paginate";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const BestSellerSinglePage = () => {
  const Navigate = useNavigate();
  const [status, setStatus] = useState(IDLE);
  const [unassignStatus, setUnassignStatus] = useState(IDLE);
  const [productDetails, setProductDetails] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [popup, setPopup] = useState(false);
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const { id } = useParams();

  const updateStatus = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
    }));
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  useEffect(() => {
    updateStatus();
  }, []);

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
  };

  const getBestSellingProduct = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.bestSelling(id),
      params: filter
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data.data.products.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              name: data.name,
              category: data.category?.name,
              categoryId: data.category?.id,
              picture: data.picture,
              dropperPrice: data.dropperPrice,
              price: data.price,
              status: data.status,
              store: data.storeBranch?.name,
              storeBranchId: data.storeBranch?.id,
              description: data.description,
              metaKeywords: data.metaKeywords,
              dropStockOnPurchase: data.dropStockOnPurchase,
              excerpt: data.excerpt,
              hasCoupon: data.hasCoupon,
              hasPromo: data.hasPromo,
              priceDiscount: data.priceDiscount,
              sku: data.sku,
              slug: data.slug,
              stock: data.stock,
              m_unit: data.m_unit,
              size: data.size,
              isBestSeller: data.isBestSeller,
              bestSellingScore: data.bestSellingScore,
              categoryScore: data.categoryScore,
            };
          }
        );
        setProductDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setMessage("Error getting User");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (id) {
      getBestSellingProduct();
    }
  }, [id, filter]);

  const deleteProduct = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getProduct(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // getProducts();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setPopup(true);
  };

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no User presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="order-details">
            <ProductsTable
              clickable
              data={productDetails}
              emptyHeight="530px"
              // arrange={overviewDropFilter}
              isCardLoading={status === LOADING ? true : false}
              isCardError={status === ERROR ? true : false}
              dataNull={status === NULLMODE ? true : false}
              message={message}
              deleteProduct={deleteProduct}
              handleEditModal={handleEditModal}
              //   storeAdmin={userRole === "STORE_ADMIN" ? true : false}
            />
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">
        <span className="go-back" onClick={() => Navigate(-1)}>
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
            alt="arrow-back"
            className="arrow"
          />
          <h6>Go back</h6>
        </span>
        <div className="page-header">
          <h2>Best Seller Details</h2>
        </div>
      </div>
      <div className="table-wrapper">
        <ProductsTable
          clickable
          data={productDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteProduct={deleteProduct}
          handleEditModal={handleEditModal}
          bestSeller
          //   storeAdmin={userRole === "STORE_ADMIN" ? true : false}
        />
      </div>
      {status !== LOADING && productDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddProductPopup
          getProducts={getBestSellingProduct}
          setPopup={setPopup}
          data={selectedProduct}
          isEdit={isEdit}
          //   selectedId={selectedId}
        />
      )}
    </>
  );
};

export default BestSellerSinglePage;
