import Layout from "../components/ui/layout";
import Profile from "../components/dashboard/settings/settingsBody/profile/Profile";

const ProfilePage = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default ProfilePage;
