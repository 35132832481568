import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../../utils/api/API";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Checkbox from "../../../ui/checkbox";
import FileInput from "../../../ui/fileInput";
import Search from "../../../ui/search";
import CardLoader from "../../../ui/cardLoader";
import {
  getErrorMessage,
  timeToTimestamp,
  timestampToTime,
} from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

interface ModalProps {
  id?: string;
  setPopup: any;
  getCategories?: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
  restaurantBranchId?: any;
}

const AddMenuItemPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const { id, setPopup, data, isEdit, restaurantBranchId } = props;

  const watchHappyHour = watch("isHappyHour");

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(IDLE);
  const [imageURL, setImageURL] = useState<any>([]);
  const [image, setImage] = useState("");
  const [imageStatus, setImageStatus] = useState(IDLE);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectionStatus, setSelectionStatus] = useState(IDLE);
  const [selectionList, setProductList] = useState<any>(
    isEdit
      ? data?.selections?.map((item: any) => {
          return { name: item?.name, id: item?.id };
        })
      : []
  );
  const [selectionDetails, setSelectionDetails] = useState([{ name: "" }]);

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(resp.data.data.url);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        // setMessage(err.response.message);
        setImageStatus(ERROR);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  const getSelections = async () => {
    setSelectionDetails([]);
    setSelectionStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.menuItem,
      // params: userRole !== "STORE_ADMIN" ? params : storeParams,
      params: { search: searchText, "filter.branch.id": restaurantBranchId },
    })
      .then((resp) => {
        setSelectionStatus(SUCCESS);
        const formattedList = resp.data?.data?.menuItems.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              name: data.name,
              description: data.description,
            };
          }
        );
        setIsSearchAvailable(true);
        setSelectionDetails(formattedList);
        formattedList?.length > 0
          ? setSelectionStatus(DATAMODE)
          : setSelectionStatus(NULLMODE);
      })
      .catch((err) => {
        setSelectionStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  useEffect(() => {
    if (searchText !== "") {
      getSelections();
    }
  }, [searchText]);

  const addMenuItem = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.image = image;
    data.menuCategoryId = id;
    data.price = data.price * 100;
    data.selections = selectionList.map((item: any) => {
      return item?.id;
    });
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.menuItem,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        setImage("");
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const updateMenuItem = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.image = image.length > 0 ? image : data?.image;
    request.menuCategoryId = id;
    request.price = request.price * 100;
    request.selections = selectionList.map((item: any) => {
      return item?.id;
    });

    const payload = {
      image: request?.image,
      menuCategoryId: request?.menuCategoryId,
      price: request?.price,
      selections: request?.selections,
      name: request?.name,
      description: request?.description,
      isMulti: request?.isMulti,
      forTesting: request?.forTesting,
      pos: request?.pos,
      isAvailable: request?.isAvailable,
      isSelectionCompulsory: request?.isSelectionCompulsory,
      happyHour: {
        isHappyHour: request?.isHappyHour,
        happyHourDiscount: request?.happyHourDiscount,
        startTime: timeToTimestamp(request?.startTime) + "",
        endTime: timeToTimestamp(request?.endTime) + "",
      },
    };
    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.getMenuItem(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        setImage("");
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateMenuItem(data) : addMenuItem(data);
  };

  useEffect(() => {
    if (isEdit) {
      const {
        name,
        description,
        price,
        isMulti,
        isSelectionCompulsory,
        forTesting,
        pos,
        isAvailable,
        isHappyHour,
      } = data || {};
      setValue("name", name);
      setValue("description", description);
      setValue("price", price / 100);
      setValue("isMulti", isMulti);
      setValue("isSelectionCompulsory", isSelectionCompulsory);
      setValue("forTesting", forTesting);
      setValue("pos", pos);
      setValue("isAvailable", isAvailable);
      setValue("isHappyHour", data?.happyHour?.isHappyHour);
      setValue("startTime", timestampToTime(data?.happyHour?.startTime));
      setValue("endTime", timestampToTime(data?.happyHour?.endTime));
      setValue("happyHourDiscount", data?.happyHour?.happyHourDiscount);
    }
  }, [data, isEdit]);

  const updateSelectionList = (selection: any) => {
    // Check if the selection is already in the selectionList
    const isSelectionInList = selectionList?.some(
      (item: any) => item.id === selection.id || item._id === selection.id
    );

    if (!isSelectionInList) {
      // If the selection is not in the list, add it
      setProductList((prevArray: any) => [...prevArray, selection]);
    } else {
      // If the selection is already in the list, you can handle it here (e.g., show a message)
      toast.error("Item is already in the selection");
    }
  };

  const handleRemoveDetail = (item: any) => {
    // Remove the selection from the list
    setProductList((prevArray: any) =>
      prevArray.filter(
        (detail: any) => detail.id !== item?.id || detail._id !== item?._id
      )
    );
  };

  const imageUrlSource =
    imageURL && imageURL[0] instanceof File
      ? URL.createObjectURL(imageURL[0])
      : "";

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Menu Item" : "Add Menu Item"}
            subTitle={
              isEdit
                ? "Enter the following details to update menu item"
                : "Enter the following details to add menu item"
            }
          />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="avatar-container">
              <div className="avatar">
                <img
                  src={
                    imageUrlSource
                      ? imageUrlSource
                      : isEdit && data?.image?.length > 0
                      ? data?.image
                      : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                  }
                  alt=""
                />
              </div>
              <FileInput
                labelText="Tap to upload menu item"
                onChange={upload}
                // isUploading={isUploading}
              />
            </div>
            <Input
              register={register}
              label="Name"
              name="name"
              required
              placeholder="Enter Name"
            />
            <Input
              register={register}
              label="Description"
              name="description"
              required
              placeholder="Enter Description"
            />
            <Input
              register={register}
              label="Price"
              type="number"
              name="price"
              required
              placeholder="Enter Price"
              valueAsNumber
            />
            {/* <Input
              register={register}
              label="Position"
              type="number"
              name="pos"
              placeholder="Enter Position"
              valueAsNumber
            /> */}
            <Checkbox
              register={register}
              label="For Testing?"
              name="forTesting"
            />
            <Checkbox
              register={register}
              label="Is Available?"
              name="isAvailable"
            />
            <Checkbox register={register} label="Is Multi?" name="isMulti" />
            <Checkbox
              register={register}
              label="Is Selection Compulsory?"
              name="isSelectionCompulsory"
            />
            {isEdit && (
              <Checkbox
                register={register}
                label="Happy Hour?"
                name="isHappyHour"
              />
            )}

            {watchHappyHour && (
              <>
                <Input
                  register={register}
                  label="Start Time"
                  type="datetime-local"
                  name="startTime"
                  required
                  placeholder="Enter Start Time"
                />
                <Input
                  register={register}
                  label="End Time"
                  type="datetime-local"
                  name="endTime"
                  required
                  placeholder="Enter End Time"
                />
                <Input
                  register={register}
                  label="Happy Hour Discount"
                  type="number"
                  valueAsNumber
                  name="happyHourDiscount"
                  required
                  placeholder="Enter Happy Hour Discounts"
                />
              </>
            )}

            {selectionList?.length > 0 && (
              <div
                className="form-heading"
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "24px",
                  fontSize: "14px",
                  marginTop: "24px",
                }}
              >
                <div style={{ width: "60%" }}>Item Name</div>
                <div style={{ width: "40%" }}>Action</div>
              </div>
            )}
            {selectionList?.map((item: any, index: any) => (
              <div
                className="product-details"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <div className="product-name" style={{ width: "60%" }}>
                  {item?.name}
                </div>

                <div className="delete-product" style={{ width: "40%" }}>
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => handleRemoveDetail(item)}
                  />
                </div>
              </div>
            ))}
          </form>
          <div
            className="search-box"
            style={{ marginBottom: "12px", marginTop: "40px" }}
          >
            <p style={{ fontSize: "16px", marginBottom: "12px" }}>
              Add selection
            </p>
            <Search
              setValue={setSearchText}
              placeholder="Search for existing item..."
              // setIsClearFilter={setIsAbstractProduct}
            />
          </div>
          {isSearchAvailable && searchText !== "" && (
            <div
              className="searched-products"
              style={{
                borderRadius: "8px",
                border: "0.5px solid rgba(26, 27, 26, 0.2)",
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {selectionStatus === DATAMODE ? (
                selectionDetails.map((item, index) => (
                  <p
                    className="product"
                    key={index}
                    onClick={() => updateSelectionList({ ...item })}
                    style={{
                      cursor: "pointer",
                      padding: "8px",
                    }}
                  >
                    {item?.name}
                  </p>
                ))
              ) : (
                <p
                  className="product"
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  No menu item match your search parameter
                </p>
              )}
            </div>
          )}
          <Button
            className="add-button"
            text={isEdit ? "Update Menu Item" : "Add Menu Item"}
            isLoading={status === "LOADING" ? true : false}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {selectionStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddMenuItemPopup;
