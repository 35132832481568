import BlackBellsInventory from "./components/dashboard/blackBellsInventory";
import GSAAnalytics from "./components/dashboard/gsa/analytics";
import GSACustomers from "./components/dashboard/gsa/customers";
import GSACustomerSinglePage from "./components/dashboard/gsa/customers/customerSinglePage/CustomerSinglePage";
import GSAOverview from "./components/dashboard/gsa/overview";
import GSAPackageOrders from "./components/dashboard/gsa/packageOrders";
import GSAPackageOrderSinglePage from "./components/dashboard/gsa/packageOrders/packageOrderSinglePage/PackageOrderSinglePage";
import GSAPackage from "./components/dashboard/gsa/packages";
import GSAPackageSinglePage from "./components/dashboard/gsa/packages/packageSinglePage/PackageSinglePage";
import GSAProduct from "./components/dashboard/gsa/products";
import GSAProductSinglePage from "./components/dashboard/gsa/products/productSinglePage/ProductSinglePage";
import GSASettings from "./components/dashboard/gsa/settings";
import GSALocationSettings from "./components/dashboard/gsa/settings/settingsBody/locationSettings";
import MenuCategorySingle from "./components/dashboard/menuCategoriesSingle";
import MenuItemSingle from "./components/dashboard/menuItemSingle";
import PromoSetting from "./components/dashboard/settings/settingsBody/promoSettings";
import Layout from "./components/ui/layout";
import {
  LoginPage,
  NotFoundPage,
  OTPVerificationPage,
  OverviewPage,
  ResetPasswordPage,
  SetNewPasswordPage,
  SignupPage,
  StorePage,
  OrdersPage,
  VehiclePage,
  OrderSinglePage,
  VendorsPage,
  CustomersPage,
  SettingsPage,
  CustomerSinglePage,
  RiderSinglePage,
  AddRiderPage,
  StoreBranchPage,
  StoreBranchSinglePage,
  StoreSinglePage,
  CategoryPage,
  ProductsPage,
  ProductSinglePage,
  CategorySinglePage,
  ChangePasswordPage,
  FeeSettingsPage,
  GlobalSettingsPage,
  AnalyticsPage,
  VoucherPage,
  VoucherSinglePage,
  BestSellerSinglePage,
  DropFoodCategoryPage,
  DropFoodCategorySinglePage,
  RestaurantPage,
  RestaurantBranchSinglePage,
  DropFoodOrderPage,
  ProfilePage,
} from "./pages";
import BannerSettingsPage from "./pages/BannerSettingsPage";
import WalletPage from "./pages/WalletPage";

export const routes = [
  {
    title: "Overview",
    subRoutes: [],
    component: <OverviewPage />,
    route: "/",
  },
  {
    title: "Login",
    subRoutes: [],
    component: <LoginPage />,
    route: "/login/",
  },
  // {
  //   title: "Signup",
  //   subRoutes: [],
  //   component: <SignupPage />,
  //   route: "/signup/",
  // },
  {
    title: "ResetPassword",
    subRoutes: [],
    component: <ResetPasswordPage />,
    route: "/reset-password/",
  },
  {
    title: "SetNewPassword",
    subRoutes: [],
    component: <SetNewPasswordPage />,
    route: "/new-password/",
  },
  {
    title: "Verification",
    subRoutes: [],
    component: <OTPVerificationPage />,
    route: "/verification/",
  },
  {
    title: "Stores",
    subRoutes: [],
    component: <StorePage />,
    route: "/stores/",
  },
  {
    title: "StoresSinglePage",
    subRoutes: [],
    component: <StoreSinglePage />,
    route: "/stores/:id/",
  },
  {
    title: "Stores Branches",
    subRoutes: [],
    component: <StoreBranchPage />,
    route: "/store-branches/",
  },
  {
    title: "StoresBranchesSingle",
    subRoutes: [],
    component: <StoreBranchSinglePage />,
    route: "/store-branches/:id/",
  },
  {
    title: "Products",
    subRoutes: [],
    component: <ProductsPage />,
    route: "/products/",
  },
  {
    title: "ProductSingle",
    subRoutes: [],
    component: <ProductSinglePage />,
    route: "/products/:id/",
  },
  {
    title: "Orders",
    subRoutes: [],
    component: <OrdersPage />,
    route: "/orders/",
  },
  {
    title: "OrderSinglePage",
    subRoutes: [],
    route: "/orders/:id/",
    component: <OrderSinglePage />,
  },
  {
    title: "Vehicle",
    subRoutes: [],
    component: <VehiclePage />,
    route: "/vehicle/",
  },
  {
    title: "Vendors",
    subRoutes: [],
    component: <VendorsPage />,
    route: "/vendors/",
  },
  {
    title: "Customers",
    subRoutes: [],
    component: <CustomersPage />,
    route: "/users/",
  },
  {
    title: "CustomerSinglePage",
    subRoutes: [],
    component: <CustomerSinglePage />,
    route: "/users/:id/",
  },
  {
    title: "RiderSinglePage",
    subRoutes: [],
    component: <RiderSinglePage />,
    route: "/riders/:id/",
  },
  {
    title: "BestSellerSinglePage",
    subRoutes: [],
    component: <BestSellerSinglePage />,
    route: "/best-seller/:id/",
  },
  {
    title: "AddRiderPage",
    subRoutes: [],
    component: <AddRiderPage />,
    route: "/add-rider/",
  },
  {
    title: "DropFoodCategoryPage",
    subRoutes: [],
    component: <DropFoodCategoryPage />,
    route: "/drop-food-cat/",
  },
  {
    title: "DropFoodCategoryPage",
    subRoutes: [],
    component: <DropFoodCategorySinglePage />,
    route: "/drop-food-cat/:id/",
  },
  {
    title: "DropFoodOrderPage",
    subRoutes: [],
    component: <DropFoodOrderPage />,
    route: "/drop-food-order/",
  },
  {
    title: "CategoryPage",
    subRoutes: [],
    component: <CategoryPage />,
    route: "/category/",
  },
  {
    title: "CategoryPage",
    subRoutes: [],
    component: <CategorySinglePage />,
    route: "/category/:id/",
  },
  {
    title: "Restaurant",
    subRoutes: [],
    component: <RestaurantPage />,
    route: "/restaurants/",
  },
  {
    title: "Restaurant Branch Single",
    subRoutes: [],
    component: <RestaurantBranchSinglePage />,
    route: "/restaurants/:id/",
  },
  {
    title: "Menu Categories Single",
    subRoutes: [],
    component: <MenuCategorySingle />,
    route: "/menu-categories/:id/",
  },
  {
    title: "Menu Item Single",
    subRoutes: [],
    component: <MenuItemSingle />,
    route: "/menu-items/:id/",
  },
  {
    title: "Overview",
    subRoutes: [],
    component: <GSAOverview />,
    route: "gsa/overview/",
  },
  {
    title: "Package",
    subRoutes: [],
    component: <GSAPackage />,
    route: "gsa/packages/",
  },
  {
    title: "PackageSingle",
    subRoutes: [],
    component: <GSAPackageSinglePage />,
    route: "gsa/packages/:id",
  },
  {
    title: "Package Orders",
    subRoutes: [],
    component: <GSAPackageOrders />,
    route: "gsa/package-orders/",
  },
  {
    title: "Package Order Single",
    subRoutes: [],
    component: <GSAPackageOrderSinglePage />,
    route: "gsa/package-orders/:id",
  },
  {
    title: "Product",
    subRoutes: [],
    component: <GSAProduct />,
    route: "gsa/products/",
  },
  {
    title: "ProductSingle",
    subRoutes: [],
    component: <GSAProductSinglePage />,
    route: "gsa/products/:id/",
  },
  {
    title: "Customers",
    subRoutes: [],
    component: <GSACustomers />,
    route: "gsa/customers/",
  },
  {
    title: "Customers",
    subRoutes: [],
    component: <GSACustomerSinglePage />,
    route: "gsa/customers/:id",
  },
  {
    title: "Analytics",
    subRoutes: [],
    component: <GSAAnalytics />,
    route: "gsa/analytics/",
  },
  {
    title: "Profile Page",
    subRoutes: [],
    component: <ProfilePage />,
    route: "profile/",
  },
  {
    title: "Settings",
    subRoutes: [],
    component: <GSASettings />,
    route: "gsa/fee-settings/",
  },
  {
    title: "Location Settings",
    subRoutes: [],
    component: <GSALocationSettings />,
    route: "gsa/location-settings/",
  },
  {
    title: "Settings",
    subRoutes: [],
    component: <SettingsPage />,
    route: "/settings/",
  },
  {
    title: "ChangePassword",
    subRoutes: [],
    component: <ChangePasswordPage />,
    route: "/change-password/",
  },
  {
    title: "FeeSettings",
    subRoutes: [],
    component: <FeeSettingsPage />,
    route: "/fee/",
  },
  {
    title: "PromoSetting",
    subRoutes: [],
    component: (
      <Layout>
        <PromoSetting />
      </Layout>
    ),
    route: "/promo/",
  },
  {
    title: "VoucherSettings",
    subRoutes: [],
    component: <VoucherPage />,
    route: "/vouchers/",
  },
  {
    title: "VoucherSinglePage",
    subRoutes: [],
    component: <VoucherSinglePage />,
    route: "/vouchers/:id/",
  },
  {
    title: "BannerSettings",
    subRoutes: [],
    component: <BannerSettingsPage />,
    route: "/banner/",
  },
  {
    title: "Revenue",
    subRoutes: [],
    component: <AnalyticsPage />,
    route: "/analytics/",
  },
  {
    title: "Wallet",
    subRoutes: [],
    component: <WalletPage />,
    route: "/wallet/",
  },
  {
    title: "GlobalSettings",
    subRoutes: [],
    component: <GlobalSettingsPage />,
    route: "/global/",
  },
  {
    title: "Blackbells",
    subRoutes: [],
    component: <BlackBellsInventory />,
    route: "/blackbells/",
  },
  {
    title: "NotFound",
    subRoutes: [],
    component: <NotFoundPage />,
    route: "*",
  },
];
