// import jwt from "jsonwebtoken";
export const storeToken = (token: any) => {
  return localStorage.setItem("token", token);
};

export const storeUser = (user: any) => {
  return localStorage.setItem("user", JSON.stringify(user));
};

export const storeBusinessDetails = (businessDetails: any) => {
  return localStorage.setItem(
    "businessDetails",
    JSON.stringify(businessDetails)
  );
};

let pathName = window.location.pathname;
let isAuthPath =
  pathName.includes("login") ||
  pathName.includes("signup") ||
  pathName.includes("new-password") ||
  pathName.includes("reset-password") ||
  pathName.includes("verification");

export const getUser = () => {
  let user = localStorage.getItem("user");
  return user
    ? JSON.parse(user || "{}")
    : !isAuthPath && (window.location.href = "/login/");
};

export const getBusinessDetails = () => {
  let businessDetails = localStorage.getItem("businessDetails");
  return JSON.parse(businessDetails || "{}")
    ? JSON.parse(businessDetails || "{}")
    : !isAuthPath && (window.location.href = "/login/");
};

// const verifyToken = () => {
//   var isExpired = false;
//   const token = localStorage.getItem("token");
//   var decodedToken = jwt.decode(token, { complete: true });
//   var dateNow = new Date();

//   if (decodedToken.exp < dateNow.getTime()) {
//     isExpired = true;
//   }
// };

export const getToken = () => {
  let token = localStorage.getItem("token");
  return token
    ? token || ""
    : (!isAuthPath && (window.location.href = "/login/")) || "";
};
