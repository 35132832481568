import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../../../ui";
import Heading from "../../../../../ui/heading";
// import "./AddCategoryPopup.scss";
import { dataQueryStatus } from "../../../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../../../utils/api";
import SuccessfulModal from "../../../../../ui/modal/successfulModal/SuccessfulModal";
import { ADD_FEE_FIELD } from "./enum";
import Select from "../../../../../ui/select";
import Checkbox from "../../../../../ui/checkbox";
import Alert from "../../../../../ui/alert";
import {
  defaultOption,
  getErrorMessage,
  getRestaurantBranch,
} from "../../../../../../helper";
import API from "../../../../../../utils/api/API";
import { toast } from "react-toastify";
import CardLoader from "../../../../../ui/cardLoader";

interface ModalProps {
  setPopup: any;
  getFees: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddFeePopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const forABranch = watch("forABranch");

  const { setPopup, getFees, data, isEdit, selectedId } = props;

  const name = [
    {
      label: "Heavy Delivery Fee",
      value: "HEAVY_DELIVERY_FEE",
    },
    {
      label: "Light Delivery Fee",
      value: "LIGHT_DELIVERY_FEE",
    },
    {
      label: "Product Markup Fee",
      value: "PRODUCT_MARKUP_FEE",
    },
    {
      label: "Service Fee",
      value: "SERVICE_FEE",
    },
  ];

  const type = [
    {
      label: "Fixed",
      value: "FIXED",
    },
    {
      label: "Percentage",
      value: "PERCENTAGE",
    },
  ];

  const [imageFile, setImageFile] = useState("");
  const [feeId, setFeeId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [branch, setBranch] = useState<any>([]);
  const [isBranchLoading, setIsBranchLoading] = useState<any>(false);

  useEffect(() => {
    getRestaurantBranch(setBranch, setIsBranchLoading);
  }, []);

  const addFee = async (data: any) => {
    setStatus(LOADING);
    data.min = data.min * 100;
    data.max = data.max * 100;
    data.value = data.type === "PERCENTAGE" ? data.value : data.value * 100;
    setMessage("");
    const payload = {
      max: data?.max,
      min: data?.min,
      name: data?.name,
      type: data?.type,
      value: data?.value,
      branchId: data?.branchId,
    };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.fee,
      data:payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const updateFee = (request: any) => {
    setStatus(LOADING);
    request.min = request.min * 100;
    request.max = request.max * 100;
    request.value =
      request.type === "PERCENTAGE" ? request.value : request.value * 100;
    request.name === undefined && (request.name = data.name);
    request.type === undefined && (request.type = data.name);
    setMessage("");

    request.branchId === undefined
      ? request.branchId = data?.branchId
      : request.branchId = request?.branchId;

    const payload = {
      max: request?.max,
      min: request?.min,
      name: request?.name,
      type: request?.type,
      value: request?.value,
      branchId: request?.branchId,
    };

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getFee(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getFees();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateFee(data) : addFee(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, code, type, value, min, max, branch } = data || {};
      setValue("name", name);
      setValue("code", code);
      setValue("type", type);
      setValue("forABranch", branch?.id?.length ? true : false);
      setValue("value", type === "PERCENTAGE" ? value : value / 100);
      setValue("min", min / 100);
      setValue("max", max / 100);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Fee" : "Add Fee"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Fee"
                : "Enter the following details to add a Fee"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Select
                  options={name}
                  value={isEdit ? defaultOption(name, data.name) : ""}
                  label="Select Fee Name"
                  onChange={(val: any) => onChange(val.value)}
                  required
                />
              )}
            />
            <Controller
              name="type"
              control={control}
              // rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Select
                  options={type}
                  value={isEdit ? defaultOption(type, data.type) : ""}
                  label="Select Fee Type"
                  onChange={(val: any) => onChange(val.value)}
                  required
                />
              )}
            />
            {ADD_FEE_FIELD.map((field, index) => (
              <Input
                register={register}
                label={field.label}
                name={field.name}
                required={field.required}
                placeholder={field.placeholder}
                type={field.type}
                valueAsNumber={field.valueAsNumber}
                key={index}
              />
            ))}
            <Checkbox
              register={register}
              label="Customized for a branch?"
              name="forABranch"
            />
            {forABranch && (
              <Controller
                name="branchId"
                control={control}
                // rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <Select
                    options={branch}
                    value={
                      isEdit ? defaultOption(branch, data?.branch?.id) : ""
                    }
                    label="Select Branch"
                    onChange={(val: any) => onChange(val.value)}
                    required
                  />
                )}
              />
            )}
            <Button
              className="add-button"
              text={isEdit ? "Update Fee" : "Add Fee"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a Fee to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Fee"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {isBranchLoading && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddFeePopup;
