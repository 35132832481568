import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import "./StoreBranchSingle.scss";
import { getErrorMessage, getMe } from "../../../helper";
import API from "../../../utils/api/API";
import AddCategoryPopup from "./addCategoryPopup/AddCategoryPopup";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Popover } from "@headlessui/react";
import DeleteIcon from "../../../assets/delete.svg";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import Switch from "../../ui/switch";
import OnlinePopup from "../storeBranchSingle/OnlinePopup";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const RestaurantBranchSingle = () => {
  const Navigate = useNavigate();

  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [menuCatPopup, setMenuCatPopup] = useState(false);
  const [user, setUser] = useState({
    role: "",
  });

  useEffect(() => {
    getMe(setUser, setIsUserLoading);
  }, []);

  useEffect(() => {
    if (user.role === "STORE_ADMIN") {
      navigate("/");
    }
  }, [user]);

  const { id } = useParams();

  const [status, setStatus] = useState(IDLE);
  const [categoryStatus, setCategoryStatus] = useState(IDLE);
  const [storeData, setStoreData] = useState({
    id: "",
    createdAt: "",
    name: "",
    description: "",
    status: "",
    address: "",
    mapLocation: "",
    openingTime: 0,
    closingTime: 0,
    maxOrderQty: 0,
    minDeliveryTime: 0,
    maxDeliveryTime: 0,
    minOrderAmount: 0,
    minOrderQty: 0,
    allowTesting: null,
    rating: null,
    state_id: 0,
    storeId: "",
    isOnline: false,
  });
  const [timeSettings, setTimeSettings] = useState<any>({});
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [menuCategoryData, setMenuCategoryData] = useState<any>();
  const [isOnline, setIsOnline] = useState<boolean>();
  const [availabilityPopup, setOnlinePopup] = useState(false);

  useEffect(() => {
    setIsOnline(storeData.isOnline);
  }, [storeData]);

  const handleEditCategory = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setMenuCatPopup(true);
  };

  const getStoreBranchSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getStoreBranch(id),
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const data = resp.data.data;
        const formattedList = {
          id: data.id,
          createdAt: data.createdAt,
          name: data.name,
          description: data.description,
          status: data.status,
          address: data.address,
          allowTesting: data.allowTesting,
          mapLocation: data.mapLocation,
          openingTime: data.openingTime,
          closingTime: data.closingTime,
          maxOrderQty: data.maxOrderQty,
          minDeliveryTime: data.minDeliveryTime,
          maxDeliveryTime: data.maxDeliveryTime,
          minOrderAmount: data.minOrderAmount,
          minOrderQty: data.minOrderQty,
          rating: data.rating,
          state_id: data.state_id,
          storeId: data.storeId,
          isOnline: data.isOnline,
        };
        if (resp?.data?.data?.timeSettings === null) {
          setTimeSettings({});
        } else {
          setTimeSettings(resp?.data?.data?.timeSettings);
        }
        setStoreData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        // setMessage("Error getting store");
        setStatus(ERROR);
      });
  };

  const params = {
    "filter.branch.id": id,
    // "filter.isVisible": true,
  };

  const getRestaurantBranchCategory = async () => {
    setCategoryStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.menuCategory,
      params: params,
    })
      .then((resp) => {
        setCategoryStatus(DATAMODE);
        const formattedData = resp.data?.data?.menuCategories;
        setMenuCategoryData(formattedData);
        // formattedData ? setCategoryStatus(DATAMODE) : setCategoryStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting store");
        toast.error(getErrorMessage(err.response.data.message));
        setCategoryStatus(ERROR);
      });
  };

  useEffect(() => {
    if (id) {
      getStoreBranchSingleData();
      getRestaurantBranchCategory();
    }
  }, [id]);

  const deleteCategory = async (id: any) => {
    // setMenuCategoryData([]);
    setCategoryStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getGsaPackages(id),
    })
      .then((resp) => {
        setCategoryStatus(SUCCESS);
        toast.success(resp.data?.message);
        getRestaurantBranchCategory();
      })
      .catch((err) => {
        setCategoryStatus(ERROR);
        // setAlert(true);
        toast.error(err.response?.data?.message);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  function isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  const today = new Date()
    .toLocaleDateString("en-US", { weekday: "long" })
    .toUpperCase();

  const storeBranchDetails = [
    // {
    //   key: "Name",
    //   value: storeData.name,
    //   className: "",
    // },
    {
      key: "Description",
      value: `${storeData.description}`,
      className: "",
    },
    {
      key: "Status",
      value: storeData.status,
      className: "",
    },
    {
      key: "Opening Time",
      value:
        isEmptyObject(timeSettings) ||
        timeSettings === undefined ||
        timeSettings === null
          ? ""
          : timeSettings[today]?.openingTime,
      className: "",
      image: "",
    },
    {
      key: "Closing Time",
      value:
        isEmptyObject(timeSettings) ||
        timeSettings === undefined ||
        timeSettings === null
          ? ""
          : timeSettings[today]?.closingTime,
      className: "",
    },
    {
      key: "Allow Testing?",
      value: storeData.allowTesting === true ? "Yes" : "No",
      className: "",
    },
    {
      key: "Minimum Delivery Time",
      value: storeData.minDeliveryTime,
      className: "",
    },
    {
      key: "Maximum Delivery Time",
      value: storeData.maxDeliveryTime,
      className: "",
    },
  ];

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no store branch presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="page-header">
              <div>
                <span className="go-back" onClick={() => Navigate(-1)}>
                  <img
                    src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                    alt="arrow-back"
                    className="arrow"
                  />
                  <h6>Go back</h6>
                </span>
                <h2>{storeData.name}</h2>
                <p className="type2" style={{ margin: "10px 0 0" }}>
                  Address: {storeData.address}
                </p>
              </div>
              <div className="header-buttons">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <p className="type2 bold" style={{ display: "block" }}>
                    Online?
                  </p>
                  <Switch
                    textOff="YES"
                    textOn="NO"
                    value={isOnline}
                    onChange={setIsOnline}
                    setPopup={setOnlinePopup}
                    bgColor="#2EBB55"
                  />
                </div>
                <Button
                  text="Add Menu Category"
                  className="shop-add"
                  onClick={() => {
                    setIsEdit(false);
                    setMenuCatPopup(true);
                  }}
                />
              </div>
            </div>
            <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
              Restaurant Branch Details
            </h5>
            <div className="store-branch-details">
              {storeBranchDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {categoryStatus === "LOADING" ? (
              <div className="cards loading">
                <CardLoader />
              </div>
            ) : (
              menuCategoryData?.length > 0 && (
                <>
                  {" "}
                  <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                    Menu Category Details
                  </h5>
                  <div className="table-details" style={{ marginTop: "12px" }}>
                    <div className="table-details-body">
                      <div
                        className="details-table-header"
                        style={{ width: "100%" }}
                      >
                        <p style={{ width: "20%", fontSize: "12px" }}>
                          Category Id
                        </p>
                        <p style={{ width: "30%", fontSize: "12px" }}>
                          Category Name
                        </p>
                        <p style={{ width: "15%", fontSize: "12px" }}>
                          Is Visible?
                        </p>
                        <p style={{ width: "15%", fontSize: "12px" }}>
                          No of Menu Items
                        </p>
                        {/* <p style={{ width: "15%", fontSize: "12px" }}>
                          Position
                        </p> */}
                        <p style={{ width: "5%", fontSize: "12px" }}>Action</p>
                      </div>
                      {menuCategoryData?.length > 0 &&
                        menuCategoryData?.map((item: any, index: any) => (
                          <div key={index} style={{ cursor: "pointer" }}>
                            <div
                              className="details-table-body"
                              style={{ width: "100%" }}
                            >
                              <p
                                style={{ width: "20%", fontSize: "12px" }}
                                onClick={() =>
                                  Navigate(`/menu-categories/${item?.id}`)
                                }
                              >
                                {item?.id?.slice(0, 7) + "..."}
                              </p>
                              <p
                                style={{ width: "30%", fontSize: "12px" }}
                                onClick={() =>
                                  Navigate(`/menu-categories/${item?.id}`)
                                }
                              >
                                {item?.name}
                              </p>
                              <p
                                style={{ width: "15%", fontSize: "12px" }}
                                onClick={() =>
                                  Navigate(`/menu-categories/${item?.id}`)
                                }
                              >
                                {item?.isVisible ? "Yes" : "No"}
                              </p>
                              <p
                                style={{ width: "15%", fontSize: "12px" }}
                                onClick={() =>
                                  Navigate(`/menu-categories/${item?.id}`)
                                }
                              >
                                {item?.menuItems?.length}
                              </p>
                              {/* <p
                                style={{ width: "15%", fontSize: "12px" }}
                                onClick={() =>
                                  Navigate(`/menu-categories/${item?.id}`)
                                }
                              >
                                {item?.pos}
                              </p> */}
                              <Popover
                                className="flex-1 text-right"
                                style={{ width: "5%", textAlign: "center" }}
                              >
                                <>
                                  <Popover.Button
                                    className="popover-button"
                                    style={{
                                      cursor: "pointer",
                                      background: "transparent",
                                    }}
                                  >
                                    <img src={MoreIcon} alt="" />
                                  </Popover.Button>

                                  <Popover.Panel
                                    className="popover-panel"
                                    style={{
                                      position: "absolute",
                                      right: "64px",
                                      zIndex: "500",
                                      // padding: "14px",
                                      width: "150px",
                                      background: "white",
                                      borderRadius: "16px",
                                      boxShadow: "5px 5px #00000030",
                                      border: "0.5px solid #00000030",
                                    }}
                                  >
                                    <div
                                      className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                      style={{ borderRadius: "16px" }}
                                    >
                                      <div
                                        className="items-center grid gap-4 bg-white px-5 py-4"
                                        style={{
                                          alignItems: "center",
                                          background: "white",
                                          padding: "16px, 20px",
                                        }}
                                      >
                                        <div
                                          onClick={() =>
                                            Navigate(
                                              `/menu-categories/${item?.id}`
                                            )
                                          }
                                          className="flex items-center"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 14px",
                                            gap: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img src={ViewIcon} alt="" />
                                          <p className="pl-2 text-[#B00020]">
                                            View Category
                                          </p>
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleEditCategory(item)
                                          }
                                          className="flex items-center"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 14px",
                                            gap: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img src={EditIcon} alt="" />
                                          <p className="pl-2 text-[#B00020]">
                                            Edit Category
                                          </p>
                                        </div>

                                        <div
                                          className="flex items-center"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: "8px 14px",
                                            gap: "8px",
                                            cursor: "pointer",
                                            color: "red",
                                          }}
                                          onClick={() => {
                                            confirmAlert({
                                              title:
                                                "Are you sure you want to delete this category?",
                                              message:
                                                "This category’s data will be erased and you will not be able to retrieve them",
                                              buttons: [
                                                {
                                                  label: "Cancel",
                                                  // Do Close operation
                                                },
                                                {
                                                  label: "Delete",
                                                  onClick: () =>
                                                    deleteCategory(item?.id),
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <img src={DeleteIcon} alt="" />
                                          <p className="pl-2 text-[16px]">
                                            Delete Category
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </>
                              </Popover>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )
            )}
            {/* <div className="order-details"></div> */}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">{renderBasedOnStatus()}</div>
      {/* {popup && <AddStoreImagePopup id={id} setPopup={setPopup} />} */}
      {menuCatPopup && (
        <AddCategoryPopup
          id={id}
          setPopup={setMenuCatPopup}
          restaurantBranchId={storeData?.id}
          isEdit={isEdit}
          data={selectedCategory}
        />
      )}
      {availabilityPopup && (
        <OnlinePopup
          value={isOnline}
          storeBranchId={id}
          setPopup={setOnlinePopup}
          setIsOnline={setIsOnline}
        />
      )}
    </>
  );
};

export default RestaurantBranchSingle;
