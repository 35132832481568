import RestaurantBranchSingle from "../components/dashboard/restaurantBranchSingle";
import Layout from "../components/ui/layout";

const RestaurantBranchSinglePage = () => {
  return (
    <Layout>
      <RestaurantBranchSingle />
    </Layout>
  );
};

export default RestaurantBranchSinglePage;
