import Security from "../components/dashboard/settings/settingsBody/security/Security";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";

const ChangePasswordPage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <Security />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default ChangePasswordPage;
