import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
// import "./AddCategoryPopup.scss";
import { ADD_FEE_FIELD } from "./enum";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../../../../../utils/dataQueryStatus";
import API from "../../../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../../../utils/api";
import Heading from "../../../../../../ui/heading";
import Select from "../../../../../../ui/select";
import { defaultOption, getErrorMessage } from "../../../../../../../helper";
import { Button, Input } from "../../../../../../ui";
import SuccessfulModal from "../../../../../../ui/modal/successfulModal/SuccessfulModal";

interface ModalProps {
  setPopup: any;
  getLocationSettings: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddLocationSettingPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const selectedOption = watch("hasPromo");

  const { setPopup, getLocationSettings, data, isEdit, selectedId } = props;

  const [imageFile, setImageFile] = useState("");
  const [feeId, setFeeId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const addServiceCharge = async (data: any) => {
    setStatus(LOADING);
    data.priceInMinor = data.priceInMinor * 100;
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaDeliveryLocations,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const updateServiceCharge = (request: any) => {
    setStatus(LOADING);
    request.priceInMinor = request.priceInMinor * 100;
    setMessage("");

    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.getGsaServiceCharges(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        getLocationSettings();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateServiceCharge(data) : addServiceCharge(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, priceInMinor } = data || {};
      setValue("name", name);
      setValue("priceInMinor", priceInMinor / 100);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Location Setting" : "Add Location Setting"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Location Setting"
                : "Enter the following details to add a Location Setting"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            {ADD_FEE_FIELD.map((field, index) => (
              <Input
                register={register}
                label={field.label}
                name={field.name}
                required={field.required}
                placeholder={field.placeholder}
                type={field.type}
                valueAsNumber={field.valueAsNumber}
                key={index}
              />
            ))}
            <Button
              className="add-button"
              text={isEdit ? "Update Location Setting" : "Add Location Setting"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Location Setting"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddLocationSettingPopup;
