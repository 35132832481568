import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import CategoryTable from "../../ui/table/categoryTable/CategoryTable";
import API from "../../../utils/api/API";
import { useNavigate } from "react-router-dom";
import { getErrorMessage, handlePrice } from "../../../helper";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import DeleteIcon from "../../../assets/delete.svg";
import ActivateIcon from "../../../assets/activate.svg";
import CardLoader from "../../ui/cardLoader";
import ErrorView from "../../ui/ErrorView";
import EmptyView from "../../ui/emptyView";
import ActivateDeactivatePopup from "./ActivateDeactivatePopup";
import DeletePopup from "./DeletePopup";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const PageHeading = () => {
  return (
    <Heading
      title="DropFood Orders"
      subTitle="See all your DropFood orders and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const DropFoodOrder = () => {
  const [popup, setPopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [cancelStatus, setCancelStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleActivateDeactivateModal = (data: any) => {
    setSelectedCategory(data);
    setActivatePopup(true);
  };

  const handleDeleteModal = (data: any) => {
    setSelectedCategory(data);
    setDeletePopup(true);
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setPopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setPopup(true);
  };

  const param = isClearFilter
    ? { limit: filter.limit, page: filter.page, "filter.isFoodOrder": true }
    : {
        limit: filter.limit,
        page: filter.page,
        "filter.isFoodOrder": true,
        // isScheduled: filter.filter.isScheduled,
        // "filter.status": filter.filter.status,
        // "filter.createdAt": filter.filter.createdAt,
      };

  // const storeParams = completedFilter
  //   ? {
  //       limit: filter.limit,
  //       page: filter.page,
  //       "filter.storeBranch.id": storeBranchId,
  //       "filter.status": "$in:COMPLETED",
  //     }
  //   : {
  //       limit: filter.limit,
  //       page: filter.page,
  //       "filter.storeBranch.id": storeBranchId,
  //       "filter.status":
  //         "$in:ACCEPTED_BY_STORE,REJECTED_BY_STORE,ARRIVED,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,REFUNDED,PAYMENT_RECEIVED",
  //     };

  // const dropperParams = completedFilter
  //   ? {
  //       limit: filter.limit,
  //       page: filter.page,
  //       "filter.dropper.id": dropperId,
  //       "filter.storeBranch.id": storeBranchId,
  //       "filter.status": "$in:COMPLETED",
  //     }
  //   : {
  //       limit: filter.limit,
  //       page: filter.page,
  //       "filter.dropper.id": dropperId,
  //       "filter.storeBranch.id": storeBranchId,
  //       "filter.status":
  //         "$in:ACCEPTED_BY_STORE,REJECTED_BY_STORE,ARRIVED,ASSIGNED,ACCEPTED_BY_RIDER,PICKING_UP,DROPPING,FLAGGED,REFUNDED,PAYMENT_RECEIVED",
  //     };

  const getOrders = async () => {
    setOrderDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getOrders,
      params: param,
    })
      .then((resp) => {
        setStatus(SUCCESS);

        setTotalItems(resp.data.data.meta.totalItems);

        const formattedList = resp.data?.data.orders.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              store: data.storeBranch?.name,
              deliveryFee: data.deliveryFee,
              orderRef: data.orderRef,
              serviceFee: data.serviceFee,
              status: data.status,
              createdAt: data.createdAt,
              subTotal: data.subTotal,
              customerName: data?.user?.firstName + " " + data?.user?.lastName,
            };
          }
        );
        setOrderDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
      });
  };

  const deleteOrder = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getOrder(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getOrders();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getOrders();
  }, [filter]);

  const cancelOrder = async (id: any) => {
    setCancelStatus(LOADING);
    setMessage("");
    const payload = {
      status: "CANCELLED",
    };
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateOrder(id),
      data: payload,
    })
      .then((resp) => {
        setCancelStatus(SUCCESS);
        toast.success(resp.data.message);
        getOrders();
      })
      .catch((err) => {
        setCancelStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  return (
    <>
      <div className="page-header">
        <PageHeading />
      </div>
      {status === LOADING ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("orders") && "active"
                }`}
                onClick={() => navigate("/orders/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-order") && "active"
                }`}
                onClick={() => navigate("/drop-food-order/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardLoader />
          </div>
        </>
      ) : status === ERROR ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("orders") && "active"
                }`}
                onClick={() => navigate("/orders/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-order") && "active"
                }`}
                onClick={() => navigate("/drop-food-order/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorView />
          </div>
        </>
      ) : status === NULLMODE ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("orders") && "active"
                }`}
                onClick={() => navigate("/orders/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-order") && "active"
                }`}
                onClick={() => navigate("/drop-food-order/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyView />
          </div>
        </>
      ) : (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("orders") && "active"
                }`}
                onClick={() => navigate("/orders/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-order") && "active"
                }`}
                onClick={() => navigate("/drop-food-order/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div className="table-details" style={{ marginTop: "24px" }}>
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "5%", fontSize: "12px" }}>No</p>
                <p style={{ width: "18%", fontSize: "12px" }}>Order Ref</p>
                <p style={{ width: "18%", fontSize: "12px" }}>Restaurant</p>
                <p style={{ width: "18%", fontSize: "12px" }}>Delivery Fee</p>
                <p style={{ width: "18%", fontSize: "12px" }}>Service Fee</p>
                <p style={{ width: "18%", fontSize: "12px" }}>Status</p>
                <p style={{ width: "5%" }}></p>
              </div>
              {orderDetails.map((item: any, index: any) => (
                <div key={index}>
                  <div className="details-table-body" style={{ width: "100%" }}>
                    <p
                      style={{
                        width: "5%",
                      }}
                    >
                      {index + 1}
                    </p>
                    <p
                      style={{ width: "18%", fontSize: "12px" }}
                      onClick={() => navigate(`/orders/${item?.id}`)}
                    >
                      {item?.orderRef}
                    </p>
                    <p
                      style={{ width: "18%", fontSize: "12px" }}
                      onClick={() => navigate(`/orders/${item?.id}`)}
                    >
                      {item?.store}
                    </p>
                    <p
                      style={{ width: "18%", fontSize: "12px" }}
                      onClick={() => navigate(`/orders/${item?.id}`)}
                    >
                      {handlePrice(item?.deliveryFee)}
                    </p>
                    <p
                      style={{ width: "18%", fontSize: "12px" }}
                      onClick={() => navigate(`/orders/${item?.id}`)}
                    >
                      {handlePrice(item?.serviceFee)}
                    </p>
                    <p
                      style={{ width: "18%", fontSize: "12px" }}
                      onClick={() => navigate(`/orders/${item?.id}`)}
                    >
                      {item?.status}
                    </p>
                    <Popover
                      className="flex-1 text-right"
                      style={{ width: "5%" }}
                    >
                      <>
                        <Popover.Button
                          className="popover-button"
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                          }}
                        >
                          <img src={MoreIcon} alt="" />
                        </Popover.Button>

                        <Popover.Panel
                          className="popover-panel"
                          style={{
                            position: "absolute",
                            right: "64px",
                            zIndex: "500",
                            // padding: "14px",
                            width: "150px",
                            background: "white",
                            borderRadius: "16px",
                            boxShadow: "5px 5px #00000030",
                            border: "0.5px solid #00000030",
                          }}
                        >
                          <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            style={{ borderRadius: "16px" }}
                          >
                            <div
                              className="items-center grid gap-4 bg-white px-5 py-4"
                              style={{
                                alignItems: "center",
                                background: "white",
                                padding: "16px, 20px",
                              }}
                            >
                              <div
                                onClick={() => navigate(`/orders/${item?.id}`)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={ViewIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">View</p>
                              </div>

                              <div
                                    className="flex items-center"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "8px 14px",
                                      gap: "8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to cancel this order?",
                                        message:
                                          "This order will be canceled and you will not be able to process them further",
                                        buttons: [
                                          {
                                            label: "Go Back",
                                            // Do Close operation
                                          },
                                          {
                                            label: "Cancel Order",
                                            onClick: () =>
                                              cancelOrder(item?.id),
                                          },
                                        ],
                                      });
                                    }}
                                  >
                                    <img src={EditIcon} alt="" />
                                    <p className="pl-2 text-[16px]">
                                      Cancel Order
                                    </p>
                                  </div>
                                  {item?.status === "STARTED" &&
                                    (userRole === "ADMIN" ||
                                      userRole === "SUPER_ADMIN") && (
                                      <div
                                        className="flex items-center"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px 14px",
                                          gap: "8px",
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                        onClick={() => {
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this order?",
                                            message:
                                              "This order’s data will be erased and you will not be able to retrieve them",
                                            buttons: [
                                              {
                                                label: "Cancel",
                                                // Do Close operation
                                              },
                                              {
                                                label: "Delete",
                                                onClick: () =>
                                                  deleteOrder(item?.id),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <img src={DeleteIcon} alt="" />
                                        <p className="pl-2 text-[16px]">
                                          Delete Order
                                        </p>
                                      </div>
                                    )}
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    </Popover>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {/* {activatePopup && (
        <ActivateDeactivatePopup
          setPopup={setActivatePopup}
          getOrders={getOrders}
          data={selectedCategory}
          selectedId={selectedId}
        />
      )}
      {deletePopup && (
        <DeletePopup
          setPopup={setDeletePopup}
          getOrders={getOrders}
          data={selectedCategory}
          selectedId={selectedId}
        />
      )} */}
      {status !== LOADING && orderDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
    </>
  );
};

export default DropFoodOrder;
