import Layout from '../components/ui/layout'
import CategorySingle from '../components/dashboard/categorySingle'

const CategorySinglePage = () => {
  return (
    <Layout>
        <CategorySingle />
    </Layout>
  )
}

export default CategorySinglePage