import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { handlePrice } from "../../../../../helper";
import CardLoader from "../../../../ui/cardLoader";

interface IMetricsProps {
  analyticsData?: any;
  status?: any;
}

const BusinessMetricsCard = (props: IMetricsProps) => {
  const { analyticsData, status } = props;

  return (
    <>
      <div className="cards">
        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            {/* <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setRevenueDropdown(!revenueDropdown)}
              >
                <h6>{revenueFilter === "" ? "today" : revenueFilter}</h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {revenueDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          revenueFilterClicked,
                          setRevenueFilterClicked,
                          setRevenueFilter,
                          setRevenueDropdown,
                          setIsClearRevenueFilter,
                          setRevenueDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {/* END */}
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total GSA User</p>
              <p className="detail">{analyticsData?.totalUsers} Users</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Amount Saved</p>
              <p className="detail">{handlePrice(analyticsData?.totalAmountInMinorSaved)}</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Package Orders</p>
              <p className="detail">{analyticsData?.totalOrders} Order</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Products</p>
              <p className="detail">{analyticsData?.totalProducts} Products</p>
            </div>
          )}
        </div>

        {/* <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Brands</p>
              <p className="detail">{analyticsData?.totalBrands} Brands</p>
            </div>
          )}
        </div> */}

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Packages</p>
              <p className="detail">{analyticsData?.totalPackages} Packages</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Claimed Orders</p>
              <p className="detail">{analyticsData?.totalClaimedOrders} Orders</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Completed Orders</p>
              <p className="detail">{analyticsData?.totalCompletedOrders} Orders</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total no of slots taken</p>
              <p className="detail">{analyticsData?.totalSlotsTaken} Slots</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total no of slots available</p>
              <p className="detail">{analyticsData?.totalAvailableSlot} Slots</p>
            </div>
          )}
        </div>

        {/* <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
          </div>
          {status === "LOADING" ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">
                No of package orders with completed payments
              </p>
              <p className="detail">{analyticsData?.totalPackages} Order</p>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default BusinessMetricsCard;
