import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import CardLoader from "../../../../ui/cardLoader";
import SuccessfulModal from "../../../../ui/modal/successfulModal/SuccessfulModal";
import { Button, Input } from "../../../../ui";
import FileInput from "../../../../ui/fileInput";
import Heading from "../../../../ui/heading";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { getErrorMessage } from "../../../../../helper";

interface ModalProps {
  setPopup: any;
  getProducts: Function;
  data?: any;
  isEdit?: boolean;
  isSubCat?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AddProductBrand = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const selectedOption = watch("isBestSeller");
  const selectedStoreBranch = watch("storeBranchId");
  const isPictureSelected = watch("picture");

  const { setPopup, isEdit, isSubCat } = props;
  let { data } = props;

  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState<any>();
  const [stores, setStores] = useState([]);
  const [subCategories, setSubCategories] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [productId, setProductId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [storeId, setStoreId] = useState();
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [storeImages, setStoreImages] = useState();
  const [searchText, setSearchText] = useState("");

  const [isProductImageLoading, setIsProductImageLoading] = useState(false);
  const [media, setMedia] = useState<any>();

  const uploadImage = async (files: any) => {
    setIsProductImageLoading(true);
    setMessage("");
    const image = files;
    var formData = new FormData();
    if (image && image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        const file = image[i];
        formData.append("images", file); // Append the file and its filename (file.name) to the FormData
      }
    }
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaUploadFile,
      data: formData,
    })
      .then((resp) => {
        setMedia(resp.data?.content);
        setIsProductImageLoading(false);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        setIsProductImageLoading(false);
      });
  };

  const upload = (file: any) => {
    uploadImage(file);
    setImageURL(file);
  };

  const addProductBrand = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    const payload = { ...request, productId: data.id, media: media };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaProductBrand,
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
      });
  };

  interface YourDataItem {
    fileName: string;
    format: string;
    path: string;
    size: number;
    _id: string;
  }

  const updateProductBrand = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    const newData = data?.media?.map(({ _id, ...rest } : YourDataItem) => rest);

    media !== undefined ? (request.media = media) : (request.media = newData);

    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.updateGsaProductBrand(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data?.message);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    // if (imageFile?.length > 0) {
    isEdit ? updateProductBrand(data) : addProductBrand(data);
    // } else {
    // setMessage("An image is required");
    // }
  };

  useEffect(() => {
    if (isEdit) {
      const { name } = data || {};

      setValue("name", name);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const imageUrlSource =
    imageURL && imageURL[0] instanceof File
      ? URL.createObjectURL(imageURL[0])
      : "";

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Product Brand" : "Add Product Brand"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Product Brand"
                : "Enter the following details to add a Product Brand"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            {
              <div className="avatar-container">
                <div className="avatar">
                  <img
                    src={
                      imageUrlSource
                        ? imageUrlSource
                        : isEdit && data?.media[0].path?.length > 0
                        ? data?.media[0].path
                        : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                    }
                    alt=""
                  />
                </div>
                <FileInput
                  labelText="Tap to upload the brand Images"
                  onChange={upload}
                  multiple
                />
              </div>
            }

            <Input
              register={register}
              label="Name"
              name="name"
              required
              placeholder="Enter Name"
              type="text"
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Product Brand" : "Add Product Brand"}
              isLoading={status === "LOADING" ? true : false}
              disabled={isProductImageLoading}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        // <AddProductImagePopup id={productId}/>
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Product Brand"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {/* {productStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )} */}
    </>
  );
};

export default AddProductBrand;
