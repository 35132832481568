import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input, TelephoneInput } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import Textarea from "../../../ui/textarea";
import Checkbox from "../../../ui/checkbox";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

interface ModalProps {
  setPopup: any;
  data?: any;
  isEdit?: boolean;
  orderId?: any;
  orderItems?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const ProductDetailsPatch = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const { setPopup, data, isEdit, orderId, orderItems } = props;

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const updateProduct = (request: any) => {
    setStatus(LOADING);
    setMessage("");

    const updatedOrderItems = orderItems.map((item: any) => {
      if (item.productId === data.productId) {
        return {
          ...item,
          ...request,
        };
      }
      return item;
    });

    const orderPatch = {
      orderItems: updatedOrderItems,
    };

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateOrder(orderId),
      data: orderPatch,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    updateProduct(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { quantity } = data || {};
      setValue("quantity", quantity);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Order" : "Add Order"}
            subTitle={
              isEdit
                ? "Enter the following details to update an order details"
                : "Enter the following details to add a Order"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Quantity"
              type="number"
              name="quantity"
              required
              placeholder="Enter Quantity"
              valueAsNumber
            />
            {/* <Checkbox register={register} label="Has Refund?" name="hasRefund" />
            {selectedOption === true && (
              <Input
                register={register}
                label="Refund Amount"
                name="refundAmount"
                required
                placeholder="Enter Refund Amount"
                type="number"
                valueAsNumber
              />
            )} */}
            {/* <Textarea
              register={register}
              label="Reason for updating"
              // type="number"
              name="reason"
              // required
              placeholder="Enter Reason for updating"
            /> */}
            <Button
              className="add-button"
              text={isEdit ? "Update Order" : "Add Order"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully updated an order."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error updating order"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default ProductDetailsPatch;
