import Layout from "../../../ui/layout";

const GSAOverview = () => {
  return (
    <Layout>
      <div>GSAOverview</div>
    </Layout>
  );
};

export default GSAOverview;
