import { useNavigate } from "react-router-dom";
import { formatTimestamp, handlePrice } from "../../../../helper";
import "./style.scss";
import { useState } from "react";
import CardLoader from "../../../ui/cardLoader";
import EmptyView from "../../../ui/emptyView";

interface IPageProps {
  data: any;
  status?: string;
  completedFilter?: boolean;
  setCompletedFilter?: any;
}

const Dropper = (props: IPageProps) => {
  const { data, status, completedFilter, setCompletedFilter } = props;
  const Navigate = useNavigate();

  return (
    <>
      <div className="page-wrapper">
        {(status === "DATAMODE" || data.length === 0) && (
          <div className="filter-wrapper dropper-shopper">
            <div
              className={`ongoing-filter ${!completedFilter && "active"}`}
              onClick={() => setCompletedFilter(false)}
            >
              <p>Ongoing</p>
            </div>
            <div
              className={`ongoing-filter ${completedFilter && "active"}`}
              onClick={() => setCompletedFilter(true)}
            >
              <p>Completed</p>
            </div>
          </div>
        )}
        {status === "LOADING" ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CardLoader />
          </div>
        ) : data?.length === 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <EmptyView />
          </div>
        ) : (
          <div className="orders-column-wrapper">
            {data.map((item: any, index: any) => (
              <div
                className="order-column-wrapper"
                key={index}
                onClick={() => Navigate(item?.id)}
              >
                <div className="order-left-side">
                  <p className="name">{item?.customerName?.toUpperCase()}</p>
                  <p className="date">{formatTimestamp(item?.createdAt)}</p>
                  <p className="status">{item?.status}</p>
                </div>
                <div className="order-right-side">
                  <p className="store">{item?.store?.toUpperCase()}</p>
                  <p className="orderRef">#{item?.orderRef}</p>
                  {/* <p className="amount">{handlePrice(item?.subTotal)}</p> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropper;
