import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import Alert from "../../../ui/alert";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Select from "../../../ui/select";
import API from "../../../../utils/api/API";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddProductImagePopup = (props: {
  id?: string;
  setPopup?: any;
  storeBranchId?: string;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const { id, setPopup, storeBranchId } = props;
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [images, setImages] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [status, setStatus] = useState(IDLE);

  const getImages = async () => {
    setIsImagesLoading(true);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getImages(storeBranchId),
    })
      .then((resp) => {
        setIsImagesLoading(false);
        const formattedList = resp.data?.data.map((data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        });
        setImages(formattedList);
      })
      .catch((err) => {
        setIsImagesLoading(false);
        setMessage(err.response.data.error);
      });
  };

  const addProductImage = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    const { mediaId } = data;
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.addProductImage(id, mediaId),
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(err.response.data.error);
      });
  };

  const onSubmit = (data: any) => {
    addProductImage(data);
  };

  useEffect(() => {
    getImages();
  }, []);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Add Product Image"
            subTitle="Enter the following details to add a Product"
          />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="mediaId"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={images}
                  label="Select Image"
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isImagesLoading}
                />
              )}
            />
            <Button
              className="add-button"
              text={"Add Product"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added an image to your product."
          onClick={refresh}
        />
      )}
      {status === ERROR && (
        <Alert
          title="Error Adding Product Image"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )}
    </>
  );
};

export default AddProductImagePopup;
