import React from "react";

interface IProductSingleProps {
  categoryId?: string;
  categoryName?: string;
  subCategoryName?: string;
  categoryThumbnail?: any;
  storeBranchId?: string;
  storeBranchName?: string;
}

const CatAndStore = (props: IProductSingleProps) => {
  const {
    categoryId,
    categoryName,
    subCategoryName,
    categoryThumbnail,
    storeBranchId,
    storeBranchName,
  } = props;

  const productInformation = [
    {
      description: "Category",
      value: categoryName,
    },
    {
      description: "Sub-Category",
      value: subCategoryName,
    },
    // {
    //   description: "Category Slug",
    //   value: subCategoryName,
    // },
    {
      description: "Store Branch",
      value: storeBranchName,
    },
  ];

  return (
    <div className="order-information">
      <div className="store-branch-details">
        {productInformation.map((info: any, index) => (
          <div className="store-branch-detail" key={index}>
            {info.imageSRC && <img src={info.imageSRC} alt="image" />}
            <div className="description">
              <p className="type3">{info.description}</p>
              <h5 style={{fontWeight:"bold", margin:"10px 0 0"}}>{info.value}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatAndStore;
