import { apiQueryMethods, apiUrls } from "../utils/api";
import API from "../utils/api/API";

const params = {
  limit: 100000,
  page: 1,
};

export const getAbstractCategory = async (
  setAbstractCategory: any,
  setIsloading: any
) => {
  setIsloading(true);
  const filter = {
    limit: 100000,
    page: 1,
  };
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.abstractCategory,
    params: filter,
  })
    .then((resp) => {
      setIsloading(false);

      const formattedList = resp.data?.data?.abstractCategories.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        }
      );
      setAbstractCategory(formattedList);
    })
    .catch((err) => {});
};

export const getRestaurantCategory = async (
  setRestaurantCategory: any,
  setIsloading: any
) => {
  setIsloading(true);
  const filter = {
    limit: 100000,
    page: 1,
  };
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.restaurantCategory,
    params: filter,
  })
    .then((resp) => {
      setIsloading(false);

      const formattedList = resp.data?.data?.categories.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        }
      );
      setRestaurantCategory(formattedList);
    })
    .catch((err) => {});
};

export const getStoreImages = async (
  storeId: any,
  setStoreImages: any,
  setIsloading: any
) => {
  setIsloading(true);
  const filter = {
    "filter.store.id": storeId,
  };
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.getGallery,
    params: filter,
  })
    .then((resp) => {
      setIsloading(false);

      const formattedList = resp.data?.data.media.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.url,
          };
        }
      );
      setStoreImages(formattedList);
    })
    .catch((err) => {});
};

export const getDropper = async (
  setDropper: any,
  setIsloading: any,
  storeBranchId?: any
) => {
  setIsloading(true);

  const filter = storeBranchId
    ? {
        "filter.role": "DROPPER",
        "filter.storeBranchId": storeBranchId,
      }
    : { "filter.role": "DROPPER" };
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.users,
    params: filter,
  })
    .then((resp) => {
      setIsloading(false);
      const formattedList = resp.data?.data.users.map(
        (data: any, index: any) => {
          return {
            label: data.lastName + " " + data.firstName,
            value: data.id,
          };
        }
      );
      setDropper(formattedList);
    })
    .catch((err) => {});
};

export const getStores = async (setStores: any, setIsloading: any) => {
  setIsloading(true);
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.storeBranch,
    params: params,
  })
    .then((resp) => {
      setIsloading(false);

      const formattedList = resp.data?.data.storeBranches.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        }
      );
      setStores(formattedList);
    })
    .catch((err) => {});
};

export const getRestaurantBranch = async (
  setRestaurantBranch: any,
  setIsloading: any
) => {
  setIsloading(true);
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.storeBranch,
    params: params,
  })
    .then((resp) => {
      setIsloading(false);

      const restaurantBranches = resp.data?.data.storeBranches || [];

      const filteredList = restaurantBranches
        .filter((branch: any) => branch.isRestaurant === true)
        .map((data: any) => ({
          label: data.name,
          value: data.id,
        }));
      setRestaurantBranch(filteredList);
    })
    .catch((err) => {});
};

export const getStoreBranch = async (
  id: any,
  setStoreBranch: any,
  setIsloading: any
) => {
  setIsloading(true);
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.getStoreBranch(id),
    params: params,
  })
    .then((resp) => {
      setIsloading(false);
      setStoreBranch(resp.data.data.storeId);

      const formattedList = resp.data?.data.map((data: any, index: any) => {
        return {
          label: data.name,
          value: data.id,
          storeId: data?.store?.id,
        };
      });
      // setStoreBranch(formattedList);
    })
    .catch((err) => {});
};

export const getCategories = async (setCategories: any, setIsloading: any) => {
  setIsloading(true);
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.category,
    params: params,
  })
    .then((resp) => {
      setIsloading(false);
      const formattedList = resp.data?.data.categories.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        }
      );
      setCategories(formattedList);
    })
    .catch((err) => {});
};

export const getSubCategories = async (
  setSubCategories: any,
  setIsloading: any,
  storeBranchId: string
) => {
  setIsloading(true);
  const params =
    storeBranchId.length > 0
      ? {
          limit: 100000,
          page: 1,
          "filter.parent.storeBranchId": storeBranchId,
        }
      : {
          limit: 100000,
          page: 1,
        };
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.getSubCategory,
    params: params,
  })
    .then((resp) => {
      setIsloading(false);
      const formattedList = resp.data?.data.subCategories.map(
        (data: any, index: any) => {
          return {
            label: data.name,
            value: data.id,
          };
        }
      );
      setSubCategories(formattedList);
    })
    .catch((err) => {});
};

export const getMe = async (setUser: any, setIsloading: any) => {
  setIsloading(true);
  API({
    method: apiQueryMethods?.GET,
    url: apiUrls?.getMe,
  })
    .then((resp) => {
      setIsloading(false);
      const data = resp.data?.data;
      const formattedList = {
        role: data.role,
      };
      setUser(formattedList);
    })
    .catch((err) => {});
};

export const handlePrice = (price?: any) => {
  if (price !== null) {
    const newPrice = price / 100;
    const realPrice = newPrice.toFixed(2);
    return Number(realPrice).toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  } else {
    return price?.toLocaleString("en-US", {
      style: "currency",
      currency: "NGN",
    });
  }
};

export const handleDate = (inputDate?: any) => {
  if (
    inputDate === null ||
    inputDate === undefined ||
    inputDate === "" ||
    !inputDate
  ) {
    return null;
  } else {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
};

export const defaultOption = (options: any, id: any) =>
  options.find((option: any) => option.value === id);

export const numberToWordOrdinals = (num: any) => {
  if (num < 0) return "Invalid";

  const units = [
    "Zeroth",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
  ];
  const teens = [
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
  ];
  const tens = [
    "Twentieth",
    "Thirtieth",
    "Fortieth",
    "Fiftieth",
    "Sixtieth",
    "Seventieth",
    "Eightieth",
    "Ninetieth",
  ];

  if (num < 10) return units[num];
  if (num >= 10 && num < 20) return teens[num - 10];
  if (num % 10 === 0) return tens[Math.floor(num / 10) - 2];

  const tensDigit = Math.floor(num / 10);
  const onesDigit = num % 10;

  return tens[tensDigit - 2] + "-" + units[onesDigit];
};

export function formatTimestamp(timestamp: any) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12; // Get hours in 12-hour format
  const minutes = ("0" + date.getMinutes()).slice(-2); // Add leading zero to minutes
  const amPm = date.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${day}${getOrdinalSuffix(
    day
  )} ${month}. ${year}, ${hours}:${minutes} ${amPm}`;
  return formattedDate;
}

function getOrdinalSuffix(day: any) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    // If the error is a string, return it as is
    return error;
  } else if (Array.isArray(error)) {
    // If the error is an array, join its elements into a string
    return error.join(", ");
  } else {
    // For other types of errors, convert them to a string
    return String(error);
  }
};

export const formatPhoneNumber = (inputNumber: any) => {
  // Remove non-digit characters from the input
  const cleanedNumber = inputNumber.replace(/\D/g, "");

  return cleanedNumber;
};

export const timeToTimestamp = (time: any) => {
  if (time) {
    const utcDateTime = new Date(time);

    // Get local timezone offset in minutes
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();

    // Calculate local datetime by subtracting the timezone offset
    const localDateTime = new Date(
      utcDateTime.getTime() - timezoneOffsetMinutes * 60 * 1000
    );

    // Convert local date-time to ISO 8601 format
    const localISOString = localDateTime.toISOString();

    return localISOString;
  } else {
    return;
  }
};

export const timestampToTime = (timestamp: any) => {
  if (
    timestamp &&
    timestamp !== null &&
    timestamp !== "undefined" &&
    timestamp !== undefined &&
    timestamp !== ""
  ) {
    const datetimeWithMilliseconds = new Date(timestamp);

    // Extract date and time components
    const datePart = datetimeWithMilliseconds?.toISOString()?.split("T")[0];
    const timePart = datetimeWithMilliseconds
      ?.toISOString()
      ?.split("T")[1]
      ?.split(".")[0];

    // Combine date and time components
    const datetimeStringWithoutMilliseconds = `${datePart}T${timePart}`;

    return datetimeStringWithoutMilliseconds;
  } else {
    return;
  }
};
