import { useEffect, useState } from "react";
import Heading from "../../../ui/heading";
import FinancialMetricsCard from "./FinancialMetricsCard";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import API from "../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const PageIntro = () => {
  return <Heading title="Financial" subTitle="See all your financial here" />;
};

const FinancialMetrics = () => {
  const [isClearDeliveryTotalFilter, setIsClearDeliveryTotalFilter] =
    useState(false);
  const [deliveryTotalStatus, setDeliveryTotalStatus] = useState(IDLE);
  const [userRole, setUserRole] = useState<string>();
  const [deliveryTotal, setDeliveryTotal] = useState("");
  const [isClearServiceChargeFilter, setIsClearServiceChargeFilter] =
    useState(false);
  const [serviceChargeStatus, setServiceChargeStatus] = useState(IDLE);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [isClearSupermarketFilter, setIsClearSupermarketFilter] =
    useState(false);
  const [supermarketTotalStatus, setSupermarketTotalStatus] = useState(IDLE);
  const [supermarketTotal, setSupermarketTotal] = useState();
  const [isClearDropperTotalFilter, setIsClearDropperTotalFilter] =
    useState(false);
  const [dropperTotalStatus, setDropperTotalStatus] = useState(IDLE);
  const [dropperTotal, setDropperTotal] = useState();
  const [isClearWalletBalanceFilter, setIsClearWalletBalanceFilter] =
    useState(false);
  const [walletBalanceStatus, setWalletBalanceStatus] = useState(IDLE);
  const [walletBalance, setWalletBalance] = useState(0);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const [deliveryDateFilter, setDeliveryDateFilter] = useState(
    getLastNDaysFormatted(1)
  );
  const [serviceChargeDateFilter, setServiceChargeDateFilter] = useState(
    getLastNDaysFormatted(1)
  );
  const [supermarketDateFilter, setSupermarketDateFilter] = useState(
    getLastNDaysFormatted(1)
  );
  const [dropperTotalDateFilter, setDropperTotalDateFilter] = useState(
    getLastNDaysFormatted(1)
  );
  const [walletBalanceDateFilter, setWalletBalanceDateFilter] = useState(
    getLastNDaysFormatted(1)
  );

  const deliveryTotalParams = isClearDeliveryTotalFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: deliveryDateFilter.endDate,
        startDate: deliveryDateFilter.startDate,
      };

  const serviceChargeParams = isClearServiceChargeFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: serviceChargeDateFilter.endDate,
        startDate: serviceChargeDateFilter.startDate,
      };

  const supermarketParams = isClearSupermarketFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: supermarketDateFilter.endDate,
        startDate: supermarketDateFilter.startDate,
      };

  const dropperTotalParams = isClearDropperTotalFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: dropperTotalDateFilter.endDate,
        startDate: dropperTotalDateFilter.startDate,
      };

  const walletBalanceParams = isClearWalletBalanceFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: walletBalanceDateFilter.endDate,
        startDate: walletBalanceDateFilter.startDate,
      };

  const getDeliveryTotal = async () => {
    setDeliveryTotalStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.deliveryTotal,
      params: deliveryTotalParams,
    })
      .then((resp) => {
        setDeliveryTotalStatus(SUCCESS);
        setDeliveryTotal(resp.data.data.totalDeliveryFee);
      })
      .catch((err) => {
        setDeliveryTotalStatus(ERROR);
      });
  };

  const getServiceCharge = async () => {
    setServiceChargeStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.serviceCharge,
      params: serviceChargeParams,
    })
      .then((resp) => {
        setServiceChargeStatus(SUCCESS);
        setServiceCharge(resp.data.data.totalServiceFee);
      })
      .catch((err) => {
        setServiceChargeStatus(ERROR);
      });
  };

  const getSupermarketTotal = async () => {
    setSupermarketTotalStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.supermarketTotal,
      params: supermarketParams,
    })
      .then((resp) => {
        setSupermarketTotalStatus(SUCCESS);
        setSupermarketTotal(resp.data.data);
      })
      .catch((err) => {
        setSupermarketTotalStatus(ERROR);
      });
  };

  const getDropperTotal = async () => {
    setDropperTotalStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.dropperTotal,
      params: dropperTotalParams,
    })
      .then((resp) => {
        setDropperTotalStatus(SUCCESS);
        setDropperTotal(resp.data.data);
      })
      .catch((err) => {
        setDropperTotalStatus(ERROR);
      });
  };

  const getWalletBalance = async () => {
    setWalletBalanceStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.walletBalance,
      params: walletBalanceParams,
    })
      .then((resp) => {
        setWalletBalanceStatus(SUCCESS);
        setWalletBalance(resp.data.data[0].total);
      })
      .catch((err) => {
        setWalletBalanceStatus(ERROR);
      });
  };

  useEffect(() => {
    getDeliveryTotal();
  }, [deliveryDateFilter]);

  useEffect(() => {
    getServiceCharge();
  }, [serviceChargeDateFilter]);

  useEffect(() => {
    getSupermarketTotal();
  }, [supermarketDateFilter]);

  useEffect(() => {
    getDropperTotal();
  }, [dropperTotalDateFilter]);

  useEffect(() => {
    getWalletBalance();
  }, [walletBalanceDateFilter]);

  return (
    <div style={{ marginBottom: "80px" }}>
      <PageIntro />
      <FinancialMetricsCard
        deliveryTotal={deliveryTotal}
        isDeliveryLoading={deliveryTotalStatus === LOADING ? true : false}
        deliveryDateFilter={deliveryDateFilter}
        setDeliveryDateFilter={setDeliveryDateFilter}
        serviceCharge={serviceCharge}
        isServiceChargeLoading={serviceChargeStatus === LOADING ? true : false}
        supermarketTotal={supermarketTotal}
        isSupermarketLoading={supermarketTotalStatus === LOADING ? true : false}
        dropperTotal={dropperTotal}
        isDropperTotalLoading={dropperTotalStatus === LOADING ? true : false}
        walletBalance={walletBalance}
        isWalletBalanceLoading={walletBalanceStatus === LOADING ? true : false}
        setServiceChargeDateFilter={setServiceChargeDateFilter}
        setSupermarketDateFilter={setSupermarketDateFilter}
        setDropperTotalDateFilter={setDropperTotalDateFilter}
        setWalletBalanceDateFilter={setWalletBalanceDateFilter}
        getLastNDaysFormatted={getLastNDaysFormatted}
        setIsClearDeliveryTotalFilter={setIsClearDeliveryTotalFilter}
        setIsClearServiceChargeFilter={setIsClearServiceChargeFilter}
        setIsClearSupermarketFilter={setIsClearSupermarketFilter}
        setIsClearDropperTotalFilter={setIsClearDropperTotalFilter}
        setIsClearWalletBalanceFilter={setIsClearWalletBalanceFilter}
        userRole={userRole}
      />
    </div>
  );
};

export default FinancialMetrics;
