import { useEffect, useState } from "react";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import Heading from "../../../ui/heading";
import Layout from "../../../ui/layout";
import { useLocation, useNavigate } from "react-router-dom";
import CardLoader from "../../../ui/cardLoader";
import EmptyView from "../../../ui/emptyView";
import ErrorView from "../../../ui/ErrorView";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../../assets/more.svg";
import ViewIcon from "../../../../assets/view.svg";
import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import ActivateIcon from "../../../../assets/activate.svg";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Paginate from "../../../ui/paginate";
import API from "../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import AddPackage from "./addPackage/AddPackage";
import { handlePrice } from "../../../../helper";
import PackageFilter from "./packageFilter/PackageFilter";
import Search from "../../../ui/search";

const PageHeading = () => {
  return (
    <Heading
      title="GSA Package"
      subTitle="See all your GSA package and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const GSAPackage = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [popup, setPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [deleteStatus, setDeleteStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedPackage, setSelectedPackage] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [userRole, setUserRole] = useState("");
  const [storeBranchId, setStoreBranchId] = useState("");

  const [filterClicked, setFilterClicked] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [filterParams, updateFilterParams] = useState<object>();

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const location = useLocation();

  const handleEditProduct = (data: any) => {
    setIsEdit(true);
    setSelectedPackage(data);
    setPopup(true);
  };

  // Get the 'page' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1; // Default to page 1 if 'page' param is not present

  const Navigate = useNavigate();

  const refresh = () => {
    window.location.reload();
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: any;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
    handlePaginationClick(result.currentPage);
  };

  const handlePaginationClick = (pageNumber: any) => {
    // Update the URL query parameter when pagination button is clicked
    queryParams.set("page", pageNumber);
    const newUrl = `?${queryParams.toString()}`;
    // Use navigate to update the URL without refreshing the page
    window.location.search = newUrl;
  };

  const PackageFilterParams = isClearFilter
    ? { limit: filter.limit, page: currentPage }
    : { ...filterParams, limit: filter.limit, page: currentPage };

  const getPackages = async (currentPage: any) => {
    setPackageDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.gsaPackages,
      params: PackageFilterParams,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setTotalItems(resp.data.data.meta.totalItems);

        const formattedList = resp.data?.content?.packages;
        setPackageDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
      });
  };

  const deletePackage = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getGsaPackages(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data?.message);
        getPackages(currentPage);
      })
      .catch((err) => {
        setStatus(DATAMODE);
        toast.error(err.response?.data?.message);
      });
  };

  const archivePackage = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.archiveGsaPackages(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data?.message);
        getPackages(currentPage);
      })
      .catch((err) => {
        setStatus(DATAMODE);
        toast.error(err.response?.data?.message);
      });
  };

  useEffect(() => {
    getPackages(currentPage);
  }, [filter, currentPage, filterParams, isClearFilter]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <EmptyView message="There's nothing here presently" />
          </div>
        );

      case ERROR:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      case DATAMODE:
        return (
          <div className="table-details" style={{ marginTop: "24px" }}>
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "40%", fontSize: "12px" }}>Package Name</p>
                <p style={{ width: "30%", fontSize: "12px" }}>Package Amount</p>
                <p style={{ width: "20%", fontSize: "12px" }}>
                  Available Slots
                </p>
                <p
                  style={{
                    width: "10%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Actions
                </p>
              </div>
              {packageDetails?.map((item: any, index: any) => (
                <div key={index} style={{ cursor: "pointer" }}>
                  <div className="details-table-body" style={{ width: "100%" }}>
                    <p
                      style={{ width: "40%", fontSize: "12px" }}
                      onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.name}
                    </p>
                    <p
                      style={{ width: "30%", fontSize: "12px" }}
                      onClick={() => Navigate(`${item?.id}`)}
                    >
                      {handlePrice(item?.priceInMinor)}
                    </p>
                    <p
                      style={{ width: "20%", fontSize: "12px" }}
                      onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.availableSlots}
                    </p>
                    <Popover
                      className="flex-1 text-right"
                      style={{ width: "10%", textAlign: "center" }}
                    >
                      <>
                        <Popover.Button
                          className="popover-button"
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                          }}
                        >
                          <img src={MoreIcon} alt="" />
                        </Popover.Button>

                        <Popover.Panel
                          className="popover-panel"
                          style={{
                            position: "absolute",
                            right: "64px",
                            zIndex: "500",
                            // padding: "14px",
                            width: "150px",
                            background: "white",
                            borderRadius: "16px",
                            boxShadow: "5px 5px #00000030",
                            border: "0.5px solid #00000030",
                          }}
                        >
                          <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            style={{ borderRadius: "16px" }}
                          >
                            <div
                              className="items-center grid gap-4 bg-white px-5 py-4"
                              style={{
                                alignItems: "center",
                                background: "white",
                                padding: "16px, 20px",
                              }}
                            >
                              <div
                                onClick={() => Navigate(`${item?.id}`)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={ViewIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">
                                  View Package
                                </p>
                              </div>
                              <div
                                onClick={() => handleEditProduct(item)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={EditIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">
                                  Edit Package
                                </p>
                              </div>

                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                  // color: "red",
                                }}
                                onClick={() => {
                                  confirmAlert({
                                    title:
                                      "Are you sure you want to archive this package?",
                                    message:
                                      "This package data will be archived and you will not be able to retrieve them",
                                    buttons: [
                                      {
                                        label: "Cancel",
                                        // Do Close operation
                                      },
                                      {
                                        label: "Archive",
                                        onClick: () => archivePackage(item?.id),
                                      },
                                    ],
                                  });
                                }}
                              >
                                <img src={EditIcon} alt="" />
                                <p className="pl-2 text-[16px]">
                                  Archive Package
                                </p>
                              </div>
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => {
                                  confirmAlert({
                                    title:
                                      "Are you sure you want to delete this package?",
                                    message:
                                      "This pacjage data will be erased and you will not be able to retrieve them",
                                    buttons: [
                                      {
                                        label: "Cancel",
                                        // Do Close operation
                                      },
                                      {
                                        label: "Delete",
                                        onClick: () => deletePackage(item?.id),
                                      },
                                    ],
                                  });
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                                <p className="pl-2 text-[16px]">
                                  Delete Package
                                </p>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    </Popover>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <PageHeading />
        <div className="header-cta" style={{ display: "flex", gap: "12px" }}>
          <Button
            text="Add Package"
            className="customer-add"
            onClick={() => {
              setIsEdit(false);
              setPopup(true);
            }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* <Search setValue={setSearchText} setIsClearFilter={setIsClearFilter} /> */}
        <div></div>
        <div
          style={{
            position: "relative",
            right: "0",
            width: "100%",
            maxWidth: "30rem",
          }}
        >
          <p
            style={{
              position: "absolute",
              right: "100px",
              top: "-24px",
              background: "#FD6E35",
              color: "white",
              cursor: "pointer",
              padding: "0.2rem 1.5rem",
              borderRadius: "0.375rem",
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }}
            onClick={() => setIsClearFilter(true)}
          >
            Clear Filter
          </p>
          <PackageFilter
            filterParams={filterParams}
            setIsClearFilter={setIsClearFilter}
            updateFilterParams={updateFilterParams}
          />
        </div>
      </div>
      {renderBasedOnStatus()}
      {status !== LOADING && packageDetails?.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={currentPage}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddPackage
          getPackages={getPackages}
          setPopup={setPopup}
          data={selectedPackage}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </Layout>
  );
};

export default GSAPackage;
