import React, { useEffect, useState } from "react";
import CardLoader from "../../../ui/cardLoader";
import "./OperationMetricsCard.scss";
import API from "../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { handlePrice } from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const topStore = [
  {
    product: "Store name",
    volume: 5,
  },
  {
    product: "Store name",
    volume: 5,
  },
  {
    product: "Store name",
    volume: 5,
  },
  {
    product: "Store name",
    volume: 5,
  },
  {
    product: "Store name",
    volume: 5,
  },
];

const OperationMetricsCard = () => {
  const [customerClicked, setCustomerClicked] = useState(true);
  const [storeClicked, setStoreClicked] = useState(true);
  const [topProducts, setTopProducts] = useState<any>();
  const [topProductStatus, seTopProductStatus] = useState(IDLE);
  const [topCustomers, setTopCustomers] = useState<any>();
  const [topCustomerStatus, seTopCustomerStatus] = useState(IDLE);
  const [topStores, setTopStores] = useState<any>();
  const [topStoreStatus, seTopStoreStatus] = useState(IDLE);

  const getTopProduct = async () => {
    seTopProductStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.topProducts,
      //   params: deliveryTotalParams,
    })
      .then((resp) => {
        seTopProductStatus(SUCCESS);
        setTopProducts(resp.data.data);
      })
      .catch((err) => {
        seTopProductStatus(ERROR);
      });
  };

  const getTopCustomer = async () => {
    seTopCustomerStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.topCustomers,
      //   params: deliveryTotalParams,
    })
      .then((resp) => {
        seTopCustomerStatus(SUCCESS);
        setTopCustomers(resp.data.data);
      })
      .catch((err) => {
        seTopCustomerStatus(ERROR);
      });
  };

  const getTopStore = async () => {
    seTopStoreStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.topStores,
      //   params: deliveryTotalParams,
    })
      .then((resp) => {
        seTopStoreStatus(SUCCESS);
        setTopStores(resp.data.data);
      })
      .catch((err) => {
        seTopStoreStatus(ERROR);
      });
  };

  useEffect(() => {
    getTopProduct();
    getTopCustomer();
    getTopStore();
  }, []);

  return (
    <>
      <div className="cards operation">
        <div className="card operation">
          <div className="head">
            <p className="type2 bold">Top 5 products</p>
            <p className="type2 bold">Volume</p>
          </div>
          {topProductStatus === LOADING ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="operation-details">
              {topProducts?.map((product: any, index: any) => (
                <div className="details" key={index}>
                  <p className="description">{product.name}</p>
                  <p className="detail" style={{ fontSize: "14px" }}>
                    {product.point}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="card operation">
          <div className="head">
            <p className="type2 bold">Top 5 Customers</p>
            <p
              className="type2 bold"
              onClick={() => setCustomerClicked(!customerClicked)}
              style={{
                padding: "4px 8px",
                background: "#cccccc",
                border: "0.5px solid #00000050",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {customerClicked ? "Volume" : "Value"}
            </p>
          </div>
          {topCustomerStatus === LOADING ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="operation-details">
              {(customerClicked
                ? topCustomers?.volume
                : topCustomers?.value
              )?.map((customer: any, index: any) => (
                <div className="details" key={index}>
                  <p className="description">
                    {customer.customer.firstName +
                      " " +
                      customer.customer.lastName}
                  </p>
                  <p className="detail" style={{ fontSize: "14px" }}>
                    {customerClicked
                      ? customer.total
                      : handlePrice(customer.total)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="card operation">
          <div className="head">
            <p className="type2 bold">Top 5 Stores</p>
            <p
              className="type2 bold"
              onClick={() => setStoreClicked(!storeClicked)}
              style={{
                padding: "4px 8px",
                background: "#cccccc",
                border: "0.5px solid #00000050",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {storeClicked ? "Volume" : "Value"}
            </p>
          </div>
          {topStoreStatus === LOADING ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="operation-details">
              {(storeClicked ? topStores?.volume : topStores?.value)?.map(
                (store: any, index: any) => (
                  <div className="details" key={index}>
                    <p className="description">{store?.store?.name}</p>
                    <p className="detail" style={{ fontSize: "14px" }}>
                      {storeClicked ? store.total : handlePrice(store.total)}
                    </p>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OperationMetricsCard;
