import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import CardLoader from "../../../../ui/cardLoader";
import EmptyView from "../../../../ui/emptyView";
import ErrorView from "../../../../ui/ErrorView";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import API from "../../../../../utils/api/API";
import { getErrorMessage } from "../../../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

interface ModalProps {
  id: any;
}

const VoucherCard = (props: ModalProps) => {
  const { id } = props;
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [voucherDetails, setVoucherDetails] = useState([
    {
      id: "",
      orderRef: "",
      details: "",
    },
  ]);

  const Navigate = useNavigate();
  const handleClick = (id: any) => {
    Navigate(`/orders/${id}`);
  };

  const refresh = () => {
    window.location.reload();
  };

  //   const filter = {
  //     "filter.store.id": id,
  //   };

  const getVoucherDetails = async () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getVouchers(id),
      //   params: filter
    })
      .then((resp) => {
        const formattedList = resp.data?.data.orders?.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              orderRef: data.orderRef,
              // details: data.description,
            };
          }
        );
        setVoucherDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  useEffect(() => {
    getVoucherDetails();
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="This voucher has not yet been used." />
          </div>
        );

      case DATAMODE:
        return (
          <div className="cards">
            {voucherDetails?.map((data, index) => {
              const { orderRef, id } = data;
              return (
                <div
                  className="branch-card"
                    onClick={() => handleClick(id)}
                  key={index}
                >
                  <div className="head">
                    <img
                      src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png"
                      alt="store-branch"
                    />
                  </div>
                  <h3>{orderRef}</h3>
                </div>
              );
            })}
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return <>{renderBasedOnStatus()}</>;
};

export default VoucherCard;
