import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMe } from "../../../helper";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import API from "../../../utils/api/API";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const RiderSingle = () => {
  const Navigate = useNavigate();

  const navigate = useNavigate();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [user, setUser] = useState({
    role: "",
  });

  useEffect(() => {
    getMe(setUser, setIsUserLoading);
  }, []);

  useEffect(() => {
    if (user.role === "STORE_ADMIN") {
      navigate("/");
    }
  }, [user]);

  const { id } = useParams();

  const [status, setStatus] = useState(IDLE);
  const [storeData, setStoreData] = useState({
    id: "",
    createdAt: "",
    name: "",
    status: "",
    address: "",
    mapLocation: "",
    maxDeliveryTime: 0,
    maxOrderAmount: 0,
    maxOrderQty: 0,
    minDeliveryTime: 0,
    minOrderAmount: 0,
    minOrderQty: 0,
    rating: null,
    state_id: 0,
    storeId: "",
  });
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const getStoreBranchSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getStoreBranch(id),
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const data = resp.data.data;
        const formattedList = {
          id: data.id,
          createdAt: data.createdAt,
          name: data.name,
          status: data.status,
          address: data.address,
          mapLocation: data.mapLocation,
          maxDeliveryTime: data.maxDeliveryTime,
          maxOrderAmount: data.maxOrderAmount,
          maxOrderQty: data.maxOrderQty,
          minDeliveryTime: data.minDeliveryTime,
          minOrderAmount: data.minOrderAmount,
          minOrderQty: data.minOrderQty,
          rating: data.rating,
          state_id: data.state_id,
          storeId: data.storeId,
        };
        setStoreData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting store");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (id) {
      getStoreBranchSingleData();
    }
  }, [id]);

  const refresh = () => {
    window.location.reload();
  };

  const storeBranchDetails = [
    {
      key: "Max. Delivery Time",
      value: `${storeData.maxDeliveryTime} hour`,
      className: "",
      image: "",
    },
    {
      key: "Min. Delivery Time",
      value: `${storeData.minDeliveryTime} hour`,
      className: "",
    },
    {
      key: "Map Location",
      value: storeData.mapLocation,
      className: "",
    },
    {
      key: "Max. Order Amount",
      value: `₦${storeData.maxOrderAmount}`,
      className: "",
    },
    {
      key: "Min. Order Amount",
      value: `₦${storeData.minOrderAmount}`,
      className: "",
    },
    {
      key: "Rating",
      value: storeData.rating,
      className: "",
    },
    {
      key: "Max. Order Quantity",
      value: storeData.maxOrderQty,
      className: "",
    },
    {
      key: "Min. Order Quantity",
      value: storeData.minOrderQty,
      className: "",
    },
    {
      key: "Status",
      value: storeData.status,
      className: "",
    },
  ];

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no rider presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="page-header">
              <div>
                <span className="go-back" onClick={() => Navigate(-1)}>
                  <img
                    src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                    alt="arrow-back"
                    className="arrow"
                  />
                  <h6>Go back</h6>
                </span>
                <h2>{storeData.name}</h2>
                <p className="type2" style={{ margin: "10px 0 0" }}>
                  Address: {storeData.address}
                </p>
              </div>
            </div>
            <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
              Rider Details
            </h5>
            <div className="store-branch-details">
              {storeBranchDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* <div className="order-details"></div> */}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">{renderBasedOnStatus()}</div>
    </>
  );
};

export default RiderSingle;
