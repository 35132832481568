import { useEffect, useState } from "react";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getErrorMessage, handlePrice } from "../../../helper";
import Paginate from "../../ui/paginate";
import CaretRight from "../../../assets/caret-right.svg";
import WalletFilter from "./walletFilter";
import CardLoader from "../../ui/cardLoader";
import WalletCard from "./walletCard/WalletCard";
import { confirmAlert } from "react-confirm-alert";
import RefundingPopup from "./RefundingPopup";
const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading title="Wallet" subTitle="See all wallet and their details here" />
  );
};
const Wallet = () => {
  const [walletDetails, setWalletDetails] = useState([]);
  const [filterClicked, setFilterClicked] = useState("DEPOSIT");
  const [walletStatus, setWalletStatus] = useState(IDLE);
  const [wallet, setWallet] = useState("");

  const [clickedIndex, setClickedIndex] = useState(walletDetails.length + 10);

  const toggle = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(walletDetails.length + 1);
    } else {
      setClickedIndex(index);
    }
  };

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedWallet, setSelectedWallet] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [refundingPopup, setRefundingPopup] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      transactionType: filterClicked,
    },
  });

  const handleRefund = (data: any) => {
    setRefundingPopup(true);
    setSelectedTransaction(data);
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const updateTransactionType = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        transactionType: filterClicked,
      },
    }));
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.transactionType": filter.filter.transactionType,
  };

  const getWallet = async () => {
    setWalletDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.wallets,
      params: params,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data?.data?.wallets;
        setWalletDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const getWalletBalance = async () => {
    setWalletStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.walletBalance,
      // params: walletBalanceParams,
    })
      .then((resp) => {
        setWalletStatus(SUCCESS);
        setWallet(resp.data.data[0].total);
      })
      .catch((err) => {
        setWalletStatus(ERROR);
      });
  };

  useEffect(() => {
    getWalletBalance();
  }, []);

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    updateTransactionType();
  }, [filterClicked]);

  useEffect(() => {
    getWallet();
  }, [filter]);

  const shortId = (id: any) => {
    if (id.length > 15) {
      id = id.substring(0, 15) + "...";
    }
    return id;
  };

  return (
    <>
      <div className="page-header">
        <PageHeading />
      </div>
      <WalletCard
        isWalletLoading={walletStatus === "LOADING" ? true : false}
        totalWallet={wallet}
      />
      {status === LOADING ? (
        <div
          className="details"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardLoader />
        </div>
      ) : (
        <div className="table-details" style={{ marginTop: "24px" }}>
          {/* {status !== ERROR && (
            <WalletFilter
              filterClicked={filterClicked}
              setFilterClicked={setFilterClicked}
              updatePage={updatePage}
            />
          )} */}
          <div className="table-details-body">
            <div className="details-table-header" style={{ width: "100%" }}>
              <p style={{ width: "2%" }}></p>
              <p style={{ width: "35%", fontSize: "12px" }}>User's name</p>
              <p style={{ width: "35%", fontSize: "12px" }}>Phone number</p>
              <p style={{ width: "30%", fontSize: "12px" }}>Wallet balance</p>
            </div>
            {walletDetails.map((item: any, index: any) => (
              <div key={index}>
                <div
                  className="details-table-body"
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => toggle(index)}
                >
                  <div
                    style={{
                      width: "2%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transform: `${
                        clickedIndex === index
                          ? "rotate(90deg)"
                          : "rotate(0deg)"
                      }`,
                    }}
                  >
                    <img src={CaretRight} alt="caret-right" />
                  </div>
                  <p style={{ width: "35%", fontSize: "12px" }}>
                    {item?.user?.firstName + " " + item?.user?.lastName}
                  </p>
                  <p style={{ width: "35%", fontSize: "12px" }}>
                    {item?.user?.phone}
                  </p>
                  <p style={{ width: "30%", fontSize: "12px" }}>
                    {handlePrice(item?.balance)}
                  </p>
                </div>
                {clickedIndex === index &&
                  (item?.transactions?.length === 0 ? (
                    <div
                      style={{
                        padding: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      No transaction found yet
                    </div>
                  ) : (
                    <div style={{ padding: "24px" }}>
                      <div className="table-details">
                        <div className="table-details-body">
                          <div
                            className="details-table-header"
                            style={{ width: "100%" }}
                          >
                            <p style={{ width: "18%" }}>Transaction ID</p>
                            <p style={{ width: "18%" }}>Transaction amount</p>
                            <p style={{ width: "18%" }}>Old balance</p>
                            <p style={{ width: "18%" }}>New balance</p>
                            <p style={{ width: "18%" }}>Transaction type</p>
                            {/* <p style={{ width: "10%" }}>Action</p> */}
                          </div>
                          {item?.transactions?.map(
                            (subItem: any, index: any) => (
                              <div
                                key={index}
                                className="details-table-body"
                                style={{ width: "100%" }}
                              >
                                <p style={{ width: "18%" }}>
                                  {shortId(subItem?.id)}
                                </p>
                                <p style={{ width: "18%" }}>
                                  {handlePrice(subItem?.amount)}
                                </p>
                                <p style={{ width: "18%" }}>
                                  {handlePrice(subItem?.oldBalance)}
                                </p>
                                <p style={{ width: "18%" }}>
                                  {handlePrice(subItem?.newBalance)}
                                </p>
                                <p style={{ width: "18%" }}>{subItem?.type}</p>
                                {/* <p style={{ width: "10%" }}>
                                  {subItem?.type === "PAYMENT" && (
                                    <span
                                      style={{
                                        color: "#32BA56",
                                        padding: "4px 8px",
                                        background: "#E4F4E6",
                                        // border: "0.5px solid #32BA56",
                                        borderRadius: "4px",
                                      }}
                                      onClick={() => handleRefund(subItem)}
                                    >
                                      Refund
                                    </span>
                                  )}
                                </p> */}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}
      {status !== LOADING && walletDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {refundingPopup && (
        <RefundingPopup
          setPopup={setRefundingPopup}
          // getCategories={getCategories}
          data={selectedTransaction}
          // selectedId={selectedId}
        />
      )}
    </>
  );
};

export default Wallet;
