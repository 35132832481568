import { Popover } from "@headlessui/react";
import "./style.scss";
import Select from "../../../../ui/select";
import { stateDetails } from "../../../../../data/city";
import Checkbox from "../../../../ui/checkbox";
import { Button } from "../../../../ui";
import { useState } from "react";

interface IFilterProps {
  updateFilterParams: any;
  filterParams: any;
  setIsClearFilter: any;
}

const PackageOrderFilter = (props: IFilterProps) => {
  const { filterParams, setIsClearFilter, updateFilterParams } = props;
  const [stateFilter, setStateFilter] = useState("");
  const [processingStatusFilter, setProcessingStatusFilter] = useState(false);
  const [assignedStatusFilter, setAssignedStatusFilter] = useState(false);
  const [dropperStatusFilter, setDropperStatusFilter] = useState(false);
  const [deliveredStatusFilter, setDeliveredStatusFilter] = useState(false);
  const [sortFilter, setSortFilter] = useState(false);
  const [isClaimedFilter, setIsClaimedFilter] = useState(false);
  const [paymentCompletedilter, setPaymentCompletedilter] = useState(false);

  const handleFilter = () => {
    setIsClearFilter(false);
    updateFilterParams({
      isClaimed: isClaimedFilter,
      status: `$in:${processingStatusFilter && "PROCESSING"},${
        deliveredStatusFilter && "COMPLETED"
      },${processingStatusFilter && "PENDING"}`,
      sortByEndDate: sortFilter,
    });
  };

  return (
    <>
      <div className="filter-overall-wrapper fixed top-16 w-full max-w-sm px-4">
        <Popover v-slot="{ open }" className="popover-overall-wrapper">
          <Popover.Button className="popover-button">
            <span>Filter</span>
          </Popover.Button>

          <Popover.Panel className="popover-panel">
            <div className="filter-categories">
              <div className="filter-category">
                <p className="type3 bold">Delivery Location</p>
                <Select options={stateDetails} />
              </div>
              <div className="filter-category">
                <p className="type3 bold">Order Status</p>
                <Checkbox
                  label="Processing"
                  onChange={() =>
                    setProcessingStatusFilter(!processingStatusFilter)
                  }
                />
                <Checkbox
                  label="Assigned to Dropper"
                  onChange={() =>
                    setAssignedStatusFilter(!assignedStatusFilter)
                  }
                />
                <Checkbox
                  label="Dropper on the way"
                  onChange={() => setDropperStatusFilter(!dropperStatusFilter)}
                />
                <Checkbox
                  label="Delivered"
                  onChange={() =>
                    setDeliveredStatusFilter(!deliveredStatusFilter)
                  }
                />
              </div>
              <div className="filter-category">
                <p className="type3 bold">Sort by</p>
                <Checkbox
                  label="Stop Date"
                  onChange={() => setSortFilter(!sortFilter)}
                />
              </div>
              <div className="filter-category">
                <p className="type3 bold">Other Filters</p>
                <Checkbox
                  label="Claimed Package Order"
                  onChange={() => setIsClaimedFilter(!isClaimedFilter)}
                />
                <Checkbox label="Orders with payment completed" />
              </div>
              <Button text="Filter" onClick={handleFilter} />
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    </>
  );
};

export default PackageOrderFilter;
