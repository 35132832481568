import { useNavigate, useParams } from "react-router-dom";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import { Button } from "../../ui";
import AddSubCategoryPopup from "./addSubCategoryPopup/AddSubCategoryPopup";
import API from "../../../utils/api/API";
import { handlePrice } from "../../../helper";
import AddProductPopup from "../products/addProductPopup/AddProductPopup";
import Paginate from "../../ui/paginate";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const DropFoodCategorySingle = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [subStatus, setSubStatus] = useState(IDLE);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productPopup, setProductPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryProductList, setCategoryProductList] = useState<any>();
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [categoryData, setCategoryData] = useState({
    id: "",
    name: "",
    status: "",
    description: "",
    info: "",
    picture: "",
  });

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const { id } = useParams();

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.category.id": id,
    isSortByCategory: true,
    isAdmin: true,
  };

  const handleProductEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setProductPopup(true);
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  const getCategorySingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getRestaurantCategory(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const data = resp.data.data;
        const formattedList = {
          id: data.id,
          status: data.status,
          name: data.name,
          info: data.info,
          description: data.description,
          picture: data.picture,
        };
        setCategoryData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting category");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getCategorySingleData();
  }, []);

  const refresh = () => {
    window.location.reload();
  };
  const categoryDetails = [
    {
      key: "Name",
      value: `${categoryData.name}`,
      className: "",
      image: `${
        categoryData.picture === null
          ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
          : categoryData.picture
      }`,
    },
    {
      key: "Description",
      value: `${categoryData.description}`,
      className: "",
    },
    {
      key: "Info",
      value: `${categoryData.info || "N/A"}`,
      className: "",
    },
    {
      key: "Status",
      value: `${categoryData.status}`,
      className: "",
    },
  ];

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no category presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="store-branch-details">
              {categoryDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* <div className="table-details">
              <div className="table-details-body">
                <div className="details-table-header" style={{ width: "100%" }}>
                  <p style={{ width: "30%" }}>Product</p>
                  <p style={{ width: "30%" }}>Category Score</p>
                  <p style={{ width: "30%" }}>Price</p>
                  <p style={{ width: "10%" }}>Action</p>
                </div>
                {categoryProductList?.length > 0 &&
                  categoryProductList.map((item: any, index: any) => (
                    <div className="details-table-body" key={index}>
                      <p style={{ width: "30%" }}>{item?.name}</p>
                      <p style={{ width: "30%" }}>{item?.categoryScore}</p>
                      <p style={{ width: "30%" }}>{handlePrice(item?.price)}</p>
                      <p
                        style={{
                          width: "10%",
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <span
                          style={{
                            color: "#32BA56",
                            padding: "4px 8px",
                            background: "#E4F4E6",
                            // border: "0.5px solid #32BA56",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleProductEditModal(item)}
                        >
                          Edit
                        </span>
                      </p>
                    </div>
                  ))}
                {productStatus !== LOADING &&
                  categoryProductList.length > 0 && (
                    <Paginate
                      totalItems={updateTotalItems()}
                      pageSize={filter?.limit}
                      currentPage={filter?.page}
                      onResult={onResult}
                    />
                  )}
              </div>
            </div> */}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">
        <div className="page-header">
          <div style={{ width: "100%" }}>
            <span className="go-back" onClick={() => Navigate(-1)}>
              <img
                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                alt="arrow-back"
                className="arrow"
              />
              <h6>Go back</h6>
            </span>
            <div className="page-header">
              <h2>Category Details</h2>
            </div>
          </div>
        </div>
        {renderBasedOnStatus()}
      </div>
    </>
  );
};

export default DropFoodCategorySingle;
