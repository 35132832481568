import "./Login.scss";
import { Button, Input, InputPassword } from "../../../components/ui";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import Alert from "../../../components/ui/alert";
import { useAppContext } from "../../../AppContext";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const { updateToken, updateUser, updateBusinessDetails } = useAppContext();

  const getUserById = async (id: any) => {
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getAUser(id),
    })
      .then((resp) => {
        console.log(resp);
        localStorage.setItem(
          "storeBranches",
          JSON.stringify(resp?.data?.data?.storeBranches)
        );
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const logIn = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    const data = {
      password: request.password,
      username: request.username.toLowerCase(),
    };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.logIn,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
        const token = resp.data.data.token.accessToken;
        const { firstName, email, lastName, role, id, storeBranchId } =
          resp.data.data.user;
        updateUser({ firstName, email, lastName, role, id, storeBranchId });
        updateToken(token);
        getUserById(id);
        navigate("/");
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => logIn(data);

  return (
    <>
      <div className="login-container">
        <div className="heading">
          <h3>Good to have you back!</h3>
          <p className="type2">It’s been a minute</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="username"
            placeholder="Enter email address"
            label="Email Address"
            register={register}
            required
          />
          <InputPassword
            name="password"
            placeholder="Enter password"
            label="Password"
            register={register}
            required
          />
          <p className="type4">
            Can’t remember password?{" "}
            <Link
              style={{ color: "#FD6E35", textDecoration: "underline" }}
              to="/reset-password"
            >
              Recover
            </Link>
          </p>
          <Button
            text="Login"
            className="button"
            isLoading={status === "LOADING" ? true : false}
          />
        </form>
        {/* <p className="type2">
          Don't have an acoount?{" "}
          <Link
            style={{ color: "#FD6E35", textDecoration: "underline" }}
            to="/signup"
          >
            Register
          </Link>
        </p> */}
      </div>
      {/* {status === ERROR && (
        <Alert
          title="Error Login in"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default Login;
