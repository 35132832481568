import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import CategoryTable from "../../ui/table/categoryTable/CategoryTable";
import AddCategoryPopup from "./addCategoryPopup/AddCategoryPopup";
import API from "../../../utils/api/API";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "../../../helper";

const PageHeading = () => {
  return (
    <Heading
      title="Categories"
      subTitle="See all your category and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const Category = () => {
  const [popup, setPopup] = useState(false);
  const [categoriesDetails, setCategoriesDetails] = useState([]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedStore, setSelectedStore] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setPopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedStore(data);
    setPopup(true);
  };

  const getCategories = async () => {
    setCategoriesDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.abstractCategory,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data?.data.meta.totalItems);
        const formattedList = resp.data?.data.abstractCategories.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              name: data.name,
              description: data.description,
              slug: data.slug,
              thumnail: data.thumnail,
              picture: data.picture,
              color: data.color,
            };
          }
        );
        setCategoriesDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        // setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteCategory = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getAbstractCategory(id),
    })
      .then((resp) => {
        // setStatus(SUCCESS);
        getCategories();
      })
      .catch((err) => {
        // setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getCategories();
  }, [filter]);

  return (
    <>
      <div className="page-header">
        <PageHeading />
        <Button
          text="Add Category"
          className="customer-add"
          onClick={handleAddModal}
        />
      </div>
      <div className="page-wrapper">
        <div className="filter-wrapper dropper-shopper">
          <div
            className={`ongoing-filter ${
              window.location.href.includes("category") && "active"
            }`}
            onClick={() => navigate("/category/")}
          >
            <p>Groceries</p>
          </div>
          <div
            className={`ongoing-filter ${
              window.location.href.includes("drop-food-cat") && "active"
            }`}
            onClick={() => navigate("/drop-food-cat/")}
          >
            <p>DropFood</p>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        {/* {!isLoading && dropsTableData.length > 0 && (
          <DropsFilter setFilterFunction={setOverviewDropFilter} />
        )} */}
        <CategoryTable
          data={categoriesDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          // clickable
          message={message}
          deleteCategory={deleteCategory}
          handleEditModal={handleEditModal}
        />
      </div>
      {popup && (
        <AddCategoryPopup
          setPopup={setPopup}
          getCategories={getCategories}
          data={selectedStore}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
      {status !== LOADING && categoriesDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
    </>
  );
};

export default Category;
