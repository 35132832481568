import StoreBranches from "../components/dashboard/storeBranch";
import Layout from "../components/ui/layout";

const StoreBranchPage = () => {
  return (
    <>
      <Layout>
        <StoreBranches />
      </Layout>
    </>
  );
};

export default StoreBranchPage;
