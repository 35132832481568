import Layout from "../components/ui/layout";
import Analytics from "../components/dashboard/analytics";

const AnalyticsPage = () => {
  return (
    <Layout>
      <Analytics />
    </Layout>
  );
};

export default AnalyticsPage;
