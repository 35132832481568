import Users from "../components/dashboard/users";
import Layout from "../components/ui/layout";

const CustomersPage = () => {
  return (
    <Layout>
      <Users />
    </Layout>
  );
};

export default CustomersPage;
