import { Popover } from "@headlessui/react";
import "./style.scss";
import Select from "../../../../ui/select";
import { stateDetails } from "../../../../../data/city";
import Checkbox from "../../../../ui/checkbox";
import { Button } from "../../../../ui";
import { useState } from "react";

interface IFilterProps {
  updateFilterParams: any;
  filterParams: any;
  setIsClearFilter: any;
}

const PackageFilter = (props: IFilterProps) => {
  const { filterParams, setIsClearFilter, updateFilterParams } = props;
  const [sortFilter, setSortFilter] = useState(false);

  const handleFilter = () => {
    setIsClearFilter(false);
    updateFilterParams({
      openSlot: sortFilter,
    });
  };

  return (
    <>
      <div className="filter-overall-wrapper fixed top-16 w-full max-w-sm px-4">
        <Popover v-slot="{ open }" className="popover-overall-wrapper">
          <Popover.Button className="popover-button">
            <span>Filter</span>
          </Popover.Button>

          <Popover.Panel className="popover-panel">
            <div className="filter-categories">
              <div className="filter-category">
                <p className="type3 bold">Filter by</p>
                <Checkbox
                  label="Open Slot"
                  onChange={() => setSortFilter(!sortFilter)}
                />
              </div>
              <Button text="Filter" onClick={handleFilter} />
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    </>
  );
};

export default PackageFilter;
