// const baseUrl = "https://1d51-102-88-82-85.ngrok-free.app/api/v1";
// const baseUrl = "https://api.grocerries.getdropper.com/api/v1";
const baseUrl = process.env.REACT_APP_BASE_URL;
const GSABaseUrl = process.env.REACT_APP_GSA_BASE_URL;

export const apiUrls = {
  logIn: `${baseUrl}/auth/login`,
  register: `${baseUrl}/auth/register-admin`,
  changePassword: `${baseUrl}/auth/password/change/`,
  forgotPassword: `${baseUrl}/auth/password/forgot/`,
  resetPassword: `${baseUrl}/auth/password/reset/`,
  getMe: `${baseUrl}/auth/me`,
  users: `${baseUrl}/users`,
  getAUser: (userId?: string) => `${baseUrl}/users/${userId}`,
  unassignStoreBranch: (userId?: string) =>
    `${baseUrl}/users/unassign-store-branch/${userId}`,
  suspendUser: (userId?: string) => `${baseUrl}/users/${userId}/suspend`,
  activateUser: (userId?: string) => `${baseUrl}/users/${userId}/activate`,
  store: `${baseUrl}/store`,
  getStore: (storeId?: string) => `${baseUrl}/store/${storeId}`,
  rider: `${baseUrl}/rider`,
  getRider: (riderId?: string) => `${baseUrl}/rider/${riderId}`,
  fee: `${baseUrl}/settings/fee`,
  getFee: (feeId?: string) => `${baseUrl}/settings/fee/${feeId}`,
  promo: `${baseUrl}/order/promo`,
  vouchers: `${baseUrl}/vouchers`,
  multipleVouchers: `${baseUrl}/vouchers/multiple`,
  getVouchers: (voucherId?: string) => `${baseUrl}/vouchers/${voucherId}`,
  global: `${baseUrl}/settings/global`,
  getGlobal: (globalId?: string) => `${baseUrl}/settings/global/${globalId}`,
  banners: `${baseUrl}/banners`,
  getBanner: (bannerId?: string) => `${baseUrl}/banners/${bannerId}`,
  addAbstractCategory: `${baseUrl}/category/abstract-categories/create`,
  abstractCategory: `${baseUrl}/category/abstract-categories`,
  getAbstractCategory: (abstractCategoryId?: string) =>
    `${baseUrl}/category/abstract-categories/${abstractCategoryId}`,
  foodCategory: `${baseUrl}/category/food`,
  getFoodCategory: (foodCategoryId?: string) =>
    `${baseUrl}/category/food/${foodCategoryId}`,
  addRestaurantCategory: `${baseUrl}/restaurant-category`,
  restaurantCategory: `${baseUrl}/restaurant-category`,
  getRestaurantCategory: (restaurantCategoryId?: string) =>
    `${baseUrl}/restaurant-category/${restaurantCategoryId}`,
  menuCategory: `${baseUrl}/menu/categories`,
  getMenuCategory: (categoryId?: string) =>
    `${baseUrl}/menu/categories/${categoryId}`,
  menuItem: `${baseUrl}/menu/items`,
  getMenuItem: (itemId?: string) =>
    `${baseUrl}/menu/items/${itemId}`,
  category: `${baseUrl}/category`,
  getCategory: (categoryId?: string) => `${baseUrl}/category/${categoryId}`,
  updateCategory: (categoryId?: string) => `${baseUrl}/category/${categoryId}`,
  subCategory: (categoryId?: string) =>
    `${baseUrl}/category/${categoryId}/child`,
  getSubCategory: `${baseUrl}/category/subcategories`,
  products: `${baseUrl}/product`,
  getProduct: (productId?: string) => `${baseUrl}/product/${productId}`,
  updateProduct: (productId?: string, storeBranchId?: string) =>
    `${baseUrl}/product/${productId}?storeBranchId=${storeBranchId}`,
  addProductImage: (productId?: string, mediaId?: string) =>
    `${baseUrl}/product/${productId}/media/${mediaId}/sync`,
  vehicles: `${baseUrl}/vehicles`,
  getVehicle: (vehicleId?: string) => `${baseUrl}/vehicles/${vehicleId}`,
  revenue: `${baseUrl}/analytics/order/revenue`,
  completedOrders: `${baseUrl}/analytics/order/completed`,
  deliveryTotal: `${baseUrl}/analytics/order/delivery-total`,
  serviceCharge: `${baseUrl}/analytics/order/service-charge-total`,
  supermarketTotal: `${baseUrl}/analytics/order/supermarket-total`,
  dropperTotal: `${baseUrl}/analytics/order/dropper-total`,
  walletBalance: `${baseUrl}/analytics/wallets/balance`,
  topProducts: `${baseUrl}/analytics/products/top`,
  topCustomers: `${baseUrl}/analytics/users/top`,
  topStores: `${baseUrl}/analytics/stores/top`,
  wallets: `${baseUrl}/wallets`,
  processRefund: `${baseUrl}/wallets/refund`,
  storeBranch: `${baseUrl}/store-branch`,
  getStoreBranch: (storeBranchId?: string) =>
    `${baseUrl}/store-branch/${storeBranchId}`,
  addStoreBranch: (storeId?: string) =>
    `${baseUrl}/store-branch/store/${storeId}`,
  makeStoreBranchAvailable: (storeId?: string) =>
    `${baseUrl}/store-branch/set-available/${storeId}`,
  makeStoreBranchUnavailable: (storeId?: string) =>
    `${baseUrl}/store-branch/set-unavailable/${storeId}`,
  checkStoreBranchAvailability: (storeId?: string) =>
    `${baseUrl}/store-branch/check-availability/${storeId}`,
  getStates: `${baseUrl}/order/states`,
  getOrders: `${baseUrl}/order/admin/paginated`,
  updateOrder: (orderId?: string) => `${baseUrl}/order/admin/${orderId}`,
  getOrder: (orderId?: string) => `${baseUrl}/order/${orderId}`,
  getRatings: `${baseUrl}/ratings`,
  getBalance: `${baseUrl}/dropper`,
  createDrops: `${baseUrl}/dropper/drop/create`,
  uploadFile: `${baseUrl}/media/upload/image`,
  uploaMultipleFile: `${baseUrl}/media/upload/multiple/image`,
  uploaMultipleProductFile: `${baseUrl}/media/upload/multiple/product-images`,
  uploadImage: (modelId?: string) => `${baseUrl}/media/upload/image/${modelId}`,
  bestSelling: (storeId?: string) =>
    `${baseUrl}/product/best-selling/${storeId}`,
  getImages: (modelId?: string) => `${baseUrl}/media/gallery/${modelId}`,
  getGallery: `${baseUrl}/media/store/gallery`,
  getImage: (mediaId?: string) => `${baseUrl}/media/${mediaId}`,
  getStoreBranches: (storeId?: string) =>
    `${baseUrl}/store-branch/store/${storeId}`,
  getSingleDrop: (dropId?: string) => `${baseUrl}/dropper/drop/${dropId}`,
  getCity: (stateId?: string) => `${baseUrl}/order/state/${stateId}/lgas`,
  // editCategory: (categoryId: string) => `${baseUrl}/categories/${categoryId}`,
  // deleteCategory: (categoryId: string) =>
  // `${adminUrl}/categories/${categoryId}`,

  //-----------------------------GSA------------------------//
  gsaProduct: `${GSABaseUrl}/products`,
  getGsaProduct: (productId?: string) => `${GSABaseUrl}/products/${productId}`,
  updateGsaProduct: (productId?: string) =>
    `${GSABaseUrl}/products/${productId}`,
  gsaProductBrand: `${GSABaseUrl}/products/brand`,
  getGsaProductBrands: `${GSABaseUrl}/products/brands`,
  updateGsaProductBrand: (brandId?: string) =>
    `${GSABaseUrl}/products/brand/${brandId}`,
  gsaUploadFile: `${GSABaseUrl}/images`,
  gsaAnalytics: `${GSABaseUrl}/stats`,
  gsaUser: `${GSABaseUrl}/users`,
  getGsaUser: (userId?: string) => `${GSABaseUrl}/users/${userId}`,
  gsaUserWallet: (userId?: string) => `${GSABaseUrl}/wallets/${userId}`,
  gsaOrder: `${GSABaseUrl}/orders`,
  cancelGsaOrder: (orderId?: string) =>
    `${GSABaseUrl}/orders/cancel/${orderId}`,
  gsaOrderByUser: (userId?: string) => `${GSABaseUrl}/orders/user/${userId}`,
  gsaOrderDetails: (orderId?: string) =>
    `${GSABaseUrl}/orders/details/${orderId}`,
  updateGsaOrderDeliveryStatus: (orderId?: string) =>
    `${GSABaseUrl}/orders/delivery/status/${orderId}`,
  gsaPackages: `${GSABaseUrl}/packages`,
  getGsaPackages: (packageId?: string) => `${GSABaseUrl}/packages/${packageId}`,
  archiveGsaPackages: (packageId?: string) =>
    `${GSABaseUrl}/packages/archive/${packageId}`,
  trashGsaPackages: (packageId?: string) =>
    `${GSABaseUrl}/packages/trash/${packageId}`,
  allGsaDeliveryLocations: `${GSABaseUrl}/delivery-locations/all`,
  gsaDeliveryLocations: `${GSABaseUrl}/delivery-locations`,
  getGsaDeliveryLocation: (locationId?: string) =>
    `${GSABaseUrl}/delivery-location/${locationId}`,
  deleteGsaDeliveryLocation: (locationId?: string) =>
    `${GSABaseUrl}/delivery-locations/${locationId}`,
  gsaServiceCharges: `${GSABaseUrl}/service-charges`,
  getGsaServiceCharges: (serviceId?: string) =>
    `${GSABaseUrl}/service-charges/${serviceId}`,

    //-----------------------------INVENTORY------------------------//
    blackBellsInventory: "https://fusion-core-service-dot-patronizeng.ew.r.appspot.com/get-vendor-data/3fce934b-163a-4232-be28-e0cdf73f8edc",
};

export const apiQueryMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};
