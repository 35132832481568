import MaterialTable from "material-table";
import { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import CardLoader from "../../cardLoader";
// import { searchContext } from "../../../../helper/Context";
import EmptyView from "../../emptyView";
import ErrorView from "../../ErrorView";

interface TableProps {
  arrange?: any;
  data: Array<object>;
  clickable?: boolean;
  paging?: boolean;
  emptyHeight: string;
  search?: string;
  isCardLoading?: boolean;
  message?: string;
  dataNull?: boolean;
  isCardError?: boolean;
  deleteGlobal: Function;
  handleEditModal: Function;
}

const GlobalTable = (props: TableProps) => {
  const {
    arrange,
    data,
    paging,
    emptyHeight,
    clickable,
    search,
    isCardLoading,
    message,
    isCardError,
    dataNull,
    deleteGlobal,
    handleEditModal,
  } = props;
  const Navigate = useNavigate();
  // const {searchPage} = useContext(searchContext);

  const refresh = () => {
    window.location.reload();
  };

  const shortId = (Id: any) => {
    if (Id.length > 5) {
      Id = Id.substring(0, 5) + "...";
    }
    return Id;
  };

  const handleImage = (image: any) => {
    if (image === null) {
      image =
        "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg";
    } else {
      image = image;
    }
    return image;
  };

  function capitalizeEachWord(inputString:any) {
    if (typeof inputString !== 'string') {
      return ''; // Return an empty string for non-string inputs
    }
  
    // Split the input string into an array of words
    const words = inputString.trim().split(/\s+/);
  
    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return '';
    });
  
    // Join the capitalized words back to form the final string
    return capitalizedWords.join(' ');
  }

  const tableColumnData = [
    {
      title: "Global Id",
      field: "globalId",
      render: (rowData: any) => (
        <div>
          <p className="type4" style={{ whiteSpace: "nowrap" }}>
            {shortId(rowData.id)}
          </p>
        </div>
      ),
    },
    {
      title: "Global Name",
      field: "name",
      render: (rowData: any) => (
        <div>
          <p className="type4" style={{ whiteSpace: "nowrap" }}>
            {capitalizeEachWord(rowData.name)}
          </p>
        </div>
      ),
    },
    {
      title: "Code",
      field: "code",
      render: (rowData: any) => (
        <div>
          <p className="type4" style={{ whiteSpace: "nowrap" }}>
            {rowData.code}
          </p>
        </div>
      ),
    },
    {
      title: "Value",
      field: "value",
      render: (rowData: any) => (
        <div>
          <p className="type4" style={{ whiteSpace: "nowrap" }}>
            {rowData.value}
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="orders-table-section">
      <div className="orders-table">
        {data.length >= 1 ? (
          <div className="">
            <MaterialTable
              title=""
              options={{
                filtering: false,
                paging: paging,
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  color: "#FFF",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                },
                // searchText: `${searchPage}`
              }}
              onRowClick={(event, rowData: any) => {
                clickable && Navigate(`${rowData.id}`);
              }}
              columns={tableColumnData}
              data={data}
              actions={[
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#1A1B1A",
                        padding: "4px",
                        background: "#11233710",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #11233730",
                      }}
                    >
                      Update
                    </div>
                  ),
                  tooltip: "Update Setting",
                  onClick: (event, rowData) => {
                    handleEditModal(rowData);
                  },
                },
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#EB5757",
                        padding: "4px",
                        background: "#FEEEEF",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #EE273730",
                      }}
                    >
                      Delete
                    </div>
                  ),
                  tooltip: "delete setting",
                  onClick: (event, rowData) => {
                    confirmAlert({
                      title: "Are you sure you want to delete this setting?",
                      message:
                        "This setting’s data will be erased and you will not be able to retrieve them",
                      buttons: [
                        {
                          label: "Cancel",
                          // Do Close operation
                        },
                        {
                          label: "Delete",
                          onClick: () => deleteGlobal(rowData.id),
                        },
                      ],
                    });
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className="empty-view" style={{ height: `${emptyHeight}` }}>
            {isCardLoading && <CardLoader />}
            {dataNull && (
              <EmptyView message="You do not have any global settings at the moment." />
            )}
            {isCardError && (
              <ErrorView message={message} handleRetry={refresh} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalTable;
