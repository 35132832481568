import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getErrorMessage, getStores } from "../../../helper";
import { Button } from "../../ui";
import Select from "../../ui/select";
import CardLoader from "../../ui/cardLoader";

interface ModalProps {
  setPopup: any;
  getCategories?: Function;
  data?: any;
  fetchData?: any;
  isEdit?: boolean;
  selectedId?: string;
  categoryId?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AssignStoreBranchPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const {
    setPopup,
    fetchData,
    getCategories,
    data,
    isEdit,
    categoryId,
    selectedId,
  } = props;
  const [stores, setStores] = useState([]);
  const [isStoresLoading, setIsStoresLoading] = useState(true);

  const [imageStatus, setImageStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [storeBranchList, setStoreBranchList] = useState<any>(
    data?.map((item: any) => ({ label: item?.name, value: item?.id })) || []
  );

  useEffect(() => {
    getStores(setStores, setIsStoresLoading);
  }, []);

  const assignStoreBranch = () => {
    setStatus(LOADING);
    setMessage("");
    if (storeBranchList.length < 1) {
      toast.error("Select a store");
      setStatus(IDLE);
    } else {
      const result = storeBranchList.map((item: any) => item.value);
      const payload = { storeBranches: result };
      API({
        method: apiQueryMethods?.PATCH,
        url: apiUrls?.getAUser(selectedId),
        data: payload,
      })
        .then((resp) => {
          setStatus(SUCCESS);
          toast.success("Successful");
          setPopup(false);
          fetchData();
        })
        .catch((err) => {
          setMessage(getErrorMessage(err.response.data.message));
          toast.error(
            getErrorMessage(getErrorMessage(err.response.data.message))
          );
          setStatus(ERROR);
        });
    }
  };

  const refresh = () => {
    window.location.reload();
  };

  const defaultOption = (options: any, id: any) =>
    options.find((option: any) => option.value === id);

  const handleRemoveDetail = (item: any) => {
    // Remove the selection from the list
    setStoreBranchList((prevArray: any) =>
      prevArray.filter((detail: any) => detail.value !== item?.value)
    );
  };

  const handleAddStore = (selection: any) => {
    // Check if the selection is already in the selectionList
    const isSelectionInList = storeBranchList?.some(
      (item: any) => item.value === selection.value
    );

    if (!isSelectionInList) {
      // If the selection is not in the list, add it
      setStoreBranchList((prevArray: any) => [...prevArray, selection]);
      reset();
    } else {
      // If the selection is already in the list, you can handle it here (e.g., show a message)
      toast.error("Item is already in the selection");
    }
  };

  console.log(storeBranchList, "list");

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={"Assign Store Branch"}
            subTitle={"Enter the following details to assign a store branch"}
          />
          <form
            className="add-form"
            style={{ marginBottom: "24px" }}
            // onSubmit={handleSubmit(onSubmit)}
          >
            {storeBranchList?.length > 0 && (
              <div
                className="form-heading"
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "24px",
                  fontSize: "14px",
                }}
              >
                <div style={{ width: "60%" }}>Store Branch Name</div>
                <div style={{ width: "40%" }}>Action</div>
              </div>
            )}
            {storeBranchList?.map((item: any, index: any) => (
              <div
                className="product-details"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <div className="product-name" style={{ width: "60%" }}>
                  {item?.label}
                </div>

                <div className="delete-product" style={{ width: "40%" }}>
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => handleRemoveDetail(item)}
                  />
                </div>
              </div>
            ))}
          </form>
          <div style={{ marginBottom: "24px" }}>
            <Controller
              name="storeBranch"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={stores}
                  label="Select Branch"
                  onChange={(val: any) => handleAddStore(val)}
                  disabled={isStoresLoading}
                />
              )}
            />
          </div>
          <Button
            className="add-button"
            text={"Assign"}
            isLoading={status === "LOADING" ? true : false}
            disabled={storeBranchList?.length < 1 ? true : false}
            onClick={assignStoreBranch}
          />
        </div>
      </div>
      {isStoresLoading && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AssignStoreBranchPopup;
