import { useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import Alert from "../../../ui/alert";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddMultipleProductImagePopup = (props: {
  id?: string;
  setPopup?: any;
  storeBranchId?: string;
}) => {
  const { setPopup } = props;
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(IDLE);

  const refresh = () => {
    window.location.reload();
  };

  const [imageURL, setImageURL] = useState();

  const uploadImage = async (files: any) => {
    setStatus(LOADING);
    setMessage("");

    const image = files;
    var formData = new FormData();
    if (image && image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        const file = image[i];
        formData.append("files", file); // Append the file and its filename (file.name) to the FormData
      }
    }
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploaMultipleProductFile,
      data: formData,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data?.message);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const setFile = (file: any) => {
    setImageURL(file);
  };
  const upload = (file: any) => {
    if (imageURL !== undefined) {
      uploadImage(file);
    }
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Add Product Image"
            subTitle="Enter the following details to add a Product"
          />
          <form className="add-form">
            <div className="avatar-container">
              <div className="avatar">
                <img
                  src={
                    imageURL
                      ? (window.URL ? URL : webkitURL).createObjectURL(
                          imageURL[0]
                        )
                      : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                  }
                  alt=""
                />
              </div>
              <FileInput
                labelText="Tap to upload the product Images"
                onChange={setFile}
                multiple
              />
            </div>
            <Button
              className="add-button"
              text={"Add Product Images"}
              isLoading={status === "LOADING" ? true : false}
              onClick={() => upload(imageURL)}
              disabled={
                status === "LOADING" || imageURL === undefined ? true : false
              }
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Product Images"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddMultipleProductImagePopup;
