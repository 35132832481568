import { useEffect, useState } from "react";
import AppRoutes from "./AppRoutes";
import {
  filterContext,
  userRoleContext,
  testToggleContext,
} from "./helper/Context";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContext } from "./AppContext";
import {
  getBusinessDetails,
  getToken,
  getUser,
  storeBusinessDetails,
  storeToken,
  storeUser,
} from "./storage";
import ScrollToTop from "./ScrollToTop";
import { getMe } from "./helper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./firebase";
import { Notifications } from "react-push-notification";

function App() {
  const [userRole, setUserRole] = useState({
    role: "",
  });
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [user, setUser] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  }>(getUser());

  // useEffect(() => {
  //   getMe(setUserRole, setIsUserLoading);
  // }, []);

  const [businessDetails, setBusinessDetails] = useState<{
    livePublicKey: string;
    liveSecretKey: string;
    testPublicKey: string;
    testSecretKey: string;
  }>(getBusinessDetails());

  const [token, setToken] = useState(getToken());

  const updateUser = (data: any) => {
    storeUser(data);
    setUser(data);
  };

  const updateBusinessDetails = (data: any) => {
    storeBusinessDetails(data);
    setBusinessDetails(data);
  };

  const updateToken = (token: any) => {
    storeToken(token);
    setToken(token);
  };

  const [overviewDropFilter, setOverviewDropFilter] = useState("");
  const [dropFilter, setDropFilter] = useState("");
  const [walletFilter, setWalletFilter] = useState("");

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js", {
        scope: "/firebase-cloud-messaging-push-scope",
      })
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }

  return (
    <>
      <Notifications />
      <AppContext.Provider
        value={{
          user,
          token,
          businessDetails,
          updateUser,
          updateToken,
          updateBusinessDetails,
        }}
      >
        <filterContext.Provider
          value={{
            dropFilter,
            setDropFilter,
            overviewDropFilter,
            setOverviewDropFilter,
            walletFilter,
            setWalletFilter,
          }}
        >
          <userRoleContext.Provider value={{ userRole, setUserRole }}>
            <div className="App">
              <Router>
                <ScrollToTop />
                <AppRoutes />
              </Router>
            </div>
          </userRoleContext.Provider>
          <ToastContainer limit={2} />
        </filterContext.Provider>
      </AppContext.Provider>
    </>
  );
}

export default App;
