import Vendors from "../components/dashboard/vendors";
import Layout from "../components/ui/layout";

const VendorsPage = () => {
  return (
    <Layout>
      <Vendors />
    </Layout>
  );
};

export default VendorsPage;
