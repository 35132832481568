import MaterialTable from "material-table";
import { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
// import { searchContext } from "../../../../helper/Context";
import CardLoader from "../../cardLoader";
import EmptyView from "../../emptyView";
import ErrorView from "../../ErrorView";
import { handlePrice } from "../../../../helper";

interface TableProps {
  arrange?: any;
  data: Array<object>;
  clickable?: boolean;
  paging?: boolean;
  emptyHeight: string;
  search?: string;
  isCardLoading?: boolean;
  message?: string;
  dataNull?: boolean;
  isCardError?: boolean;
  deleteProduct: Function;
  handleEditModal: Function;
  storeAdmin?: boolean;
  bestSeller?: boolean;
}

const ProductsTable = (props: TableProps) => {
  const {
    arrange,
    data,
    paging,
    emptyHeight,
    clickable,
    search,
    isCardLoading,
    message,
    isCardError,
    dataNull,
    deleteProduct,
    handleEditModal,
    storeAdmin,
    bestSeller,
  } = props;
  const Navigate = useNavigate();
  // const { searchPage } = useContext(searchContext);

  const refresh = () => {
    window.location.reload();
  };

  const shortWord = (word: any) => {
    if (word.length > 20) {
      word = word.substring(0, 20) + "...";
    }
    return word;
  };

  const productShort = (orderId: any) => {
    if (orderId.length > 5) {
      orderId = orderId.substring(0, 8) + "...";
    }
    return orderId;
  };

  const handleImage = (image: any) => {
    if (image === null) {
      image =
        "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg";
    } else {
      image = image;
    }
    return image;
  };

  // const handleMarkedPrice = (price: any) => {
  //   const markedPrice = parseInt(price) + parseInt(price) * 0.05;
  //   return markedPrice.toFixed(2);
  // };

  function capitalizeEachWord(inputString: any) {
    if (typeof inputString !== "string") {
      return ""; // Return an empty string for non-string inputs
    }

    // Split the input string into an array of words
    const words = inputString.trim().split(/\s+/);

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return "";
    });

    // Join the capitalized words back to form the final string
    return capitalizedWords.join(" ");
  }

  const tableColumnData = [
    {
      title: "",
      field: "image",
      render: (rowData: any) => (
        <div
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img
            style={{ width: "100%" }}
            src={handleImage(rowData.picture)}
            alt="img"
          />
        </div>
      ),
    },
    {
      title: "Product Id",
      field: "productId",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {productShort(rowData.id)}
          </p>
        </div>
      ),
    },
    {
      title: "Name",
      field: "name",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {capitalizeEachWord(rowData.name)}
          </p>
        </div>
      ),
    },
    {
      title: bestSeller ? "Score" : "Store",
      field: "store",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {bestSeller
              ? rowData.bestSellingScore
              : capitalizeEachWord(shortWord(rowData.store))}
          </p>
        </div>
      ),
    },
    {
      title: "Category",
      field: "category",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {capitalizeEachWord(shortWord(rowData.store))}
          </p>
        </div>
      ),
    },
    {
      title: "Price",
      field: "price",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {storeAdmin
              ? handlePrice(rowData.price)
              : handlePrice(rowData.dropperPrice)}
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="orders-table-section">
      <div className="orders-table">
        {data.length >= 1 ? (
          <div className="">
            <MaterialTable
              title=""
              options={{
                filtering: false,
                paging: paging,
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  color: "#FFF",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                },
                // searchText: `${searchPage}`,
              }}
              onRowClick={(event, rowData: any) => {
                clickable &&
                  Navigate(
                    `${bestSeller ? `/products/${rowData.id}` : rowData.id}`
                  );
              }}
              columns={tableColumnData}
              data={data}
              actions={
                bestSeller
                  ? [
                      {
                        icon: () => (
                          <div
                            className="suspend"
                            style={{
                              color: "#1A1B1A",
                              padding: "4px",
                              background: "#11233710",
                              fontSize: "10px",
                              borderRadius: "4px",
                              border: "1px solid #11233730",
                            }}
                          >
                            Update
                          </div>
                        ),
                        tooltip: "Update Product",
                        onClick: (event, rowData) => {
                          handleEditModal(rowData);
                        },
                      },
                    ]
                  : [
                      {
                        icon: () => (
                          <div
                            className="suspend"
                            style={{
                              color: "#1A1B1A",
                              padding: "4px",
                              background: "#11233710",
                              fontSize: "10px",
                              borderRadius: "4px",
                              border: "1px solid #11233730",
                            }}
                          >
                            Update
                          </div>
                        ),
                        tooltip: "Update Product",
                        onClick: (event, rowData) => {
                          handleEditModal(rowData);
                        },
                      },
                      {
                        icon: () => (
                          <div
                            className="suspend"
                            style={{
                              color: "#EB5757",
                              padding: "4px",
                              background: "#FEEEEF",
                              fontSize: "10px",
                              borderRadius: "4px",
                              border: "1px solid #EE273730",
                            }}
                          >
                            Delete
                          </div>
                        ),
                        tooltip: "delete User",
                        onClick: (event, rowData) => {
                          confirmAlert({
                            title:
                              "Are you sure you want to delete this product?",
                            message:
                              "This product’s data will be erased and you will not be able to retrieve them",
                            buttons: [
                              {
                                label: "Cancel",
                                // Do Close operation
                              },
                              {
                                label: "Delete",
                                onClick: () => deleteProduct(rowData.id),
                              },
                            ],
                          });
                        },
                      },
                    ]
              }
            />
          </div>
        ) : (
          <div className="empty-view" style={{ height: `${emptyHeight}` }}>
            {isCardLoading && <CardLoader />}
            {dataNull && (
              <EmptyView message="You do not have any product at the moment." />
            )}
            {isCardError && (
              <ErrorView message={message} handleRetry={refresh} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsTable;
