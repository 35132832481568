import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../../assets/more.svg";
import ViewIcon from "../../../../assets/view.svg";
import EditIcon from "../../../../assets/edit.svg";
import DeleteIcon from "../../../../assets/delete.svg";
import ActivateIcon from "../../../../assets/activate.svg";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Heading from "../../ui/heading";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import Paginate from "../../ui/paginate";
import Layout from "../../ui/layout";
import { handlePrice } from "../../../helper";

const PageHeading = () => {
  return (
    <Heading
      title="Blackbells Inventory"
      subTitle="See all blackbells inventory and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const BlackBellsInventory = () => {
  const [inventoryDetails, setInventoryDetails] = useState([]);
  const [popup, setPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [userRole, setUserRole] = useState("");
  const [storeBranchId, setStoreBranchId] = useState("");

  const [filterClicked, setFilterClicked] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const location = useLocation();

  const handleEditProduct = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setPopup(true);
  };

  // Get the 'page' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1; // Default to page 1 if 'page' param is not present

  const Navigate = useNavigate();

  const refresh = () => {
    window.location.reload();
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: any;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
    handlePaginationClick(result.currentPage);
  };

  const handlePaginationClick = (pageNumber: any) => {
    // Update the URL query parameter when pagination button is clicked
    queryParams.set("page", pageNumber);
    const newUrl = `?${queryParams.toString()}`;
    // Use navigate to update the URL without refreshing the page
    window.location.search = newUrl;
  };

  const getInventory = async (currentPage: any) => {
    setInventoryDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.blackBellsInventory,
      // params: {
      //   pageSize: filter.limit,
      //   page: currentPage,
      // },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setTotalItems(resp.data.data.meta.totalItems);

        const formattedList = resp.data?.data?.meal_inventory;
        setInventoryDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
      });
  };

  useEffect(() => {
    getInventory(currentPage);
  }, [filter, currentPage]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <EmptyView message="There's nothing here presently" />
          </div>
        );

      case ERROR:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      case DATAMODE:
        return (
          <div className="table-details" style={{ marginTop: "24px" }}>
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "5%", fontSize: "12px" }}>No.</p>
                <p style={{ width: "5%", fontSize: "12px" }}>Image</p>
                <p style={{ width: "25%", fontSize: "12px" }}>Meal Name</p>
                <p style={{ width: "15%", fontSize: "12px" }}>Price</p>
                <p style={{ width: "20%", fontSize: "12px" }}>Meal Category</p>
                <p style={{ width: "15%", fontSize: "12px" }}>Is Available?</p>
                <p style={{ width: "15%", fontSize: "12px" }}>Limited?</p>
                {/* <p
                  style={{
                    width: "10%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Actions
                </p> */}
              </div>
              {inventoryDetails?.map((item: any, index: any) => (
                <div key={index} style={{cursor:"pointer"}}>
                  <div className="details-table-body" style={{ width: "100%" }}>
                    <p
                      style={{ width: "5%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {index + 1}
                    </p>
                    <div
                      style={{
                        width: "5%",
                      }}
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={item?.food?.image !== null ? item?.food?.image : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"}
                          alt={item?.food?.name}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <p
                      style={{ width: "25%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.food?.name}
                    </p>
                    <p
                      style={{ width: "15%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {handlePrice(item?.price)}
                    </p>
                    <p
                      style={{ width: "20%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.meal_category?.name}
                    </p>
                    <p
                      style={{ width: "15%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.available ? "Yes" : "No"}
                    </p>
                    <p
                      style={{ width: "15%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.food?.limited ? "Yes" : "No"}
                    </p>
                    {/* <Popover
                      className="flex-1 text-right"
                      style={{ width: "10%", textAlign: "center" }}
                    >
                      <>
                        <Popover.Button
                          className="popover-button"
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                          }}
                        >
                          <img src={MoreIcon} alt="" />
                        </Popover.Button>

                        <Popover.Panel
                          className="popover-panel"
                          style={{
                            position: "absolute",
                            right: "64px",
                            zIndex: "500",
                            // padding: "14px",
                            width: "150px",
                            background: "white",
                            borderRadius: "16px",
                            boxShadow: "5px 5px #00000030",
                            border: "0.5px solid #00000030",
                          }}
                        >
                          <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            style={{ borderRadius: "16px" }}
                          >
                            <div
                              className="items-center grid gap-4 bg-white px-5 py-4"
                              style={{
                                alignItems: "center",
                                background: "white",
                                padding: "16px, 20px",
                              }}
                            >
                              <div
                                onClick={() => Navigate(`${item?.id}`)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={ViewIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">
                                  View Customer
                                </p>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    </Popover> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <PageHeading />
      </div>
      {/* <div style={{ maxWidth: "500px", width: "100%", marginTop: "24px" }}>
        <Search setValue={setSearchText} setIsClearFilter={setIsClearFilter} />
      </div> */}
      {renderBasedOnStatus()}
      {status !== LOADING && inventoryDetails?.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={currentPage}
          onResult={onResult}
        />
      )}
    </Layout>
  );
};

export default BlackBellsInventory;
