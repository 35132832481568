import { useParams } from "react-router-dom";
import VoucherCard from "./VoucherCards";


const VoucherSingle = () => {

  const { id } = useParams();

  return (
    <>
      <div className="order-single-page">
        <div className="page-header">
          <div>
            <h2>Voucher Details</h2>
          </div>
        </div>

        <div className="store-details">
          <VoucherCard id={id} />
        </div>
      </div>
    </>
  );
};

export default VoucherSingle;
