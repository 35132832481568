import MaterialTable from "material-table";
import { useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
// import { searchContext } from "../../../../helper/Context";
import CardLoader from "../../cardLoader";
import EmptyView from "../../emptyView";
import ErrorView from "../../ErrorView";
import "./OrdersTable.scss";
import { orderStatusEnum } from "../../../../data/statusEnum";
import { handlePrice } from "../../../../helper";

interface TableProps {
  arrange?: any;
  data: any;
  clickable?: boolean;
  paging?: boolean;
  emptyHeight: string;
  search?: string;
  isCardLoading?: boolean;
  message?: string;
  dataNull?: boolean;
  isCardError?: boolean;
  deleteOrder: Function;
  handleEditModal: Function;
}

const OrdersTable = (props: TableProps) => {
  const {
    arrange,
    data,
    paging,
    emptyHeight,
    clickable,
    search,
    isCardLoading,
    message,
    isCardError,
    dataNull,
    deleteOrder,
    handleEditModal,
  } = props;
  const Navigate = useNavigate();
  // const { searchPage } = useContext(searchContext);

  const refresh = () => {
    window.location.reload();
  };

  const orderShort = (orderId: any) => {
    if (orderId.length > 5) {
      orderId = orderId.substring(0, 8) + "...";
    }
    return orderId;
  };

  // const getStatus = (status: any) => {
  //   return orderStatusEnum.find((singleStatus: any) => {
  //     if (status === singleStatus.value) {
  //       return singleStatus;
  //     }
  //   });
  // };

  function capitalizeEachWord(inputString:any) {
    if (typeof inputString !== 'string') {
      return ''; // Return an empty string for non-string inputs
    }
  
    // Split the input string into an array of words
    const words = inputString.trim().split(/\s+/);
  
    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return '';
    });
  
    // Join the capitalized words back to form the final string
    return capitalizedWords.join(' ');
  }

  const tableColumnData = [
    {
      title: "No",
      field: "number",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {rowData.number}
          </p>
        </div>
      ),
    },
    {
      title: "Order Ref",
      field: "orderRef",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {rowData.orderRef}
          </p>
        </div>
      ),
    },
    {
      title: "Store",
      field: "store",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {capitalizeEachWord(rowData.store)}
          </p>
        </div>
      ),
    },
    {
      title: "Delivering Fee",
      field: "deliveryFee",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {handlePrice(rowData.deliveryFee)}
          </p>
        </div>
      ),
    },

    {
      title: "Service Fee",
      field: "serviceFee",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {handlePrice(rowData.serviceFee)}
          </p>
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",
      defaultFilter: arrange,
      render: (rowData: any) => (
        <div>
          <p
            className={`status ${rowData.status}`}
            style={{ whiteSpace: "nowrap" }}
          >
            {rowData.status}
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="orders-table-section">
      <div className="orders-table">
        {data.length >= 1 ? (
          <div className="">
            <MaterialTable
              title=""
              options={{
                filtering: false,
                paging: paging,
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  color: "#FFF",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                },
                // searchText: `${searchPage}`,
              }}
              onRowClick={(event, rowData: any) => {
                clickable && Navigate(`${rowData.id}`);
              }}
              columns={tableColumnData}
              data={data}
              // actions={[
              //   {
              //     icon: () => (
              //       <div
              //         className="suspend"
              //         style={{
              //           color: "#1A1B1A",
              //           padding: "4px",
              //           background: "#11233710",
              //           fontSize: "10px",
              //           borderRadius: "4px",
              //           border: "1px solid #11233730",
              //         }}
              //       >
              //         Update
              //       </div>
              //     ),
              //     tooltip: "Update Store",
              //     onClick: (event, rowData) => {
              //       handleEditModal(rowData);
              //     },
              //   },
              //   {
              //     icon: () => (
              //       <div
              //         className="suspend"
              //         style={{
              //           color: "#EB5757",
              //           padding: "4px",
              //           background: "#FEEEEF",
              //           fontSize: "10px",
              //           borderRadius: "4px",
              //           border: "1px solid #EE273730",
              //         }}
              //       >
              //         Delete
              //       </div>
              //     ),
              //     tooltip: "delete Order",
              //     onClick: (event, rowData) => {
              //       confirmAlert({
              //         title: "Are you sure you want to delete this order?",
              //         message:
              //           "This order will be erased and you will not be able to retrieve them",
              //         buttons: [
              //           {
              //             label: "Cancel",
              //             // Do Close operation
              //           },
              //           {
              //             label: "Delete",
              //             onClick: () => deleteOrder(rowData.id),
              //           },
              //         ],
              //       });
              //     },
              //   },
              // ]}
            />
          </div>
        ) : (
          <div className="empty-view" style={{ height: `${emptyHeight}` }}>
            {isCardLoading && <CardLoader />}
            {dataNull && (
              <EmptyView message="You do not have any order at the moment." />
            )}
            {isCardError && (
              <ErrorView message={message} handleRetry={refresh} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OrdersTable;
