import Profile from "../assets/profile.svg";
import Padlock from "../assets/padlock.svg";

export const storeNavbarDetails = [
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Overview",
  //   path: "/",
  // },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Orders",
    path: "/orders",
  },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Products",
  //   path: "/products/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Categories",
  //   path: "/category/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Stores",
  //   path: "/stores/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Store Branches",
  //   path: "/store-branches/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Customers",
  //   path: "/customers/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Vendors",
  //   path: "/vendors/",
  // },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Riders",
  //   path: "/riders/",
  // },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Settings",
    path: "/settings",
  },
];

export const navbarDetails = [
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Overview",
    path: "/",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Orders",
    path: "/orders",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Products",
    path: "/products",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Categories",
    path: "/category",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Stores",
    path: "/stores",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Store Branches",
    path: "/store-branches",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Restaurants",
    path: "/restaurants",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Users",
    path: "/users",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Analytics",
    path: "/analytics",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Wallet",
    path: "/wallet",
  },
  // {
  //   iconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
  //   activeIconSRC:
  //     "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
  //   handle: "Vendors",
  //   path: "/vendors/",
  // },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Vehicles",
    path: "/vehicle",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Blackbells",
    path: "/blackbells",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Settings",
    path: "/settings",
  },
];

export const gsaNavbarDetails = [
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Overview",
    path: "/gsa/overview",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Products",
    path: "/gsa/products",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Packages",
    path: "/gsa/packages",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Package Orders",
    path: "/gsa/package-orders",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Customers",
    path: "/gsa/customers",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Analytics",
    path: "/gsa/analytics",
  },
  {
    iconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview_lnq58n.png",
    activeIconSRC:
      "https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png",
    handle: "Settings",
    path: "/gsa/fee-settings",
  },
];

export const settingsNavigationDetails = [
  {
    title: "Profile",
    navs: [
      {
        handle: "Personal Information",
        path: "/profile",
        icon: Profile,
      },
    ],
  },
  // {
  //   title: "Teams",
  //   navs: [
  //     {
  //       handle: "Add Teammates",
  //       path: "/add-teammate",
  //     icon: Profile},
  //   ],
  // },
  {
    title: "Security",
    navs: [
      {
        handle: "Change password",
        path: "/change-password",
        icon: Padlock,
      },
    ],
  },
  {
    title: "Pricing",
    navs: [
      {
        handle: "Global Settings",
        path: "/global",
        icon: Profile,
      },
      {
        handle: "Fee Settings",
        path: "/fee",
        icon: Profile,
      },
    ],
  },
  {
    title: "Banner",
    navs: [
      {
        handle: "Banner Settings",
        path: "/banner",
        icon: Profile,
      },
    ],
  },
  {
    title: "Vouchers",
    navs: [
      {
        handle: "Vouchers",
        path: "/vouchers",
        icon: Profile,
      },
    ],
  },
  {
    title: "Promo",
    navs: [
      {
        handle: "Promo",
        path: "/promo",
        icon: Profile,
      },
    ],
  },
  // {
  //   title: "Activity",
  //   navs: [
  //     {
  //       handle: "Log History",
  //       path: "/history",
  //     icon: Profile},
  //     {
  //       handle: "Notifications",
  //       path: "/notifications",
  //     icon: Profile},
  //   ],
  // },
];
export const storeSettingsNavigationDetails = [
  {
    title: "Profile",
    navs: [
      {
        handle: "Personal Information",
        path: "/profile",
        icon: Profile,
      },
    ],
  },
  {
    title: "Security",
    navs: [
      {
        handle: "Change password",
        path: "/change-password",
        icon: Padlock,
      },
    ],
  },
  // {
  //   title: "Vouchers",
  //   navs: [
  //     {
  //       handle: "Vouchers",
  //       path: "/vouchers",
  //     icon: Profile},
  //   ],
  // },
];
export const gsaSettingdNavigationDetails = [
  {
    title: "Pricing",
    navs: [
      {
        handle: "Service Charge",
        path: "/gsa/fee-settings",
        icon: Profile,
      },
      {
        handle: "Delivery Location",
        path: "/gsa/location-settings",
        icon: Profile,
      },
    ],
  },
  // {
  //   title: "Vouchers",
  //   navs: [
  //     {
  //       handle: "Vouchers",
  //       path: "/vouchers",
  //     icon: Profile},
  //   ],
  // },
];

export const option = [
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "Dropper",
    value: "DROPPER",
  },
  {
    label: "Super Packer",
    value: "STORE_ADMIN",
  },
  {
    label: "Super Admin",
    value: "SUPER_ADMIN",
  },
];

export const overviewCardDetails = [
  {
    icon: "",
    description: "Total Order",
    details: "15000 orders",
    className: "fa-solid fa-people-group",
    path: "/orders",
  },
  {
    icon: "",
    description: "Total Customer",
    details: "6000 customers",
    className: "fa-solid fa-people-group",
    path: "/customers",
  },
  {
    icon: "",
    description: "Total Store",
    details: "25 stores",
    className: "fa-sharp fa-solid fa-landmark",
    path: "/stores",
  },
];

export const ordersTableData = [
  {
    number: "1",
    id: "dkfjkjdjj3890i0323",
    store: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    reciepient: "Abiodun Jayb",
    status: "Delivered",
    contact: "08138900164",
  },
  {
    number: "2",
    id: "dkfjkjdjj3890i0323",
    store: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    reciepient: "Abiodun Jayb",
    status: "Picking_up",
    contact: "08138900164",
  },
  {
    number: "3",
    id: "dkfjkjdjj3890i0323",
    store: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    reciepient: "Abiodun Jayb",
    status: "Delivered",
    contact: "08138900164",
  },
];

export const shopsTableData = [
  {
    number: "1",
    id: "dkfjkjdjj3890i0323",
    name: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    email: "ileshashoprite2@gmail.com",
    rep: "Ilesha Shoprite",
    status: "Open",
    contact: "070000000000",
  },
  {
    number: "2",
    id: "dkfjkjdjj3890i0323",
    name: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    email: "ileshashoprite2@gmail.com",
    rep: "Ilesha Shoprite",
    status: "Closed",
    contact: "070000000000",
  },
  {
    number: "3",
    id: "dkfjkjdjj3890i0323",
    name: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    email: "ileshashoprite2@gmail.com",
    rep: "Ilesha Shoprite",
    status: "Open",
    contact: "070000000000",
  },
  {
    number: "4",
    id: "dkfjkjdjj3890i0323",
    name: "Shoprite",
    address: "No 7 Ajagunmolu Street, Off Ofatedo high",
    email: "ileshashoprite2@gmail.com",
    rep: "Ilesha Shoprite",
    status: "Open",
    contact: "070000000000",
  },
];

export const customerTableData = [
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    image:
      "https://res.cloudinary.com/dsx94d7pa/image/upload/v1665670086/Dropper%20System/pocket_klrgzg.png",
    name: "Abiodun Jayb",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    email: "hello@pocket.app",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
];

export const ridersTableData = [
  {
    name: "Jayeoba Joseph Busayo",
    email: "hello@pocket.app",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    status: "Active",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
  {
    name: "Jayeoba Joseph Busayo",
    email: "hello@pocket.app",
    address: "256, Corporation drive, maitama, Gwarimpa Estate,Abuja",
    status: "Disabled",
    rep: "Adaeze Deborah Osaji",
    contact: "0806-234-5673",
  },
];

export const barData = [
  {
    name: "Jan",
    pv: 2400,
  },
  {
    name: "Feb",
    pv: 1398,
  },
  {
    name: "Mar",
    pv: 9800,
  },
  {
    name: "Apr",
    pv: 3908,
  },
  {
    name: "May",
    pv: 4800,
  },
  {
    name: "Jun",
    pv: 3800,
  },
  {
    name: "Jul",
    pv: 4300,
  },
  {
    name: "Aug",
    pv: 4300,
  },
  {
    name: "Sep",
    pv: 7500,
  },
  {
    name: "Oct",
    pv: 4300,
  },
  {
    name: "Nov",
    pv: 4300,
  },
  {
    name: "Dec",
    pv: 4300,
  },
];

export const walletData = [
  {
    action: "Credit",
    amount: "NGN 3,000.00",
    name: "Topship",
    ref: "123439022038476283",
    status: "Successful",
    date: "12/06/2022",
  },
  {
    action: "Credit",
    amount: "NGN 3,000.00",
    name: "Topship",
    ref: "123439022038476283",
    status: "Successful",
    date: "12/06/2022",
  },
  {
    action: "Credit",
    amount: "NGN 3,000.00",
    name: "Topship",
    ref: "123439022038476283",
    status: "Successful",
    date: "12/06/2022",
  },
  {
    action: "Credit",
    amount: "NGN 3,000.00",
    name: "Topship",
    ref: "123439022038476283",
    status: "Successful",
    date: "12/06/2022",
  },
  {
    action: "Credit",
    amount: "NGN 3,000.00",
    name: "Topship",
    ref: "123439022038476283",
    status: "Successful",
    date: "12/06/2022",
  },
];
