import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import "./OrderInformation.scss";
import "./ProductInformation.scss";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import OrderProductsTable from "../../../ui/table/orderProductTable/OrderProductTable";
import ProductDetailsPatch from "./ProductDetailsPatch";
import ConfirmDeletePatch from "./ConfirmDeleteModal";
import API from "../../../../utils/api/API";
import ViewProductImagePopup from "./ViewProductImagePopup";
import CaretRight from "../../../../assets/caret-right.svg";
import {
  getErrorMessage,
  handlePrice,
  numberToWordOrdinals,
} from "../../../../helper";
import UpdatingPopup from "./UpdatingPopup";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface OrderSingleProps {
  orderProductSingleData?: any;
  orderId?: any;
  orderSingleData?: any;
  packs?: any;
}

const ProductInformation = (props: OrderSingleProps) => {
  const { orderProductSingleData, orderId, orderSingleData, packs } = props;

  const [popup, setPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedPack, setSelectedPack] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [viewProductPopup, setviewProductPopup] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(packs.length + 10);
  const [updatingPopup, setUpdatingPopup] = useState(false);

  const [userRole, setUserRole] = useState("");

  const orderItems = orderProductSingleData.map((item: any) => {
    return {
      productId: item.productId,
      quantity: item.quantity,
    };
  });

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const refresh = () => {
    window.location.reload();
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setPopup(true);
  };

  const handleProductImagePopup = (data: any) => {
    setSelectedProduct(data);
    setviewProductPopup(true);
  };

  const handleDeleteModal = (data: any) => {
    setSelectedProduct(data);
    setDeletePopup(true);
  };

  const handleUpdateOrder = (item: any) => {
    setSelectedPack(item);
    setUpdatingPopup(true);
  };

  const deleteProduct = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getProduct(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        refresh();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const toggle = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(packs.length + 1);
    } else {
      setClickedIndex(index);
    }
  };
  return (
    <>
      {!orderSingleData?.isFoodOrder ? (
        <div className="table-wrapper">
          <OrderProductsTable
            data={orderProductSingleData}
            emptyHeight="500px"
            deleteProduct={deleteProduct}
            handleEditModal={handleEditModal}
            handleDeleteModal={handleDeleteModal}
            storeAdmin={userRole === "STORE_ADMIN" ? true : false}
            clickable
            handleProductImagePopup={handleProductImagePopup}
          />
        </div>
      ) : (
        <div
          className="table-details product-information"
          style={{ marginTop: "24px" }}
        >
          <div className="table-details-body">
            <div className="details-table-header product-information-header" style={{ width: "100%" }}>
              <p style={{ width: "2%" }}></p>
              <p style={{ width: "80%", fontSize: "12px" }}>Packs</p>
              <p
                style={{ width: "10%", fontSize: "12px", textAlign: "center" }}
              >
                Action
              </p>
            </div>
            {packs.map(
              (item: any, index: any) =>
                item?.restaurantCategory !== null && (
                  <div key={index}>
                    <div
                      className="details-table-body product-information-body"
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          width: "2%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          transform: `${
                            clickedIndex === index
                              ? "rotate(90deg)"
                              : "rotate(0deg)"
                          }`,
                        }}
                        onClick={() => toggle(index)}
                      >
                        <img src={CaretRight} alt="caret-right" />
                      </div>

                      <p style={{ width: "80%", fontSize: "12px" }}>
                        {numberToWordOrdinals(index + 1)} Pack
                      </p>
                      <p
                      className="menu-update-button"
                        style={{
                          width: "fit-content",
                          fontSize: "12px",
                          padding: "4px 8px",
                          color: "#2EBB55",
                          borderRadius: "4px",
                          background: "#2EBB5526",
                          cursor: "pointer",
                        }}
                        onClick={() => handleUpdateOrder(item)}
                      >
                        Update Pack
                      </p>
                    </div>
                    {clickedIndex === index &&
                      (item?.packItems?.length === 0 ? (
                        <div
                          style={{
                            padding: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No Pack Item yet
                        </div>
                      ) : (
                        <div style={{ padding: "24px" }}>
                          <div className="table-details menu-items-information">
                            <div className="table-details-body">
                              <div
                                className="details-table-header"
                                style={{ width: "100%" }}
                              >
                                <p style={{ width: "50%" }}>Menu Name</p>
                                <p style={{ width: "20%" }}>Quantity</p>
                                <p style={{ width: "30%" }}>Unit Cost</p>
                                {/* <p style={{ width: "5%" }}></p> */}
                              </div>
                              {item?.packItems?.map(
                                (subItem: any, index: any) => (
                                  <div
                                    key={index}
                                    className="details-table-body"
                                    style={{ width: "100%" }}
                                  >
                                    <p style={{ width: "50%" }}>
                                      {subItem?.menuItem?.name}
                                    </p>
                                    <div style={{ width: "20%" }}>
                                      <p
                                        style={{
                                          background: "#FD6E35",
                                          padding: "4px 8px",
                                          fontWeight: "700",
                                          width: "fit-content",
                                          borderRadius: "4px",
                                          color: "white",
                                        }}
                                      >
                                        {subItem?.quantity}
                                      </p>
                                    </div>

                                    <p style={{ width: "30%" }}>
                                      {handlePrice(subItem?.unitCost)}
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="mobile-cards-container">
                            {item?.packItems?.map(
                              (subItem: any, index: any) => (
                                <div
                                  className="mobile-card-container"
                                  key={index}
                                >
                                  <p className="type2 bold">
                                    {subItem?.quantity} x{" "}
                                    {subItem?.menuItem?.name}
                                  </p>
                                  <p>{handlePrice(subItem?.unitCost)}</p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                )
            )}
          </div>
        </div>
      )}
      {popup && (
        <ProductDetailsPatch
          setPopup={setPopup}
          data={selectedProduct}
          isEdit={isEdit}
          orderId={orderId}
          orderItems={orderItems}
        />
      )}
      {deletePopup && (
        <ConfirmDeletePatch
          setPopup={setDeletePopup}
          data={selectedProduct}
          orderId={orderId}
          orderItems={orderItems}
        />
      )}
      {viewProductPopup && (
        <ViewProductImagePopup
          setPopup={setviewProductPopup}
          data={selectedProduct}
          orderId={orderId}
          orderItems={orderItems}
        />
      )}
      {updatingPopup && (
        <UpdatingPopup
          setPopup={setUpdatingPopup}
          // data={orderSingleData}
          orderProductSingleData={orderProductSingleData}
          orderId={orderSingleData.id}
          amountPaid={orderSingleData.total}
          deliveryFee={orderSingleData.deliveryFee}
          serviceFee={orderSingleData.serviceFee}
          packs={packs}
          selectedPack={selectedPack}
          isFoodOrder={orderSingleData.isFoodOrder}
        />
      )}
    </>
  );
};

export default ProductInformation;
