import RiderSingle from '../components/dashboard/riderSingle'
import Layout from '../components/ui/layout'

const RiderSinglePage = () => {
  return (
    <Layout>
        <RiderSingle />
    </Layout>
  )
}

export default RiderSinglePage