import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import CardLoader from "../../../ui/cardLoader";
import EmptyView from "../../../ui/emptyView";
import ErrorView from "../../../ui/ErrorView";
import "./CategoryCard.scss";
import API from "../../../../utils/api/API";
import { confirmAlert } from "react-confirm-alert";
import AddCategoryPopup from "../addCategoryPopup/AddCategoryPopup";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

interface ModalProps {
  storeBranchId: any;
}

const CategoryCard = (props: ModalProps) => {
  const { storeBranchId } = props;
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [subStatus, setSubStatus] = useState(IDLE);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryDetails, setCategoryDetails] = useState([
    {
      id: "",
      name: "",
      details: "",
    },
  ]);

  const id = useParams()

  const Navigate = useNavigate();
  const handleClick = (id: any) => {
    Navigate(`/category/${id}`);
  };

  const refresh = () => {
    window.location.reload();
  };

  const filter = {
    "filter.storeBranchId": storeBranchId,
    limit: 100000,
    page: 1,
  };

  const getCategory = async () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.category,
      params: filter,
    })
      .then((resp) => {
        const formattedList = resp.data?.data.categories.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              name: data.name,
              details: data.description,
            };
          }
        );
        setCategoryDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setPopup(true);
  };

  const deleteCategory = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getCategory(id),
    })
      .then((resp) => {
        refresh()
        // setStatus(SUCCESS);
        // getSubCategories();
      })
      .catch((err) => {
        // setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const handleDeleteCategory = (id: any) => {
    confirmAlert({
      title: "Are you sure you want to delete this sub category?",
      message:
        "This sub category’s data will be erased and you will not be able to retrieve them",
      buttons: [
        {
          label: "Cancel",
          // Do Close operation
        },
        {
          label: "Delete",
          onClick: () => deleteCategory(id),
        },
      ],
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no category under this store presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="cat-cards">
            {categoryDetails?.map((data, index) => {
              const { name, id, details } = data;
              return (
                <>
                  <div
                    className="branch-card"
                    style={{ position: "relative" }}
                    // onClick={() => handleClick(id)}
                    key={index}
                  >
                    <div className="head">
                      <img
                        src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png"
                        alt="store-branch"
                      />
                    </div>
                    <h3>{name}</h3>
                    <p className="type2">{details}</p>
                    <div
                      className="action-btn"
                      style={{
                        display: "flex",
                        gap: "24px",
                        alignItems: "center",
                        position: "absolute",
                        top: "24px",
                        right: "24px",
                      }}
                    >
                      <img
                        src="https://res.cloudinary.com/dm19qay3n/image/upload/v1691502943/edit_zbcdba.svg"
                        alt="View"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClick(id)}
                      />
                      <img
                        src="https://res.cloudinary.com/dm19qay3n/image/upload/v1695314935/internal-dashboard/edit_rjgkyr.svg"
                        alt="Edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditModal(data)}
                      />
                      <img
                        src="https://res.cloudinary.com/dm19qay3n/image/upload/v1691502943/delete_obm6px.svg"
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteCategory(data.id)}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return <>{renderBasedOnStatus()}
  {popup && (
    <AddCategoryPopup
      storeBranchId={id}
      setPopup={setPopup}
      isEdit={isEdit}
      data={selectedCategory}
      // getSubCategory={getSubCategories}
    />
  )}</>;
};

export default CategoryCard;
