import { useEffect, useState } from "react";
import AddFeePopup from "./addFeePopup/AddFeePopup";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../../utils/api";
import { dataQueryStatus } from "../../../../../../utils/dataQueryStatus";
import { Button } from "../../../../../ui";
import { confirmAlert } from "react-confirm-alert";
import { Popover } from "@headlessui/react";
import CardLoader from "../../../../../ui/cardLoader";
import EmptyView from "../../../../../ui/emptyView";
import ErrorView from "../../../../../ui/ErrorView";
import MoreIcon from "../../../../../../assets/more.svg";
import ViewIcon from "../../../../../../assets/view.svg";
import EditIcon from "../../../../../../assets/edit.svg";
import DeleteIcon from "../../../../../../assets/delete.svg";
import Paginate from "../../../../../ui/paginate";
import { getErrorMessage, handlePrice } from "../../../../../../helper";
import { toast } from "react-toastify";
import Heading from "../../../../../ui/heading";

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Fee Settings"
      subTitle="See all your fee settings and their details here"
    />
  );
};


const FeeSettings = () => {
  const [feeDetails, setFeeDetails] = useState([]);
  const [popup, setPopup] = useState(false);

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedFee, setSelectedFee] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const location = useLocation();

  // Get the 'page' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1; // Default to page 1 if 'page' param is not present

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleEditFee = (data: any) => {
    setIsEdit(true);
    setSelectedFee(data);
    setPopup(true);
  };

  const getServiceCharge = async () => {
    setFeeDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.gsaServiceCharges,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const formattedList = resp.data?.content?.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              name: data.name,
              feeInMinor: data.feeInMinor,
              percentValue: data.percentValue,
              type: data.type,
            };
          }
        );
        setFeeDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  const deleteCharge = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getGsaServiceCharges(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data?.message);
        getServiceCharge();
      })
      .catch((err) => {
        setStatus(ERROR);
        toast.error(err.response?.data?.message);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: any;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
    handlePaginationClick(result.currentPage);
  };

  const handlePaginationClick = (pageNumber: any) => {
    // Update the URL query parameter when pagination button is clicked
    queryParams.set("page", pageNumber);
    const newUrl = `?${queryParams.toString()}`;
    // Use navigate to update the URL without refreshing the page
    window.location.search = newUrl;
  };

  useEffect(() => {
    getServiceCharge();
  }, [filter, currentPage]);

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <EmptyView message="There's nothing here presently" />
          </div>
        );

      case ERROR:
        return (
          <div
            className="cards loading"
            style={{ border: "0.5px solid #00000030", borderRadius: "12px" }}
          >
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      case DATAMODE:
        return (
          <div className="table-details" style={{ marginTop: "24px" }}>
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "20%", fontSize: "12px" }}>Id</p>
                <p style={{ width: "30%", fontSize: "12px" }}>Name</p>
                <p style={{ width: "20%", fontSize: "12px" }}>Fee</p>
                <p
                  style={{
                    width: "10%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  Actions
                </p>
              </div>
              {feeDetails?.map((item: any, index: any) => (
                <div key={index} style={{ cursor: "pointer" }}>
                  <div className="details-table-body" style={{ width: "100%" }}>
                    <p
                      style={{ width: "20%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.id?.slice(0, 7) + "..."}
                    </p>
                    <p
                      style={{ width: "30%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {item?.name}
                    </p>
                    <p
                      style={{ width: "20%", fontSize: "12px" }}
                      // onClick={() => Navigate(`${item?.id}`)}
                    >
                      {handlePrice(item?.feeInMinor)}
                    </p>
                    <Popover
                      className="flex-1 text-right"
                      style={{ width: "10%", textAlign: "center" }}
                    >
                      <>
                        <Popover.Button
                          className="popover-button"
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                          }}
                        >
                          <img src={MoreIcon} alt="" />
                        </Popover.Button>

                        <Popover.Panel
                          className="popover-panel"
                          style={{
                            position: "absolute",
                            right: "64px",
                            zIndex: "500",
                            // padding: "14px",
                            width: "150px",
                            background: "white",
                            borderRadius: "16px",
                            boxShadow: "5px 5px #00000030",
                            border: "0.5px solid #00000030",
                          }}
                        >
                          <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            style={{ borderRadius: "16px" }}
                          >
                            <div
                              className="items-center grid gap-4 bg-white px-5 py-4"
                              style={{
                                alignItems: "center",
                                background: "white",
                                padding: "16px, 20px",
                              }}
                            >
                              {/* <div
                                // onClick={() => Navigate(`${item?.id}`)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={ViewIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">
                                  View Charge
                                </p>
                              </div> */}
                              <div
                                onClick={() => handleEditFee(item)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={EditIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">
                                  Edit Charge
                                </p>
                              </div>
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => {
                                  confirmAlert({
                                    title:
                                      "Are you sure you want to delete this charge?",
                                    message:
                                      "This charge's data will be erased and you will not be able to retrieve them",
                                    buttons: [
                                      {
                                        label: "Cancel",
                                        // Do Close operation
                                      },
                                      {
                                        label: "Delete",
                                          onClick: () =>
                                            deleteCharge(item?.id),
                                      },
                                    ],
                                  });
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                                <p className="pl-2 text-[16px]">
                                  Delete Charge
                                </p>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    </Popover>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className=""></div>
        {(status === "DATAMODE" || status === "ERROR" || status === NULLMODE) &&
          feeDetails?.length === 0 && (
            <Button
              text="Add Service Charge"
              className="customer-add"
              onClick={() => {
                setIsEdit(false);
                setPopup(true);
              }}
            />
          )}
      </div>
      {renderBasedOnStatus()}
      {status !== LOADING && feeDetails?.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddFeePopup
          getServiceCharge={getServiceCharge}
          setPopup={setPopup}
          data={selectedFee}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default FeeSettings;
