import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import Heading from "../heading";
import Button from "../button";
import SuccessfulModal from "../modal/successfulModal/SuccessfulModal";
import Alert from "../alert";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";
import Switch from "../switch";
import OnlinePopup from "../../dashboard/storeBranchSingle/OnlinePopup";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const OnlineModalPopup = (props: { setPopup?: any }) => {
  const { setPopup } = props;
  //   const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(IDLE);
  const [storeBranches, setStoreBranches] = useState<any>([]);
  //   const [isOnline, setIsOnline] = useState<boolean>();
  const [onlinePopup, setOnlinePopup] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>({});

  console.log(selectedStore, "selected");

  console.log(storeBranches, "store");

  const closePopup = () => {
    setPopup(false);
  };

  useEffect(() => {
    let storeBranches = null;

    try {
      const storedData = localStorage.getItem("storeBranches");
      if (storedData) {
        storeBranches = JSON.parse(storedData);
      }
    } catch (error) {
      console.error("Error parsing data from localStorage:", error);
    }
    setStoreBranches(storeBranches);
  }, []);

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={closePopup}
          />
          <Heading className="popup-header" title={"Set store as online or offline?"} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginBottom: "24px",
            }}
          >
            {storeBranches.map((branch: any, index: any) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <p className="type2 bold">{branch?.name}</p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                  onClick={() => setSelectedStore(branch)}
                >
                  <p className="type2 bold" style={{ display: "block" }}>
                    Online?
                  </p>
                  <Switch
                    textOff="YES"
                    textOn="NO"
                    value={branch.isOnline}
                    // onChange={setIsOnline}
                    setPopup={setOnlinePopup}
                    bgColor="#2EBB55"
                  />
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              gap: "24px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              text="Done"
              onClick={closePopup}
              style={{ width: "100%", flex: "1" }}
              className=""
            />
          </div>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={() => setPopup(false)}
        />
      )}
      {onlinePopup && (
        <OnlinePopup
          value={selectedStore?.isOnline}
          storeBranchId={selectedStore?.id}
          setPopup={setOnlinePopup}
          setIsOnline={selectedStore?.isOnline}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default OnlineModalPopup;
