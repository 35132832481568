import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import { Button } from "../../../../ui";
import Paginate from "../../../../ui/paginate";
import GlobalTable from "../../../../ui/table/globalTable/GlobalTable";
import AddGlobalPopup from "./addGlobalPopup/AddGlobalPopup";
import { useNavigate } from "react-router-dom";
import API from "../../../../../utils/api/API";
import { getErrorMessage } from "../../../../../helper";
import Heading from "../../../../ui/heading";

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Global Settings"
      subTitle="See all your global settings and their details here"
    />
  );
};

const GlobalSettings = () => {
  const [globalDetails, setGlobalDetails] = useState([]);
  const [popup, setPopup] = useState(false);

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedGlobal, setSelectedGlobal] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedGlobal(data);
    setPopup(true);
  };

  const getGlobal = async () => {
    setGlobalDetails([])
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.global,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const formattedList = resp.data.data.map((data: any, index: any) => {
          return {
            id: data.id,
            name: data.name,
            code: data.code,
            value: data.value,
          };
        });
        setGlobalDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  const deleteGlobal = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getGlobal(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getGlobal();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getGlobal();
  }, [filter]);
  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className=""></div>
        <Button
          text="Add Global Settings"
          className="customer-add"
          onClick={() => {
            setIsEdit(false);
            setPopup(true);
          }}
        />
      </div>
      <div className="table-wrapper">
        <GlobalTable
          data={globalDetails}
          emptyHeight="300px"
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteGlobal={deleteGlobal}
          handleEditModal={handleEditModal}
        />
      </div>
      {status !== LOADING && globalDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddGlobalPopup
          getGlobal={getGlobal}
          setPopup={setPopup}
          data={selectedGlobal}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default GlobalSettings;
