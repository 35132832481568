import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  gsaSettingdNavigationDetails,
  settingsNavigationDetails,
  storeSettingsNavigationDetails,
} from "../../../data/Data";
import Heading from "../heading";
import "./SettingLayout.scss";
import Arrow from '../../../assets/arrow.svg'

interface ISettingsProps {
  isGSA?: boolean;
  children: ReactNode;
}
const PageHeading = () => {
  return (
    <Heading
      title="Settings"
      subTitle="Manage everything relating to your account here"
    />
  );
};

const SettingLayout = (props: ISettingsProps) => {
  const path = window.location.pathname;
  const { children, isGSA } = props;
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  return (
    <>
      <PageHeading />
      <div className="settings-layout">
        <div className="settings-navigation">
          {(isGSA
            ? gsaSettingdNavigationDetails
            : userRole === "ADMIN" || userRole === "SUPER_ADMIN"
            ? settingsNavigationDetails
            : storeSettingsNavigationDetails
          ).map((navs, index) => (
            <div className="setting-navigation" key={index}>
              {/* <p className="type3 bold title">{navs.title}</p> */}
              {navs.navs.map((nav, index) => (
                <div className="settings-navs" key={index}>
                  <Link
                    to={nav.path}
                    className={`type2 link ${path === nav.path && "active"}`}
                  >
                    <div className="setting-inner-div">
                      <img src={nav?.icon} alt="icon" />
                      <p>{nav.handle}</p>
                    </div>
                    <div className="settings-arrow">
                      <img src={Arrow} alt="arrow" />
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* <div className="settings-main">{children}</div> */}
      </div>
    </>
  );
};

export default SettingLayout;
