import { Controller, useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import Search from "../../../ui/search";
import API from "../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import CardLoader from "../../../ui/cardLoader";
import { toast } from "react-toastify";
import { getErrorMessage, handlePrice } from "../../../../helper";

interface ModalProps {
  setPopup: any;
  data?: any;
  orderProductSingleData?: any;
  isEdit?: boolean;
  orderId?: any;
  orderItems?: any;
  amountPaid?: any;
  deliveryFee?: any;
  serviceFee?: any;
  updatedOrderDetails?: any;
  packs?: any;
  selectedPack?: any;
  isFoodOrder?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const UpdatingPopupStepTwo = (props: ModalProps) => {
  const {
    setPopup,
    data,
    orderProductSingleData,
    isEdit,
    amountPaid,
    deliveryFee,
    serviceFee,
    updatedOrderDetails,
    orderId,
    orderItems,
    packs,
    selectedPack,
    isFoodOrder,
  } = props;
  const [searchText, setSearchText] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const [productDetails, setProductDetails] = useState([{ name: "" }]);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);
  const [message, setMessage] = useState("");
  const [newAmount, setNewAmount] = useState<any>();
  const [selectedPackAmount, setSelectedPackAmount] = useState<any>();
  const [amountDifference, setAmountDifference] = useState<any>();
  const [status, setStatus] = useState(IDLE);

  useEffect(() => {
    const productsWithTotal = updatedOrderDetails.map((item: any) => {
      const total = parseInt(item.dropperPrice) * parseInt(item.quantity);
      return { ...item, total };
    });

    const totalSum = productsWithTotal.reduce(
      (acc: any, item: any) => acc + item.total,
      0
    );
    setNewAmount(parseInt(totalSum));
  }, []);

  useEffect(() => {
    const packsWithTotal = selectedPack?.packItems?.map((item: any) => {
      const total = parseInt(item?.unitCost) * parseInt(item?.quantity);
      return { ...item, total };
    });

    const totalSum = packsWithTotal?.reduce(
      (acc: any, item: any) => acc + item.total,
      0
    );
    setSelectedPackAmount(parseInt(totalSum));
  }, []);

  useEffect(() => {
    setAmountDifference(
      isFoodOrder
        ? parseInt(selectedPackAmount + serviceFee + deliveryFee) -
            parseInt(newAmount + serviceFee + deliveryFee)
        : parseInt(amountPaid) - parseInt(newAmount + serviceFee + deliveryFee)
    );
  }, [amountDifference]);


  const filteredData = packs?.filter(
    (item: any) => item.id !== selectedPack?.id
  );
  const transformedData = filteredData?.map((item: any) => ({
    // ...item,
    packItems: item.packItems.map((packItem: any) => ({
      menuItemId: packItem.menuItem.id,
      quantity: packItem.quantity,
    })),
  }));

  const updateOrder = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    // const updatedOrderItems = updatedOrderDetails.map(
    //   (details: any, index: any) => {
    //     return {
    //       productId: details?.productId,
    //       quantity: details?.quantity,
    //     };
    //   }
    // );

    const updatedOrderItems = updatedOrderDetails
      .filter((details: any) => details.quantity !== 0) // Filter out items with quantity 0
      .map((details: any) =>
        isFoodOrder
          ? {
              menuItemId: details.productId,
              quantity: details.quantity,
            }
          : {
              productId: details.productId,
              quantity: details.quantity,
            }
      );

    const orderPatch = isFoodOrder
      ? {
          packs: [
            {
              packItems: updatedOrderItems,
            },
            ...transformedData,
          ],
        }
      : {
          orderItems: updatedOrderItems,
        };

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateOrder(orderId),
      data: orderPatch,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp?.data?.message);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup widest">
        <div
          className="popup-container trail"
          style={{ width: "100%", maxWidth: "500px" }}
        >
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading className="popup-header" title="Update Order" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              marginBottom: "24px",
            }}
          >
            <div
              className="form-heading"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>
                {isFoodOrder
                  ? "Amount Paid for the Pack with delivery fee and service charge"
                  : "Original Amount"}
              </div>
              <div style={{ fontSize: "18px" }}>
                {handlePrice(
                  isFoodOrder
                    ? selectedPackAmount + serviceFee + deliveryFee
                    : amountPaid
                )}
              </div>
            </div>
            <div
              className="form-heading"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
                gap: "32px",
              }}
            >
              <div>New Total Amount with delivery fee and service charge</div>
              <div style={{ fontSize: "18px" }}>
                {handlePrice(newAmount + deliveryFee + serviceFee)}
              </div>
            </div>
            <div
              className="form-heading"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                fontSize: "16px",
              }}
            >
              <div>Difference</div>
              <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                {amountDifference !== "" && handlePrice(amountDifference)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "24px", width: "100%" }}>
            {amountDifference > 0 && (
              <Button
                className="add-button"
                invertStyle
                text="Refund Wallet"
                onClick={updateOrder}
                style={{ width: "100%" }}
                disabled={amountDifference < 0 && true}
                isLoading={status === "LOADING" ? true : false}
              />
            )}
            {amountDifference < 0 && (
              <Button
                className="add-button"
                text="Charge Wallet"
                onClick={updateOrder}
                style={{ width: "100%" }}
                disabled={amountDifference > 0 && true}
                isLoading={status === "LOADING" ? true : false}
              />
            )}
            {amountDifference === 0 && (
              <Button
                className="add-button"
                text="Proceed"
                onClick={updateOrder}
                style={{ width: "100%" }}
                // disabled={amountDifference > 0 && true}
                isLoading={status === "LOADING" ? true : false}
              />
            )}
          </div>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {productStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default UpdatingPopupStepTwo;
