import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import "./AddCategoryPopup.scss";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { getToken } from "../../../../storage";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import {
  getErrorMessage,
  getStores,
  timeToTimestamp,
  timestampToTime,
} from "../../../../helper";
import Select from "../../../ui/select";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import Checkbox from "../../../ui/checkbox";

interface ModalProps {
  setPopup: any;
  getCategories?: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
  categoryId?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddCategoryPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();
  const { setPopup, getCategories, data, isEdit, categoryId, selectedId } =
    props;

  const watchHappyHour = watch("isHappyHour");

  const [imageURL, setImageURL] = useState();
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const [stores, setStores] = useState([]);
  const [isStoresLoading, setIsStoresLoading] = useState(true);

  const [imageStatus, setImageStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const bgColours = [
    {
      label: "Green",
      value: "#DEEDCA",
    },
    {
      label: "Orange",
      value: "#FFF3D5",
    },
    {
      label: "Red",
      value: "#FFC8B2",
    },
    {
      label: "Purple",
      value: "#F1D9FF",
    },
  ];
  const borderColours = [
    {
      label: "Green",
      value: "#739745",
    },
    {
      label: "Orange",
      value: "#E1C561",
    },
    {
      label: "Red",
      value: "#E5825C",
    },
    {
      label: "Purple",
      value: "#7435B2",
    },
  ];

  useEffect(() => {
    getStores(setStores, setIsStoresLoading);
  }, []);

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(resp.data.data.url);
        setThumbnail(resp.data.data.url);
      })
      .catch((err) => {
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
        // setMessage(err.response.message);
        setImageStatus(ERROR);
      });
  };

  const addCategory = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.picture = image;
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.addRestaurantCategory,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const updateCategory = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.picture = image;
    (request.picture === "" || request.picture === undefined) &&
      (request.picture = data.picture);
    request.backgrounColor === undefined &&
      (request.backgrounColor = data.backgrounColor);
    request.status = data.status;
    request.startTime = timeToTimestamp(request?.startTime) + "";
    request.endTime = timeToTimestamp(request?.endTime) + "";

    const payload = {
      backgrounColor: request?.backgroundColor,
      delivery_time: request?.delivery_time,
      description: request?.description,
      info: request?.info,
      name: request?.name,
      picture: request?.picture,
      pos: request?.pos,
      status: request?.status,
      happyHour: {
        endTime: request?.endTime,
        isHappyHour: request?.isHappyHour,
        happyHourDiscount: request?.happyHourDiscount,
        startTime: request?.startTime,
      },
    };

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getRestaurantCategory(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // getCategories();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    // if (imageFile?.length > 0) {
    isEdit ? updateCategory(data) : addCategory(data);
    // } else {
    // setStatus(ERROR)
    // setMessage("An image is required");
    // }
  };

  useEffect(() => {
    if (isEdit) {
      const { name, description, delivery_time, info, productImage, pos } =
        data || {};
      setValue("name", name);
      setValue("pos", pos);
      setValue("description", description);
      setValue("info", info);
      setValue("isHappyHour", data?.happyHour?.isHappyHour);
      setValue("startTime", timestampToTime(data?.happyHour?.startTime));
      setValue("endTime", timestampToTime(data?.happyHour?.endTime));
      setValue("happyHourDiscount", data?.happyHour?.happyHourDiscount);
      setValue("delivery_time", delivery_time);
      setImageFile(productImage);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  const defaultOption = (options: any, id: any) =>
    options.find((option: any) => option.value === id);

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Category" : "Add Category"}
            subTitle={
              isEdit
                ? "Enter the following details to update a category"
                : "Enter the following details to add a category"
            }
          />
          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : isEdit && data?.picture?.length > 0
                    ? data?.picture
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload store logo "
              onChange={upload}
              // isUploading={isUploading}
            />
          </div>
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Category Name"
              name="name"
              required
              placeholder="Enter Category Name"
            />
            <Input
              register={register}
              label="Description"
              name="description"
              required
              placeholder="Enter Description"
            />
            <Input
              register={register}
              label="Info"
              name="info"
              placeholder="Enter Info"
            />
            <Input
              register={register}
              label="Delivery Time"
              name="delivery_time"
              placeholder="Enter Delivery Time"
              // required
            />
            <Controller
              name="backgrounColor"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={bgColours}
                  label="Select Background Color"
                  value={
                    isEdit ? defaultOption(bgColours, data.backgrounColor) : ""
                  }
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isStoresLoading}
                />
              )}
            />
            <Controller
              name="borderColor"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={borderColours}
                  label="Select Border Color"
                  value={
                    isEdit ? defaultOption(borderColours, data.borderColor) : ""
                  }
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isStoresLoading}
                />
              )}
            />
            <Input
              register={register}
              label="Position"
              name="pos"
              valueAsNumber
              placeholder="Enter Position"
              // required
            />
            {/* <Controller
              name="color"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={bgColours}
                  value={isEdit ? defaultOption(bgColours, data.color) : ""}
                  label="Select An Accent"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            /> */}
            {isEdit && (
              <div style={{ marginBottom: "16px" }}>
                <Checkbox
                  register={register}
                  label="Happy Hour?"
                  name="isHappyHour"
                />
              </div>
            )}

            {watchHappyHour && (
              <>
                <Input
                  register={register}
                  label="Start Time"
                  type="datetime-local"
                  name="startTime"
                  required
                  placeholder="Enter Start Time"
                />
                <Input
                  register={register}
                  label="End Time"
                  type="datetime-local"
                  name="endTime"
                  required
                  placeholder="Enter End Time"
                />
                <Input
                  register={register}
                  label="Happy Hour Discount"
                  type="number"
                  valueAsNumber
                  name="happyHourDiscount"
                  required
                  placeholder="Enter Happy Hour Discounts"
                />
              </>
            )}
            <Button
              className="add-button"
              text={isEdit ? "Update Category" : "Add Category"}
              isLoading={status === "LOADING" ? true : false}
              disabled={imageStatus === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a category to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Category"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddCategoryPopup;
