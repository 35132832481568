import { createContext } from "react";

interface ContextState {
    overviewDropFilter?: string;
    setOverviewDropFilter?: any;
    dropFilter?: string;
    setDropFilter?: any;
    walletFilter?: string;
    setWalletFilter?: any;
    searchPage?: string;
    setSearchPage?: any;
    setTestToggle?:any;
    testToggle?: any;
}

export const filterContext = createContext({} as ContextState);
export const searchContext = createContext({} as ContextState);
export const testToggleContext = createContext({} as ContextState);

export const userRoleContext = createContext<any>(null);
