import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import API from "./utils/api/API";
import { apiQueryMethods, apiUrls } from "./utils/api";
import { dataQueryStatus } from "./utils/dataQueryStatus";
import { getErrorMessage } from "./helper";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const updateUser = (request) => {
  // setStatus(LOADING);
  // setMessage("");

  const userId = JSON.parse(localStorage.getItem("user")).id;

  if (userId !== "" || userId !== null) {
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getAUser(userId),
      data: { deviceToken: request },
    })
      .then((resp) => {
        // setStatus(SUCCESS);
        // setMessage(resp.data.message);
      })
      .catch((err) => {
        // setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        // setStatus(ERROR);
      });
  }
};

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      const app = initializeApp(firebaseConfig);

      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = getMessaging(app);

      getToken(messaging, {
        vapidKey:
          process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("current token:", currentToken);
            // Send the token to your server and update the UI if necessary
            updateUser(currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

requestNotificationPermission();
