import Layout from "../components/ui/layout";
import BestSellerSinglePage from "../components/dashboard/bestSellerSinglePage";

const BestSellerSingle = () => {
  return (
    <Layout>
      <BestSellerSinglePage />
    </Layout>
  );
};

export default BestSellerSingle;
