import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { getToken } from "../../../../storage";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import Select from "../../../ui/select";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

interface ModalProps {
  setPopup: any;
  categoryId?: any;
  getSubCategory?: any;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddSubCategoryPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const { setPopup, categoryId, data, isEdit, getSubCategory, selectedId } =
    props;
  const [imageURL, setImageURL] = useState();
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(`https://assets.getdropper.com/${resp.data.original}`);
        setThumbnail(`https://assets.getdropper.com/${resp.data.thumbnail}`);
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(err.response.data.error)
        setImageStatus(ERROR);
      });
  };

  const addCategory = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.subCategory(categoryId),
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(err.response.data.error)
        setStatus(ERROR);
      });
  };

  const updateCategory = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getCategory(data.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getSubCategory();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(err.response.data.error)
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateCategory(data) : addCategory(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, description } = data || {};
      setValue("name", name);
      setValue("description", description);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Sub Category" : "Add Sub Category"}
            subTitle={
              isEdit
                ? "Enter the following details to update a sub category"
                : "Enter the following details to add a sub category"
            }
          />
          {/* <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload store logo "
              onChange={upload}
              // isUploading={isUploading}
            />
          </div> */}
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Category Name"
              name="name"
              required
              placeholder="Enter Category Name"
            />
            <Input
              register={register}
              label="Description"
              name="description"
              required
              placeholder="Enter Description"
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Sub Category" : "Add Sub Category"}
              isLoading={status === "LOADING" ? true : false}
              disabled={imageStatus === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a sub category to this category."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Sub Category"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddSubCategoryPopup;
