export const orderStatusEnum = [
  { label: "Started", value: "STARTED" },
  { label: "Accepted By Store", value: "ACCEPTED_BY_STORE" },
  { label: "Rejected By Store", value: "REJECTED_BY_STORE" },
  { label: "Order Ready", value: "ORDER_READY" },
  // { label: "Assigned", value: "ASSIGNED" },
  { label: "Dropper Accepted", value: "ACCEPTED_BY_DROPPER" },
  // { label: "Picking Up", value: "PICKING_UP" },
  { label: "Dropping", value: "DROPPING" },
  { label: "Arrived", value: "ARRIVED" },
  { label: "Flagged", value: "FLAGGED" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Refunded", value: "REFUNDED" },
  { label: "Payment Received", value: "PAYMENT_RECEIVED" },
];

export const storeOrderStatusEnum = [
  // { label: "Accepted By Store", value: "ACCEPTED_BY_STORE" },
  { label: "Dropping", value: "DROPPING" },
];

export const dropperOrderStatusEnum = [
  { label: "Arrived", value: "ARRIVED" },
  { label: "Completed", value: "COMPLETED" },
];

export const productStatusEnum = [
  { label: "In Stock", value: "IN_STOCK" },
  { label: "Out of Stock", value: "OUT_OF_STOCK" },
];
