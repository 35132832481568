import { useNavigate } from "react-router-dom";
import "./WalletCard.scss";
import { useState } from "react";
import CardLoader from "../../../ui/cardLoader";
import { Button } from "../../../ui";
import { handlePrice } from "../../../../helper";

interface IOverviewProps {
  totalWallet?: any;
  totalProducts?: any;
  totalStoreBranches?: any;
  isWalletLoading?: any;
  isProductLoading?: any;
  isStoreBranchLoading?: any;
  revenue?: boolean;
  dateFilter?: any;
  setDateFilter?: any;
  getLastNDaysFormatted?: any;
  setIsClearFilter?: any;
  userRole?: any;
}

const WalletCard = (props: IOverviewProps) => {
  const {
    totalWallet,
    isWalletLoading,
    setDateFilter,
    getLastNDaysFormatted,
    setIsClearFilter,
  } = props;
  const Navigate = useNavigate();
  const [orderFilter, setOrderFilter] = useState("");

  const [filterClicked, setFilterClicked] = useState(0);
  const [dropDown, setDropDown] = useState(false);

  // const filterMap = [
  //   {
  //     name: "Today",
  //     value: "Today",
  //     dateRange: getLastNDaysFormatted(1),
  //   },
  //   {
  //     name: "This Week",
  //     value: "This Week",
  //     dateRange: getLastNDaysFormatted(8),
  //   },
  //   {
  //     name: "This Month",
  //     value: "This Month",
  //     dateRange: getLastNDaysFormatted(31),
  //   },
  //   {
  //     name: "All Time",
  //     value: "All Time",
  //     dateRange: "clearFilter",
  //   },
  // ];
  const handleClicked = (index: any, item: any, dateRange: any) => {
    if (filterClicked === index) {
      setFilterClicked(1);
      setOrderFilter(item[0]);
      if (dateRange === "clearFilter") {
        setIsClearFilter(true);
      } else {
        setIsClearFilter(false);
      }

      setDateFilter(dateRange[0]);
      setDropDown(false);
    }
    setFilterClicked(index);
    setOrderFilter(item);
    if (dateRange === "clearFilter") {
      setIsClearFilter(true);
    } else {
      setIsClearFilter(false);
    }
    setDateFilter(dateRange);
    setDropDown(false);
  };

  const handleClick = (path: any) => {
    Navigate(path);
  };

  const walletCardDetails = [
    {
      icon: "",
      description: "Total Wallet Balance",
      details: `${handlePrice(totalWallet)}`,
      className: "fa-solid fa-people-group",
      path: "/orders/",
      isLoading: isWalletLoading,
      filter: true,
    },
  ];

  return (
    <>
      <div className="wallets-cards">
        {walletCardDetails.map((detail, index) => (
          <div className="card" key={index}>
            <div className="head">
              <i className={detail.className}></i>
              {detail.filter
                ? ""
                : detail.path !== "" && (
                    <Button
                      text="More details"
                      onClick={() => handleClick(detail.path)}
                    />
                  )}

              {/* START */}

              {/* {detail.filter && (
                <div className="filter-wrapper">
                  <div
                    className="dropdown"
                    onClick={() => setDropDown(!dropDown)}
                  >
                    <h6>{orderFilter === "" ? "today" : orderFilter}</h6>
                    <img
                      className="arrow-down"
                      src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                      alt="arrow"
                    />
                  </div>
                  {dropDown && (
                    <div className="dropdown-option">
                      {filterMap.map((item, index) => (
                        <div
                          className="dropdown-list"
                          key={index}
                          onClick={() =>
                            handleClicked(index, item.value, item.dateRange)
                          }
                        >
                          <h6>{item.name}</h6>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )} */}

              {/* END */}
            </div>
            {detail.isLoading ? (
              <div
                className="details"
                style={{
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardLoader />
              </div>
            ) : (
              <div className="details">
                <p className="description">{detail.description}</p>
                <p className="detail">{detail.details}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default WalletCard;
