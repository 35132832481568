import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import EmptyView from "../../emptyView";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CardLoader from "../../cardLoader";
import ErrorView from "../../ErrorView";

interface TableProps {
  arrange?: any;
  data: Array<object>;
  clickable?: boolean;
  paging?: boolean;
  emptyHeight: string;
  isCardLoading?: boolean;
  message?: string;
  dataNull?: boolean;
  isCardError?: boolean;
  deleteVehicle: Function;
  handleEditModal: Function;
}

const VehicleTable = (props: TableProps) => {
  const {
    data,
    paging,
    emptyHeight,
    clickable,
    isCardLoading,
    arrange,
    message,
    isCardError,
    dataNull,
    deleteVehicle,
    handleEditModal,
  } = props;
  const Navigate = useNavigate();

  const refresh = () => {
    window.location.reload();
  };

  const shortId = (Id: any) => {
    if (Id.length > 5) {
      Id = Id.substring(0, 5) + "...";
    }
    return Id;
  };

  const tableColumnData = [
    {
      title: "No",
      field: "number",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {rowData.number}
          </p>
        </div>
      ),
    },
    {
      title: "Vehicle Id",
      field: "vehicleId",
      render: (rowData: any) => (
        <div>
          <p className="type4" style={{ whiteSpace: "nowrap" }}>
            {shortId(rowData.id)}
          </p>
        </div>
      ),
    },
    {
      title: "Dropper",
      field: "dropper",
      render: (rowData: any) => (
        <div>
          <p>{rowData.dropper}</p>
        </div>
      ),
    },
    {
      title: "Vehicle Plate Number",
      field: "plateNumber",
      render: (rowData: any) => (
        <div>
          <p>{rowData.plateNumber}</p>
        </div>
      ),
    },
    {
      title: "Vehicle Maker",
      field: "maker",
      render: (rowData: any) => (
        <div>
          <p>{rowData.maker}</p>
        </div>
      ),
    },
    {
      title: "Vehicle Model",
      field: "model",
      render: (rowData: any) => (
        <div>
          <p>{rowData.model}</p>
        </div>
      ),
    },
  ];

  return (
    <div className="orders-table-section">
      <div className="orders-table">
        {data.length >= 1 ? (
          <div className="">
            <MaterialTable
              title=""
              options={{
                paging: paging,
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  color: "#FFF",
                  fontWeight: "600",
                },
              }}
              onRowClick={(event, rowData: any) => {
                clickable && Navigate(`${rowData.sender}`);
              }}
              columns={tableColumnData}
              data={data}
              actions={[
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#1A1B1A",
                        padding: "4px",
                        background: "#11233710",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #11233730",
                      }}
                    >
                      Update
                    </div>
                  ),
                  tooltip: "Update Rider",
                  onClick: (event, rowData) => {
                    handleEditModal(rowData);
                  },
                },
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#EB5757",
                        padding: "4px",
                        background: "#FEEEEF",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #EE273730",
                      }}
                    >
                      Delete
                    </div>
                  ),
                  tooltip: "Delete Rider",
                  onClick: (event, rowData) => {
                    confirmAlert({
                      title: "Are you sure you want to delete this vehicle?",
                      message:
                        "This vehicle’s data will be erased and you will not be able to retrieve them",
                      buttons: [
                        {
                          label: "Cancel",
                          // Do Close operation
                        },
                        {
                          label: "Delete",
                          onClick: () => deleteVehicle(rowData.id),
                        },
                      ],
                    });
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className="empty-view" style={{ height: `${emptyHeight}` }}>
            {isCardLoading && <CardLoader />}
            {dataNull && (
              <EmptyView message="You do not have any vehicle at the moment." />
            )}
            {isCardError && (
              <ErrorView message={message} handleRetry={refresh} />
            )}
          </div>
        )}
      </div>
      {/* {actionClicked === "Pocket" && <CustomerModal  />} */}
    </div>
  );
};

export default VehicleTable;
