import Vehicle from "../components/dashboard/vehicle";
import Layout from "../components/ui/layout";

const VehiclePage = () => {
  return (
    <Layout>
      <Vehicle />
    </Layout>
  );
};

export default VehiclePage;
