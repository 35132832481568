import { useState } from "react";
import { Button } from "../../ui";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";

interface ModalProps {
  setPopup: any;
  getCategories?: Function;
  data?: any;
  selectedId?: string;
  categoryId?: any;
  isBranch?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const ActivateDeactivatePopup = (props: ModalProps) => {
  const { setPopup, data, isBranch } = props;
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const updateRestaurant = async () => {
    setStatus(LOADING);
    setMessage("");
    const request = isBranch
      ? {
          address: data?.address,
          coords: data?.coords,
          email: data?.email,
          maxDeliveryTime: data?.maxDeliveryTime,
          maxOrderAmount: data?.maxOrderAmount,
          maxOrderQty: data?.maxOrderQty,
          minDeliveryTime: data?.minDeliveryTime,
          minOrderAmount: data?.minOrderAmount,
          minOrderQty: data?.minOrderQty,
          name: data?.name,
          placeId: data?.placeId,
          // rating: data?.rating,
          state_id: data?.state_id,
          storeId: data?.storeId,
          timeSettings: data.timeSettings,
          status: data?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        }
      : {
          name: data?.name,
          logo: data?.logo,
          backgroundPicture: data?.backgroundPicture,
          restaurantCategoryId: data?.restaurantCategory?.id,
          status: data?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        };
    API({
      method: apiQueryMethods?.PATCH,
      url: isBranch ? apiUrls?.getStoreBranch(data.id) : apiUrls?.getStore(data.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={
              data.status === "ACTIVE"
                ? "Deactivate Restaurant"
                : "Activate Restaurant"
            }
            subTitle={
              data.status === "ACTIVE"
                ? "Are you sure you want to deactivate this restaurant"
                : "Are you sure you want to deactivate this restaurant"
            }
          />
          <Button
            className="add-button"
            onClick={updateRestaurant}
            text={
              data?.status === "ACTIVE"
                ? "Deactivate Restaurant"
                : "Activate Restaurant"
            }
            isLoading={status === "LOADING" ? true : false}
          />
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
    </>
  );
};

export default ActivateDeactivatePopup;
