import { useEffect, useState } from "react";
import Heading from "../../../ui/heading";
import BusinessMetricsCard from "./BusinessMetricsCard";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import API from "../../../../utils/api/API";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const PageIntro = () => {
  return (
    <Heading
      title="Business Metrics"
      subTitle="See all your business metrics here"
    />
  );
};

const BusinessMetrics = () => {
  const [isClearRevenueFilter, setIsClearRevenueFilter] = useState(false);
  const [isClearOrderFilter, setIsClearOrderFilter] = useState(false);
  const [revenueStatus, setRevenueStatus] = useState(IDLE);
  const [orderStatus, setOrderStatus] = useState(IDLE);
  const [userRole, setUserRole] = useState<string>();
  const [orderCompleted, setOrderCompleted] = useState("");
  const [totalRevenue, setTotalRevenue] = useState(0);

  const getLastNDaysFormatted = (n: any) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const endDate = currentDate.toISOString().slice(0, 10); // Today's date in "YYYY-MM-DD" format
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - n);
    const startDateFormatted = startDate.toISOString().slice(0, 10); // Start date in "YYYY-MM-DD" format

    return {
      startDate: startDateFormatted,
      endDate,
    };
  };

  const [revenueDateFilter, setRevenueDateFilter] = useState(
    getLastNDaysFormatted(1)
  );
  const [orderDateFilter, setOrderDateFilter] = useState(
    getLastNDaysFormatted(1)
  );

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const revenueParams = isClearRevenueFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: revenueDateFilter.endDate,
        startDate: revenueDateFilter.startDate,
      };

  const orderParams = isClearOrderFilter
    ? { isAll: true }
    : {
        isAll: false,
        endDate: orderDateFilter.endDate,
        startDate: orderDateFilter.startDate,
      };

  const getTotalRevenue = async () => {
    setRevenueStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.revenue,
      params: revenueParams,
    })
      .then((resp) => {
        setRevenueStatus(SUCCESS);
        setTotalRevenue(resp.data.data.revenue);
      })
      .catch((err) => {
        setRevenueStatus(ERROR);
      });
  };

  const getTotalOrderCompleted = async () => {
    setOrderStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.completedOrders,
      params: orderParams,
    })
      .then((resp) => {
        setOrderStatus(SUCCESS);
        setOrderCompleted(resp.data.data.count);
      })
      .catch((err) => {
        setOrderStatus(ERROR);
      });
  };

  useEffect(() => {
    getTotalRevenue();
  }, [revenueDateFilter]);

  useEffect(() => {
    getTotalOrderCompleted();
  }, [orderDateFilter]);

  return (
    <div style={{ marginBottom: "80px" }}>
      <PageIntro />
      <BusinessMetricsCard
        totalRevenue={totalRevenue}
        totalOrder={orderCompleted}
        isRevenueLoading={revenueStatus === LOADING ? true : false}
        isOrderLoading={orderStatus === LOADING ? true : false}
        revenueDateFilter={revenueDateFilter}
        setRevenueDateFilter={setRevenueDateFilter}
        orderDateFilter={orderDateFilter}
        setOrderDateFilter={setOrderDateFilter}
        getLastNDaysFormatted={getLastNDaysFormatted}
        setIsClearRevenueFilter={setIsClearRevenueFilter}
        setIsClearOrderFilter={setIsClearOrderFilter}
        userRole={userRole}
      />
    </div>
  );
};

export default BusinessMetrics;
