import React from 'react'
import Orders from '../components/dashboard/orders'
import Layout from '../components/ui/layout'

const OrdersPage = () => {
  return (
    <Layout>
        <Orders />
    </Layout>
  )
}

export default OrdersPage