import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input, TelephoneInput } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import PlaceAutoComplete from "../../../ui/placeAutocomplete/PlaceAutoComplete";
import CaretRight from "../../../../assets/caret-right.svg";
import {
  getErrorMessage,
  timeToTimestamp,
  timestampToTime,
} from "../../../../helper";
import Checkbox from "../../../ui/checkbox";

interface ModalProps {
  setPopup: any;
  getStores: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddRestaurantBranchPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const { setPopup, getStores, data, isEdit, selectedId } = props;

  const watchHappyHour = watch("isHappyHour");

  const [imageURL, setImageURL] = useState();
  const [image, setImage] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [timeSettings, setTimeSettings] = useState(false);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const addStoreBranch = async (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.coords = [latLng.lat, latLng.lng];
    const payload = {
      name: request?.name,
      email: request?.email,
      slackChannel: request?.slackChannel,
      description: request?.description,
      restaurantMeasurement: request?.restaurantMeasurement,
      address: request?.address,
      allowTesting: request?.allowTesting,
      costPerKm: request?.costPerKm * 100,
      maxDeliveryFee: request?.maxDeliveryFee * 100,
      serveRadius: request?.serveRadius * 1000,
      takeawayPackCost: request?.takeawayPackCost * 100,
      portionsPerPack: request?.portionsPerPack,
      preparationTime: request?.preparationTime * 60,
      coords: [latLng.lat, latLng.lng],
      timeSettings: {
        SUNDAY: {
          openingTime: request?.sundayOpeningTime,
          closingTime: request?.sundayClosingTime,
        },
        MONDAY: {
          openingTime: request?.mondayOpeningTime,
          closingTime: request?.mondayClosingTime,
        },
        TUESDAY: {
          openingTime: request?.tuesdayOpeningTime,
          closingTime: request?.tuesdayClosingTime,
        },
        WEDNESDAY: {
          openingTime: request?.wednessdayOpeningTime,
          closingTime: request?.wednessdayClosingTime,
        },
        THURSDAY: {
          openingTime: request?.thursdayOpeningTime,
          closingTime: request?.thursdayClosingTime,
        },
        FRIDAY: {
          openingTime: request?.fridayOpeningTime,
          closingTime: request?.fridayClosingTime,
        },
        SATURDAY: {
          openingTime: request?.saturdayOpeningTime,
          closingTime: request?.saturdayClosingTime,
        },
      },
    };

    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.addStoreBranch(data.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  timestampToTime(1710873200515);
  const updateStoreBranch = (request: any) => {
    setStatus(LOADING);
    setMessage("");

    request.coords = [latLng.lat, latLng.lng];
    (request.coords[0] === null ||
      request.coords[1] === null ||
      request.coords === undefined ||
      request.coords === null) &&
      (request.coords = data.coordinates);

    const payload = {
      name: request?.name,
      email: request?.email,
      slackChannel: request?.slackChannel,
      description: request?.description,
      restaurantMeasurement: request?.restaurantMeasurement,
      costPerKm: request?.costPerKm * 100,
      maxDeliveryFee: request?.maxDeliveryFee * 100,
      serveRadius: request?.serveRadius * 1000,
      takeawayPackCost: request?.takeawayPackCost * 100,
      portionsPerPack: parseFloat(request?.portionsPerPack),
      preparationTime: request?.preparationTime * 60,
      address: request?.address,
      allowTesting: request?.allowTesting,
      coords: [latLng.lat, latLng.lng],
      happyHour: {
        isHappyHour: request?.isHappyHour,
        startTime: timeToTimestamp(request?.startTime),
        endTime: timeToTimestamp(request?.endTime),
        happyHourDiscount: request?.happyHourDiscount,
      },
      timeSettings: {
        SUNDAY: {
          openingTime: request?.sundayOpeningTime,
          closingTime: request?.sundayClosingTime,
        },
        MONDAY: {
          openingTime: request?.mondayOpeningTime,
          closingTime: request?.mondayClosingTime,
        },
        TUESDAY: {
          openingTime: request?.tuesdayOpeningTime,
          closingTime: request?.tuesdayClosingTime,
        },
        WEDNESDAY: {
          openingTime: request?.wednessdayOpeningTime,
          closingTime: request?.wednessdayClosingTime,
        },
        THURSDAY: {
          openingTime: request?.thursdayOpeningTime,
          closingTime: request?.thursdayClosingTime,
        },
        FRIDAY: {
          openingTime: request?.fridayOpeningTime,
          closingTime: request?.fridayClosingTime,
        },
        SATURDAY: {
          openingTime: request?.saturdayOpeningTime,
          closingTime: request?.saturdayClosingTime,
        },
      },
    };

    payload.coords = [latLng.lat, latLng.lng];
    (payload.coords[0] === null ||
      payload.coords[1] === null ||
      payload.coords === undefined ||
      payload.coords === null) &&
      (payload.coords = data.coordinates);

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getStoreBranch(data?.id),
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(
          getErrorMessage(getErrorMessage(err.response.data.message))
        );
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    if (isEdit) {
      updateStoreBranch(data);
    } else {
      if (latLng.lat === null && latLng.lng === null) {
        setStatus(ERROR);
        setMessage("Location is required");
      } else {
        addStoreBranch(data);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      const {
        name,
        email,
        minOrderQty,
        maxOrderQty,
        minOrderAmount,
        maxOrderAmount,
        preparationTime,
        portionsPerPack,
        takeawayPackCost,
        costPerKm,
        maxDeliveryFee,
        serveRadius,
        address,
        allowTesting,
        slackChannel,
        rating,
        mapLocation,
        description,
        restaurantMeasurement,
      } = data || {};
      setValue("name", name);
      setValue("email", email);
      setValue("description", description);
      setValue("restaurantMeasurement", restaurantMeasurement);
      setValue("minOrderQty", minOrderQty);
      setValue("maxOrderQty", maxOrderQty);
      setValue("minOrderAmount", minOrderAmount);
      setValue("maxOrderAmount", maxOrderAmount);
      setValue("preparationTime", preparationTime / 60);
      setValue("takeawayPackCost", takeawayPackCost / 100);
      setValue("portionsPerPack", portionsPerPack);
      setValue("costPerKm", costPerKm / 100);
      setValue("maxDeliveryFee", maxDeliveryFee / 100);
      setValue("serveRadius", serveRadius / 1000);
      setValue("address", address);
      setValue("allowTesting", allowTesting);
      setValue("slackChannel", slackChannel);
      // setValue("rating", rating);
      setValue("mapLocation", mapLocation);
      setValue("isHappyHour", data?.happyHour?.isHappyHour);
      setValue("startTime", timestampToTime(data?.happyHour?.startTime));
      setValue("endTime", timestampToTime(data?.happyHour?.endTime));
      setValue("happyHourDiscount", data?.happyHour?.happyHourDiscount);
      setValue("sundayClosingTime", data?.timeSettings?.SUNDAY?.closingTime);
      setValue("sundayOpeningTime", data?.timeSettings?.SUNDAY?.openingTime);
      setValue("mondayClosingTime", data?.timeSettings?.MONDAY?.closingTime);
      setValue("mondayOpeningTime", data?.timeSettings?.MONDAY?.openingTime);
      setValue("tuesdayClosingTime", data?.timeSettings?.TUESDAY?.closingTime);
      setValue("tuesdayOpeningTime", data?.timeSettings?.TUESDAY?.openingTime);
      setValue(
        "wednessdayClosingTime",
        data?.timeSettings?.WEDNESDAY?.closingTime
      );
      setValue(
        "wednessdayOpeningTime",
        data?.timeSettings?.WEDNESDAY?.openingTime
      );
      setValue(
        "thursdayClosingTime",
        data?.timeSettings?.THURSDAY?.closingTime
      );
      setValue(
        "thursdayOpeningTime",
        data?.timeSettings?.THURSDAY?.openingTime
      );
      setValue("fridayClosingTime", data?.timeSettings?.FRIDAY?.closingTime);
      setValue("fridayOpeningTime", data?.timeSettings?.FRIDAY?.openingTime);
      setValue(
        "saturdayClosingTime",
        data?.timeSettings?.SATURDAY?.closingTime
      );
      setValue(
        "saturdayOpeningTime",
        data?.timeSettings?.SATURDAY?.openingTime
      );
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Restaurant" : "Add Restaurant"}
            subTitle={
              isEdit
                ? "Enter the following details to update a restaurant"
                : "Enter the following details to add a restaurant"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Branch Name"
              name="name"
              required
              placeholder="Enter Branch Name"
            />
            <Input
              register={register}
              label="Email"
              name="email"
              required
              placeholder="Enter Email"
            />
            <Input
              register={register}
              label="Branch Description"
              name="description"
              required
              placeholder="Enter Branch Description"
            />
            <Input
              register={register}
              label="Address"
              name="address"
              required
              placeholder="Enter Address"
            />
            <Input
              register={register}
              label="Preparation Time in mins"
              type="number"
              valueAsNumber
              name="preparationTime"
              required
              placeholder="Enter Preparation Time"
            />
            <Input
              register={register}
              label="Restaurant Measurement"
              type="text"
              name="restaurantMeasurement"
              placeholder="Enter Restaurant Measurement"
            />
            <Input
              register={register}
              label="Cost per Kilometer"
              type="number"
              valueAsNumber
              name="costPerKm"
              required
              placeholder="Enter Cost per Kilometer"
            />
            <Input
              register={register}
              label="portions per pack"
              type="number"
              valueAsNumber
              name="portionsPerPack"
              required
              placeholder="Enter portions per pack"
            />
            <Input
              register={register}
              label="Cost takeaway pack cost"
              type="number"
              valueAsNumber
              name="takeawayPackCost"
              required
              placeholder="Enter Cost takeaway pack cost"
            />
            <Input
              register={register}
              label="Maximum Delivery Fee"
              type="number"
              valueAsNumber
              name="maxDeliveryFee"
              placeholder="Enter Maximum Delivery Fee"
            />
            <Input
              register={register}
              label="Serve Radius in Km"
              type="number"
              valueAsNumber
              name="serveRadius"
              required
              placeholder="Enter Serve Radius"
            />
            <Input
              register={register}
              label="Slack Channel"
              type="text"
              name="slackChannel"
              // required
              placeholder="Enter Slack Channel"
            />
            {/* <Input
              register={register}
              label="Rating"
              type="number"
              valueAsNumber
              name="rating"
              required
              // step="0.001"
              placeholder="Enter Rating"
            /> */}
            <PlaceAutoComplete
              setLatLng={setLatLng}
              inputLabel="Location"
              placeholder="Enter your location"
            />
            <Checkbox
              register={register}
              label="Allow Testing?"
              name="allowTesting"
            />

            {isEdit && (
              <div style={{ marginBottom: "16px" }}>
                <Checkbox
                  register={register}
                  label="Happy Hour?"
                  name="isHappyHour"
                />
              </div>
            )}

            {watchHappyHour && (
              <>
                <Input
                  register={register}
                  label="Start Time"
                  type="datetime-local"
                  name="startTime"
                  required
                  placeholder="Enter Start Time"
                />
                <Input
                  register={register}
                  label="End Time"
                  type="datetime-local"
                  name="endTime"
                  required
                  placeholder="Enter End Time"
                />
                <Input
                  register={register}
                  label="Happy Hour Discount"
                  type="number"
                  valueAsNumber
                  name="happyHourDiscount"
                  required
                  placeholder="Enter Happy Hour Discounts"
                />
              </>
            )}

            <div className="branch-time-setting">
              <div
                className="time-setting-heading"
                onClick={() => setTimeSettings(!timeSettings)}
              >
                <img
                  src={CaretRight}
                  alt="arrow"
                  style={{
                    transform: `${
                      timeSettings ? "rotate(90deg)" : "rotate(0deg)"
                    }`,
                  }}
                />
                <p className="type2">Time Setting</p>
              </div>
              {timeSettings && (
                <div className="time-settings-body">
                  <div className="time-settings-row">
                    <p className="type3">Sunday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        label="Opening Time"
                        name="sundayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        label="Closing Time"
                        name="sundayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Monday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="mondayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="mondayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Tuesday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="tuesdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="tuesdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Wednessday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="wednessdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="wednessdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Thursday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="thursdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="thursdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Friday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="fridayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="fridayClosingTime"
                        required
                      />
                    </div>
                  </div>
                  <div className="time-settings-row">
                    <p className="type3">Saturday</p>
                    <div className="time-settings-column">
                      <Input
                        register={register}
                        type="time"
                        // label="Opening Time"
                        name="saturdayOpeningTime"
                        required
                      />
                      <Input
                        register={register}
                        type="time"
                        // label="Closing Time"
                        name="saturdayClosingTime"
                        required
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Button
              className="add-button"
              text={isEdit ? "Update Restaurant" : "Add Restaurant"}
              isLoading={status === "LOADING" ? true : false}
              disabled={imageStatus === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a restaurant to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Restaurant"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddRestaurantBranchPopup;
