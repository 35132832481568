import Settings from "../components/dashboard/settings";
import Profile from "../components/dashboard/settings/settingsBody/profile/Profile";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";

const SettingsPage = () => {
  return (
    <Layout>
      <SettingLayout>
        {/* <Profile /> */} <div></div>
      </SettingLayout>
    </Layout>
  );
};

export default SettingsPage;
