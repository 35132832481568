import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { Button, Dropdown } from "../../../ui";
// import "./OrderInformation.scss";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../ui/select";
import { orderStatusEnum } from "../../../../data/statusEnum";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import ErrorModal from "../../../ui/modal/errorModel/ErrorModal";
import { getErrorMessage, getStores, handleDate } from "../../../../helper";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import Paginate from "../../../ui/paginate";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface OrderSingleProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  email?: string;
  phone?: string;
  photo?: string;
  placeId?: string;
  referralCode?: string;
  referredBy?: string;
  defaultStoreBranchId?: string;
  storeBranchId: string;
  storeBranches: any;
  dateCreated?: string;
}

interface Store {
  label: string;
  value: string;
}

const UserInformation = (props: OrderSingleProps) => {
  const {
    id,
    defaultStoreBranchId,
    email,
    firstName,
    lastName,
    phone,
    photo,
    placeId,
    referralCode,
    referredBy,
    role,
    storeBranchId,
    storeBranches,
    dateCreated,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [stores, setStores] = useState([]);
  const [isStoresLoading, setIsStoresLoading] = useState(true);

  const [statusUpdate, setStatusUpdate] = useState(IDLE);
  const [statuss, setStatuss] = useState();
  const [message, setMessage] = useState("");
  const [userRole, setUserRole] = useState("");
  const [storeBranchesName, setStoreBranchesName] = useState("");
  const [ratingStatus, setRatingStatus] = useState(IDLE);
  const [ratingDetails, setRatingDetails] = useState<any>([]);
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    getStores(setStores, setIsStoresLoading);
  }, []);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  const ratingParams =
    role === "DROPPER"
      ? {
          limit: filter.limit,
          page: filter.page,
          "filter.driver.id": id,
        }
      : {
          limit: filter.limit,
          page: filter.page,
          "filter.user.id": id,
        };

  const getRatings = async () => {
    setRatingDetails([]);
    setRatingStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.getRatings,
      params: ratingParams,
    })
      .then((resp) => {
        setRatingStatus(SUCCESS);
        console.log(resp);
        setRatingDetails(resp.data.data.ratings);
      })
      .catch((err) => {
        setRatingStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message));
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  useEffect(() => {
    if (id) {
      getRatings();
    }
  }, [id]);

  const getStoreBranch = (store: string) => {
    return isStoresLoading ? (
      <div>loading...</div>
    ) : (
      (stores as Store[]).find(
        (singleStore: Store) => store === singleStore.value
      )?.label
    );
  };

  useEffect(() => {
    const namesArray = storeBranches?.map((item: any) => item.name);
    const namesString = namesArray?.join(", ");
    setStoreBranchesName(namesString);
  }, []);

  const assignStoreBranch = (request: any) => {
    setStatusUpdate(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getAUser(id),
      data: request,
    })
      .then((resp) => {
        setStatusUpdate(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        setStatusUpdate(ERROR);
      });
  };

  const onSubmit = (data: any) => assignStoreBranch(data);

  const ordersInformation = [
    {
      imageSRC: "",
      description: "Name",
      value: firstName + " " + lastName,
    },
    {
      description: "Email Address",
      value: email,
    },
    {
      description: "Phone Number",
      value: phone,
    },
    {
      description: "Role",
      value: role === "STORE_ADMIN" ? "SUPER_PACKER" : role,
    },
    {
      description: "Store Branch",
      value: storeBranchesName,
    },
    {
      description: "Referral Code",
      value: referralCode,
    },
    {
      description: "Referred By",
      value: referredBy,
    },
    {
      description: "Default Store",
      value: defaultStoreBranchId,
    },
    {
      description: "Date Created",
      value: handleDate(dateCreated),
    },
  ];

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="order-information">
        <div className="store-branch-details">
          {ordersInformation.map((info: any, index) => (
            <div
              className={
                info.imageSRC
                  ? "store-branch-detail images"
                  : "store-branch-detail"
              }
              key={index}
            >
              {info.imageSRC && (
                <img className="image" src={info.imageSRC} alt="image" />
              )}
              <div className="description">
                <p className="type3">{info.description}</p>
                <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                  {info.value}
                </h5>
              </div>
            </div>
          ))}

          {/* {(role === "STORE_ADMIN" || role === "DROPPER") && (
            <div className="store-branch-detail">
              <div className="description">
                <p className="type3">Assign a store</p>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Controller
                    name="storeBranchId"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Select
                        options={stores}
                        onChange={(val: any) => {
                          onChange(val.value);
                          setStatuss(val.value);
                        }}
                        disabled={isStoresLoading}
                      />
                    )}
                  />
                  <Button
                    className="add-button"
                    text="Assign store"
                    isLoading={statusUpdate === "LOADING" ? true : false}
                    disabled={statuss === undefined ? true : false}
                  />
                </form>
              </div>
            </div>
          )} */}
        </div>
        {(userRole === "ADMIN" || userRole === "SUPER_ADMIN") &&
          role === "DROPPER" &&
          ratingDetails?.length > 0 && (
            <>
              <p
                style={{
                  marginTop: "32px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Ratings
              </p>
              <div style={{ paddingTop: "24px" }}>
                <div className="table-details">
                  <div className="table-details-body">
                    <div
                      className="details-table-header"
                      style={{ width: "100%" }}
                    >
                      <p style={{ width: "20%" }}>Customer Name</p>
                      <p style={{ width: "10%" }}>Rating Value</p>
                      <p style={{ width: "60%" }}>Comment</p>
                    </div>
                    {ratingDetails?.map((rating: any, index: any) => (
                      <div
                        key={index}
                        className="details-table-body"
                        style={{ width: "100%" }}
                      >
                        <p
                          style={{ width: "20%" }}
                          // onClick={() => navigate(`${rating?.id}`)}
                        >
                          {rating?.user?.firstName +
                            " " +
                            rating?.user?.lastName}
                        </p>
                        <p
                          style={{ width: "10%" }}
                          // onClick={() => navigate(`${rating?.id}`)}
                        >
                          {rating?.value}
                        </p>
                        <p
                          style={{ width: "60%" }}
                          // onClick={() => navigate(`${rating?.id}`)}
                        >
                          {rating?.content ? rating?.content : "Null"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {ratingStatus !== LOADING && ratingDetails.length > 0 && (
                <Paginate
                  totalItems={updateTotalItems()}
                  pageSize={filter?.limit}
                  currentPage={filter?.page}
                  onResult={onResult}
                />
              )}
            </>
          )}
      </div>
      {statusUpdate === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {statusUpdate === ERROR && (
        <ErrorModal
          title="Error!"
          subTitle="There's an error assigning this super packer to a store."
          onClick={refresh}
        />
      )}
    </>
  );
};

export default UserInformation;
