import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import OrderInformation from "./orderDetails/OrderInformation";
import RecieverInfo from "./orderDetails/RecieverInfo";
import "./OrderSingle.scss";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import { orderStatusEnum } from "../../../data/statusEnum";
import ProductInformation from "./orderDetails/ProductInformation";
import { Button } from "../../ui";
import OrderTrailListModal from "../../ui/modal/orderTrailListModal/OrderTrailListModal";
import OrderHistory from "./orderDetails/OrderHistory";
import API from "../../../utils/api/API";
import RefundingPopup from "../wallet/RefundingPopup";
import UpdatingPopup from "./orderDetails/UpdatingPopup";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const OrderSingle = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [userRole, setUserRole] = useState<string>();
  const [isEdit, setIsEdit] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [refundingPopup, setRefundingPopup] = useState(false);
  const [updatingPopup, setUpdatingPopup] = useState(false);
  const [packs, setPacks] = useState<any>();
  const [orderSingleData, setOrderSingleData] = useState({
    id: "",
    instruction: "",
    isFoodOrder: false,
    modelId: "",
    orderRef: "",
    paymentGateway: "",
    status: "",
    deliveryFee: "",
    paymentMethod: "",
    paymentReference: "",
    serviceFee: "",
    storeBranchId: "",
    storeBranch: "",
    storeBranchAddress: "",
    refundAmounts: "",
    total: "",
    subTotal: "",
    dropperSubTotal: "",
    building: "",
    floor: "",
    deliveryInstruction: "",
    street: "",
    customerEmail: "",
    customerFirstName: "",
    customerLastName: "",
    customerPhone: "",
    dropperName: "",
    packerName: "",
    lat: "",
    lng: "",
    ratingValue: 0,
    ratingContent: "",
    chefInstruction: "",
  });
  const [orderProductSingleData, setOrderProductSingleData] = useState();
  const [isOrderTrailsList, setIsOrderTrailsList] = useState(false);
  const [preOrderTrailsList, setPreOrderTrailsList] = useState({
    // id: "",
    // serviceFee: "",
    // deliveryFee: "",
    // subTotal: "",
    // dropperSubTotal: "",
    // total: "",
  });
  const [orderTrailsList, setOrderTrailsList] = useState([
    {
      hi: "hey",
    },
  ]);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [orderHistory, setOrderHistory] = useState<any[]>([]);
  const [orderHistoryData, setOrderHistoryData] = useState({
    riderInSupermarket: "",
    riderAssigned: "",
    paymentReceived: "",
    orderStarted: "",
    orderReceived: "",
    orderDelivered: "",
    orderInTransit: "",
    riderAccepted: "",
  });

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const { id } = useParams();

  const getOrderSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getOrder(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const data = resp.data?.data;
        const formattedList = {
          id: data?.id,
          instruction: data?.instructions,
          isFoodOrder: data?.isFoodOrder,
          modelId: data?.modelId,
          orderRef: data?.orderRef,
          refundAmounts: data?.refundAmounts,
          paymentGateway: data?.paymentGateway,
          status: data?.status,
          deliveryFee: data?.deliveryFee,
          paymentMethod: data?.paymentMethod,
          paymentReference: data?.paymentReference,
          serviceFee: data?.serviceFee,
          storeBranchId: data?.storeBranch?.id,
          storeBranch: data?.storeBranch?.name,
          storeBranchAddress: data?.storeBranch?.address,
          total: data?.total,
          subTotal: data?.subTotal,
          dropperSubTotal: data?.dropperSubTotal,
          building: data?.location?.building,
          floor: data?.location?.floor,
          deliveryInstruction: data?.location?.instructions,
          street: data?.location?.street,
          lat: data?.location?.coords?.coordinates[0],
          lng: data?.location?.coords?.coordinates[1],
          customerEmail: data?.user?.email,
          customerFirstName: data?.user?.firstName,
          customerLastName: data?.user?.lastName,
          customerPhone: data?.user?.phone,
          dropperName: data?.dropper?.firstName + " " + data?.dropper?.lastName,
          packerName: data?.packer?.firstName + " " + data?.packer?.lastName,
          ratingValue: data?.rating?.value,
          ratingContent: data?.rating?.content,
          chefInstruction: data?.chefInstruction,
        };

        const productFormattedList = resp.data?.data?.orderItems?.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              quantity: data.quantity,
              productId: data.product?.id,
              dropperPrice: data.product?.dropperPrice,
              price: data.product?.price,
              name: data.product?.name,
              picture: data.product?.picture,
              status: data.product?.status,
            };
          }
        );

        const orderTrailsFormattedList = resp.data?.data?.orderItemTrails?.map(
          (data: any, index: any) => {
            data?.orderItems?.map((subData: any, index: any) => {
              return {
                id: subData.productId,
                quantity: subData.quantity,
                unitCost: subData.unitCost,
              };
            });
          }
        );

        const preOrderData = resp.data?.data?.orderItemTrails?.map(
          (data: any, index: any) => {
            return {
              id: data?.id,
              serviceFee: data?.serviceFee,
              deliveryFee: data?.deliveryFee,
              subTotal: data?.subTotal,
              dropperSubTotal: data?.dropperSubTotal,
              total: data?.total,
              orderItems: data?.orderItems,
            };
          }
        );
        setPacks(data?.packs);
        setOrderHistory(resp.data?.data?.history);
        setPreOrderTrailsList(preOrderData);
        setOrderTrailsList(orderTrailsFormattedList);
        setOrderSingleData(formattedList);
        setOrderProductSingleData(productFormattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response?.data?.message));
        setStatus(ERROR);
        setAlert(true);
      });
  };

  const getStatusCreatedAt = (status: any) => {
    const createdAtValues: any = orderHistory
      ?.filter((item) => item.status === status)
      .map((item) => item.createdAt);
    return createdAtValues;
  };

  useEffect(() => {
    setOrderHistoryData((prevState) => ({
      ...prevState,
      orderStarted: `${getStatusCreatedAt("STARTED")}`,
      paymentReceived: `${getStatusCreatedAt("PAYMENT_RECEIVED")}`,
      orderReceived: `${getStatusCreatedAt("ACCEPTED_BY_STORE")}`,
      riderAssigned: `${getStatusCreatedAt("ASSIGNED")}`,
      riderAccepted: `${getStatusCreatedAt("ACCEPTED_BY_DROPPER")}`,
      riderInSupermarket: `${getStatusCreatedAt("PICKING_UP")}`,
      orderInTransit: `${getStatusCreatedAt("DROPPING")}`,
      orderDelivered: `${getStatusCreatedAt("COMPLETED")}`,
    }));
  }, [orderHistory]);

  useEffect(() => {
    if (id) {
      getOrderSingleData();
    }
  }, [id]);

  const getStatus = (status: any) => {
    return orderStatusEnum.find((singleStatus: any) => {
      if (status === singleStatus.value) {
        return singleStatus;
      }
    });
  };

  const handleNav = (index: any) => {
    if (activeNav === index) {
      setActiveNav(0);
    }
    setActiveNav(index);
  };

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <EmptyView message="There's no order presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="order-details">
            <div className="details-navigation">
              <div className="navbars">
                {(userRole === "DROPPER" ? dropperNavigation : navigation).map(
                  (item: any, index) => (
                    <p
                      className={`navbar ${
                        activeNav === index ? "active" : ""
                      }`}
                      onClick={() => handleNav(index)}
                      key={index}
                    >
                      {item}
                    </p>
                  )
                )}
              </div>
              <p className="type3 order-id">{orderSingleData.id}</p>
            </div>
            {activeNav === 0 && (
              <OrderInformation
                id={orderSingleData.id}
                instruction={orderSingleData.instruction}
                modelId={orderSingleData.modelId}
                orderRef={orderSingleData.orderRef}
                status={orderSingleData.status}
                deliveryFee={orderSingleData.deliveryFee}
                paymentGateway={orderSingleData.paymentGateway}
                paymentMethod={orderSingleData.paymentMethod}
                paymentReference={orderSingleData.paymentReference}
                serviceFee={orderSingleData.serviceFee}
                storeBranchId={orderSingleData.storeBranchId}
                storeBranch={orderSingleData.storeBranch}
                storeBranchAddress={orderSingleData.storeBranchAddress}
                total={orderSingleData.total}
                subTotal={orderSingleData.subTotal}
                dropperSubTotal={orderSingleData.dropperSubTotal}
                refundAmounts={orderSingleData.refundAmounts}
                getOrderSingleData={getOrderSingleData}
                chefInstruction={orderSingleData.chefInstruction}
                ratingValue={orderSingleData.ratingValue}
                ratingContent={orderSingleData.ratingContent}
              />
            )}
            {userRole === "DROPPER" && activeNav === 1 ? (
              <>
                <RecieverInfo
                  building={orderSingleData.building}
                  floor={orderSingleData.floor}
                  deliveryInstruction={orderSingleData.deliveryInstruction}
                  street={orderSingleData.street}
                  customerEmail={orderSingleData.customerEmail}
                  customerFirstName={orderSingleData.customerFirstName}
                  customerLastName={orderSingleData.customerLastName}
                  customerPhone={orderSingleData.customerPhone}
                  lat={orderSingleData.lat}
                  lng={orderSingleData.lng}
                />
              </>
            ) : (
              activeNav === 1 && (
                <>
                  <ProductInformation
                    orderSingleData={orderSingleData}
                    packs={packs}
                    orderProductSingleData={orderProductSingleData}
                    orderId={orderSingleData.id}
                  />
                </>
              )
            )}
            {activeNav === 2 && (
              <>
                <RecieverInfo
                  building={orderSingleData.building}
                  floor={orderSingleData.floor}
                  deliveryInstruction={orderSingleData.deliveryInstruction}
                  street={orderSingleData.street}
                  customerEmail={orderSingleData.customerEmail}
                  customerFirstName={orderSingleData.customerFirstName}
                  customerLastName={orderSingleData.customerLastName}
                  customerPhone={orderSingleData.customerPhone}
                  lat={orderSingleData.lat}
                  lng={orderSingleData.lng}
                />
              </>
            )}
            {activeNav === 3 && (
              <>
                <OrderHistory
                  orderDelivered={orderHistoryData.orderDelivered}
                  orderStarted={orderHistoryData.orderStarted}
                  orderInTransit={orderHistoryData.orderInTransit}
                  orderReceived={orderHistoryData.orderReceived}
                  riderAssigned={orderHistoryData.riderAssigned}
                  riderAccepted={orderHistoryData.riderAccepted}
                  riderInSupermarket={orderHistoryData.riderInSupermarket}
                  paymentReceived={orderHistoryData.paymentReceived}
                  dropperName={orderSingleData.dropperName}
                  packerName={orderSingleData.packerName}
                />
              </>
            )}
          </div>
        );

      case ERROR:
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  const navigation = [
    "Order Information",
    "Product Information",
    "Customer Information",
    "Order History",
  ];

  const dropperNavigation = ["Order Information", "Customer Information"];

  return (
    <>
      <div className="order-single-page">
        <span className="go-back" onClick={() => Navigate(-1)}>
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
            alt="arrow-back"
            className="arrow"
          />
          <h6>Go back</h6>
        </span>
        <div className="page-header">
          <h2>
            Orders Details -{" "}
            {orderSingleData?.customerFirstName +
              " " +
              orderSingleData?.customerLastName}
          </h2>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "#9F9F9F",
              marginTop: "4px",
              marginBottom: "20px",
            }}
          >
            {orderSingleData?.id}
          </p>
          <div className="header-cta" style={{ display: "flex", gap: "12px" }}>
            {/* {userRole !== "STORE_ADMIN" && (
              <Button
                text="Refund"
                invertStyle
                onClick={() => setRefundingPopup(true)}
              />
            )} */}
            {activeNav === 1 &&
              userRole !== "STORE_ADMIN" &&
              userRole !== "DROPPER" &&
              !orderSingleData?.isFoodOrder && (
                <Button
                  text="Update Order"
                  invertStyle
                  onClick={() => setUpdatingPopup(true)}
                />
              )}
            {orderTrailsList.length > 0 &&
              userRole !== "STORE_ADMIN" &&
              userRole !== "DROPPER" && (
                <Button
                  text="View Changes"
                  onClick={() => setIsOrderTrailsList(true)}
                />
              )}
          </div>
        </div>
        {renderBasedOnStatus()}
      </div>
      {isOrderTrailsList && (
        <OrderTrailListModal
          setPopup={setIsOrderTrailsList}
          orderTrailsList={preOrderTrailsList}
        />
      )}
      {refundingPopup && (
        <RefundingPopup
          setPopup={setRefundingPopup}
          // getCategories={getCategories}
          data={orderSingleData}
          isOrderSide
          // selectedId={selectedId}
        />
      )}
      {updatingPopup && (
        <UpdatingPopup
          setPopup={setUpdatingPopup}
          // data={orderSingleData}
          orderProductSingleData={orderProductSingleData}
          orderId={orderSingleData.id}
          amountPaid={orderSingleData.total}
          deliveryFee={orderSingleData.deliveryFee}
          serviceFee={orderSingleData.serviceFee}
        />
      )}
    </>
  );
};

export default OrderSingle;
