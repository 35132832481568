import Heading from "../../heading";
import "./OrderTrailListModal.scss";
import { useState } from "react";
import { handlePrice, numberToWordOrdinals } from "../../../../helper";
import CaretRight from "../../../../assets/caret-right.svg";

interface IOrderTrailListModal {
  setPopup?: any;
  orderTrailsList?: any;
  serviceFee?: any;
  deliveryFee?: any;
  subTotal?: any;
  dropperSubTotal?: any;
  total?: any;
}

const OrderTrailListModal = (props: IOrderTrailListModal) => {
  const { setPopup, orderTrailsList } = props;

  const [clickedIndex, setClickedIndex] = useState(orderTrailsList.length + 1);

  const toggle = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(orderTrailsList.length + 1);
    } else {
      setClickedIndex(index);
    }
  };

  const orderTrailsListWithSum = orderTrailsList.map((item: any) => {
    const sumOfQuantities = item.orderItems.reduce(
      (accumulator: number, orderItem: any) => accumulator + orderItem.quantity,
      0
    );

    return {
      ...item,
      sumOfQuantities: sumOfQuantities, // Add the sum as a new property
    };
  });

  return (
    <>
      <div className="popup">
        <div className="popup-container trail">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Default Order Details"
            subTitle="Here are the default order details from the customer"
          />
          <div className="table-details">
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "2%" }}></p>
                <p style={{ width: "20%" }}>Updates</p>
                <p style={{ width: "20%" }}>No of items</p>
                <p style={{ width: "38%" }}>Qty in basket</p>
                <p style={{ width: "20%" }}>Total</p>
              </div>
              {orderTrailsListWithSum.map((item: any, index: any) => (
                <div key={index}>
                  <div
                    className="details-table-body"
                    style={{ width: "100%" }}
                    onClick={() => toggle(index)}
                  >
                    <div
                      style={{
                        width: "2%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: `${
                          clickedIndex === index
                            ? "rotate(90deg)"
                            : "rotate(0deg)"
                        }`,
                      }}
                    >
                      <img src={CaretRight} alt="caret-right" />
                    </div>
                    <p style={{ width: "20%" }}>
                      {index === 0
                        ? "Original Order"
                        : `${numberToWordOrdinals(index)} update`}
                    </p>
                    <p style={{ width: "20%" }}>{item?.orderItems?.length}</p>
                    <p style={{ width: "38%" }}>{item?.sumOfQuantities}</p>
                    {/* <p style={{ width: "16%" }}>
                      {handlePrice(item?.subTotal)}
                    </p>
                    <p style={{ width: "16%" }}>
                      {handlePrice(item?.deliveryFee)}
                    </p>
                    <p style={{ width: "16%" }}>
                      {handlePrice(item?.serviceFee)}
                    </p> */}
                    <p style={{ width: "20%" }}>{handlePrice(item?.total)}</p>
                  </div>
                  {clickedIndex === index && (
                    <div style={{ padding: "24px" }}>
                      <div className="table-details">
                        <div className="table-details-body">
                          <div
                            className="details-table-header"
                            style={{ width: "100%" }}
                          >
                            <p style={{ width: "25%" }}>Product</p>
                            <p style={{ width: "25%" }}>Price</p>
                            <p style={{ width: "25%" }}>Quantity</p>
                            <p style={{ width: "25%" }}>Amount</p>
                          </div>
                          {item?.orderItems.map((subItem: any, index: any) => (
                            <div
                              key={index}
                              className="details-table-body"
                              style={{ width: "100%" }}
                              // onClick={() => Navigate(`${item?.Id}`)}
                            >
                              <p style={{ width: "25%" }}>
                                {subItem?.product?.name}
                              </p>
                              <p style={{ width: "25%" }}>
                                {handlePrice(subItem?.unitCost)}
                              </p>
                              <p style={{ width: "25%" }}>
                                {subItem?.quantity}
                              </p>
                              <p style={{ width: "25%" }}>
                                {handlePrice(
                                  subItem?.unitCost * subItem?.quantity
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "24px",
                        }}
                      >
                        <div></div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                            padding: "16px",
                            width: "250px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <p>Subtotal</p>{" "}
                            <p>{handlePrice(item?.dropperSubTotal)}</p>{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <p>Delivery Fee</p>{" "}
                            <p>{handlePrice(item?.deliveryFee)}</p>{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <p>Service Fee</p>{" "}
                            <p>{handlePrice(item?.serviceFee)}</p>{" "}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            <p style={{ fontWeight: "bold" }}>Total</p>{" "}
                            <p style={{ fontWeight: "bold" }}>
                              {handlePrice(item?.total)}
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderTrailListModal;
