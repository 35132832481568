import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import AddStoreImagePopup from "./addCategoryPopup/AddCategoryPopup";
import "./StoreBranchSingle.scss";
import ViewStoreImagesPopup from "./viewStoreImagesPopup/ViewStoreImagesPopup";
import { getErrorMessage, getMe, handlePrice } from "../../../helper";
import API from "../../../utils/api/API";
import AddCategoryPopup from "./addCategoryPopup/AddCategoryPopup";
import CategoryCard from "./categoryCard/CategoryCard";
import { confirmAlert } from "react-confirm-alert";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../ui/alert";
import { toast } from "react-toastify";
import Switch from "../../ui/switch";
import OnlinePopup from "./OnlinePopup";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const StoreBranchSingle = () => {
  const Navigate = useNavigate();

  const navigate = useNavigate();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [categoryPopup, setCategoryPopup] = useState(false);
  const [storeProductList, setStoreProductList] = useState([]);
  const [user, setUser] = useState({
    role: "",
  });

  useEffect(() => {
    getMe(setUser, setIsUserLoading);
  }, []);

  useEffect(() => {
    if (user.role === "STORE_ADMIN") {
      navigate("/");
    }
  }, [user]);

  const { id } = useParams();

  const [status, setStatus] = useState(IDLE);
  const [storeData, setStoreData] = useState({
    id: "",
    createdAt: "",
    name: "",
    status: "",
    address: "",
    mapLocation: "",
    openingTime: 0,
    closingTime: 0,
    maxOrderQty: 0,
    minDeliveryTime: 0,
    minOrderAmount: 0,
    minOrderQty: 0,
    rating: null,
    state_id: 0,
    storeId: "",
    isOnline: false,
  });
  const [timeSettings, setTimeSettings] = useState<any>({});
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);
  const [isOnline, setIsOnline] = useState<boolean>();
  const [onlinePopup, setOnlinePopup] = useState(false);

  useEffect(() => {
    setIsOnline(storeData.isOnline);
  }, [storeData]);

  const getStoreBranchSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getStoreBranch(id),
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const data = resp.data.data;
        const formattedList = {
          id: data.id,
          createdAt: data.createdAt,
          name: data.name,
          status: data.status,
          address: data.address,
          mapLocation: data.mapLocation,
          openingTime: data.openingTime,
          closingTime: data.closingTime,
          maxOrderQty: data.maxOrderQty,
          minDeliveryTime: data.minDeliveryTime,
          minOrderAmount: data.minOrderAmount,
          minOrderQty: data.minOrderQty,
          rating: data.rating,
          state_id: data.state_id,
          storeId: data.storeId,
          isOnline: data.isOnline,
        };
        if (resp?.data?.data?.timeSettings === null) {
          setTimeSettings({});
        } else {
          setTimeSettings(resp?.data?.data?.timeSettings);
        }
        setStoreData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        // setMessage("Error getting store");
        setStatus(ERROR);
      });
  };

  const params = {
    "filter.storeBranch.id": id,
  };

  const getStoreBranchProduct = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.products,
      params: params,
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const formattedData = resp.data?.data?.products;
        setStoreProductList(formattedData);
        // formattedData ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting store");
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (id) {
      getStoreBranchSingleData();
      getStoreBranchProduct();
    }
  }, [id]);

  const refresh = () => {
    window.location.reload();
  };

  function isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  const today = new Date()
    .toLocaleDateString("en-US", { weekday: "long" })
    .toUpperCase();
  // const openingTime = timeSettings[today]?.openingTime || "Closed";
  // const closingTime = timeSettings[today]?.closingTime || "Closed";

  const storeBranchDetails = [
    // {
    //   key: "Name",
    //   value: storeData.name,
    //   className: "",
    // },
    // {
    //   key: "Address",
    //   value: `${storeData.address}`,
    //   className: "",
    // },
    {
      key: "Status",
      value: storeData.status,
      className: "",
    },
    {
      key: "Opening Time",
      value:
        isEmptyObject(timeSettings) ||
        timeSettings === undefined ||
        timeSettings === null
          ? ""
          : timeSettings[today]?.openingTime,
      className: "",
      image: "",
    },
    {
      key: "Closing Time",
      value:
        isEmptyObject(timeSettings) ||
        timeSettings === undefined ||
        timeSettings === null
          ? ""
          : timeSettings[today]?.closingTime,
      className: "",
    },
  ];

  const [activateStatus, setActivateStatus] = useState(IDLE);

  const activateDeactivate = async (id: string, status: string) => {
    setActivateStatus(LOADING);
    await API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls.getStoreBranch(id),
      data: { status: status },
    })
      .then((resp) => {
        setActivateStatus(SUCCESS);
        setMessage(resp.data?.message);
        // formattedData ? setActivateStatus(DATAMODE) : setActivateStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage(err.message);
        setActivateStatus(ERROR);
      });
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no store branch presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="page-header">
              <div>
                <span className="go-back" onClick={() => Navigate(-1)}>
                  <img
                    src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                    alt="arrow-back"
                    className="arrow"
                  />
                  <h6>Go back</h6>
                </span>
                <h2>{storeData.name}</h2>
                <p className="type2" style={{ margin: "10px 0 0" }}>
                  Address: {storeData.address}
                </p>
              </div>
              <div className="header-buttons">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <p className="type2 bold" style={{ display: "block" }}>
                    Online?
                  </p>
                  <Switch
                    textOff="YES"
                    textOn="NO"
                    value={isOnline}
                    onChange={setIsOnline}
                    setPopup={setOnlinePopup}
                    bgColor="#2EBB55"
                  />
                </div>
                <Button
                  invertStyle
                  text={
                    storeData.status === "ACTIVE" ? "Deactivate" : "Activate"
                  }
                  isLoading={activateStatus === LOADING && true}
                  className="shop-add"
                  onClick={() => {
                    confirmAlert({
                      title:
                        storeData.status === "ACTIVE"
                          ? "Are you sure you want to deactivate this store branch?"
                          : "Are you sure you want to activate this store branch?",
                      message:
                        storeData.status === "ACTIVE"
                          ? "This store branch’s will be deactivated and might not be able to function as expected"
                          : "This store branch’s will be activated",
                      buttons: [
                        {
                          label: "Cancel",
                          // Do Close operation
                        },
                        {
                          label:
                            storeData.status === "ACTIVE"
                              ? "Deactivate"
                              : "Activate",
                          onClick: () =>
                            storeData.status === "ACTIVE"
                              ? activateDeactivate(storeData.id, "INACTIVE")
                              : activateDeactivate(storeData.id, "ACTIVE"),
                        },
                      ],
                    });
                  }}
                />
                <Button
                  text="Add Category"
                  className="shop-add"
                  onClick={() => setCategoryPopup(true)}
                />
              </div>
            </div>
            <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
              Store Branch Details
            </h5>
            <div className="store-branch-details">
              {storeBranchDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              style={{
                marginTop: "24px",
                display: "flex",
                gap: "24px",
                flexDirection: "column",
              }}
            >
              <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                Categories
              </h5>
              <CategoryCard storeBranchId={id} />
            </div>
            <div
              style={{
                marginTop: "24px",
                display: "flex",
                gap: "24px",
                flexDirection: "column",
              }}
            >
              <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                Best Seller
              </h5>
              <div className="cat-cards">
                <div
                  className="branch-card"
                  onClick={() => Navigate(`/best-seller/${id}`)}
                >
                  <div className="head">
                    <img
                      src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png"
                      alt="store-branch"
                    />
                  </div>
                  <h3>Best Seller</h3>
                  <p className="type2"></p>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                marginTop: "24px",
                display: "flex",
                gap: "24px",
                flexDirection: "column",
              }}
            >
              <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                Products
              </h5>
              <div className="table-details">
                <div className="table-details-body">
                  <div
                    className="details-table-header"
                    style={{ width: "100%" }}
                  >
                    <p style={{ width: "25%" }}>Product</p>
                    <p style={{ width: "25%" }}>Category</p>
                    <p style={{ width: "25%" }}>Subcategory</p>
                    <p style={{ width: "25%" }}>Price</p>
                  </div>
                  {storeProductList.map((item: any, index: any) => (
                    <div className="details-table-body" key={index}>
                      <p style={{ width: "25%" }}>{item?.name}</p>
                      <p style={{ width: "25%" }}>{item?.sumOfQuantities}</p>
                      <p style={{ width: "25%" }}>{item?.category?.name}</p>
                      <p style={{ width: "25%" }}>{handlePrice(item?.price)}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            {/* <div className="order-details"></div> */}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">{renderBasedOnStatus()}</div>
      {/* {popup && <AddStoreImagePopup id={id} setPopup={setPopup} />} */}
      {categoryPopup && (
        <AddCategoryPopup id={id} setPopup={setCategoryPopup} />
      )}
      {activateStatus === SUCCESS && (
        // <AddProductImagePopup id={productId}/>
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {activateStatus === ERROR && (
        <Alert
          title={message}
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {activateStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
      {onlinePopup && (
        <OnlinePopup
          value={isOnline}
          storeBranchId={id}
          setPopup={setOnlinePopup}
          setIsOnline={setIsOnline}
        />
      )}
    </>
  );
};

export default StoreBranchSingle;
