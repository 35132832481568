import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import StoresTable from "../../ui/table/storesTable/StoresTable";
import AddStorePopup from "./addStorePopup/AddStorePopup";
import "./Store.scss";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/api/API";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Stores"
      subTitle="Hi there! This are the available stores"
    />
  );
};

const Store = () => {
  const [addShopPopup, setAddShopPopup] = useState(false);
  const [storeDetails, setStoreDetails] = useState([]);
  const navigate = useNavigate();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedStore, setSelectedStore] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setAddShopPopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedStore(data);
    setAddShopPopup(true);
  };

  const getStore = async () => {
    setStoreDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.store,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data?.data.stores.map((data: any, index: any) => {
          return {
            number: index + 1,
            id: data.id,
            name: data.name,
            status: data.status,
            contact: data.phoneNumber,
            logo: data.logo,
          };
        });
        setStoreDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteStore = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getStore(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getStore();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getStore();
  }, [filter]);

  return (
    <>
      <div className="page-header">
        <PageHeading />
        <Button
          text="Add Store"
          className="shop-add"
          onClick={handleAddModal}
        />
      </div>
      <div className="table-wrapper">
        {/* {!isLoading && dropsTableData.length > 0 && (
          <DropsFilter setFilterFunction={setOverviewDropFilter} />
        )} */}
        <StoresTable
          clickable
          data={storeDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteStore={deleteStore}
          handleEditModal={handleEditModal}
        />
      </div>
      {status !== LOADING && storeDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {addShopPopup && (
        <AddStorePopup
          getStores={getStore}
          setPopup={setAddShopPopup}
          data={selectedStore}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default Store;
