import { useNavigate } from "react-router-dom";
import { ridersTableData } from "../../../data/Data";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import VehicleTable from "../../ui/table/vehicleTable/VehicleTable";
import { useEffect, useState } from "react";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import Paginate from "../../ui/paginate";
import AddVehiclePopup from "./addVehiclePopup/AddVehiclePopup";
import API from "../../../utils/api/API";
import { getErrorMessage } from "../../../helper";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Vehicles"
      subTitle="See all your vehicles and their details here"
    />
  );
};

const Vehicles = () => {
  const [addVehiclePopup, setAddVehiclePopup] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setAddVehiclePopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedVehicle(data);
    setAddVehiclePopup(true);
  };

  const getVehicle = async () => {
    setVehicleDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.vehicles,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data?.data.vehicles.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data?.id,
              maker: data?.maker,
              model: data?.model,
              plateNumber: data?.plateNumber,
              dropperId: data?.dropperId,
              dropper: data?.dropper?.firstName + " " + data?.dropper?.lastName,
            };
          }
        );
        setVehicleDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteVehicle = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getVehicle(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getVehicle();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getVehicle();
  }, [filter]);
  return (
    <>
      <div className="page-header">
        <PageHeading />
        <Button
          text="Add Vehicle"
          className="customer-add"
          onClick={handleAddModal}
        />
      </div>
      <div className="table-wrapper">
        <VehicleTable
          data={vehicleDetails}
          emptyHeight="600px"
          // clickable
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteVehicle={deleteVehicle}
          handleEditModal={handleEditModal}
        />
      </div>
      {status !== LOADING && vehicleDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {addVehiclePopup && (
        <AddVehiclePopup
          getRiders={getVehicle}
          setPopup={setAddVehiclePopup}
          data={selectedVehicle}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

export default Vehicles;
