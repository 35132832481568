import StoreBranchSingle from "../components/dashboard/storeBranchSingle";
import Layout from "../components/ui/layout";

const StoreBranchSinglePage = () => {
  return (
    <>
      <Layout>
        <StoreBranchSingle />
      </Layout>
    </>
  );
};

export default StoreBranchSinglePage;
