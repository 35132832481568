import "./TelephoneInput.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useState } from "react";
import Alert from "../alert";
import { toast } from "react-toastify";

interface InputTypes {
  inputClass?: string;
  labelClass?: string;
  label?: string;
  placeholder?: string;
  onChange?: any;
  value?: any;
  id?: string;
  name?: string;
  invertStyle?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  className?: string;
  disabled?: boolean;
  copy?: boolean;
  register?: Function;
  required?: boolean;
  pattern?: any;
  copyText?: boolean;
}

const TelephoneInput = (props: InputTypes) => {
  const [alert, setAlert] = useState(false);
  const {
    inputClass,
    labelClass,
    placeholder,
    label,
    onChange,
    value,
    id,
    name,
    invertStyle,
    errorMsg,
    hasError,
    className,
    disabled,
    copy,
    register,
    required,
    pattern,
    copyText,
  } = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setAlert(true);
    toast.success("Phone number copied");
  };

  return (
    <div className={`telephone-input ${className}`}>
      <label className={`label ${labelClass}`} htmlFor={id}>
        {label}
      </label>
      <PhoneInput
        className={`tel-input ${
          invertStyle ? "input-invert" : ""
        } ${inputClass} ${hasError ? "input-error-input" : ""}`}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        international
        country="NG"
        countryCallingCodeEditable={false}
        defaultCountry="NG"
        value={value}
        id={id}
        name={name}
        disabled={disabled}
        {...register?.(name, { required: required, pattern: pattern })}
      />
      {copy &&
        (copyText ? (
          <p className="copy-text" onClick={handleCopy}>
            Copy
          </p>
        ) : (
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/copy-outline_yfjdto.svg"
            alt="copy"
            className="copy"
            onClick={handleCopy}
          />
        ))}

      {hasError && (
        <span className="input-error">
          {errorMsg ? errorMsg : `${label} is required.`}
        </span>
      )}
      {/* {alert && (
        <Alert
          title="Text Copied"
          text="Phone Number copied"
          alertOut={setAlert}
          timeOut={10000}
        />
      )} */}
    </div>
  );
};

export default TelephoneInput;
