import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../../../ui";
import Heading from "../../../../../ui/heading";
import { dataQueryStatus } from "../../../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../../../utils/api";
import SuccessfulModal from "../../../../../ui/modal/successfulModal/SuccessfulModal";
import Checkbox from "../../../../../ui/checkbox";
import { getErrorMessage } from "../../../../../../helper";
import API from "../../../../../../utils/api/API";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  getPromo: Function;
  data?: any;
  isEdit?: boolean;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddVoucherPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { setPopup, getPromo, data, isEdit } = props;

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const updateVoucher = (request: any) => {
    setStatus(LOADING);
    setMessage("");

    const payload = {
      isPromoOngoing: request.isPromoOngoing,
      promoAmount: request.promoAmount * 100,
      promoDiscount: request.promoDiscount * 100,
    };

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.promo,
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getPromo();
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    updateVoucher(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { promoDiscount, promoAmount, isPromoOngoing } = data || {};
      setValue("promoDiscount", promoDiscount / 100);
      setValue("promoAmount", promoAmount / 100);
      setValue("isPromoOngoing", isPromoOngoing);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Promo" : "Add Promo"}
            subTitle={
              isEdit
                ? "Enter the following details to update your Promo"
                : "Enter the following details to add your Promo"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Checkbox
              label="Is Promo Ongoing?"
              name="isPromoOngoing"
              register={register}
            />
            <Input
              register={register}
              label="Promo Amount"
              name="promoAmount"
              required
              placeholder="Enter promo amount"
              type="number"
              valueAsNumber
            />
            <Input
              register={register}
              label="Promo Discount"
              name="promoDiscount"
              required
              placeholder="Enter promo discount"
              type="number"
              valueAsNumber
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Promo" : "Add Promo"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully updated the  Promo"
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Promo"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddVoucherPopup;
