import Layout from "../components/ui/layout";
import Wallet from "../components/dashboard/wallet";

const WalletPage = () => {
  return (
    <>
      <Layout>
        <Wallet />
      </Layout>
    </>
  );
};

export default WalletPage;
