import { createContext, useContext } from "react";

export const AppContext = createContext({
  token: null || "",
  user: {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  },
  businessDetails: {
    livePublicKey: "",
    liveSecretKey: "",
    testPublicKey: "",
    testSecretKey: "",
  },
  updateUser: (data: any) => {},
  updateToken: (token: any) => {},
  updateBusinessDetails: (businessData: any) => {},
});

export const useAppContext = () => {
  const {
    updateToken,
    updateUser,
    updateBusinessDetails,
    businessDetails,
    token,
    user,
  } = useContext(AppContext);

  return {
    updateToken,
    updateUser,
    updateBusinessDetails,
    businessDetails,
    token,
    user,
  };
};
