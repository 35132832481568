import Layout from '../components/ui/layout'
import DropFoodCategorySingle from '../components/dashboard/dropFoodCategorySingle'

const DropFoodCategorySinglePage = () => {
  return (
    <Layout>
        <DropFoodCategorySingle />
    </Layout>
  )
}

export default DropFoodCategorySinglePage