import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  gsaNavbarDetails,
  navbarDetails,
  storeNavbarDetails,
} from "../../../data/Data";
import Button from "../button";
import OnlineModalPopup from "./OnlineModalPopup";

export interface ILayoutProps {
  isSidebarExpanded?: any;
  setIsSidebarExpanded?: any;
  userImage?: any;
  firstLetter?: any;
  lastLetter?: any;
  children?: any;
}

const LayoutSidebar = (props: ILayoutProps) => {
  const { isSidebarExpanded, setIsSidebarExpanded } = props;
  const [userRole, setUserRole] = useState("");
  const [isOnlinePopup, setIsOnlinePopup] = useState(false);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const path = window.location.pathname;

  return (
    <>
      <div className={`sidebar ${isSidebarExpanded ? "" : "expanded"}`}>
        <div className="sidebar-content">
          <div className="logo-container">
            <Link to="/">
              <img
                src={`${
                  isSidebarExpanded
                    ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/dropper-white_z2kqm2.svg"
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/mini-logo_tdlfzl.svg"
                }`}
                alt="Dropper logo"
                className={`${isSidebarExpanded ? "full-logo" : "half-logo"}`}
              />
            </Link>
            <img
              src={`${
                isSidebarExpanded
                  ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/back-arrow_sv06u4.svg"
                  : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685958942/internal-dashboard/arrow-right_glstc5.svg"
              }`}
              alt="arrow"
              onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
              className="sidebar-toggle"
            />
          </div>
          <div className="navbars">
            {(userRole === "ADMIN" || userRole === "SUPER_ADMIN"
              ? path.includes("/gsa/")
                ? gsaNavbarDetails
                : navbarDetails
              : storeNavbarDetails
            ).map((navbar, index) => (
              <Link
                style={{ textDecoration: "none" }}
                className={`navbar ${navbar.path === path ? "active" : ""}`}
                to={navbar.path}
                key={index}
              >
                <img
                  src={
                    navbar.path === path ? navbar.activeIconSRC : navbar.iconSRC
                  }
                  alt="Dropper Icon"
                />
                <h6 className={`${isSidebarExpanded ? "show" : "hide"}`}>
                  {navbar.handle}
                </h6>
              </Link>
            ))}
          </div>
          {userRole === "STORE_ADMIN" && (
            <div
              className="status-button"
              style={{ position: "absolute", bottom: "24px" }}
            >
              <Button
                text="Online Status"
                onClick={() => setIsOnlinePopup(true)}
              />
            </div>
          )}
        </div>
      </div>
      {isOnlinePopup && <OnlineModalPopup setPopup={setIsOnlinePopup} />}
    </>
  );
};

export default LayoutSidebar;
