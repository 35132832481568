import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateFilter.scss";

interface IDateFilterProps {
  startDate?: any;
  endDate?: any;
  setStartDate: any;
  setEndDate: any;
  setPopup?: any;
  setIsClearFilter?: any;
}

const DateFilter = (props: IDateFilterProps) => {
  const { setEndDate, setStartDate, endDate, startDate, setPopup, setIsClearFilter } = props;
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);

  const handleDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  const filterDataByDateRange = () => {
    const formattedStartDate = start ? start.toLocaleDateString("en-CA") : "";
    const formattedEndDate = end ? end.toLocaleDateString("en-CA") : "";

    setIsClearFilter(false)
    // Perform filtering logic based on the selected date range
    // You can use the startDate and endDate states here
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setPopup(false)
  };

  return (
    <div className="date-filter-wrapper">
      <DatePicker
        selected={start}
        onChange={handleDateRangeChange}
        startDate={start}
        endDate={end}
        selectsRange
        inline
      />
      <button className="filter-button" onClick={filterDataByDateRange}>Filter</button>
    </div>
  );
};

export default DateFilter;
