import { useEffect, useState } from "react";
import { Button, Input } from "../../../components/ui";
import TelephoneInput from "../../../components/ui/telephoneInput";
import "./style/ResetPassword.scss";
import { useNavigate } from "react-router-dom";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { useForm } from "react-hook-form";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import API from "../../../utils/api/API";
import { formatPhoneNumber, getErrorMessage } from "../../../helper";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const Reset = () => {
  // const [useEmail, setUseEmail] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const handlePayload = (phone: any) => {
    if (formatPhoneNumber(phone).startsWith("2340")) {
      return `234${formatPhoneNumber(phone).slice(4)}`;
    } else {
      return formatPhoneNumber(phone);
    }
  };

  const forgotPassword = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    const payload = handlePayload(data?.phone);

    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.forgotPassword,
      params: { phone: payload },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
        toast.success(resp.data?.message);
        setTimeout(() => {
          navigate("/new-password/");
        }, 2000);
      })
      .catch((err) => {
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => forgotPassword(data);

  return (
    <div className="login-section">
      <div className="login-container">
        <h3>Get your access back</h3>
        <p className="type2">
          Enter your registered <span>email</span>
          {/* <span>{useEmail ? "email" : "phone number"}</span> */}
        </p>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          {/* {useEmail ? ( */}
          <>
            <TelephoneInput
              placeholder="Enter Phone Number"
              label="Phone Number"
              name="phone"
              register={register}
              required
            />
            {/* <Input
              label="Phone number"
              placeholder="Enter Phone number"
              name="phoneNumber"
              type="tel"
              register={register}
              required
              id="login"
            /> */}
            <Button
              text="Recover Password"
              className="login-button"
              isLoading={status === LOADING ? true : false}
            />
          </>
          {/* ) : (
            <>
              <TelephoneInput
                label="Phone number"
                placeholder="Enter Phone number"
                id="login"
                onChange={(e: any) => console.log(e)}
              />
              <Button
                text="Recover Password"
                className="login-button"
                onClick={handleSubmit}
              />
            </>
          )} */}
        </form>
        {/* <p className="to-recover">
          Don’t have this?{" "}
          <span
            className="link"
            style={{ color: "#2EBB55", cursor: "pointer" }}
            onClick={() => setUseEmail(!useEmail)}
          >
            Try another method
          </span>
        </p> */}
      </div>
    </div>
  );
};

export default Reset;
