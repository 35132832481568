import { useState } from "react";
import { Button } from "../../ui";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";

interface ModalProps {
  setPopup: any;
  getCategories?: Function;
  data?: any;
  selectedId?: string;
  categoryId?: any;
  isBranch?: any;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const DeletePopup = (props: ModalProps) => {
  const { setPopup, data, isBranch } = props;
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const deleteRestaurant = async () => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: isBranch
        ? apiUrls?.getStoreBranch(data.id)
        : apiUrls?.getStore(data.id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={"Delete Restaurant"}
            subTitle="Are you sure you want to delete this restaurant"
          />
          <Button
            className="add-button"
            onClick={deleteRestaurant}
            text={"Delete Restaurant"}
            isLoading={status === "LOADING" ? true : false}
          />
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
    </>
  );
};

export default DeletePopup;
