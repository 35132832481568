import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input, TelephoneInput } from "../../../ui";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import Select from "../../../ui/select";
import { defaultOption, getErrorMessage, getRestaurantCategory } from "../../../../helper";
import CardLoader from "../../../ui/cardLoader";

interface ModalProps {
  setPopup: any;
  getStores: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddRestaurantPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();
  const { setPopup, getStores, data, isEdit, selectedId } = props;

  const [imageURL, setImageURL] = useState();
  const [backgroundURL, setBackgroundUrl] = useState();
  const [image, setImage] = useState("");
  const [backgroundPicture, setBackgoundPicture] = useState("");

  const [isUploading, setIsUploading] = useState(false);
  const [imageFile, setImageFile] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [restaurantCategory, setRestaurantCategory] = useState([]);
  const [isRestaurantCategoryLoading, setIsRestaurantCategoryLoading] =
    useState(false);

  useEffect(() => {
    getRestaurantCategory(
      setRestaurantCategory,
      setIsRestaurantCategoryLoading
    );
  }, []);

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(resp.data.data.url);
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(getErrorMessage(err.response.data.message));
        setImageStatus(ERROR);
      });
  };

  const uploadBackgroundPicture = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setBackgoundPicture(resp.data.data.url);
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(getErrorMessage(err.response.data.message));
        setImageStatus(ERROR);
      });
  };

  const addRestaurant = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.logo = image;
    data.backgroundPicture = backgroundPicture;
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.store,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const updateRestaurant = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.logo = image;
    request.backgroundPicture = backgroundPicture;
    (request.logo === "" || request.logo === undefined) &&
      (request.logo = data.logo);
    (request.backgroundPicture === "" ||
      request.backgroundPicture === undefined) &&
      (request.backgroundPicture = data.backgroundPicture);
    request.restaurantCategoryId === undefined &&
      (request.restaurantCategoryId = data.restaurantCategory?.id);
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getStore(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    // if (imageFile?.length > 0) {
    isEdit ? updateRestaurant(data) : addRestaurant(data);
    // } else {
    // setStatus(ERROR)
    // setMessage("An image is required");
    // }
  };

  useEffect(() => {
    if (isEdit) {
      const { name, productImage } = data || {};
      setValue("name", name);
      setImageFile(productImage);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  const uploadBackgroundFile = (file: any) => {
    uploadBackgroundPicture(file);
    setBackgroundUrl(file);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Restaurant" : "Add Restaurant"}
            subTitle={
              isEdit
                ? "Enter the following details to update a restaurant"
                : "Enter the following details to add a restaurant"
            }
          />

          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : isEdit && data?.logo?.length > 0
                    ? data?.logo
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload restaurant logo "
              onChange={upload}
              // isUploading={isUploading}
            />
          </div>

          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  backgroundURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        backgroundURL[0]
                      )
                    : isEdit && data?.backgroundPicture?.length > 0
                    ? data?.backgroundPicture
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload background picture"
              onChange={uploadBackgroundFile}
              // isUploading={isUploading}
            />
          </div>
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Restaurant Name"
              name="name"
              required
              placeholder="Enter Restaurant Name"
            />
            <Controller
              name="restaurantCategoryId"
              control={control}
              rules={{ required: 'Restaurant Category is required' }}
              render={({ field: { onChange } }) => (
                <Select
                  options={restaurantCategory}
                  label="Select Restaurant Category"
                  value={
                    restaurantCategory !== null && isEdit
                      ? defaultOption(
                          restaurantCategory,
                          data?.restaurantCategory?.id
                        )
                      : ""
                  }
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isRestaurantCategoryLoading}
                />
              )}
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Restaurant" : "Add Restaurant"}
              isLoading={status === "LOADING" ? true : false}
              disabled={imageStatus === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a restaurant to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Restaurant"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {isRestaurantCategoryLoading && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddRestaurantPopup;
