import VoucherSingle from "../components/dashboard/settings/settingsBody/voucherSingle";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";

const VoucherSinglePage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <VoucherSingle />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default VoucherSinglePage;
