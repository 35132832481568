import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui";
// import "./OverviewCard.scss";
import CardLoader from "../../../ui/cardLoader";
import { useState } from "react";
import { handlePrice } from "../../../../helper";
import CustomPopup from "../customPopup/CustomPopup";

interface IMetricsProps {
  deliveryTotal?: any;
  isDeliveryLoading?: any;
  deliveryDateFilter?: any;
  setDeliveryDateFilter?: any;
  getLastNDaysFormatted?: any;
  setIsClearDeliveryTotalFilter?: any;
  serviceCharge?: any;
  isServiceChargeLoading?: any;
  setServiceChargeDateFilter?: any;
  setIsClearServiceChargeFilter?: any;
  supermarketTotal?: any;
  isSupermarketLoading?: any;
  setSupermarketDateFilter?: any;
  setIsClearSupermarketFilter?: any;
  dropperTotal?: any;
  isDropperTotalLoading?: any;
  setDropperTotalDateFilter?: any;
  setIsClearDropperTotalFilter?: any;
  walletBalance?: any;
  isWalletBalanceLoading?: any;
  setWalletBalanceDateFilter?: any;
  setIsClearWalletBalanceFilter?: any;
  userRole?: any;
}

const FinancialMetricsCard = (props: IMetricsProps) => {
  const {
    deliveryTotal,
    isDeliveryLoading,
    getLastNDaysFormatted,
    setIsClearDeliveryTotalFilter,
    setDeliveryDateFilter,
    serviceCharge,
    isServiceChargeLoading,
    setIsClearServiceChargeFilter,
    setServiceChargeDateFilter,
    supermarketTotal,
    isSupermarketLoading,
    setIsClearSupermarketFilter,
    setSupermarketDateFilter,
    dropperTotal,
    isDropperTotalLoading,
    setIsClearDropperTotalFilter,
    setDropperTotalDateFilter,
    walletBalance,
    isWalletBalanceLoading,
    setIsClearWalletBalanceFilter,
    setWalletBalanceDateFilter,
  } = props;
  const Navigate = useNavigate();
  const [deliveryFilter, setDeliveryFilter] = useState("");
  const [deliveryFilterClicked, setDeliveryFilterClicked] = useState(0);
  const [deliveryDropdown, setDeliveryDropdown] = useState(false);
  const [serviceChargeFilter, setServiceChargeFilter] = useState("");
  const [serviceChargeFilterClicked, setServiceChargeFilterClicked] =
    useState(0);
  const [serviceChargeDropdown, setServiceChargeDropdown] = useState(false);
  const [serviceChargeFilter2, setSupermarketFilter] = useState("");
  const [supermarketFilterClicked, setSupermarketFilterClicked] = useState(0);
  const [supermarketDropdown, setSupermarketDropdown] = useState(false);
  const [dropperTotalFilter, setDropperTotalFilter] = useState("");
  const [dropperTotalFilterClicked, setDropperTotalFilterClicked] = useState(0);
  const [dropperTotalDropdown, setDropperTotalDropdown] = useState(false);
  const [walletBalanceFilter, setWalletBalanceFilter] = useState("");
  const [walletBalanceFilterClicked, setWalletBalanceFilterClicked] =
    useState(0);
  const [walletBalanceDropdown, setWalletBalanceDropdown] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [customPopup, setCustomPopup] = useState(false);

  function getStartAndEndDate() {
    const currentDate = new Date();

    // Start date: First day of the current month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const formattedStartDate = formatDate(startOfMonth);

    // End date: Tomorrow's date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedEndDate = formatDate(tomorrow);

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  }

  function getStartAndEndDateOfWeek() {
    const currentDate = new Date();
  
    // Start date: First day of the current week (Monday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));
    const formattedStartDate = formatDate(startOfWeek);
  
    // End date: Tomorrow's date
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedEndDate = formatDate(tomorrow);
  
    return { startDate: formattedStartDate, endDate: formattedEndDate };
  }

  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const filterMap = [
    {
      name: "Today",
      value: "Today",
      dateRange: getLastNDaysFormatted(1),
    },
    {
      name: "This Week",
      value: "This Week",
      dateRange: getStartAndEndDateOfWeek(),
    },
    {
      name: "This Month",
      value: "This Month",
      dateRange: getStartAndEndDate(),
    },
    {
      name: "All Time",
      value: "All Time",
      dateRange: "clearFilter",
    },
  ];

  const handleClicked = (
    index: any,
    item: any,
    dateRange: any,
    filterClicked: any,
    setFilterClicked: any,
    setFilter: any,
    setDropdown: any,
    setIsClearFilter: any,
    setDateFilter: any
  ) => {
    if (filterClicked === index) {
      setFilterClicked(1);
      setFilter(item[0]);
      dateRange === "clearFilter"
        ? setIsClearFilter(true)
        : setIsClearFilter(false);
      setDateFilter(dateRange[0]);
      setDropdown(false);
    }
    setFilterClicked(index);
    setFilter(item);
    dateRange === "clearFilter"
      ? setIsClearFilter(true)
      : setIsClearFilter(false);
    setDateFilter(dateRange);
    setDropdown(false);
  };

  const handleClick = (path: any) => {
    Navigate(path);
  };
  //
  const handleDropperTotal = (data: any) => {
    const totalSum = data?.reduce(
      (acc: any, obj: any) => acc + parseInt(obj.total),
      0
    );
    return handlePrice(totalSum);
  };

  // const overviewCardDetails = [
  //   {
  //     icon: "",
  //     description: "Delivery Total",
  //     details: `${totalOrders} orders`,
  //     className: "fa-solid fa-people-group",
  //     path: "/orders/",
  //     isLoading: isServiceChargeLoading,
  //     filter: true,
  //   },
  //   {
  //     icon: "",
  //     description: "Service Charge",
  //     details: `${totalProducts} products`,
  //     className: "fa-solid fa-people-group",
  //     path: "/products/",
  //     isLoading: isProductLoading,
  //     filter: true,
  //   },
  //   {
  //     icon: "",
  //     description: "Supermarket Total",
  //     details: `${totalProducts} products`,
  //     className: "fa-solid fa-people-group",
  //     path: "/products/",
  //     isLoading: isProductLoading,
  //     filter: true,
  //   },
  //   {
  //     icon: "",
  //     description: "Dropper Total",
  //     details: `${totalProducts} products`,
  //     className: "fa-solid fa-people-group",
  //     path: "/products/",
  //     isLoading: isProductLoading,
  //     filter: true,
  //   },
  //   {
  //     icon: "",
  //     description: "Total Wallet Balance",
  //     details: `${totalProducts} products`,
  //     className: "fa-solid fa-people-group",
  //     path: "/products/",
  //     isLoading: isProductLoading,
  //     filter: true,
  //   },
  // ];

  const [secDropdown, setSecDropdown] = useState(false);

  return (
    <>
      <div className="cards">
        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            <div
              className="custom-date-filter"
              style={{
                fontSize: "12px",
                padding: "4px 8px",
                borderRadius: "4px",
                color: "white",
                backgroundColor: "#FD5C1D",
                cursor: "pointer",
                marginRight: "-100px",
              }}
              onClick={() => {
                setSelectedCard("DELIVERY");
                setCustomPopup(true);
              }}
            >
              Custom
            </div>
            <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setDeliveryDropdown(!deliveryDropdown)}
              >
                <h6>{deliveryFilter === "" ? "today" : deliveryFilter}</h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {deliveryDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          deliveryFilterClicked,
                          setDeliveryFilterClicked,
                          setDeliveryFilter,
                          setDeliveryDropdown,
                          setIsClearDeliveryTotalFilter,
                          setDeliveryDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* END */}
          </div>
          {isDeliveryLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Delivery Total</p>
              <p className="detail">{handlePrice(deliveryTotal)}</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            <div
              className="custom-date-filter"
              style={{
                fontSize: "12px",
                padding: "4px 8px",
                borderRadius: "4px",
                color: "white",
                backgroundColor: "#FD5C1D",
                cursor: "pointer",
                marginRight: "-100px",
              }}
              onClick={() => {
                setSelectedCard("SERVICE_CHARGE");
                setCustomPopup(true);
              }}
            >
              Custom
            </div>
            <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setServiceChargeDropdown(!serviceChargeDropdown)}
              >
                <h6>
                  {serviceChargeFilter === "" ? "today" : serviceChargeFilter}
                </h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {serviceChargeDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          serviceChargeFilterClicked,
                          setServiceChargeFilterClicked,
                          setServiceChargeFilter,
                          setServiceChargeDropdown,
                          setIsClearServiceChargeFilter,
                          setServiceChargeDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* END */}
          </div>
          {isServiceChargeLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Service Charge</p>
              <p className="detail">{handlePrice(serviceCharge)}</p>
            </div>
          )}
        </div>

        {/* <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            START
            <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setSupermarketDropdown(!supermarketDropdown)}
              >
                <h6>
                  {serviceChargeFilter2 === "" ? "today" : serviceChargeFilter2}
                </h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {supermarketDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          supermarketFilterClicked,
                          setSupermarketFilterClicked,
                          setSupermarketFilter,
                          setSupermarketDropdown,
                          setIsClearSupermarketFilter,
                          setSupermarketDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div>

            END
          </div>
          {isSupermarketLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Supermarket Total</p>
              <p className="detail">{handlePrice(supermarketTotal)}</p>
            </div>
          )}
        </div> */}

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            {/* <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setDropperTotalDropdown(!dropperTotalDropdown)}
              >
                <h6>
                  {dropperTotalFilter === "" ? "today" : dropperTotalFilter}
                </h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {dropperTotalDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          dropperTotalFilterClicked,
                          setDropperTotalFilterClicked,
                          setDropperTotalFilter,
                          setDropperTotalDropdown,
                          setIsClearDropperTotalFilter,
                          setDropperTotalDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {/* END */}
          </div>
          {isDropperTotalLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Dropper Total</p>
              <p className="detail">{handleDropperTotal(dropperTotal)}</p>
            </div>
          )}
        </div>

        <div className="card">
          <div className="head">
            <i className="fa-solid fa-people-group"></i>
            {/* START */}
            {/* <div className="filter-wrapper">
              <div
                className="dropdown"
                onClick={() => setWalletBalanceDropdown(!walletBalanceDropdown)}
              >
                <h6>
                  {walletBalanceFilter === "" ? "today" : walletBalanceFilter}
                </h6>
                <img
                  className="arrow-down"
                  src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004166/internal-dashboard/arrow_qev5ch.png"
                  alt="arrow"
                />
              </div>
              {walletBalanceDropdown && (
                <div className="dropdown-option">
                  {filterMap.map((item, index) => (
                    <div
                      className="dropdown-list"
                      key={index}
                      onClick={() =>
                        handleClicked(
                          index,
                          item.value,
                          item.dateRange,
                          walletBalanceFilterClicked,
                          setWalletBalanceFilterClicked,
                          setWalletBalanceFilter,
                          setWalletBalanceDropdown,
                          setIsClearWalletBalanceFilter,
                          setWalletBalanceDateFilter
                        )
                      }
                    >
                      <h6>{item.name}</h6>
                    </div>
                  ))}
                </div>
              )}
            </div> */}

            {/* END */}
          </div>
          {isWalletBalanceLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="details">
              <p className="description">Total Wallet Balance</p>
              <p className="detail">{handlePrice(walletBalance)}</p>
            </div>
          )}
        </div>
      </div>
      <p
        className="type2 bold"
        style={{ marginTop: "30px", marginBottom: "-8px" }}
      >
        Supermarket Total
      </p>
      <div className="cards operation">
        <div className="card operation">
          <div className="head">
            <p className="type2 bold">Supermarket</p>
            <p className="type2 bold">Total Revenue</p>
          </div>
          {isSupermarketLoading ? (
            <div
              className="details"
              style={{
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardLoader />
            </div>
          ) : (
            <div className="operation-details">
              {supermarketTotal?.map((product: any, index: any) => (
                <div className="details" key={index}>
                  <p className="description">{product?.branchName}</p>
                  <p className="detail" style={{ fontSize: "14px" }}>
                    {handlePrice(product?.total)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {customPopup && (
        <CustomPopup
          setPopup={setCustomPopup}
          setDateFilter={
            selectedCard === "DELIVERY"
              ? setDeliveryDateFilter
              : setServiceChargeDateFilter
          }
        />
      )}
    </>
  );
};

export default FinancialMetricsCard;
