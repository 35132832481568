import Restaurant from "../components/dashboard/restaurant";
import Layout from "../components/ui/layout";

const RestaurantPage = () => {
  return (
    <Layout>
      <Restaurant />
    </Layout>
  );
};

export default RestaurantPage;
