import { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { navbarDetails } from "../../../data/Data";
import { getUser } from "../../../storage";
import "./Layout.scss";
import LayoutSidebar from "./LayoutSidebar";
import LayoutHeader from "./LayoutHeader";

const Layout = (props: { children: ReactNode }) => {
  const { children } = props;
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [notification, setNotification] = useState(12);

  useEffect(() => {
    if (screenWidth < 1024) {
      setIsSidebarExpanded(false);
    } else {
      setIsSidebarExpanded(true);
    }
  }, []);

  // const { setSearchPage } = useContext(searchContext);
  const [elem, setElem] = useState("");
  const [firstLetter, setFirstLetter] = useState("");
  const [lastLetter, setLastLetter] = useState("");
  const [userImage, setUserImage] = useState("");

  const handleSearch = (e: any) => {
    e.preventDefault();
    // setSearchPage(elem);
  };

  //USER INITIAL ----------------------

  useEffect(() => {
    const user = getUser();
    const firstName = user.firstName;
    const lastName = user.lastName;
    const image = user.photo;
    setFirstLetter(firstName.slice(0, 1));
    setLastLetter(lastName.slice(0, 1));
    setUserImage(image);
  }, []);

  return (
    <div className="dashboard-wrapper">
      <LayoutSidebar
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
      />
      <LayoutHeader
        isSidebarExpanded={isSidebarExpanded}
        setIsSidebarExpanded={setIsSidebarExpanded}
        children={children}
        firstLetter={firstLetter}
        lastLetter={lastLetter}
        userImage={userImage}
      />
    </div>
  );
};

export default Layout;
