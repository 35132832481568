import GlobalSettings from "../components/dashboard/settings/settingsBody/globalSettings";
import Layout from "../components/ui/layout";
import SettingLayout from "../components/ui/settingLayout/SettingLayout";

const GlobalSettingsPage = () => {
  return (
    <Layout>
      {/* <SettingLayout> */}
        <GlobalSettings />
      {/* </SettingLayout> */}
    </Layout>
  );
};

export default GlobalSettingsPage;
