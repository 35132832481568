import { useForm } from "react-hook-form";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";

interface ModalProps {
  setPopup: any;
  setDateFilter?: any;
  isEdit?: boolean;
  selectedId?: string;
  categoryId?: any;
}

const CustomPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setPopup, isEdit, setDateFilter } = props;

  const onSubmit = (data: any) => {
    setDateFilter({ startDate: data.startDate, endDate: data.endDate });
    setPopup(false);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Select date range" : "Select date range"}
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Start Date"
              name="startDate"
              type="date"
              required
              placeholder="Select Start Date"
            />
            <Input
              register={register}
              label="End Date"
              name="endDate"
              type="date"
              required
              placeholder="Select End Date"
            />
            <Button
              className="add-button"
              text={isEdit ? "Filter" : "Filter"}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomPopup;
