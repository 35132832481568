import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
// import "./AddCategoryPopup.scss";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { getToken } from "../../../../storage";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import { ADD_PRODUCT_FIELD, UNIT_ENUM } from "./enum";
import Select from "../../../ui/select";
import Checkbox from "../../../ui/checkbox";
import {
  defaultOption,
  getCategories,
  getErrorMessage,
  getStoreBranch,
  getStoreImages,
  getStores,
  getSubCategories,
} from "../../../../helper";
import Alert from "../../../ui/alert";
import FileInput from "../../../ui/fileInput";
import CardLoader from "../../../ui/cardLoader";
import { option } from "../../../../data/Data";
import API from "../../../../utils/api/API";
import Search from "../../../ui/search";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  getProducts: Function;
  data?: any;
  isEdit?: boolean;
  isSubCat?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AddProductPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const selectedOption = watch("isBestSeller");
  const selectedStoreBranch = watch("storeBranchId");
  const isPictureSelected = watch("picture");

  const { setPopup, isEdit, isSubCat } = props;
  let { data } = props;

  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState();
  const [stores, setStores] = useState([]);
  const [isStoresLoading, setIsStoresLoading] = useState(true);
  const [subCategories, setSubCategories] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [isSubCategoriesLoading, setIsSubCategoriesLoading] = useState(true);
  const [productId, setProductId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [storeId, setStoreId] = useState();
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [storeImages, setStoreImages] = useState();
  const [isStoreImagesLoading, setIsStoreImagesLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getStoreBranch(selectedStoreBranch, setStoreId, setIsStoreLoading);
  }, [selectedStoreBranch]);

  useEffect(() => {
    if (storeId !== undefined) {
      getStoreImages(storeId, setStoreImages, setIsStoreImagesLoading);
    }
  }, [storeId]);

  useEffect(() => {
    getStores(setStores, setIsStoresLoading);
  }, []);
  useEffect(() => {
    getSubCategories(
      setSubCategories,
      setIsSubCategoriesLoading,
      selectedStoreBranch
    );
  }, [selectedStoreBranch]);

  const imageFilter = {
    storeId: storeId,
  };

  const [productDetails, setProductDetails] = useState([{ name: "" }]);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);

  const getProducts = async () => {
    setProductDetails([]);
    setProductStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.products,
      // params: userRole !== "STORE_ADMIN" ? params : storeParams,
      params: { search: searchText, isAdmin: true },
    })
      .then((resp) => {
        setProductStatus(SUCCESS);
        const formattedList = resp.data.data.products.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              name: data.name,
              category: data.category?.name,
              categoryId: data.category?.id,
              picture: data.picture,
              dropperPrice: data.dropperPrice,
              price: data.price,
              status: data.status,
              store: data.storeBranch?.name,
              storeBranchId: data.storeBranch?.id,
              description: data.description,
              metaKeywords: data.metaKeywords,
              dropStockOnPurchase: data.dropStockOnPurchase,
              excerpt: data.excerpt,
              hasCoupon: data.hasCoupon,
              hasPromo: data.hasPromo,
              priceDiscount: data.priceDiscount,
              sku: data.sku,
              slug: data.slug,
              stock: data.stock,
              m_unit: data.m_unit,
              size: data.size,
              isBestSeller: data.isBestSeller,
              bestSellingScore: data.bestSellingScore,
              categoryScore: data.categoryScore,
            };
          }
        );
        setIsSearchAvailable(true);
        setProductDetails(formattedList);
        formattedList?.length > 0
          ? setProductStatus(DATAMODE)
          : setProductStatus(NULLMODE);
      })
      .catch((err) => {
        setProductStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  const uploadImage = async (files: any) => {
    setIsStoreImagesLoading(true);
    setMessage("");
    const image = files;
    var formData = new FormData();
    if (image && image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        const file = image[i];
        formData.append("files", file); // Append the file and its filename (file.name) to the FormData
      }
    }
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploaMultipleFile,
      data: formData,
      params: imageFilter,
    })
      .then((resp) => {
        setImageFile(resp.data?.data?.media[0].url);
        setIsStoreImagesLoading(false);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message))
        setIsStoreImagesLoading(false);
      });
  };

  const upload = (file: any) => {
    uploadImage(file);
    setImageURL(file);
  };

  useEffect(() => {
    if (searchText !== "") {
      getProducts();
    }
  }, [searchText]);

  const addProduct = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.price = data.price * 100;
    imageFile.length > 1 && (data.picture = imageFile);
    data.categoryId === undefined &&
      (data.categoryId = isSubCat
        ? prefillData?.category?.id
        : prefillData?.categoryId);
    data.picture === "" ||
      (data.picture === undefined &&
        (data.picture = prefillData?.picture));
    data.storeBranchId === undefined &&
      (data.storeBranchId = isSubCat
        ? prefillData?.storeBranch?.id
        : prefillData?.storeBranchId);
    data.m_unit === undefined && (data.m_unit = prefillData?.m_unit);

    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.products,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setPopup(false)
        const { id } = resp.data;
        setProductId(id);
        reset();
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message))
      });
  };

  const updateProduct = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    imageFile.length > 1 && (request.picture = imageFile);
    const productData = request;
    productData.price = request.price * 100;
    productData.categoryId === undefined &&
      (productData.categoryId = isSubCat
        ? data?.category?.id
        : data.categoryId);
    productData.picture === "" ||
      (productData.picture === undefined &&
        (productData.picture = data.picture));
    productData.storeBranchId === undefined &&
      (productData.storeBranchId = isSubCat
        ? data?.storeBranch.id
        : data.storeBranchId);
    productData.m_unit === undefined && (productData.m_unit = data.m_unit);

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateProduct(
        data?.id,
        isSubCat ? data?.storeBranch?.id : data?.storeBranchId
      ),
      data: productData,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // setPopup(false)
        // setImagePopup(true);
        // getProducts();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    // if (imageFile?.length > 0) {
    isEdit ? updateProduct(data) : addProduct(data);
    // } else {
    // setMessage("An image is required");
    // }
  };

  const [prefillData, setPrefillData] = useState<any>();
  const [isAbstractProduct, setIsAbstractProduct] = useState(false);

  useEffect(() => {
    if (isEdit || isAbstractProduct) {
      const {
        name,
        sku,
        parentId,
        productImage,
        exerpt,
        metaKeywords,
        description,
        thumbnail,
        categoryId,
        storeBranchId,
        brandId,
        price,
        priceDiscount,
        stock,
        hasPromo,
        hasCoupon,
        outOfStock,
        dropStockOnPurchase,
        isHeavy,
        size,
        isBestSeller,
        bestSellingScore,
        categoryScore,
      } = (isEdit ? data : prefillData) || {};

      setValue("name", name);
      setValue("sku", sku);
      setValue("parentId", parentId);
      setValue("exerpt", exerpt);
      setValue("metaKeywords", metaKeywords);
      setValue("description", description);
      setValue("thumbnail", thumbnail);
      // setValue("categoryId", { value: categoryId, label: categoryName });
      setValue("storeBranchId", storeBranchId);
      setValue("brandId", brandId);
      setValue("price", price / 100);
      setValue("priceDiscount", priceDiscount);
      setValue("stock", stock);
      setValue("hasPromo", hasPromo);
      setValue("hasCoupon", hasCoupon);
      setValue("outOfStock", outOfStock);
      setValue("isBestSeller", isBestSeller);
      setValue("isHeavy", isHeavy);
      setValue("bestSellingScore", bestSellingScore);
      setValue("categoryScore", categoryScore);
      setValue("size", size);
    }
  }, [data, isEdit, isAbstractProduct, prefillData]);

  const refresh = () => {
    window.location.reload();
  };

  const handlePrefillProduct = (item: any) => {
    setIsAbstractProduct(true);
    setPrefillData(item);
    setIsSearchAvailable(false);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Product" : "Add Product"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Product"
                : "Enter the following details to add a Product"
            }
          />

          {!isEdit && (
            <div className="search-box" style={{ marginBottom: "12px" }}>
              <Search
                setValue={setSearchText}
                placeholder="Search for existing product..."
                // setIsClearFilter={setIsAbstractProduct}
              />
            </div>
          )}
          {isSearchAvailable && !isEdit && (
            <div
              className="searched-products"
              style={{
                borderRadius: "8px",
                border: "0.5px solid rgba(26, 27, 26, 0.2)",
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {productStatus === DATAMODE ? (
                productDetails.map((item, index) => (
                  <p
                    className="product"
                    key={index}
                    onClick={() => handlePrefillProduct(item)}
                    style={{
                      cursor: "pointer",
                      padding: "8px",
                    }}
                  >
                    {item?.name}
                  </p>
                ))
              ) : (
                <p
                  className="product"
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  No product match your search parameter
                </p>
              )}
            </div>
          )}

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            {selectedStoreBranch?.length > 1 &&
              isPictureSelected === undefined && (
                <div className="avatar-container">
                  <div className="avatar">
                    <img
                      src={
                        imageURL
                          ? (window.URL ? URL : webkitURL).createObjectURL(
                              imageURL[0]
                            )
                          : isEdit && data?.picture?.length > 0
                          ? data?.picture
                          : isAbstractProduct &&
                            prefillData?.picture?.length > 0
                          ? prefillData?.picture
                          : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                      }
                      alt=""
                    />
                  </div>
                  <FileInput
                    labelText="Tap to upload the product Image"
                    onChange={upload}
                    multiple
                  />
                </div>
              )}

            {ADD_PRODUCT_FIELD.map((field, index) => (
              <Input
                register={register}
                label={field.label}
                name={field.name}
                required={field.required}
                placeholder={field.placeholder}
                type={field.type}
                valueAsNumber={field.valueAsNumber}
                key={index}
              />
            ))}
            <Controller
              name="storeBranchId"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={stores}
                  label="Select Store Branch"
                  value={
                    isEdit
                      ? defaultOption(
                          stores,
                          isSubCat ? data?.storeBranch?.id : data?.storeBranchId
                        )
                      : isAbstractProduct
                      ? defaultOption(
                          stores,
                          isSubCat
                            ? prefillData?.storeBranch?.id
                            : prefillData?.storeBranchId
                        )
                      : ""
                  }
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isStoresLoading}
                />
              )}
            />
            {selectedStoreBranch?.length > 1 && imageFile.length < 1 && (
              <Controller
                name="picture"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    options={storeImages}
                    label="Select Product Picture"
                    // value={
                    //   storeImages !== null && isEdit
                    //     ? defaultOption(storeImages, data?.picture)
                    //     : ""
                    // }
                    onChange={(val: any) => onChange(val.value)}
                    disabled={isStoreImagesLoading}
                  />
                )}
              />
            )}
            {selectedStoreBranch?.length > 1 && (
              <Controller
                name="categoryId"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    options={subCategories}
                    label="Select Sub-Category"
                    value={
                      isEdit &&
                      defaultOption(
                        subCategories,
                        isSubCat ? data?.category.id : data?.categoryId
                      )
                    }
                    onChange={(val: any) => onChange(val.value)}
                    disabled={isSubCategoriesLoading}
                  />
                )}
              />
            )}

            <Controller
              name="m_unit"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={UNIT_ENUM}
                  value={
                    isEdit
                      ? defaultOption(UNIT_ENUM, data?.m_unit)
                      : isAbstractProduct
                      ? defaultOption(UNIT_ENUM, prefillData?.m_unit)
                      : ""
                  }
                  label="Select Unit"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            />

            <Input
              register={register}
              label="Size"
              name="size"
              // required
              placeholder="Enter Size"
              type="number"
              step="0.0001"
              valueAsNumber
            />
            <Input
              register={register}
              label="Category Score"
              name="categoryScore"
              // required
              placeholder="Enter Category Score"
              type="number"
              valueAsNumber
            />
            <Checkbox
              register={register}
              label="Best Seller?"
              name="isBestSeller"
            />
            {selectedOption === true && (
              <Input
                register={register}
                label="Best Selling Score"
                name="bestSellingScore"
                required
                placeholder="Enter Best Selling Score"
                type="number"
                valueAsNumber
              />
            )}
            <Checkbox register={register} label="Is Heavy?" name="isHeavy" />
            {/* <Checkbox
              register={register}
              label="Has Coupon?"
              name="hasCoupon"
            /> */}
            <Button
              className="add-button"
              text={isEdit ? "Update Product" : "Add Product"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        // <AddProductImagePopup id={productId}/>
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a product to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Product"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {(isStoresLoading ||
        isStoreImagesLoading ||
        productStatus === LOADING) && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddProductPopup;
