export const ADD_GLOBAL_FIELD = [
  {
    name: "name",
    type: "text",
    label: "Name",
    required: false,
    valueAsNumber: false,
    placeholder: "Enter Name",
  },
  {
    name: "code",
    type: "text",
    label: "Code",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Code",
  },
  {
    name: "value",
    type: "text",
    label: "Value",
    required: false,
    valueAsNumber: false,
    placeholder: "Enter Value",
  },
];
