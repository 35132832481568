import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../ui";
import Heading from "../../../ui/heading";
import "./AddVehiclePopup.scss";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../ui/alert";
import Select from "../../../ui/select";
import { defaultOption, getDropper, getErrorMessage } from "../../../../helper";
import CardLoader from "../../../ui/cardLoader";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  getRiders: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddVehiclePopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();
  const { setPopup, getRiders, data, isEdit, selectedId } = props;

  const [isDropperLoading, setIsDropperLoading] = useState(false);
  const [dropper, setDropper] = useState([]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  useEffect(() => {
    getDropper(setDropper, setIsDropperLoading);
  }, []);

  const addVehicle = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.vehicles,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const updateVehicle = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getVehicle(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateVehicle(data) : addVehicle(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { plateNumber, model, maker } = data || {};
      setValue("plateNumber", plateNumber);
      setValue("model", model);
      setValue("maker", maker);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Vehicle" : "Add Vehicle"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Vehicle"
                : "Enter the following details to add a Vehicle"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Plate Number"
              name="plateNumber"
              required
              placeholder="Enter Plate Number"
            />
            <Input
              register={register}
              label="Vehicle Model"
              name="model"
              required
              placeholder="Enter Vehicle Model"
            />
            <Input
              register={register}
              label="Vehicle Maker"
              name="maker"
              required
              placeholder="Enter Vehicle Maker"
            />
            <Controller
              name="dropperId"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={dropper}
                  value={isEdit ? defaultOption(dropper, data.dropperId) : ""}
                  label="Assign a Dropper"
                  onChange={(val: any) => onChange(val.value)}
                  disabled={isDropperLoading}
                />
              )}
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Vehicle" : "Add Vehicle"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a Vehicle to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Vehicle"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {isDropperLoading && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddVehiclePopup;
