import { useEffect, useState } from "react";
import { getErrorMessage, getStores } from "../../../helper";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import ProductsTable from "../../ui/table/productTable.tsx/ProductTable";
import AddProductPopup from "./addProductPopup/AddProductPopup";
import API from "../../../utils/api/API";
import { useNavigate } from "react-router-dom";
import ProductFilter from "./ProductFilter";
import Search from "../../ui/search";
import AddMultipleProductImagePopup from "./addProductPopup/AddMultipleProductImagePopup";

const PageHeading = () => {
  return (
    <Heading
      title="Products"
      subTitle="See all your products and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, NULLMODE, DATAMODE, ERROR } = dataQueryStatus;

const Products = () => {
  const [productDetails, setProductDetails] = useState([]);
  const [popup, setPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [userRole, setUserRole] = useState("");
  const [storeBranchId, setStoreBranchId] = useState("");

  const [filterClicked, setFilterClicked] = useState("");
  const [isClearFilter, setIsClearFilter] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      storeBranchId: filterClicked,
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const storeBranchId: string = user.storeBranchId;
      const role: string = user.role;
      setUserRole(role);
      setStoreBranchId(storeBranchId);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setPopup(true);
  };

  const updateStatus = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        storeBranchId: storeBranchId,
      },
    }));
  };

  useEffect(() => {
    updateStatus();
  }, [storeBranchId]);

  const storeParams = {
    limit: filter.limit,
    page: filter.page,
    "filter.storeBranch.id": filter.filter.storeBranchId,
  };

  const params = isClearFilter
    ? { limit: filter.limit, page: filter.page, isAdmin: true }
    : {
        limit: filter.limit,
        page: filter.page,
        search: searchText,
        isAdmin: true,
        "filter.storeBranch.id": filter.filter.storeBranchId,
      };

  const getProducts = async () => {
    setProductDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.products,
      // params: userRole !== "STORE_ADMIN" ? params : storeParams,
      params: params,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data.data.products.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data.id,
              name: data.name,
              category: data.category?.name,
              categoryId: data.category?.id,
              picture: data.picture,
              dropperPrice: data.dropperPrice,
              price: data.price,
              status: data.status,
              store: data.storeBranch?.name,
              storeBranchId: data.storeBranch?.id,
              description: data.description,
              metaKeywords: data.metaKeywords,
              dropStockOnPurchase: data.dropStockOnPurchase,
              excerpt: data.excerpt,
              hasCoupon: data.hasCoupon,
              hasPromo: data.hasPromo,
              priceDiscount: data.priceDiscount,
              sku: data.sku,
              slug: data.slug,
              stock: data.stock,
              m_unit: data.m_unit,
              size: data.size,
              isBestSeller: data.isBestSeller,
              isHeavy: data.isHeavy,
              bestSellingScore: data.bestSellingScore,
              categoryScore: data.categoryScore,
            };
          }
        );
        setProductDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  const deleteProduct = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getProduct(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getProducts();
      })
      .catch((err) => {
        setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getProducts();
  }, [filter, isClearFilter, searchText]);
  const [multipleImagePopup, setMultipleImagePopup] = useState(false);
  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className="header-cta" style={{ display: "flex", gap: "12px" }}>
          <Button
            text="Add Multiple Image"
            className="customer-add"
            invertStyle
            onClick={() => {
              setMultipleImagePopup(true);
            }}
          />
          <Button
            text="Add Product"
            className="customer-add"
            onClick={() => {
              setIsEdit(false);
              setPopup(true);
            }}
          />
        </div>
      </div>
      <div style={{ maxWidth:"500px", width: "100%", marginTop:"24px" }}>
        <Search setValue={setSearchText} setIsClearFilter={setIsClearFilter} />
      </div>
      <div className="table-wrapper">
        {status !== LOADING && (
          <ProductFilter
            filterClicked={storeBranchId}
            setFilterClicked={setStoreBranchId}
            setIsClearFilter={setIsClearFilter}
          />
        )}
        <ProductsTable
          clickable
          data={productDetails}
          emptyHeight="530px"
          // arrange={overviewDropFilter}
          isCardLoading={status === LOADING ? true : false}
          isCardError={status === ERROR ? true : false}
          dataNull={status === NULLMODE ? true : false}
          message={message}
          deleteProduct={deleteProduct}
          handleEditModal={handleEditModal}
          storeAdmin={userRole === "STORE_ADMIN" ? true : false}
        />
      </div>
      {status !== LOADING && productDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {popup && (
        <AddProductPopup
          getProducts={getProducts}
          setPopup={setPopup}
          data={selectedProduct}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
      {multipleImagePopup && (
        <AddMultipleProductImagePopup setPopup={setMultipleImagePopup} />
      )}
    </>
  );
};

export default Products;
