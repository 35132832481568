import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import Heading from "../../ui/heading";
import Paginate from "../../ui/paginate";
import CategoryTable from "../../ui/table/categoryTable/CategoryTable";
import AddCategoryPopup from "./addCategoryPopup/AddCategoryPopup";
import API from "../../../utils/api/API";
import { useNavigate } from "react-router-dom";
import { getErrorMessage, handlePrice } from "../../../helper";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import DeleteIcon from "../../../assets/delete.svg";
import ActivateIcon from "../../../assets/activate.svg";
import CardLoader from "../../ui/cardLoader";
import ErrorView from "../../ui/ErrorView";
import EmptyView from "../../ui/emptyView";
import ActivateDeactivatePopup from "./ActivateDeactivatePopup";
import DeletePopup from "./DeletePopup";

const PageHeading = () => {
  return (
    <Heading
      title="Drop Food Categories"
      subTitle="See all your category and their details here"
    />
  );
};

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const DropFoodCategory = () => {
  const [popup, setPopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [categoriesDetails, setCategoriesDetails] = useState([]);

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedId, setSelectedId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const handleActivateDeactivateModal = (data: any) => {
    setSelectedCategory(data);
    setActivatePopup(true);
  };

  const handleDeleteModal = (data: any) => {
    setSelectedCategory(data);
    setDeletePopup(true);
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setPopup(true);
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setPopup(true);
  };

  const getCategories = async () => {
    setCategoriesDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.restaurantCategory,
      params: {
        ...filter,
      },
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data?.data.meta.totalItems);
        const formattedList = resp.data?.data.categories.map(
          (data: any, index: any) => {
            return {
              number: index + 1,
              id: data?.id,
              name: data?.name,
              description: data?.description,
              status: data?.status,
              picture: data?.picture,
              info: data?.info,
              delivery_time: data?.delivery_time,
              backgrounColor: data?.backgrounColor,
              borderColor: data?.borderColor,
              pos: data?.pos,
              happyHour: data?.happyHour,
            };
          }
        );
        setCategoriesDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        // setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const deleteCategory = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getRestaurantCategory(id),
    })
      .then((resp) => {
        // setStatus(SUCCESS);
        getCategories();
      })
      .catch((err) => {
        // setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    getCategories();
  }, [filter]);
  return (
    <>
      <div className="page-header">
        <PageHeading />
        <Button
          text="Add Category"
          className="customer-add"
          onClick={handleAddModal}
        />
      </div>
      {status === LOADING ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("category") && "active"
                }`}
                onClick={() => navigate("/category/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-cat") && "active"
                }`}
                onClick={() => navigate("/drop-food-cat/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardLoader />
          </div>
        </>
      ) : status === ERROR ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("category") && "active"
                }`}
                onClick={() => navigate("/category/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-cat") && "active"
                }`}
                onClick={() => navigate("/drop-food-cat/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorView />
          </div>
        </>
      ) : status === NULLMODE ? (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("category") && "active"
                }`}
                onClick={() => navigate("/category/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-cat") && "active"
                }`}
                onClick={() => navigate("/drop-food-cat/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <EmptyView />
          </div>
        </>
      ) : (
        <>
          <div className="page-wrapper">
            <div className="filter-wrapper dropper-shopper">
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("category") && "active"
                }`}
                onClick={() => navigate("/category/")}
              >
                <p>Groceries</p>
              </div>
              <div
                className={`ongoing-filter ${
                  window.location.href.includes("drop-food-cat") && "active"
                }`}
                onClick={() => navigate("/drop-food-cat/")}
              >
                <p>DropFood</p>
              </div>
            </div>
          </div>
          <div className="table-details" style={{ marginTop: "24px" }}>
            <div className="table-details-body">
              <div className="details-table-header" style={{ width: "100%" }}>
                <p style={{ width: "5%", fontSize: "12px" }}>Image</p>
                <p style={{ width: "30%", fontSize: "12px" }}>Category Name</p>
                <p style={{ width: "25%", fontSize: "12px" }}>Description</p>
                <p style={{ width: "15%", fontSize: "12px" }}>Position</p>
                <p style={{ width: "20%", fontSize: "12px" }}>Status</p>
                <p style={{ width: "5%" }}></p>
              </div>
              {categoriesDetails.map((item: any, index: any) => (
                <div key={index}>
                  <div className="details-table-body" style={{ width: "100%" }}>
                    <div
                      style={{
                        width: "5%",
                      }}
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          // marginRight:"24px"
                        }}
                        onClick={() => navigate(`${item?.id}`)}
                      >
                        <img
                          src={item?.picture}
                          alt={item.name}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <p
                      style={{ width: "30%", fontSize: "12px" }}
                      onClick={() => navigate(`${item?.id}`)}
                    >
                      {item?.name}
                    </p>
                    <p
                      style={{ width: "25%", fontSize: "12px" }}
                      onClick={() => navigate(`${item?.id}`)}
                    >
                      {item?.description}
                    </p>
                    <p
                      style={{ width: "15%", fontSize: "12px" }}
                      onClick={() => navigate(`${item?.id}`)}
                    >
                      {item?.pos}
                    </p>
                    <p
                      style={{ width: "20%", fontSize: "12px" }}
                      onClick={() => navigate(`${item?.id}`)}
                    >
                      {item?.status}
                    </p>
                    <Popover
                      className="flex-1 text-right"
                      style={{ width: "5%" }}
                    >
                      <>
                        <Popover.Button
                          className="popover-button"
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                          }}
                        >
                          <img src={MoreIcon} alt="" />
                        </Popover.Button>

                        <Popover.Panel
                          className="popover-panel"
                          style={{
                            position: "absolute",
                            right: "64px",
                            zIndex: "500",
                            // padding: "14px",
                            width: "150px",
                            background: "white",
                            borderRadius: "16px",
                            boxShadow: "5px 5px #00000030",
                            border: "0.5px solid #00000030",
                          }}
                        >
                          <div
                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            style={{ borderRadius: "16px" }}
                          >
                            <div
                              className="items-center grid gap-4 bg-white px-5 py-4"
                              style={{
                                alignItems: "center",
                                background: "white",
                                padding: "16px, 20px",
                              }}
                            >
                              <div
                                onClick={() => navigate(`${item?.id}`)}
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                              >
                                <img src={ViewIcon} alt="" />
                                <p className="pl-2 text-[#B00020]">View</p>
                              </div>
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditModal(item)}
                              >
                                <img src={EditIcon} alt="" />
                                <p className="pl-2 text-[16px]">Edit</p>
                              </div>
                              {item?.status === "ACTIVE" ? (
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleActivateDeactivateModal(item)
                                  }
                                >
                                  <img src={ActivateIcon} alt="" />
                                  <p
                                    className="pl-2 text-[16px]"
                                    style={{ color: "red" }}
                                  >
                                    Deactivate
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleActivateDeactivateModal(item)
                                  }
                                >
                                  <img src={ActivateIcon} alt="" />
                                  <p className="pl-2 text-[16px]">Activate</p>
                                </div>
                              )}
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "8px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => handleDeleteModal(item)}
                              >
                                <img src={DeleteIcon} alt="" />
                                <p className="pl-2 text-[16px]">Delete</p>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </>
                    </Popover>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {popup && (
        <AddCategoryPopup
          setPopup={setPopup}
          getCategories={getCategories}
          data={selectedCategory}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
      {activatePopup && (
        <ActivateDeactivatePopup
          setPopup={setActivatePopup}
          getCategories={getCategories}
          data={selectedCategory}
          selectedId={selectedId}
        />
      )}
      {deletePopup && (
        <DeletePopup
          setPopup={setDeletePopup}
          getCategories={getCategories}
          data={selectedCategory}
          selectedId={selectedId}
        />
      )}
      {status !== LOADING && categoriesDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
    </>
  );
};

export default DropFoodCategory;
