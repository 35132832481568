import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../../../ui";
import Heading from "../../../../../ui/heading";
import { dataQueryStatus } from "../../../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../../../utils/api";
import SuccessfulModal from "../../../../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../../../../ui/alert";
import FileInput from "../../../../../ui/fileInput";
import CardLoader from "../../../../../ui/cardLoader";
import API from "../../../../../../utils/api/API";
import { toast } from "react-toastify";
import { defaultOption, getErrorMessage } from "../../../../../../helper";
import Select from "../../../../../ui/select";

interface ModalProps {
  setPopup: any;
  getBanners: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddBannerPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const { setPopup, getBanners, data, isEdit, selectedId } = props;

  const [imageFile, setImageFile] = useState("");
  const [BannerId, setBannerId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState();

  const addBanner = async (data: any) => {
    setStatus(LOADING);
    data.imageUrl = image;
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.banners,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const updateBanner = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.imageUrl = image;
    (request.imageUrl === "" || request.imageUrl === undefined) &&
      (request.imageUrl = data.imageUrl);

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getBanner(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getBanners();
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateBanner(data) : addBanner(data);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, imageUrl } = data || {};
      setValue("name", name);
      setValue("imageUrl", imageUrl);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(resp.data.data.url);
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(getErrorMessage(err.response.data.message));
        setImageStatus(ERROR);
      });
  };

  const upload = (files: any) => {
    uploadFile(files);
    setImageUrl(files);
  };

  const pathOption = [
    {
      label: "Drop Food",
      value: "DROP_FOOD",
    },
    {
      label: "Groceries",
      value: "GROCERIES",
    },
  ];

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Banner" : "Add Banner"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Banner"
                : "Enter the following details to add a Banner"
            }
          />

          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageUrl
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageUrl[0]
                      )
                    : isEdit && data?.imageUrl?.length > 0
                    ? data?.imageUrl
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload banner image"
              onChange={upload}
              // isUploading={isUploading}
            />
          </div>

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              register={register}
              label="Enter banner name"
              name="name"
              required
              placeholder="Banner Name"
              type="text"
            />
            <Controller
              name="path"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={pathOption}
                  label="Select Path"
                  value={isEdit ? defaultOption(pathOption, data.path) : ""}
                  onChange={(val: any) => onChange(val.value)}
                  // disabled={isStoresLoading}
                  required
                />
              )}
            />
            <Button
              className="add-button"
              text={isEdit ? "Update Banner" : "Add Banner"}
              isLoading={status === "LOADING" ? true : false}
              disabled={(imageStatus === LOADING || imageUrl === "") && true}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a Banner to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Banner"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {imageStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddBannerPopup;
