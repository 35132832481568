import { useEffect, useState } from "react";
import "./OrderInformation.scss";

interface OrderSingleProps {
  building?: string;
  floor?: string;
  deliveryInstruction?: string;
  street?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerPhone?: string;
  lat?: string;
  lng?: string;
}

const RecieverInfo = (props: OrderSingleProps) => {
  const {
    building,
    customerEmail,
    customerFirstName,
    customerLastName,
    customerPhone,
    deliveryInstruction,
    floor,
    street,
    lat,
    lng,
  } = props;
  const [userRole, setUserRole] = useState<string>();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const customerInformation = [
    {
      description: "Customer Name",
      value: `${customerFirstName + " " + customerLastName}`,
    },
    {
      description: "Customer Contact",
      value: `${customerPhone}`,
    },
    {
      description: "Customer Email",
      value: `${customerEmail}`,
    },
    {
      description: "Customer Street",
      value: `${street}`,
    },
    {
      description: "Customer Building",
      value: `${building}`,
    },
    {
      description: "Customer Floor",
      value: `${floor}`,
    },
    {
      description: "Delivery Instruction",
      value: `${deliveryInstruction}`,
    },
  ];

  const dropperCustomerInformation = [
    {
      description: "Customer Name",
      value: `${customerFirstName + " " + customerLastName}`,
    },
    {
      description: "Customer Contact",
      value: `${customerPhone}`,
    },
    {
      description: "Customer Street",
      value: `${street}`,
    },
    {
      description: "Customer Building",
      value: `${building}`,
    },
    {
      description: "Customer Floor",
      value: `${floor}`,
    },
    {
      description: "Delivery Instruction",
      value: `${deliveryInstruction}`,
    },
  ];

  return (
    <div className="order-information">
      <div className="store-branch-details dropper-order-details">
        {(userRole === "DROPPER"
          ? dropperCustomerInformation
          : customerInformation
        ).map((info: any, index) => (
          <div
            className={
              info.imageSRC
                ? "store-branch-detail images"
                : "store-branch-detail dropper-order-detail"
            }
            key={index}
          >
            {info.imageSRC && (
              <img className="image" src={info.imageSRC} alt="image" />
            )}
            <div className="description">
              <p className="type3">{info.description}</p>
              <h5
                style={{
                  fontWeight: "bold",
                  margin: "10px 0 0",
                  color: `${
                    info.description === "Customer Name" ? "#7435B2" : "#222222"
                  }`,
                }}
              >
                {info.value}
              </h5>
            </div>
          </div>
        ))}
        <div className={"store-branch-detail mobile-hidden"}>
          <div className="description">
            <p className="type3">Map Location</p>
            <a
              style={{ fontWeight: "bold", margin: "10px 0 0" }}
              href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
              target="_blank"
            >
              {`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
            </a>
          </div>
        </div>
      </div>
      <div className="page-action-bar">
        <div className="action-description">
          <p>Click here to see the location on the map</p>
        </div>
        <div className="action-bar">
          <button
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                "_blank"
              )
            }
          >
            View Location
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecieverInfo;
