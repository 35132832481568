import StoreSingle from '../components/dashboard/storeSingle'
import Layout from '../components/ui/layout'

const StoreSinglePage = () => {
  return (
    <>
    <Layout>
        <StoreSingle />
    </Layout>
    </>
  )
}

export default StoreSinglePage