import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Dropdown, Input } from "../../ui";
import FileInput from "../../ui/fileInput";
import Heading from "../../ui/heading";
import PlaceAutoComplete from "../../ui/placeAutocomplete/PlaceAutoComplete";
import TelephoneInput from "../../ui/telephoneInput";
import "./AddRider.scss";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getToken } from "../../../storage";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import Alert from "../../ui/alert";
import Select from "../../ui/select";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import API from "../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../helper";
// import AddServiceAreaModal from "../../ui/modal/addServiceAreaModal/AddServiceAreaModal";

const { IDLE, LOADING, ERROR, SUCCESS } = dataQueryStatus;

const AddRider = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [imageURL, setImageURL] = useState();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [dropperId, setDropperId] = useState();

  const navigate = useNavigate();

  const addRider = async (data: any) => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.POST,
      // url: apiUrls?.register,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setDropperId(resp.data.id);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(getErrorMessage(err.response.data.message)));
        setStatus(ERROR);
        setAlert(true);
      });
  };

  const onSubmit = (data: any) => addRider(data);

  return (
    <>
      <div className="order-details">
        <span className="go-back" onClick={() => navigate(-1)}>
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
            alt="arrow-back"
            className="arrow"
          />
          <h6>Go back</h6>
        </span>
        <div className="details-container">
          <Heading
            className="popup-header"
            title="Add Rider"
            subTitle="Enter the following details to add a rider"
          />
          <div className="information-fields">
            <div className="section-description topmost">
              <p className="description-text">Riders Information</p>
              <hr className="horizonatal-line" />
            </div>
            <div className="avatar-container">
              <div className="avatar">
                <img
                  src={
                    imageURL
                      ? (window.URL ? URL : webkitURL).createObjectURL(
                          imageURL[0]
                        )
                      : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                  }
                  alt=""
                />
              </div>
              <FileInput
                labelText="Tap to upload profile picture "
                onChange={setImageURL}
              />
            </div>
            <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
              <Input
                placeholder="Enter First Name"
                label="First Name"
                name="firstName"
                register={register}
                required
              />
              <Input
                placeholder="Enter Last Name"
                label="Last Name"
                name="lastName"
                register={register}
                required
              />
              <Input
                placeholder="Enter Email Address"
                label="Email Address"
                name="email"
                register={register}
                required
              />
              <TelephoneInput
                placeholder="Enter Phone Number"
                label="Phone Number"
                name="phoneNumber"
                register={register}
                required
              />
              <Button
                className="add-button"
                text="Add Rider"
                onClick={handleSubmit(onSubmit)}
                isLoading={status === LOADING && true}
              />
            </form>
          </div>
        </div>
      </div>
      {/* {alert && (
        <Alert
          title="Error Adding Droppers"
          text={alertMessage}
          timeOut={3000}
          alertOut={setAlert}
        />
      )} */}
      {/* {successful && <AddServiceAreaModal dropperId={dropperId} />} */}
    </>
  );
};

export default AddRider;
