import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import CardLoader from "../../../../ui/cardLoader";
import SuccessfulModal from "../../../../ui/modal/successfulModal/SuccessfulModal";
import { Button, Input } from "../../../../ui";
import FileInput from "../../../../ui/fileInput";
import Heading from "../../../../ui/heading";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { getErrorMessage, handlePrice } from "../../../../../helper";
import Search from "../../../../ui/search";

interface ModalProps {
  setPopup: any;
  getPackages: Function;
  data?: any;
  isEdit?: boolean;
  isSubCat?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const AddPackage = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const { setPopup, isEdit, isSubCat } = props;
  let { data } = props;

  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState<any>([]);
  const [stores, setStores] = useState([]);
  const [subCategories, setSubCategories] = useState([
    {
      value: "",
      label: "",
    },
  ]);
  const [productId, setProductId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [storeId, setStoreId] = useState();
  const [isStoreLoading, setIsStoreLoading] = useState(false);
  const [storeImages, setStoreImages] = useState();
  const [isStoreImagesLoading, setIsStoreImagesLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const imageFilter = {
    storeId: storeId,
  };

  const [productDetails, setProductDetails] = useState([{ name: "" }]);
  const [isSearchAvailable, setIsSearchAvailable] = useState(false);
  const [productStatus, setProductStatus] = useState(IDLE);

  const [isPackageImageLoading, setIsPackageImageLoading] = useState(false);
  const [media, setMedia] = useState<any>();
  const [productList, setProductList] = useState<any>(
    isEdit
      ? data?.products?.map((item: any) => {
          return { name: item?.name, quantity: item?.quantity, id: item?.id };
        })
      : []
  );

  const getProducts = async () => {
    setProductDetails([]);
    setProductStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.gsaProduct,
      // params: userRole !== "STORE_ADMIN" ? params : storeParams,
      params: { name: searchText },
    })
      .then((resp) => {
        setProductStatus(SUCCESS);
        const formattedList = resp.data?.content?.products.map(
          (data: any, index: any) => {
            return {
              id: data.id,
              name: data.name,
              description: data.description,
            };
          }
        );
        setIsSearchAvailable(true);
        setProductDetails(formattedList);
        formattedList?.length > 0
          ? setProductStatus(DATAMODE)
          : setProductStatus(NULLMODE);
      })
      .catch((err) => {
        setProductStatus(ERROR);
        // setAlert(true);
        // setMessage(err.response.message);
      });
  };

  useEffect(() => {
    if (searchText !== "") {
      getProducts();
    }
  }, [searchText]);

  const uploadImage = async (files: any) => {
    setIsPackageImageLoading(true);
    setMessage("");
    const image = files;
    var formData = new FormData();
    if (image && image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        const file = image[i];
        formData.append("images", file); // Append the file and its filename (file.name) to the FormData
      }
    }
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaUploadFile,
      data: formData,
    })
      .then((resp) => {
        setMedia(resp.data?.content);
        setIsPackageImageLoading(false);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        setIsPackageImageLoading(false);
      });
  };

  const upload = (file: any) => {
    uploadImage(file);
    setImageURL(file);
  };

  const addPackage = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.priceInMinor = data.priceInMinor * 100;
    const payload = { ...data, media: media };
    API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.gsaPackages,
      data: payload,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
      });
  };

  const updateProduct = (request: any) => {
    setStatus(LOADING);
    setMessage("");
    request.priceInMinor = request.priceInMinor * 100;
    imageFile.length > 1
      ? (request.media = media)
      : (request.media = data?.media);

    API({
      method: apiQueryMethods?.PUT,
      url: apiUrls?.getGsaPackages(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const updateProductList = (product: any) => {
    // Check if the product is already in the productList
    const isProductInList = productList?.some(
      (item: any) => item.id === product.id || item._id === product.id
    );

    if (!isProductInList) {
      // If the product is not in the list, add it
      setProductList((prevArray: any) => [...prevArray, product]);
    } else {
      // If the product is already in the list, you can handle it here (e.g., show a message)
      toast.error("Product is already in the package items");
    }
  };

  const handleRemoveDetail = (item: any) => {
    // Remove the product from the list
    setProductList((prevArray: any) =>
      prevArray.filter(
        (detail: any) => detail.id !== item?.id || detail._id !== item?._id
      )
    );

    // Reset the form state for the deleted product
    // reset({ [`quantity_${item?.productId}_${dropperPrice}`]: 0 });
    setValue(`quantity_${item?.id}`, 0);
  };

  const convertQuantityObjectsToArray = (obj: any) => {
    const quantityKeys = Object.keys(obj).filter((key) =>
      key.startsWith("quantity_")
    );

    const quantityArray = quantityKeys.reduce((acc: any, key) => {
      const productId = key.replace("quantity_", ""); // Extract productId from key
      const quantity = obj[key];

      if (quantity !== 0) {
        acc.push({ productId, quantity }); // Add to the array only if quantity is not 0
      }

      return acc;
    }, []);

    const updatedObj = { ...obj };

    quantityKeys.forEach((key) => {
      delete updatedObj[key]; // Remove original quantity keys from the object
    });

    updatedObj.products = quantityArray; // Create a new 'quantities' key in the object

    return updatedObj;
  };

  const onSubmit = (data: any) => {
    const payload = convertQuantityObjectsToArray(data);
    isEdit ? updateProduct(payload) : addPackage(payload);
  };

  useEffect(() => {
    if (isEdit) {
      const { name, description, priceInMinor, slots, availableSlots } =
        data || {};

      setValue("name", name);
      setValue("description", description);
      setValue("priceInMinor", priceInMinor / 100);
      setValue("slots", slots);
      setValue("availableSlots", availableSlots);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  const imageUrlSource =
    imageURL && imageURL[0] instanceof File
      ? URL.createObjectURL(imageURL[0])
      : "";

  return (
    <>
      <div className="popup widest">
        <div
          className="popup-container"
          style={{ width: "100%", maxWidth: "750px" }}
        >
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Package" : "Add Package"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Package"
                : "Enter the following details to add a Package"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="avatar-container">
              <div className="avatar">
                <img
                  src={
                    imageUrlSource
                      ? imageUrlSource
                      : isEdit && data?.media[0].path?.length > 0
                      ? data?.media[0].path
                      : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                  }
                  alt=""
                />
              </div>
              <FileInput
                labelText="Tap to upload the product Images"
                onChange={upload}
                multiple
              />
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "24px",
                marginBottom: "40px",
              }}
            >
              <Input
                register={register}
                label="Name"
                name="name"
                required
                placeholder="Enter Name"
                type="text"
              />
              <Input
                register={register}
                label="Description"
                name="description"
                required
                placeholder="Enter Description"
                type="text"
              />
              <Input
                register={register}
                label="Price"
                name="priceInMinor"
                required
                placeholder="Enter Price"
                type="number"
                valueAsNumber
              />
              <Input
                register={register}
                label="Slots"
                name="slots"
                required
                placeholder="Enter Slots"
                type="number"
                valueAsNumber
              />
              {/* <Input
                register={register}
                label="Available Slots"
                name="availableSlots"
                required
                placeholder="Enter Available Slots"
                type="number"
                valueAsNumber
              /> */}
            </div>
            {productList?.length > 0 && (
              <div
                className="form-heading"
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "24px",
                  fontSize: "14px",
                }}
              >
                <div style={{ width: "60%" }}>Product Name</div>
                <div style={{ width: "20%" }}>Quantity</div>
                <div style={{ width: "20%" }}>Action</div>
              </div>
            )}
            {productList?.map((item: any, index: any) => (
              <div
                className="product-details"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "24px",
                  width: "100%",
                }}
              >
                <div className="product-name" style={{ width: "60%" }}>
                  {item?.name}
                </div>
                <div
                  className="input"
                  style={{ width: "20%", marginTop: "-20px" }}
                >
                  <Input
                    register={register}
                    type="number"
                    name={`quantity_${item?._id ? item?._id : item?.id}`} // Use a unique name based on the product's identifier
                    required
                    placeholder="Enter Quantity"
                    valueAsNumber
                    // defaultValue={1}
                    defaultValue={item?.quantity ? item?.quantity : 1}
                  />
                </div>
                <div className="delete-product" style={{ width: "20%" }}>
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => handleRemoveDetail(item)}
                  />
                </div>
              </div>
            ))}
          </form>
          <div
            className="search-box"
            style={{ marginBottom: "12px", marginTop: "40px" }}
          >
            <p style={{ fontSize: "16px", marginBottom: "12px" }}>
              Add more Product
            </p>
            <Search
              setValue={setSearchText}
              placeholder="Search for existing product..."
              // setIsClearFilter={setIsAbstractProduct}
            />
          </div>
          {isSearchAvailable && searchText !== "" && (
            <div
              className="searched-products"
              style={{
                borderRadius: "8px",
                border: "0.5px solid rgba(26, 27, 26, 0.2)",
                padding: "4px",
                display: "flex",
                flexDirection: "column",
                marginBottom: "24px",
              }}
            >
              {productStatus === DATAMODE ? (
                productDetails.map((item, index) => (
                  <p
                    className="product"
                    key={index}
                    onClick={() => updateProductList({ ...item, quantity: 1 })}
                    style={{
                      cursor: "pointer",
                      padding: "8px",
                    }}
                  >
                    {item?.name}
                  </p>
                ))
              ) : (
                <p
                  className="product"
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  No product match your search parameter
                </p>
              )}
            </div>
          )}
          <Button
            className="add-button"
            text={isEdit ? "Update Package" : "Add Package"}
            isLoading={status === "LOADING" ? true : false}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      {status === SUCCESS && (
        // <AddProductImagePopup id={productId}/>
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a package to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Package"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
      {productStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default AddPackage;
