import { useEffect, useState } from "react";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import Heading from "../../ui/heading";
import { useNavigate } from "react-router-dom";
import API from "../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getErrorMessage, handlePrice } from "../../../helper";
import Paginate from "../../ui/paginate";
import CaretRight from "../../../assets/caret-right.svg";
import CardLoader from "../../ui/cardLoader";
import { confirmAlert } from "react-confirm-alert";
import { Popover } from "@headlessui/react";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import DeleteIcon from "../../../assets/delete.svg";
import ActivateIcon from "../../../assets/activate.svg";
import ErrorView from "../../ui/ErrorView";
import EmptyView from "../../ui/emptyView";
import { Button } from "../../ui";
import AddRestaurantPopup from "./addRestaurantPopup/AddRestaurantPopup";
import AddRestaurantBranchPopup from "./addRestaurantPopup/AddRestaurantBranchPopup";
import ActivateDeactivatePopup from "./ActivateDeactivatePopup";
import DeletePopup from "./DeletePopup";

const { IDLE, LOADING, DATAMODE, NULLMODE, SUCCESS, ERROR } = dataQueryStatus;

const PageHeading = () => {
  return (
    <Heading
      title="Restaurants"
      subTitle="See all your restaurant and their details here"
    />
  );
};
const Restaurant = () => {
  const [restaurantDetails, setRestaurantDetails] = useState([]);
  const [filterClicked, setFilterClicked] = useState("DEPOSIT");
  const [walletStatus, setWalletStatus] = useState(IDLE);
  const [wallet, setWallet] = useState("");
  const [isBranch, setIsBranch] = useState(false);

  const [clickedIndex, setClickedIndex] = useState(
    restaurantDetails.length + 10
  );

  const toggle = (index: any) => {
    if (clickedIndex === index) {
      setClickedIndex(restaurantDetails.length + 1);
    } else {
      setClickedIndex(index);
    }
  };

  const [userRole, setUserRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  useEffect(() => {
    if (userRole === "STORE_ADMIN") {
      navigate("/");
    }
  }, [userRole]);

  const [alert, setAlert] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isBranchEdit, setIsBranchEdit] = useState(false);
  const [addRestaurantPopup, setAddRestaurantPopup] = useState(false);
  const [selectedRestaurant, setSelectedRestaurant] = useState("");
  const [selectedRestaurantBranch, setSelectedRestaurantBranch] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const [addRestaurantBranchPopup, setAddRestaurantBranchPopup] =
    useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
    filter: {
      transactionType: filterClicked,
    },
  });

  const handleActivateDeactivateModal = (data: any, isBranch: boolean) => {
    setSelectedRestaurant(data);
    setActivatePopup(true);
    setIsBranch(isBranch);
  };

  const handleDeleteModal = (data: any, isBranch: boolean) => {
    setSelectedRestaurant(data);
    setDeletePopup(true);
    setIsBranch(isBranch);
  };

  const handleAddModal = () => {
    setIsEdit(false);
    setAddRestaurantPopup(true);
  };

  const handleAddBranchModal = (data: any) => {
    setIsBranchEdit(false);
    setSelectedRestaurant(data);
    setAddRestaurantBranchPopup(true);
  };

  const handleEditRestaurant = (data: any) => {
    setIsEdit(true);
    setSelectedRestaurant(data);
    setAddRestaurantPopup(true);
  };

  const handleEditRestaurantBranch = (data: any) => {
    setIsBranchEdit(true);
    setSelectedRestaurant(data);
    setAddRestaurantBranchPopup(true);
  };

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const updateTransactionType = () => {
    updateFilter((prevFilter) => ({
      ...prevFilter,
      filter: {
        ...prevFilter.filter,
        transactionType: filterClicked,
      },
    }));
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    isRestaurant : true,
  };

  const getRestaurant = async () => {
    setRestaurantDetails([]);
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.store,
      params: params,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const formattedList = resp.data?.data?.stores;
        setRestaurantDetails(formattedList);
        formattedList?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const getWalletBalance = async () => {
    setWalletStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls?.walletBalance,
      // params: walletBalanceParams,
    })
      .then((resp) => {
        setWalletStatus(SUCCESS);
        setWallet(resp.data.data[0].total);
      })
      .catch((err) => {
        setWalletStatus(ERROR);
      });
  };

  useEffect(() => {
    getWalletBalance();
  }, []);

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    updateTransactionType();
  }, [filterClicked]);

  useEffect(() => {
    getRestaurant();
  }, [filter]);

  const shortId = (id: any) => {
    if (id.length > 15) {
      id = id.substring(0, 15) + "...";
    }
    return id;
  };

  return (
    <>
      <div className="page-header">
        <PageHeading />
        <div className="header-cta" style={{ display: "flex", gap: "12px" }}>
          {/* <Button
          text="Add Restaurant"
          className="customer-add"
          invertStyle
          // onClick={handleAddModal}
        /> */}
          <Button
            text="Add Restaurant"
            className="customer-add"
            onClick={handleAddModal}
          />
        </div>
      </div>
      {status === LOADING ? (
        <div
          className="details"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardLoader />
        </div>
      ) : (
        <div className="table-details" style={{ marginTop: "24px" }}>
          <div className="table-details-body">
            <div className="details-table-header" style={{ width: "100%" }}>
              <p style={{ width: "2%" }}></p>
              <p style={{ width: "5%", fontSize: "12px" }}>Image</p>
              <p style={{ width: "30%", fontSize: "12px" }}>Restaurant Name</p>
              <p style={{ width: "38%", fontSize: "12px" }}>
                Restaurant Category
              </p>
              <p style={{ width: "20%", fontSize: "12px" }}>Status</p>
              <p style={{ width: "5%", fontSize: "12px" }}></p>
            </div>
            {restaurantDetails.map(
              (item: any, index: any) =>
                item?.restaurantCategory !== null && (
                  <div key={index}>
                    <div
                      className="details-table-body"
                      style={{ width: "100%" }}
                    >
                      <div
                        style={{
                          width: "2%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          transform: `${
                            clickedIndex === index
                              ? "rotate(90deg)"
                              : "rotate(0deg)"
                          }`,
                        }}
                        onClick={() => toggle(index)}
                      >
                        <img src={CaretRight} alt="caret-right" />
                      </div>
                      <div
                        style={{
                          width: "5%",
                        }}
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            backgroundColor: "black",
                          }}
                        >
                          <img
                            src={item?.logo}
                            alt={item?.name}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                      <p style={{ width: "30%", fontSize: "12px" }}>
                        {item?.name}
                      </p>
                      <p style={{ width: "38%", fontSize: "12px" }}>
                        {item?.restaurantCategory?.name}
                      </p>
                      <p style={{ width: "20%", fontSize: "12px" }}>
                        {item?.status}
                      </p>
                      <Popover
                        className="flex-1 text-right"
                        style={{ width: "5%" }}
                      >
                        <>
                          <Popover.Button
                            className="popover-button"
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                            }}
                          >
                            <img src={MoreIcon} alt="" />
                          </Popover.Button>

                          <Popover.Panel
                            className="popover-panel"
                            style={{
                              position: "absolute",
                              right: "64px",
                              zIndex: "50",
                              // padding: "14px",
                              width: "150px",
                              background: "white",
                              borderRadius: "16px",
                              boxShadow: "5px 5px #00000030",
                              border: "0.5px solid #00000030",
                            }}
                          >
                            <div
                              className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                              style={{ borderRadius: "16px" }}
                            >
                              <div
                                className="items-center grid gap-4 bg-white px-5 py-4"
                                style={{
                                  alignItems: "center",
                                  background: "white",
                                  padding: "16px, 20px",
                                }}
                              >
                                {/* <div
                              onClick={() => navigate(`${item?.id}`)}
                              className="flex items-center"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 14px",
                                gap: "8px",
                                cursor: "pointer",
                              }}
                            >
                              <img src={ViewIcon} alt="" />
                              <p className="pl-2 text-[#B00020]">View</p>
                            </div> */}
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleAddBranchModal(item)}
                                >
                                  <img src={EditIcon} alt="" />
                                  <p className="pl-2 text-[16px]">Add Branch</p>
                                </div>
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleEditRestaurant(item)}
                                >
                                  <img src={EditIcon} alt="" />
                                  <p className="pl-2 text-[16px]">Edit</p>
                                </div>
                                {/* {item?.status === "ACTIVE" ? (
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleActivateDeactivateModal(item, false)
                                }
                              >
                                <img src={ActivateIcon} alt="" />
                                <p
                                  className="pl-2 text-[16px]"
                                  style={{ color: "red" }}
                                >
                                  Suspend
                                </p>
                              </div>
                            ) : (
                              <div
                                className="flex items-center"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 14px",
                                  gap: "4px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleActivateDeactivateModal(item, false)
                                }
                              >
                                <img src={ActivateIcon} alt="" />
                                <p className="pl-2 text-[16px]">Activate</p>
                              </div>
                            )} */}
                                <div
                                  className="flex items-center"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 14px",
                                    gap: "8px",
                                    cursor: "pointer",
                                    color: "red",
                                  }}
                                  onClick={() => handleDeleteModal(item, false)}
                                >
                                  <img src={DeleteIcon} alt="" />
                                  <p className="pl-2 text-[16px]">Delete</p>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </>
                      </Popover>
                    </div>
                    {clickedIndex === index &&
                      (item?.branches?.length === 0 ? (
                        <div
                          style={{
                            padding: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No branch yet
                        </div>
                      ) : (
                        <div style={{ padding: "24px" }}>
                          <div className="table-details">
                            <div className="table-details-body">
                              <div
                                className="details-table-header"
                                style={{ width: "100%" }}
                              >
                                <p style={{ width: "35%" }}>Branch Name</p>
                                <p style={{ width: "40%" }}>Branch Address</p>
                                <p style={{ width: "20%" }}>Status</p>
                                <p style={{ width: "5%" }}></p>
                              </div>
                              {item?.branches?.map(
                                (subItem: any, index: any) => (
                                  <div
                                    key={index}
                                    className="details-table-body"
                                    style={{ width: "100%" }}
                                  >
                                    <p
                                      style={{ width: "35%" }}
                                      onClick={() => navigate(`${subItem?.id}`)}
                                    >
                                      {subItem?.name}
                                    </p>
                                    <p
                                      style={{ width: "40%" }}
                                      onClick={() => navigate(`${subItem?.id}`)}
                                    >
                                      {subItem?.address}
                                    </p>
                                    <p
                                      style={{ width: "20%" }}
                                      onClick={() => navigate(`${subItem?.id}`)}
                                    >
                                      {subItem?.status}
                                    </p>
                                    <Popover
                                      className="flex-1 text-right"
                                      style={{ width: "5%" }}
                                    >
                                      <>
                                        <Popover.Button
                                          className="popover-button"
                                          style={{
                                            cursor: "pointer",
                                            background: "transparent",
                                          }}
                                        >
                                          <img src={MoreIcon} alt="" />
                                        </Popover.Button>

                                        <Popover.Panel
                                          className="popover-panel"
                                          style={{
                                            position: "absolute",
                                            right: "64px",
                                            zIndex: "50",
                                            // padding: "14px",
                                            width: "150px",
                                            background: "white",
                                            borderRadius: "16px",
                                            boxShadow: "5px 5px #00000030",
                                            border: "0.5px solid #00000030",
                                          }}
                                        >
                                          <div
                                            className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                            style={{ borderRadius: "16px" }}
                                          >
                                            <div
                                              className="items-center grid gap-4 bg-white px-5 py-4"
                                              style={{
                                                alignItems: "center",
                                                background: "white",
                                                padding: "16px, 20px",
                                              }}
                                            >
                                              <div
                                                onClick={() =>
                                                  navigate(`${subItem?.id}`)
                                                }
                                                className="flex items-center"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  padding: "8px 14px",
                                                  gap: "8px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <img src={ViewIcon} alt="" />
                                                <p className="pl-2 text-[#B00020]">
                                                  View
                                                </p>
                                              </div>
                                              <div
                                                className="flex items-center"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  padding: "8px 14px",
                                                  gap: "8px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  handleEditRestaurantBranch(
                                                    subItem
                                                  )
                                                }
                                              >
                                                <img src={EditIcon} alt="" />
                                                <p className="pl-2 text-[16px]">
                                                  Edit
                                                </p>
                                              </div>
                                              {subItem?.status === "ACTIVE" ? (
                                                <div
                                                  className="flex items-center"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "8px 14px",
                                                    gap: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleActivateDeactivateModal(
                                                      subItem,
                                                      true
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={ActivateIcon}
                                                    alt=""
                                                  />
                                                  <p
                                                    className="pl-2 text-[16px]"
                                                    style={{ color: "red" }}
                                                  >
                                                    Suspend
                                                  </p>
                                                </div>
                                              ) : (
                                                <div
                                                  className="flex items-center"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "8px 14px",
                                                    gap: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleActivateDeactivateModal(
                                                      subItem,
                                                      true
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={ActivateIcon}
                                                    alt=""
                                                  />
                                                  <p className="pl-2 text-[16px]">
                                                    Activate
                                                  </p>
                                                </div>
                                              )}
                                              <div
                                                className="flex items-center"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  padding: "8px 14px",
                                                  gap: "8px",
                                                  cursor: "pointer",
                                                  color: "red",
                                                }}
                                                onClick={() =>
                                                  handleDeleteModal(subItem, true)
                                                }
                                              >
                                                <img src={DeleteIcon} alt="" />
                                                <p className="pl-2 text-[16px]">
                                                  Delete
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </Popover.Panel>
                                      </>
                                    </Popover>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )
            )}
          </div>
        </div>
      )}
      {status !== LOADING && restaurantDetails.length > 0 && (
        <Paginate
          totalItems={updateTotalItems()}
          pageSize={filter?.limit}
          currentPage={filter?.page}
          onResult={onResult}
        />
      )}
      {addRestaurantPopup && (
        <AddRestaurantPopup
          getStores={getRestaurant}
          setPopup={setAddRestaurantPopup}
          data={selectedRestaurant}
          isEdit={isEdit}
          selectedId={selectedId}
        />
      )}
      {addRestaurantBranchPopup && (
        <AddRestaurantBranchPopup
          getStores={getRestaurant}
          setPopup={setAddRestaurantBranchPopup}
          data={selectedRestaurant}
          isEdit={isBranchEdit}
          selectedId={selectedId}
        />
      )}
      {activatePopup && (
        <ActivateDeactivatePopup
          setPopup={setActivatePopup}
          getCategories={getRestaurant}
          data={selectedRestaurant}
          selectedId={selectedId}
          isBranch={isBranch}
        />
      )}
      {deletePopup && (
        <DeletePopup
          setPopup={setDeletePopup}
          getCategories={getRestaurant}
          data={selectedRestaurant}
          selectedId={selectedId}
          isBranch={isBranch}
        />
      )}
    </>
  );
};

export default Restaurant;
