import { useEffect, useState } from "react";
import "./ProductInformation.scss";
import { handlePrice } from "../../../../helper";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../ui/select";
import { Button } from "../../../ui";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { productStatusEnum } from "../../../../data/statusEnum";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import ErrorModal from "../../../ui/modal/errorModel/ErrorModal";
import API from "../../../../utils/api/API";
import ViewProductImagePopup from "./ViewProductImagePopup";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface IProductSingleProps {
  id?: any;
  createdAt?: any;
  description?: any;
  metaKeywords?: any;
  dropStockOnPurchase?: boolean;
  excerpt?: any;
  hasCoupon?: boolean;
  hasPromo?: boolean;
  isHeavy?: boolean;
  name?: any;
  picture?: any;
  dropperPrice?: any;
  price?: any;
  priceDiscount?: any;
  sku?: any;
  slug?: any;
  size?: any;
  m_unit?: any;
  status?: any;
  stock?: any;
  thumbnail?: any;
  storeBranchId: any;
}

const ProductInformation = (props: IProductSingleProps) => {
  const {
    createdAt,
    description,
    dropStockOnPurchase,
    excerpt,
    hasCoupon,
    hasPromo,
    isHeavy,
    id,
    metaKeywords,
    name,
    picture,
    dropperPrice,
    price,
    priceDiscount,
    size,
    m_unit,
    status,
    stock,
    thumbnail,
    storeBranchId,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm();

  const [isUserLoading, setIsUserLoading] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState(IDLE);
  const [statuss, setStatuss] = useState();
  const [message, setMessage] = useState("");
  const [userRole, setUserRole] = useState("");
  const [viewProductPopup, setviewProductPopup] = useState(false);

  useEffect(() => {
    const userJSON = localStorage.getItem("user");
    if (userJSON) {
      const user: any = JSON.parse(userJSON);
      const role: string = user.role;
      setUserRole(role);
    }
  }, []);

  const handleMarkedPrice = (price: any) => {
    const markedPrice = parseInt(price) + parseInt(price) * 0.05;
    return markedPrice.toFixed(2);
  };

  const handleDecimalPrice = (price: any) => {
    return parseInt(price).toFixed(2);
  };

  const updateStatus = (request: any) => {
    setStatusUpdate(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.updateProduct(id, storeBranchId),
      data: request,
    })
      .then((resp) => {
        setStatusUpdate(SUCCESS);
        // setPopup(false)
        // setImagePopup(true);
      })
      .catch((err) => {
        setMessage(err.response.data.error);
        setStatusUpdate(ERROR);
      });
  };

  const onSubmit = (data: any) => updateStatus(data);

  const productInformation = [
    {
      image: picture,
      description: "Product Name",
      value: name,
    },
    {
      description: "Product Description",
      value: description,
    },
    {
      description: "Meta Keywords",
      value: metaKeywords,
    },
    {
      description: "Store Price",
      value: `${handlePrice(price)}`,
    },
    {
      description: "Dropper Price",
      value: `${handlePrice(dropperPrice)}`,
    },
    {
      description: "Heavy Item?",
      value: `${isHeavy ? "Yes" : "No"}`,
    },
    {
      description: "Size",
      value: size + m_unit,
    },
    {
      description: "Status",
      value: status,
    },

    // {
    //   description: "Date Added",
    //   value: createdAt,
    // },

    // {
    //   description: "Has Coupon?",
    //   value: `${hasCoupon ? "True" : "False"}`,
    // },
    // {
    //   description: "Has Promo?",
    //   value: `${hasPromo ? "True" : "False"}`,
    // },

    // {
    //   description: "sku",
    //   value: sku,
    // },
    // {
    //   description: "slug",
    //   value: slug,
    // },
    // {
    //   description: "Excerpt",
    //   value: excerpt,
    // },
    // {
    //   description: "Product Quantity",
    //   value: stock,
    // },
    // {
    //   description: "Price Discount",
    //   value: priceDiscount,
    // },
    // {
    //   description: "Drop Stock On Purchase?",
    //   value: `${dropStockOnPurchase ? "True" : "False"}`,
    // },
  ];

  const storeProductInformation = [
    {
      image: picture,
      description: "Product Name",
      value: name,
    },
    {
      description: "Product Desc.",
      value: description,
    },
    {
      description: "Price",
      value: `₦${handlePrice(price)}`,
    },
    {
      description: "Status",
      value: status,
    },

    // {
    //   description: "Date Added",
    //   value: createdAt,
    // },
    {
      description: "Meta Keywords",
      value: metaKeywords,
    },
    {
      description: "Has Coupon?",
      value: `${hasCoupon ? "True" : "False"}`,
    },
    {
      description: "Has Promo?",
      value: `${hasPromo ? "True" : "False"}`,
    },
    {
      description: "is Heavy?",
      value: `${isHeavy ? "True" : "False"}`,
    },
    // {
    //   description: "sku",
    //   value: sku,
    // },
    // {
    //   description: "slug",
    //   value: slug,
    // },
    {
      description: "Excerpt",
      value: excerpt,
    },
    {
      description: "Product Quantity",
      value: stock,
    },
    {
      description: "Price Discount",
      value: priceDiscount,
    },
    {
      description: "Drop Stock On Purchase?",
      value: `${dropStockOnPurchase ? "True" : "False"}`,
    },
  ];

  const refresh = () => {
    window.location.reload();
  };

  const [productName, setProductName] = useState("");
  const [productImage, setProductImage] = useState("");

  const handleProductImagePopup = (data: any) => {
    setProductName(data?.value);
    setProductImage(data?.image);
    setviewProductPopup(true);
  };

  return (
    <>
      <div className="order-information">
        <div className="store-branch-details">
          {(userRole === "STORE_ADMIN"
            ? storeProductInformation
            : productInformation
          ).map((item: any, index) => (
            <div className="store-branch-detail" key={index}>
              {item.image ? (
                <div className="store-branch-image">
                  <img
                    src={item.image}
                    alt={item.className}
                    onClick={() => handleProductImagePopup(item)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="store-branch-info">
                    <p className="type3">{item.description}</p>
                    <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                      {item.value}
                    </h5>
                  </div>
                </div>
              ) : (
                <div className="store-branch-info">
                  <p className="type3">{item.description}</p>
                  <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                    {item.value}
                  </h5>
                </div>
              )}
            </div>
          ))}
          <div className="store-branch-detail">
            <div className="description">
              <p className="type3">Update Product Status</p>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Select
                      options={productStatusEnum}
                      onChange={(val: any) => {
                        onChange(val.value);
                        setStatuss(val.value);
                      }}
                    />
                  )}
                />
                <Button
                  className="add-button"
                  text="Update Status"
                  isLoading={statusUpdate === "LOADING" ? true : false}
                  disabled={statuss === undefined ? true : false}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      {statusUpdate === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully updated your product status."
          onClick={refresh}
        />
      )}
      {statusUpdate === ERROR && (
        <ErrorModal
          title="Error!"
          subTitle="There's an error updating this product status."
          onClick={refresh}
        />
      )}
      {viewProductPopup && (
        <ViewProductImagePopup
          setPopup={setviewProductPopup}
          name={productName}
          picture={productImage}
        />
      )}
    </>
  );
};

export default ProductInformation;
