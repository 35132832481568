import { useEffect, useState } from "react";
import { getUser } from "../../../../../storage";
import { Button, Input } from "../../../../ui";
import FileInput from "../../../../ui/fileInput";
import TelephoneInput from "../../../../ui/telephoneInput";
import "./Profile.scss";

const Profile = () => {
  const [imageURL, setImageURL] = useState();
  const [edit, setEdit] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    image: "https://res.cloudinary.com/dm19qay3n/image/upload/v1666003575/samples/people/jazz.jpg",
  });

  const handleEdit = (event: any) => {
    event.preventDefault();
    setEdit(!edit);
  };

  useEffect(() => {
    setUser(getUser());
  }, []);

  return (
    <div className="profile-section">
      <div className="avatar-container">
        <div className="avatar">
          <img
            src={
              // user.image !== null && user.image !== ""
              //   ? user.image
                 imageURL
                ? (window.URL ? URL : webkitURL).createObjectURL(imageURL[0])
                : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
            }
            alt="empty-profile"
          />
        </div>
        <div className="file-input">
          <h3>Profile Picture</h3>
          {/* <FileInput
            labelText="Tap to upload a profile picture"
            onChange={setImageURL}
          /> */}
        </div>
      </div>
      <form onSubmit={handleEdit}>
        <Input
          label="First Name"
          value={user.firstName}
          placeholder=" "
          disabled={!edit ? true : false}
        />
        <Input
          label="Last Name"
          value={user.lastName}
          placeholder=" "
          disabled={!edit ? true : false}
        />
        <Input
          label="Email Address"
          value={user.email}
          placeholder=" "
          disabled={!edit ? true : false}
          copy
          copyText
        />
        <TelephoneInput
          label="Phone Number"
          value={user.phoneNumber}
          disabled={!edit ? true : false}
          copy
          copyText
        />
        {/* <Button
          className="profile-button"
          text={!edit ? "Edit Details" : "Save Changes"}
        /> */}
      </form>
    </div>
  );
};

export default Profile;
