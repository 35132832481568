import Products from "../components/dashboard/products";
import Layout from "../components/ui/layout";

const ProductsPage = () => {
  return (
    <Layout>
      <Products />
    </Layout>
  );
};

export default ProductsPage;
