import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { Button } from "../../ui";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import { getErrorMessage, getMe, handlePrice } from "../../../helper";
import API from "../../../utils/api/API";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Popover } from "@headlessui/react";
import DeleteIcon from "../../../assets/delete.svg";
import MoreIcon from "../../../assets/more.svg";
import ViewIcon from "../../../assets/view.svg";
import EditIcon from "../../../assets/edit.svg";
import AddMenuItemPopup from "./addMenuItemPopup/AddMenuItemPopup";
import Layout from "../../ui/layout";
import Paginate from "../../ui/paginate";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const MenuCategorySingle = () => {
  const Navigate = useNavigate();

  const navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [menuCatPopup, setMenuCatPopup] = useState(false);
  const [user, setUser] = useState({
    role: "",
  });

  useEffect(() => {
    getMe(setUser, setIsUserLoading);
  }, []);

  useEffect(() => {
    if (user.role === "STORE_ADMIN") {
      navigate("/");
    }
  }, [user]);

  const { id } = useParams();

  const [status, setStatus] = useState(IDLE);
  const [itemData, setItemData] = useState<any>();
  const [categoryData, setCategoryData] = useState<any>();
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const location = useLocation();

  // Get the 'page' query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const currentPage = queryParams.get("page") || 1; // Default to page 1 if 'page' param is not present

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: any;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
    });
    handlePaginationClick(result.currentPage);
  };

  const handlePaginationClick = (pageNumber: any) => {
    // Update the URL query parameter when pagination button is clicked
    queryParams.set("page", pageNumber);
    const newUrl = `?${queryParams.toString()}`;
    // Use navigate to update the URL without refreshing the page
    window.location.search = newUrl;
  };

  const handleEditCategory = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setMenuCatPopup(true);
  };

  const getCategoryData = async () => {
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getMenuCategory(id),
    })
      .then((resp) => {
        const data = resp.data.data;
        setCategoryData(data);
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        // setMessage("Error getting store");
      });
  };

  const getCategorySingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.menuItem,
      params: {
        "filter.menuCategoryId": id,
        limit: filter.limit,
        page: currentPage,
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        setTotalItems(resp.data.data.meta.totalItems);
        const data = resp.data.data;
        setItemData(data);
        data ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage(getErrorMessage(err.response.data.message));
        // setMessage("Error getting store");
        setStatus(ERROR);
      });
  };

  const params = {
    "filter.menuCategory.id": id,
  };

  useEffect(() => {
    if (id) {
      getCategorySingleData();
      getCategoryData();
    }
  }, [id]);

  const deleteMenuItem = async (id: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getMenuItem(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        toast.success(resp.data?.message);
        getCategorySingleData();
      })
      .catch((err) => {
        setStatus(ERROR);
        // setAlert(true);
        toast.error(err.response?.data?.message);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no store branch presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="page-header">
              <div>
                <span className="go-back" onClick={() => Navigate(-1)}>
                  <img
                    src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                    alt="arrow-back"
                    className="arrow"
                  />
                  <h6>Go back</h6>
                </span>
                <h2>{categoryData?.name}</h2>
                <p className="type2" style={{ margin: "10px 0 0" }}>
                  Restaurant Branch: {categoryData?.branch?.name}
                </p>
              </div>
              <div className="header-buttons">
                <Button
                  text="Add Menu Item"
                  className="shop-add"
                  onClick={() => {
                    setIsEdit(false);
                    setMenuCatPopup(true);
                  }}
                />
              </div>
            </div>
            {itemData?.menuItems?.length > 0 && (
              <>
                {" "}
                <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                  Menu Item Details
                </h5>
                <div className="table-details" style={{ marginTop: "12px" }}>
                  <div className="table-details-body">
                    <div
                      className="details-table-header"
                      style={{ width: "100%" }}
                    >
                      <p style={{ width: "20%", fontSize: "12px" }}>Item Id</p>
                      <p style={{ width: "30%", fontSize: "12px" }}>
                        Item Name
                      </p>
                      <p style={{ width: "15%", fontSize: "12px" }}>
                        Item Price
                      </p>
                      <p style={{ width: "15%", fontSize: "12px" }}>
                        No of Selections
                      </p>
                      {/* <p style={{ width: "15%", fontSize: "12px" }}>Position</p> */}
                      <p style={{ width: "5%", fontSize: "12px" }}>Action</p>
                    </div>
                    {itemData?.menuItems?.length > 0 &&
                      itemData?.menuItems?.map((item: any, index: any) => (
                        <div key={index} style={{ cursor: "pointer" }}>
                          <div
                            className="details-table-body"
                            style={{ width: "100%" }}
                          >
                            <p
                              style={{ width: "20%", fontSize: "12px" }}
                              onClick={() =>
                                Navigate(`/menu-items/${item?.id}`)
                              }
                            >
                              {item?.id?.slice(0, 7) + "..."}
                            </p>
                            <p
                              style={{ width: "30%", fontSize: "12px" }}
                              onClick={() =>
                                Navigate(`/menu-items/${item?.id}`)
                              }
                            >
                              {item?.name}
                            </p>
                            <p
                              style={{ width: "15%", fontSize: "12px" }}
                              onClick={() =>
                                Navigate(`/menu-items/${item?.id}`)
                              }
                            >
                              {handlePrice(item?.price)}
                            </p>
                            <p
                              style={{ width: "15%", fontSize: "12px" }}
                              onClick={() =>
                                Navigate(`/menu-items/${item?.id}`)
                              }
                            >
                              {item?.selections?.length}
                            </p>
                            {/* <p
                              style={{ width: "15%", fontSize: "12px" }}
                              onClick={() =>
                                Navigate(`/menu-items/${item?.id}`)
                              }
                            >
                              {item?.pos}
                            </p> */}
                            <Popover
                              className="flex-1 text-right"
                              style={{ width: "5%", textAlign: "center" }}
                            >
                              <>
                                <Popover.Button
                                  className="popover-button"
                                  style={{
                                    cursor: "pointer",
                                    background: "transparent",
                                  }}
                                >
                                  <img src={MoreIcon} alt="" />
                                </Popover.Button>

                                <Popover.Panel
                                  className="popover-panel"
                                  style={{
                                    position: "absolute",
                                    right: "64px",
                                    zIndex: "500",
                                    // padding: "14px",
                                    width: "150px",
                                    background: "white",
                                    borderRadius: "16px",
                                    boxShadow: "5px 5px #00000030",
                                    border: "0.5px solid #00000030",
                                  }}
                                >
                                  <div
                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                    style={{ borderRadius: "16px" }}
                                  >
                                    <div
                                      className="items-center grid gap-4 bg-white px-5 py-4"
                                      style={{
                                        alignItems: "center",
                                        background: "white",
                                        padding: "16px, 20px",
                                      }}
                                    >
                                      <div
                                        onClick={() =>
                                          Navigate(`/menu-items/${item?.id}`)
                                        }
                                        className="flex items-center"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px 14px",
                                          gap: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={ViewIcon} alt="" />
                                        <p className="pl-2 text-[#B00020]">
                                          View Item
                                        </p>
                                      </div>
                                      <div
                                        onClick={() => handleEditCategory(item)}
                                        className="flex items-center"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px 14px",
                                          gap: "8px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img src={EditIcon} alt="" />
                                        <p className="pl-2 text-[#B00020]">
                                          Edit Item
                                        </p>
                                      </div>

                                      <div
                                        className="flex items-center"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "8px 14px",
                                          gap: "8px",
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                        onClick={() => {
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this menu item?",
                                            message:
                                              "This menu item's data will be erased and you will not be able to retrieve them",
                                            buttons: [
                                              {
                                                label: "Cancel",
                                                // Do Close operation
                                              },
                                              {
                                                label: "Delete",
                                                onClick: () =>
                                                  deleteMenuItem(item?.id),
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        <img src={DeleteIcon} alt="" />
                                        <p className="pl-2 text-[16px]">
                                          Delete Item
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </>
                            </Popover>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
            {/* <div className="order-details"></div> */}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <Layout>
      <div className="order-single-page">
        {renderBasedOnStatus()}
        {status !== LOADING && itemData?.menuItems?.length > 0 && (
          <Paginate
            totalItems={updateTotalItems()}
            pageSize={filter?.limit}
            currentPage={currentPage}
            onResult={onResult}
          />
        )}
      </div>
      {/* {popup && <AddStoreImagePopup id={id} setPopup={setPopup} />} */}
      {menuCatPopup && (
        <AddMenuItemPopup
          id={id}
          setPopup={setMenuCatPopup}
          restaurantBranchId={categoryData?.branch?.id}
          isEdit={isEdit}
          data={selectedCategory}
        />
      )}
    </Layout>
  );
};

export default MenuCategorySingle;
