export const ORDER_STATUS = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Inactive",
    value: "ACTIVE",
  },
  {
    label: "Out Of Stock",
    value: "OUT_OF_STOCK",
  },
];

export const ADD_PRODUCT_FIELD = [
  {
    name: "name",
    type: "text",
    label: "Product Name",
    required: false,
    valueAsNumber: false,
    placeholder: "Enter Product Name",
  },
  // {
  //   name: "sku",
  //   type: "text",
  //   label: "SKU",
  //   required: true,
  //   valueAsNumber: false,
  //   placeholder: "Enter SKU",
  // },
  // {
  //   name: "parentId",
  //   type: "text",
  //   label: "ParentId",
  //   required: false,
  //   valueAsNumber: false,
  //   placeholder: "Enter ParentId",
  // },
  // {
  //   name: "excerpt",
  //   type: "text",
  //   label: "Excerpt",
  //   required: true,
  //   valueAsNumber: false,
  //   placeholder: "Enter Excerpt",
  // },
  {
    name: "metaKeywords",
    type: "text",
    label: "Keyword",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Keyword",
  },
  {
    name: "description",
    type: "text",
    label: "Description",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Description",
  },
  // {
  //   name: "brandId",
  //   type: "text",
  //   label: "Brand",
  //   required: true,
  //   valueAsNumber: false,
  //   placeholder: "Enter Brand",
  // },
  {
    name: "price",
    type: "number",
    label: "Price",
    required: false,
    valueAsNumber: true,
    placeholder: "Enter Price",
  },
  // {
  //   name: "stock",
  //   type: "number",
  //   label: "Stock",
  //   required: true,
  //   valueAsNumber: false,
  //   placeholder: "Enter Product Quantity",
  // },
];

export const UNIT_ENUM = [
  {
    label: "Kilogram",
    value: "kg",
  },
  {
    label: "Centimeter",
    value: "cm",
  },
  {
    label: "Centilitre",
    value: "cl",
  },
  {
    label: "Milliliter",
    value: "ml",
  },
  {
    label: "Litre",
    value: "l",
  },
  {
    label: "gramme",
    value: "g",
  },
];