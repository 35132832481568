import "./OrderInformation.scss";

interface OrderSingleProps {
  riderInSupermarket?: string;
  orderInTransit?: string;
  orderStarted?: string;
  orderDelivered?: string;
  riderAccepted?: string;
  riderAssigned?: string;
  paymentReceived?: string;
  orderReceived?: string;
  dropperName?: string;
  packerName?: string;
}

const OrderHistory = (props: OrderSingleProps) => {
  const {
    riderInSupermarket,
    riderAssigned,
    paymentReceived,
    orderStarted,
    orderReceived,
    orderDelivered,
    orderInTransit,
    riderAccepted,
    dropperName,
    packerName,
  } = props;

  const getFormattedTime = (givenDate: any) => {
    const firstValueBeforeComma = givenDate.split(",")[0];
    const dateString = firstValueBeforeComma;
    const date = new Date(dateString);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`;

    return formattedTime;
  };

  const getTimeDifference = (startingDate: any, endingDate: any) => {
    const date1 = new Date(startingDate.split(",")[0]);
    const date2 = new Date(endingDate.split(",")[0]);

    const timeDifference = date2.getTime() - date1.getTime(); // Difference in milliseconds

    const milliseconds = timeDifference;
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedTimeDifference = `${hours} hrs, ${minutes % 60} mins, ${
      seconds % 60
    } sec`;

    return formattedTimeDifference;
  };

  const customerInformation = [
    {
      description: "Payment received",
      value: `${
        paymentReceived ? getFormattedTime(paymentReceived) : "In progress..."
      }`,
    },
    {
      description: "Order received",
      value: `${
        orderReceived ? getFormattedTime(orderReceived) : "In progress..."
      }`,
    },
    {
      description: "Rider Assigned",
      value: `${
        riderAssigned ? getFormattedTime(riderAssigned) : "In progress..."
      }`,
    },
    {
      description: "Rider accepted",
      value: `${
        riderAccepted ? getFormattedTime(riderAccepted) : "In progress..."
      }`,
    },
    {
      description: "Rider in supermarket",
      value: `${
        riderInSupermarket
          ? getFormattedTime(riderInSupermarket)
          : "In progress..."
      }`,
    },
    {
      description: "Order in transit",
      value: `${
        orderInTransit ? getFormattedTime(orderInTransit) : "In progress..."
      }`,
    },
    {
      description: "Order delivered",
      value: `${
        orderDelivered ? getFormattedTime(orderDelivered) : "In progress..."
      }`,
    },
  ];

  const orderSummary = [
    {
      description: "Time to pack and hand over to rider",
      value:  `${orderStarted && orderInTransit ? getTimeDifference(orderStarted, orderInTransit) : "In progress..."}`,
    },
    {
      description: "Time for rider to deliver to customer",
      value: `${orderInTransit && orderDelivered ? getTimeDifference(orderInTransit, orderDelivered) : "In progree..."}`,
    },
    {
      description: "Total Order Completion time",
      value: `${orderStarted && orderDelivered ? getTimeDifference(orderStarted, orderDelivered) : "In progress..."}`,
    },
    {
      description: "Dropper",
      value: dropperName === "undefined undefined" ? "Unassigned" : dropperName,
    },
    {
      description: "Super Packer",
      value: packerName === "undefined undefined" ? "Unassigned" : packerName,
    },
  ];

  return (
    <>
      <div className="order-information">
        <p className="type2 bold" style={{ marginTop: "20px" }}>
          ORDER TIMESTAMPS
        </p>
        <div className="store-branch-details">
          {customerInformation.map((info: any, index) => (
            <div
              className={
                info.imageSRC
                  ? "store-branch-detail images"
                  : "store-branch-detail"
              }
              key={index}
            >
              {info.imageSRC && (
                <img className="image" src={info.imageSRC} alt="image" />
              )}
              <div className="description">
                <p className="type3">{info.description}</p>
                <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                  {info.value}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="order-information">
        <p className="type2 bold" style={{ marginTop: "20px" }}>
          ORDER SUMMARY
        </p>
        <div className="store-branch-details">
          {orderSummary.map((info: any, index) => (
            <div
              className={
                info.imageSRC
                  ? "store-branch-detail images"
                  : "store-branch-detail"
              }
              key={index}
            >
              {info.imageSRC && (
                <img className="image" src={info.imageSRC} alt="image" />
              )}
              <div className="description">
                <p className="type3">{info.description}</p>
                <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                  {info.value}
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
