import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, TelephoneInput } from "../..";
import FileInput from "../../fileInput";
import Heading from "../../heading";
import Select from "../../select";
import "./CustomerModal.scss";
import { option } from "../../../../data/Data";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import axios from "axios";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import SuccessfulModal from "../successfulModal/SuccessfulModal";
import Alert from "../../alert";
import { getToken } from "../../../../storage";
import { getErrorMessage, getStores } from "../../../../helper";
import { toast } from "react-toastify";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface CustomerModal {
  setPopup?: any;
  isLoading?: any;
  cityOptions?: any;
  setState?: any;
  stateLoading?: boolean;
}

const CustomerModal = (props: CustomerModal) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const { setPopup, isLoading } = props;

  // const selectedOption = watch("role");

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const [imageStatus, setImageStatus] = useState(IDLE);
  const [imageURL, setImageURL] = useState();
  const [image, setImage] = useState("");

  const [stores, setStores] = useState();
  const [isStoresLoading, setIsStoresLoading] = useState(false);

  useEffect(() => {
    getStores(setStores, setIsStoresLoading);
  }, []);

  const uploadFile = async (files: any) => {
    setImageStatus(LOADING);
    setMessage("");
    const image = files[0];
    var formData = new FormData();
    formData.append("file", image);
    await axios({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploadFile,
      data: formData,
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      transformRequest: (formData) => formData,
    })
      .then((resp) => {
        setImageStatus(SUCCESS);
        setImage(`https://assets.getdropper.com/${resp.data.original}`);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message));
        // setMessage(err.response.message);
        setImageStatus(ERROR);
      });
  };

  const changeUserRole = (role: any) => {
    let neededRole;
    if (role === "SUPER_ADMIN") {
      neededRole = "Super Admin";
    } else if (role === "STORE_ADMIN") {
      neededRole = "Store Admin";
    } else if (role === "DROPPER") {
      neededRole = "Dropper";
    }

    return neededRole;
  };

  const addAdmin = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    data.password = "DropperAdmin";

    axios({
      method: apiQueryMethods?.POST,
      url: apiUrls?.register,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message)
        // setMessage(`${changeUserRole(data.role) + " "} succesfully added`);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const upload = (file: any) => {
    uploadFile(file);
    setImageURL(file);
  };

  const refresh = () => {
    window.location.reload();
  };

  const onSubmit = (data: any) => addAdmin(data);

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Add Admin"
            subTitle="Complete the form below to add a Admin"
          />
          {/* <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload company logo "
              onChange={upload}
            />
          </div> */}
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Input
              placeholder="Enter First Name"
              label="First Name"
              name="firstName"
              register={register}
              required
            />
            <Input
              placeholder="Enter Last Name"
              label="Last Name"
              name="lastName"
              register={register}
              required
            />
            <Input
              placeholder="Enter Email Address"
              label="Email Address"
              name="email"
              register={register}
              required
            />
            <TelephoneInput
              placeholder="Enter Phone Number"
              label="Phone Number"
              name="phone"
              register={register}
              required
            />
            {/* <Input
              placeholder="Enter Phone Number"
              label="Phone Number"
              name="phone"
              type="tel"
              register={register}
              required
            /> */}
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={option}
                  label="Select Role"
                  onChange={(val: any) => onChange(val.value)}
                  placeholder="Role"
                  // disabled={isImagesLoading}
                />
              )}
            />
            {/* {selectedOption === "STORE_ADMIN" && (
              <Controller
                name="storeBranch"
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    options={stores}
                    label="Select Store Branch"
                    onChange={(val: any) => onChange(val.value)}
                    placeholder="Store Branch"
                    disabled={isStoresLoading}
                  />
                )}
              />
            )} */}
            <Button
              className="add-button"
              text="Add Admin"
              // onClick={handleSubmit(onSubmit)}
              isLoading={status === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Admin"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default CustomerModal;
