import axios from "axios";
import { getToken } from "../../storage";

export const httpSignal = axios.CancelToken.source();

const logOutUser = () => {
  return (window.location.href = "/login/");
};

const CancelToken = axios.CancelToken;
let requestSignal;

// const config = {
//   apiGateway: {
//     BASE_URL: process.env.REACT_APP_DEV_API_BASE_URL,
//   },
// };
const config = {
  apiGateway: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
  },
};


const { BASE_URL } = config.apiGateway;

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use(
  (config) => {
    const accessToken = getToken();
    accessToken && (config.headers!.Authorization = "Bearer " + accessToken);
    requestSignal = CancelToken.source();
    config.cancelToken = requestSignal.token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    if (status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      logOutUser();
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default API;

export const axiosInstance = axios.create();

export const getData = async (url: string) => {
  const { data } = await axiosInstance({
    method: "get",
    url,
  });
  return data;
};

export const postData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data;
};

export const patchData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
  });
  return data;
};

export const putData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data;
};

export const deleteData = async (url: string, reqBody: {}) => {
  const { data } = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data;
};
