import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { getErrorMessage } from "../../../helper";
import Heading from "../../ui/heading";
import Select from "../../ui/select";
import { Button } from "../../ui";
import SuccessfulModal from "../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../utils/api/API";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

interface RoleModal {
  setPopup?: any;
  isLoading?: any;
  cityOptions?: any;
  setState?: any;
  data?: any;
  stateLoading?: boolean;
}

const RoleModal = (props: RoleModal) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const { setPopup, isLoading, data } = props;

  // const selectedOption = watch("role");

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const updateRole = async (request: any) => {
    setStatus(LOADING);
    setMessage("");

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getAUser(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setMessage(resp.data.message);
        // setMessage(`${changeUserRole(data.role) + " "} succesfully added`);
        reset();
      })
      .catch((err) => {
        setMessage(getErrorMessage(err.response.data.message));
        toast.error(getErrorMessage(err.response.data.message));
        setStatus(ERROR);
      });
  };

  const refresh = () => {
    window.location.reload();
  };

  const onSubmit = (data: any) => updateRole(data);

  const option = [
    {
      label: "Admin",
      value: "ADMIN",
    },
    {
      label: "Dropper",
      value: "DROPPER",
    },
    {
      label: "Super Packer",
      value: "STORE_ADMIN",
    },
    {
      label: "Super Admin",
      value: "SUPER_ADMIN",
    },
  ];

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Update User Role"
            subTitle="Complete the form below to update a user"
          />
          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={option}
                  label="Select Role"
                  onChange={(val: any) => onChange(val.value)}
                  placeholder="Role"
                  // disabled={isImagesLoading}
                />
              )}
            />
            <Button
              className="add-button"
              text="Add Admin"
              // onClick={handleSubmit(onSubmit)}
              isLoading={status === LOADING ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle={message}
          onClick={refresh}
        />
      )}
    </>
  );
};

export default RoleModal;
