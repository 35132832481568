import { useNavigate, useParams } from "react-router-dom";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import { useEffect, useState } from "react";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import CardLoader from "../../../../ui/cardLoader";
import EmptyView from "../../../../ui/emptyView";
import ErrorView from "../../../../ui/ErrorView";
import Layout from "../../../../ui/layout";
import { handlePrice } from "../../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const GSAPackageSinglePage = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [subStatus, setSubStatus] = useState(IDLE);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productPopup, setProductPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryProductList, setCategoryProductList] = useState<any>();
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });

  const [pageData, setPageData] = useState<any>({media:[]});

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const { id } = useParams();

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.category.id": id,
    isSortByCategory: true,
    isAdmin: true,
  };

  const handleProductEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setProductPopup(true);
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  const getSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getGsaPackages(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        setPageData(resp.data?.content);
        Object.keys(resp.data?.content).length === 0
          ? setStatus(NULLMODE)
          : setStatus(DATAMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting data");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getSingleData();
  }, []);

  const refresh = () => {
    window.location.reload();
  };
  const pageDetails = [
    {
      key: "Name",
      value: `${pageData?.name}`,
      className: "",
      image: `${
        pageData?.picture === null
          ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
          : pageData?.media[0]?.path
      }`,
    },
    {
      key: "Description",
      value: `${pageData?.description}`,
      className: "",
    },
    {
      key: "Archived?",
      value: `${pageData?.isArchived ? "Yes" : "No"}`,
      className: "",
    },
    {
      key: "Slot",
      value: `${pageData?.slots}`,
      className: "",
    },
    {
      key: "Available Slot",
      value: `${pageData?.availableSlots}`,
      className: "",
    },
    {
      key: "Price",
      value: `${handlePrice(pageData?.priceInMinor)}`,
      className: "",
    },
  ];

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's nothing here presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="store-branch-details">
              {pageDetails?.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {pageData?.products?.length > 0 && (
              <div className="table-details" style={{ marginTop: "24px" }}>
                <div className="table-details-body">
                  <div
                    className="details-table-header"
                    style={{ width: "100%" }}
                  >
                    <p style={{ width: "30%" }}>Product</p>
                    <p style={{ width: "30%" }}>Quantity</p>
                    {/* <p style={{ width: "10%" }}>Action</p> */}
                  </div>
                  {pageData?.products?.length > 0 &&
                    pageData?.products?.map((item: any, index: any) => (
                      <div className="details-table-body" key={index}>
                        <p style={{ width: "30%" }}>{item?.name}</p>
                        <p style={{ width: "30%" }}>{item?.quantity}</p>
                        <p
                          style={{
                            width: "10%",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        ></p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <Layout>
      <div className="order-single-page">
        <div className="page-header">
          <div style={{ width: "100%" }}>
            <span className="go-back" onClick={() => Navigate(-1)}>
              <img
                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                alt="arrow-back"
                className="arrow"
              />
              <h6>Go back</h6>
            </span>
            <div className="page-header">
              <h2>GSA Package Details</h2>
            </div>
          </div>
        </div>
        {renderBasedOnStatus()}
      </div>
    </Layout>
  );
};

export default GSAPackageSinglePage;
