import { useNavigate, useParams } from "react-router-dom";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import { useEffect, useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import { Button } from "../../ui";
import AddSubCategoryPopup from "./addSubCategoryPopup/AddSubCategoryPopup";
import { confirmAlert } from "react-confirm-alert";
import API from "../../../utils/api/API";
import { getErrorMessage, handlePrice } from "../../../helper";
import AddProductPopup from "../products/addProductPopup/AddProductPopup";
import Paginate from "../../ui/paginate";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const CategorySingle = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productStatus, setProductStatus] = useState(IDLE);
  const [subStatus, setSubStatus] = useState(IDLE);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productPopup, setProductPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryProductList, setCategoryProductList] = useState<any>();
  const [totalItems, setTotalItems] = useState(1);
  const [filter, updateFilter] = useState({
    limit: 20,
    page: 1,
  });
  const [subCategoryData, setSubCategoryData] = useState([
    {
      id: "",
      picture: "",
      isChild: "",
      name: "",
    },
  ]);
  const [categoryData, setCategoryData] = useState({
    id: "",
    name: "",
    isChild: "",
    description: "",
    picture: "",
    thumbnail: false,
  });

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const { id } = useParams();

  const updateTotalItems = () => {
    if (totalItems < 20) {
      return 20;
    } else {
      return totalItems;
    }
  };

  const params = {
    limit: filter.limit,
    page: filter.page,
    "filter.category.id": id,
    isSortByCategory: true,
    isAdmin: true,
  };

  const getSubCategoryProduct = () => {
    // setCategoryProductList([])
    setProductStatus(LOADING);
    API({
      method: apiQueryMethods?.GET,
      url: apiUrls.products,
      params: params,
    })
      .then((resp) => {
        setProductStatus(SUCCESS);
        setTotalItems(resp.data.data.meta.totalItems);
        const data = resp.data.data.products;
        setCategoryProductList(data);
        data ? setProductStatus(DATAMODE) : setProductStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting category");
        setProductStatus(ERROR);
      });
  };

  const handleProductEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedProduct(data);
    setProductPopup(true);
  };

  const onResult = (result: {
    totalItems: number;
    pageSize: number;
    currentPage: number;
  }) => {
    updateFilter({
      ...filter,
      page: result?.currentPage,
      // totalItems: result?.totalItems
    });
  };

  useEffect(() => {
    if (id) {
      getSubCategoryProduct();
    }
  }, [filter, id]);

  const getCategorySingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getCategory(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const data = resp.data.data;
        const formattedList = {
          id: data.id,
          isChild: data.isChild,
          name: data.name,
          description: data.description,
          picture: data.picture,
          thumbnail: data.thumbnail,
        };
        setCategoryData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting category");
        setStatus(ERROR);
      });
  };

  const subParams = {
    "filter.parent.id": id,
  };

  const getSubCategories = async () => {
    setSubStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getSubCategory,
      params: subParams,
    })
      .then((resp) => {
        setSubStatus(SUCCESS);
        const formattedList = resp.data.data.subCategories.map(
          (data: any, index: any) => {
            return {
              image: data.photo,
              id: data.id,
              isChild: data.isChild,
              name: data.name,
              description: data.description,
            };
          }
        );
        setSubCategoryData(formattedList);
        formattedList ? setSubStatus(DATAMODE) : setSubStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting sub category");
        setSubStatus(ERROR);
      });
  };

  const handleEditModal = (data: any) => {
    setIsEdit(true);
    setSelectedCategory(data);
    setPopup(true);
  };

  const deleteCategory = async (id: any) => {
    // setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.DELETE,
      url: apiUrls?.getCategory(id),
    })
      .then((resp) => {
        // setStatus(SUCCESS);
        getSubCategories();
      })
      .catch((err) => {
        // setStatus(ERROR);
        setMessage(getErrorMessage(err.response.data.message));
      });
  };

  const handleDeleteCategory = (id: any) => {
    confirmAlert({
      title: "Are you sure you want to delete this sub category?",
      message:
        "This sub category’s data will be erased and you will not be able to retrieve them",
      buttons: [
        {
          label: "Cancel",
          // Do Close operation
        },
        {
          label: "Delete",
          onClick: () => deleteCategory(id),
        },
      ],
    });
  };

  useEffect(() => {
    if (id) {
      getCategorySingleData();
      getSubCategories();
    }
  }, [id]);

  const refresh = () => {
    window.location.reload();
  };
  const categoryDetails = [
    {
      key: "Name",
      value: `${categoryData.name}`,
      className: "",
      image: `${
        categoryData.picture === null
          ? "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
          : categoryData.picture
      }`,
    },
    {
      key: "Description",
      value: `${categoryData.description}`,
      className: "",
    },
  ];

  const handleClick = (id: any) => {
    Navigate(`/category/${id}`);
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no category presently" />
          </div>
        );

      case DATAMODE:
        return (
          <>
            <div className="store-branch-details">
              {categoryDetails.map((item, index) => (
                <div className="store-branch-detail" key={index}>
                  {item.image ? (
                    <div className="store-branch-image">
                      <img src={item.image} alt={item.className} />
                      <div className="store-branch-info">
                        <p className="type3">{item.key}</p>
                        <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                          {item.value}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="store-branch-info">
                      <p className="type3">{item.key}</p>
                      <h5 style={{ fontWeight: "bold", margin: "10px 0 0" }}>
                        {item.value}
                      </h5>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {!categoryData.isChild && (
              <>
                <div className="page-header" style={{ marginTop: "50px" }}>
                  <h2>Sub-category Details</h2>
                </div>
                {subCategoryData.length < 1 ? (
                  <div className="cards loading">
                    <EmptyView message="There's no sub-category here yet" />
                  </div>
                ) : (
                  <>
                    <div className="cat-cards">
                      {subCategoryData?.map((data, index) => {
                        const { name, id } = data;
                        return (
                          <div
                            className="branch-card"
                            key={index}
                            style={{ position: "relative" }}
                          >
                            <div className="head">
                              <img
                                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1666004168/internal-dashboard/overview-2_wwccrg.png"
                                alt="store-branch"
                              />
                            </div>
                            <h3>{name}</h3>

                            <div
                              className="action-btn"
                              style={{
                                display: "flex",
                                gap: "24px",
                                alignItems: "center",
                                position: "absolute",
                                top: "24px",
                                right: "24px",
                              }}
                            >
                              <img
                                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1691502943/edit_zbcdba.svg"
                                alt="Edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(id)}
                              />
                              <img
                                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1695314935/internal-dashboard/edit_rjgkyr.svg"
                                alt="Edit"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEditModal(data)}
                              />
                              <img
                                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1691502943/delete_obm6px.svg"
                                alt="Delete"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDeleteCategory(data.id)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
            {categoryData.isChild && (
              <div
                style={{
                  marginTop: "24px",
                  display: "flex",
                  gap: "24px",
                  flexDirection: "column",
                }}
              >
                <h5 style={{ fontWeight: "bold", margin: "30px 0 0" }}>
                  Products
                </h5>
                <div className="table-details">
                  <div className="table-details-body">
                    <div
                      className="details-table-header"
                      style={{ width: "100%" }}
                    >
                      <p style={{ width: "30%" }}>Product</p>
                      <p style={{ width: "30%" }}>Category Score</p>
                      <p style={{ width: "30%" }}>Price</p>
                      <p style={{ width: "10%" }}>Action</p>
                    </div>
                    {categoryProductList?.length > 0 &&
                      categoryProductList.map((item: any, index: any) => (
                        <div className="details-table-body" key={index}>
                          <p style={{ width: "30%" }}>{item?.name}</p>
                          <p style={{ width: "30%" }}>{item?.categoryScore}</p>
                          <p style={{ width: "30%" }}>
                            {handlePrice(item?.price)}
                          </p>
                          <p
                            style={{
                              width: "10%",
                              fontSize: "12px",
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <span
                              style={{
                                color: "#32BA56",
                                padding: "4px 8px",
                                background: "#E4F4E6",
                                // border: "0.5px solid #32BA56",
                                borderRadius: "4px",
                              }}
                              onClick={() => handleProductEditModal(item)}
                            >
                              Edit
                            </span>
                          </p>
                        </div>
                      ))}
                    {productStatus !== LOADING &&
                      categoryProductList.length > 0 && (
                        <Paginate
                          totalItems={updateTotalItems()}
                          pageSize={filter?.limit}
                          currentPage={filter?.page}
                          onResult={onResult}
                        />
                      )}
                  </div>
                </div>
              </div>
            )}
          </>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <>
      <div className="order-single-page">
        <div className="page-header">
          <div style={{ width: "100%" }}>
            <span className="go-back" onClick={() => Navigate(-1)}>
              <img
                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                alt="arrow-back"
                className="arrow"
              />
              <h6>Go back</h6>
            </span>
            <div className="page-header">
              <h2>
                {!categoryData.isChild
                  ? "Category Details"
                  : "Sub-category Details"}
              </h2>
              {!categoryData.isChild && (
                <Button
                  text="Add a Sub-category"
                  className="customer-add"
                  onClick={() => {
                    setPopup(true);
                    setIsEdit(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {renderBasedOnStatus()}
      </div>
      {popup && (
        <AddSubCategoryPopup
          categoryId={id}
          setPopup={setPopup}
          isEdit={isEdit}
          data={selectedCategory}
          getSubCategory={getSubCategories}
        />
      )}
      {productPopup && (
        <AddProductPopup
          getProducts={getSubCategoryProduct}
          setPopup={setProductPopup}
          data={selectedProduct}
          isEdit={isEdit}
          isSubCat
          //   selectedId={selectedId}
        />
      )}
      {productStatus === LOADING && (
        <div
          className="popup"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardLoader />{" "}
        </div>
      )}
    </>
  );
};

export default CategorySingle;
