import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { Button, Input } from "../../../../../ui";
import Heading from "../../../../../ui/heading";
// import "./AddCategoryPopup.scss";
import { dataQueryStatus } from "../../../../../../utils/dataQueryStatus";
import { apiQueryMethods, apiUrls } from "../../../../../../utils/api";
import SuccessfulModal from "../../../../../ui/modal/successfulModal/SuccessfulModal";
import { ADD_VOUCHER_FIELD } from "./enum";
import Select from "../../../../../ui/select";
import Checkbox from "../../../../../ui/checkbox";
import Alert from "../../../../../ui/alert";
import { defaultOption, getErrorMessage } from "../../../../../../helper";
import API from "../../../../../../utils/api/API";
import { toast } from "react-toastify";

interface ModalProps {
  setPopup: any;
  getVouchers: Function;
  data?: any;
  isEdit?: boolean;
  selectedId?: string;
}

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddVoucherPopup = (props: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch,
  } = useForm();

  const selectedOption = watch("hasPromo");
  const isClicked = watch("multiple");

  const { setPopup, getVouchers, data, isEdit, selectedId } = props;

  const type = [
    {
      label: "Fixed",
      value: "FIXED",
    },
    {
      label: "Percentage",
      value: "PERCENTAGE",
    },
    // {
    //   label: "Free Delivery",
    //   value: "FREE_DELIVERY",
    // },
  ];

  const cdm = [
    {
      label: "Free Delivery",
      value: "FREE_DELIVERY",
    },
  ];

  const [imageFile, setImageFile] = useState("");
  const [feeId, setFeeId] = useState("");

  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");
  const [clicked, setClicked] = useState(false);

  const addVoucher = async (data: any) => {
    setStatus(LOADING);
    // data.min = data.min * 100;
    // data.max = data.max * 100;
    data.value = data.type === "PERCENTAGE" ? data.value : data.value * 100;
    setMessage("");
    API({
      method: apiQueryMethods?.POST,
      url: data.multiple ? apiUrls?.multipleVouchers : apiUrls.vouchers,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
      })
      .catch((err) => {
        setStatus(ERROR);
        setAlert(true);
        toast.error(getErrorMessage(err.response.data.message))
        setMessage(err.response.data.error);
      });
  };

  const updateVoucher = (request: any) => {
    setStatus(LOADING);
    // request.min = request.min * 100;
    // request.max = request.max * 100;
    request.value =
      request.type === "PERCENTAGE" ? request.value : request.value * 100;
    setMessage("");

    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.getVouchers(data?.id),
      data: request,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        getVouchers();
      })
      .catch((err) => {
        toast.error(getErrorMessage(err.response.data.message))
        setMessage(err.response.data.error);
        setStatus(ERROR);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateVoucher(data) : addVoucher(data);
  };

  const convertDate = (isoDateString: any) => {
    const date = new Date(isoDateString);

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    return formattedDate;
  };

  useEffect(() => {
    if (isEdit) {
      const {
        name,
        code,
        type,
        value,
        startDate,
        endDate,
        isUnique,
        useCount,
        validOn,
        isForDelivery,
      } = data || {};
      setValue("name", name);
      setValue("code", code);
      setValue("type", type);
      setValue("startDate", convertDate(startDate));
      setValue("endDate", convertDate(endDate));
      setValue("isUnique", isUnique);
      setValue("useCount", useCount);
      setValue("validOn", validOn);
      setValue("isForDelivery", isForDelivery);
      setValue("value", type === "PERCENTAGE" ? value : value / 100);
    }
  }, [data, isEdit]);

  const refresh = () => {
    window.location.reload();
  };

  

  

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title={isEdit ? "Update Voucher" : "Add Voucher"}
            subTitle={
              isEdit
                ? "Enter the following details to update a Voucher"
                : "Enter the following details to add a Voucher"
            }
          />

          <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
            {ADD_VOUCHER_FIELD.map((field, index) => (
              <Input
                register={register}
                label={field.label}
                name={field.name}
                required={field.required}
                placeholder={field.placeholder}
                type={field.type}
                valueAsNumber={field.valueAsNumber}
                key={index}
              />
            ))}
            <Controller
              name="type"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={type}
                  value={isEdit ? defaultOption(type, data.type) : ""}
                  label="Select Voucher Type"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            />
            {/* <Controller
              name="cdMeanings"
              control={control}
              render={({ field: { onChange } }) => (
                <Select
                  options={cdm}
                  value={isEdit ? defaultOption(cdm, data.cdMeaning) : ""}
                  label="Custom Voucher Meaning"
                  onChange={(val: any) => onChange(val.value)}
                />
              )}
            /> */}

            <Checkbox
              label="Unique?"
              // onChange={() => setClicked(!clicked)}
              name="isUnique"
              register={register}
            />

            <Checkbox
              label="For Delivery?"
              // onChange={() => setClicked(!clicked)}
              name="isForDelivery"
              register={register}
            />

            {!isEdit && (
              <Checkbox
                label="Multiple?"
                onChange={() => setClicked(!clicked)}
                name="multiple"
                register={register}
              />
            )}

            {!isEdit && isClicked && (
              <Input
                register={register}
                label="Number of voucher"
                name="count"
                required
                placeholder="Enter number of voucher"
                type="number"
                valueAsNumber
              />
            )}
            <Button
              className="add-button"
              text={isEdit ? "Update Voucher" : "Add Voucher"}
              isLoading={status === "LOADING" ? true : false}
            />
          </form>
        </div>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added a Voucher to your dashboard."
          onClick={refresh}
        />
      )}
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Voucher"
          text={message}
          timeOut={3000}
          alertOut={setStatus}
        />
      )} */}
    </>
  );
};

export default AddVoucherPopup;
