import AddRider from '../components/dashboard/addRider/AddRider'
import Layout from '../components/ui/layout'

const AddRiderPage = () => {
  return (
    <Layout>
        <AddRider />
    </Layout>
  )
}

export default AddRiderPage