export const ADD_FEE_FIELD = [
  {
    name: "value",
    type: "number",
    label: "Value",
    required: true,
    valueAsNumber: true,
    placeholder: "Enter Value",
  },
  {
    name: "min",
    type: "number",
    label: "Minimum",
    required: false,
    valueAsNumber: true,
    placeholder: "Enter Minimum",
  },
  {
    name: "max",
    type: "number",
    label: "Maximum",
    required: false,
    valueAsNumber: true,
    placeholder: "Enter Maximum",
  },
];
