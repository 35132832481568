import MaterialTable from "material-table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EmptyView from "../../emptyView";
// import CustomerModal from "../../modal/customerModal/CustomerModal";
// import "./CustomersTable.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CardLoader from "../../cardLoader";
import ErrorView from "../../ErrorView";

interface TableProps {
  arrange?: any;
  data: Array<object>;
  clickable?: boolean;
  paging?: boolean;
  emptyHeight: string;
  isCardLoading?: boolean;
  message?: string;
  dataNull?: boolean;
  isCardError?: boolean;
  deleteCategory: Function;
  handleEditModal: Function;
}

const CategoryTable = (props: TableProps) => {
  const [actionClicked, setActionClicked] = useState("");
  const {
    data,
    paging,
    emptyHeight,
    clickable,
    isCardLoading,
    arrange,
    message,
    isCardError,
    dataNull,
    deleteCategory,
    handleEditModal,
  } = props;
  const Navigate = useNavigate();

  const refresh = () => {
    window.location.reload();
  };

  const categoryShort = (orderId: any) => {
    if (orderId.length > 5) {
      orderId = orderId.substring(0, 8) + "...";
    }
    return orderId;
  };

  const handleImage = (image: any) => {
    if (image === null) {
      image =
        "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg";
    } else {
      image = image;
    }
    return image;
  };

  function capitalizeEachWord(inputString: any) {
    if (typeof inputString !== "string") {
      return ""; // Return an empty string for non-string inputs
    }

    // Split the input string into an array of words
    const words = inputString.trim().split(/\s+/);

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return "";
    });

    // Join the capitalized words back to form the final string
    return capitalizedWords.join(" ");
  }

  const tableColumnData = [
    {
      title: "",
      field: "image",
      render: (rowData: any) => (
        <div
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
            overflow: "hidden",
            background: `${rowData.color}`,
          }}
        >
          <img
            style={{ width: "100%" }}
            src={handleImage(rowData.picture)}
            alt="img"
          />
        </div>
      ),
    },
    {
      title: "Name",
      field: "name",
      render: (rowData: any) => (
        <div>
          <p style={{ whiteSpace: "nowrap" }}>
            {capitalizeEachWord(rowData.name)}
          </p>
        </div>
      ),
    },
    {
      title: "Category Id",
      field: "categoryId",
      render: (rowData: any) => (
        <div>
          <p className="Type3" style={{ whiteSpace: "nowrap" }}>
            {categoryShort(rowData.id)}
          </p>
        </div>
      ),
    },
    {
      title: "Slug",
      field: "slug",
      render: (rowData: any) => (
        <div>
          <p style={{ whiteSpace: "nowrap" }}>{rowData.slug}</p>
        </div>
      ),
    },
    {
      title: "Description",
      field: "description",
      render: (rowData: any) => (
        <div style={{ width: "200px" }}>
          <p style={{ whiteSpace: "nowrap" }}>{rowData.description}</p>
        </div>
      ),
    },
  ];

  return (
    <div className="orders-table-section">
      <div className="orders-table">
        {data.length >= 1 ? (
          <div className="">
            <MaterialTable
              title=""
              options={{
                paging: paging,
                search: false,
                actionsColumnIndex: -1,
                headerStyle: {
                  color: "#FFF",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                },
              }}
              onRowClick={(event, rowData: any) => {
                clickable && Navigate(`${rowData.id}`);
              }}
              columns={tableColumnData}
              data={data}
              actions={[
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#1A1B1A",
                        padding: "4px",
                        background: "#11233710",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #11233730",
                      }}
                    >
                      Update
                    </div>
                  ),
                  tooltip: "Update Category",
                  onClick: (event, rowData) => {
                    handleEditModal(rowData);
                  },
                },
                {
                  icon: () => (
                    <div
                      className="suspend"
                      style={{
                        color: "#EB5757",
                        padding: "4px",
                        background: "#FEEEEF",
                        fontSize: "10px",
                        borderRadius: "4px",
                        border: "1px solid #EE273730",
                      }}
                    >
                      Delete
                    </div>
                  ),
                  tooltip: "delete Category",
                  onClick: (event, rowData) => {
                    confirmAlert({
                      title: "Are you sure you want to delete this category?",
                      message:
                        "This category’s data will be erased and you will not be able to retrieve them",
                      buttons: [
                        {
                          label: "Cancel",
                          // Do Close operation
                        },
                        {
                          label: "Delete",
                          onClick: () => deleteCategory(rowData.id),
                        },
                      ],
                    });
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div className="empty-view" style={{ height: `${emptyHeight}` }}>
            {isCardLoading && <CardLoader />}
            {dataNull && (
              <EmptyView message="You do not have any category at the moment." />
            )}
            {isCardError && (
              <ErrorView message={message} handleRetry={refresh} />
            )}
          </div>
        )}
      </div>
      {/* {actionClicked === "Pocket" && <CustomerModal  />} */}
    </div>
  );
};

export default CategoryTable;
