export const ADD_VOUCHER_FIELD = [
  {
    name: "name",
    type: "text",
    label: "Name",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Name",
  },
  {
    name: "value",
    type: "number",
    label: "Value",
    required: true,
    valueAsNumber: true,
    placeholder: "Enter Value",
  },
  {
    name: "code",
    type: "text",
    label: "Code",
    required: false,
    valueAsNumber: false,
    placeholder: "Enter Code",
  },
  {
    name: "validOn",
    type: "number",
    label: "Valid On",
    required: false,
    valueAsNumber: true,
    placeholder: "Enter Value",
  },
  {
    name: "useCount",
    type: "number",
    label: "Use Count",
    required: true,
    valueAsNumber: true,
    placeholder: "Enter Value",
  },
  {
    name: "startDate",
    type: "date",
    label: "Start Date",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Date",
  },
  {
    name: "endDate",
    type: "date",
    label: "End Date",
    required: true,
    valueAsNumber: false,
    placeholder: "Enter Date",
  },
];
