import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiQueryMethods, apiUrls } from "../../../utils/api";
import { dataQueryStatus } from "../../../utils/dataQueryStatus";
import CardLoader from "../../ui/cardLoader";
import EmptyView from "../../ui/emptyView";
import ErrorView from "../../ui/ErrorView";
import AddProductImagePopup from "../products/addProductPopup/AddProductImagePopup";
import CatAndStore from "./productDetails/CatAndStore";
import ProductInformation from "./productDetails/ProductInformation";
import API from "../../../utils/api/API";

const { IDLE, LOADING, SUCCESS, ERROR, DATAMODE, NULLMODE } = dataQueryStatus;

const ProductSingle = () => {
  const Navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(0);
  const [popup, setPopup] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [productData, setProductData] = useState({
    id: "",
    createdAt: "",
    description: "",
    metaKeywords: "",
    dropStockOnPurchase: false,
    excerpt: "",
    hasCoupon: false,
    hasPromo: false,
    isHeavy: false,
    name: "",
    size: "",
    m_unit: "",
    picture: "",
    dropperPrice: "",
    price: "",
    priceDiscount: "",
    sku: "",
    slug: "",
    status: "",
    stock: "",
    thumbnail: "",
    categoryId: "",
    categoryName: "",
    subCategoryName: "",
    categorySlug: "",
    categoryThumbnail: "",
    storeBranchId: "",
    storeBranchName: "",
  });
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState(false);

  const { id } = useParams();

  const getProductSingleData = async () => {
    setStatus(LOADING);
    await API({
      method: apiQueryMethods?.GET,
      url: apiUrls.getProduct(id),
    })
      .then((resp) => {
        setStatus(SUCCESS);
        const data = resp.data.data;
        const formattedList = {
          id: data?.id,
          createdAt: data?.createdAt,
          description: data?.description,
          metaKeywords: data?.metaKeywords,
          dropStockOnPurchase: data?.dropStockOnPurchase,
          excerpt: data?.excerpt,
          hasCoupon: data?.hasCoupon,
          hasPromo: data?.hasPromo,
          isHeavy: data?.isHeavy,
          name: data?.name,
          size: data?.size,
          m_unit: data?.m_unit,
          picture: data?.picture,
          dropperPrice: data?.dropperPrice,
          price: data?.price,
          priceDiscount: data?.priceDiscount,
          sku: data?.sku,
          slug: data?.slug,
          status: data?.status,
          stock: data?.stock,
          thumbnail: data?.thumbnail,
          categoryId: data?.category?.id,
          subCategoryName: data?.category?.name,
          categoryName: data?.category?.parent?.name,
          categorySlug: data?.category?.slug,
          categoryThumbnail: data?.category?.thumbnail,
          storeBranchId: data?.storeBranch?.id,
          storeBranchName: data?.storeBranch?.name,
        };
        setProductData(formattedList);
        formattedList ? setStatus(DATAMODE) : setStatus(NULLMODE);
      })
      .catch((err) => {
        setAlert(true);
        setMessage("Error getting product");
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    if (id) {
      getProductSingleData();
    }
  }, [id]);

  const refresh = () => {
    window.location.reload();
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return (
          <div className="cards loading">
            <CardLoader />
          </div>
        );

      case NULLMODE:
        return (
          <div className="cards loading">
            <EmptyView message="There's no store branch presently" />
          </div>
        );

      case DATAMODE:
        return (
          <div className="order-details">
            <div className="details-navigation">
              <div className="navbars">
                {navigation.map((item: any, index) => (
                  <p
                    className={`navbar ${activeNav === index ? "active" : ""}`}
                    onClick={() => handleNav(index)}
                    key={index}
                  >
                    {item}
                  </p>
                ))}
              </div>
              <p className="type3 order-id">{productData.id}</p>
            </div>
            {activeNav === 0 ? (
              <ProductInformation
                id={productData.id}
                createdAt={productData.createdAt}
                description={productData.description}
                metaKeywords={productData.metaKeywords}
                dropStockOnPurchase={productData.dropStockOnPurchase}
                excerpt={productData.excerpt}
                hasCoupon={productData.hasCoupon}
                hasPromo={productData.hasPromo}
                isHeavy={productData.isHeavy}
                name={productData.name}
                picture={productData.picture}
                dropperPrice={productData.dropperPrice}
                price={productData.price}
                priceDiscount={productData.priceDiscount}
                sku={productData.sku}
                slug={productData.slug}
                size={productData.size}
                m_unit={productData.m_unit}
                status={productData.status}
                stock={productData.stock}
                thumbnail={productData.thumbnail}
                storeBranchId={productData.storeBranchId}
              />
            ) : (
              <CatAndStore
                categoryId={productData.categoryId}
                categoryName={productData.categoryName}
                subCategoryName={productData.subCategoryName}
                categoryThumbnail={productData.categoryThumbnail}
                storeBranchId={productData.storeBranchId}
                storeBranchName={productData.storeBranchName}
              />
            )}
          </div>
        );

      case ERROR:
        return (
          <div className="cards loading">
            <ErrorView message={message} handleRetry={refresh} />
          </div>
        );

      default:
        return "";
    }
  };

  const handleNav = (index: any) => {
    if (activeNav === index) {
      setActiveNav(0);
    }
    setActiveNav(index);
  };

  const navigation = ["Product Information", "Category and Store Branch"];
  return (
    <>
      <div className="order-single-page">
        <div className="page-header">
          <div style={{ width: "100%" }}>
            <span className="go-back" onClick={() => Navigate(-1)}>
              <img
                src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685703787/internal-dashboard/arrow-square-left_wiuaz5.svg"
                alt="arrow-back"
                className="arrow"
              />
              <h6>Go back</h6>
            </span>
            <div className="page-header">
              <h2>Product Details</h2>
                {/* <Button
                  text="Add Product Image"
                  className="customer-add"
                  onClick={() => {
                    setPopup(true);
                  }}
                /> */}
            </div>
          </div>
        </div>
        {renderBasedOnStatus()}
      </div>
      {popup && (
        <AddProductImagePopup
          id={id}
          setPopup={setPopup}
          storeBranchId={productData.storeBranchId}
        />
      )}
    </>
  );
};

export default ProductSingle;
