import { useState } from "react";
import { apiQueryMethods, apiUrls } from "../../../../utils/api";
import { dataQueryStatus } from "../../../../utils/dataQueryStatus";
import { Button } from "../../../ui";
import Alert from "../../../ui/alert";
import FileInput from "../../../ui/fileInput";
import Heading from "../../../ui/heading";
import SuccessfulModal from "../../../ui/modal/successfulModal/SuccessfulModal";
import API from "../../../../utils/api/API";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../helper";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const AddStoreImagePopup = (props: { id?: string; setPopup?: any }) => {
  const { id, setPopup } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [imageURL, setImageURL] = useState();
  const [message, setMessage] = useState("");
  const [imageFile, setImageFile] = useState([]);
  const [status, setStatus] = useState(IDLE);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (file: any) => {
    const filesArray = Array.from(file);
    setSelectedFiles(filesArray as File[]);
  };


  const refresh = () => {
    window.location.reload();
  };

  const filter = {
    storeId: id,
  };

  const uploadImage = async () => {
    setStatus(LOADING);
    setMessage("");
    const image = imageFile;
    var formData = new FormData();
    // formData.append("file", image);
    if (image && image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        const file = image[i];
        formData.append('files', file); // Append the file and its filename (file.name) to the FormData
      }
    }
    // selectedFiles.forEach((file) => {
    //   formData.append("file", file);
    // });
    await API({
      method: apiQueryMethods?.POST,
      url: apiUrls?.uploaMultipleFile,
      data: formData,
      params: filter,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        // refresh();
      })
      .catch((err) => {
        // setMessage(err.response.message);
        toast.error(getErrorMessage(err.response.data.message))
        setStatus(ERROR);
      });
  };

  const upload = (file: any) => {
    setImageFile(file);
    setImageURL(file);
  };

  return (
    <>
      <div className="popup">
        <div className="popup-container">
          <img
            src="https://res.cloudinary.com/dm19qay3n/image/upload/v1685958252/internal-dashboard/close-new_kuhyix.svg"
            alt="close"
            className="close"
            onClick={() => setPopup(false)}
          />
          <Heading
            className="popup-header"
            title="Add Store Image"
            subTitle="Enter the following details to add a Store"
          />
          <div className="avatar-container">
            <div className="avatar">
              <img
                src={
                  imageURL
                    ? (window.URL ? URL : webkitURL).createObjectURL(
                        imageURL[0]
                      )
                    : "https://res.cloudinary.com/dm19qay3n/image/upload/v1685703775/internal-dashboard/profilePicture_idhxy1.svg"
                }
                alt=""
              />
            </div>
            <FileInput
              labelText="Tap to upload an image to this store branch "
              onChange={setImageFile}
              multiple
            />
          </div>
          <Button
            text="Upload"
            isLoading={status === LOADING ? true : false}
            onClick={uploadImage}
          />
        </div>
      </div>
      {/* {status === ERROR && (
        <Alert
          title="Error Adding Image"
          text=""
          alertOut={setStatus}
          timeOut={3000}
        />
      )} */}
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully added an image to this store branch."
          onClick={refresh}
        />
      )}
    </>
  );
};

export default AddStoreImagePopup;
