import { useState } from "react";
import "./Security.scss";
import ChangePasswordModal from "./ChangePasswordModal";
import Heading from "../../../../ui/heading";
import { Button, Input } from "../../../../ui";
import { useForm } from "react-hook-form";
import API from "../../../../../utils/api/API";
import { apiQueryMethods, apiUrls } from "../../../../../utils/api";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../../helper";
import { dataQueryStatus } from "../../../../../utils/dataQueryStatus";
import SuccessfulModal from "../../../../ui/modal/successfulModal/SuccessfulModal";

const { IDLE, LOADING, SUCCESS, ERROR } = dataQueryStatus;

const Security = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [status, setStatus] = useState(IDLE);
  const [message, setMessage] = useState("");

  const changepassword = async (data: any) => {
    setStatus(LOADING);
    setMessage("");
    API({
      method: apiQueryMethods?.PATCH,
      url: apiUrls?.changePassword,
      data,
    })
      .then((resp) => {
        setStatus(SUCCESS);
        reset();
      })
      .catch((err) => {
        setStatus(ERROR);
        toast.error(getErrorMessage(err.response.data.message));
        setMessage(err.response.data.error);
      });
  };

  const onSubmit = (data: any) => changepassword(data);

  const refresh = () => {
    window.location.reload();
  };
  return (
    <>
      <Heading
        className="popup-header"
        title="Change Password"
        subTitle="Enter the following details to change your password"
      />
      <div className="security-container">
        <form className="add-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Old Password"
            register={register}
            name="currentPassword"
            required
            placeholder="Enter Old Password"
            type="text"
          />
          <Input
            label="New Password"
            register={register}
            name="newPassword"
            required
            placeholder="Enter New Password"
            type="text"
          />
          <Input
            label="Confirm New Password"
            register={register}
            name="newPasswordConfirm"
            required
            placeholder="Enter new Password Again"
            type="text"
          />
          <Button
            className="add-button"
            text="Save Changes"
            isLoading={status === LOADING ? true : false}
          />
        </form>
      </div>
      {status === SUCCESS && (
        <SuccessfulModal
          title="Successful!"
          subTitle="You have successfully changed your password."
          onClick={refresh}
        />
      )}
    </>
  );
};

export default Security;
